import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { BackButton } from '../../../../../components/buttons/BackButton';
import Stepper from '../../../../../components/stepper/Stepper';
import { Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FadeIn } from '../../../../animations/FadeIn';
import * as Yup from "yup";
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { getErrorMessage, getNestedTouchedTrue, getObjectDifference } from '../../../../../utils/utils';
import { useToast } from '../../../../../context/ToastContext';
import { useNavigate, useParams } from 'react-router-dom';
import { phoneRegExp } from '../../../../../utils/consts';
import { SFCVenue, VenueContact } from '../../../../../types/place.types';
import { useGetAllZipcodesQuery, useLazyGetAllZipcodesQuery } from '../../../../../services/endpoints/places/zipcode';
import { useCreateVenueMutation, useGetVenueQuery, useUpdateVenueMutation } from '../../../../../services/endpoints/places/venue';
import { VenueForm } from './VenueForm';

const steps = [
  {name: 'Details', form: VenueForm},
];

export interface FormValues {
  id?: number;
  title: string;
  is_active: boolean;
  location_id: {value: number, label: string};
  organization_ids: {value: number, label: string}[];
  address: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  notes?: string;
  facility_use_fee_payment_details?: string;
  contacts: VenueContact[];
}

type Overwrite<T, NewT> = Omit<T, keyof NewT> & NewT;

export type CreateFormValues = Overwrite<FormValues, {
  organization_ids: number[],
  location_id: number, 
}>

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values?: FormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
}

export const ContactSchema = Yup.object({
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  title: Yup.string().optional(),
  phone_number: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(10, 'Phone number should have at least 10 digits')
    .optional(),
  email: Yup.string().email('Email is invalid').required('Email is required'),
  should_be_sent_roster: Yup.boolean().required().default(false),
  should_be_sent_invoices: Yup.boolean().required().default(false),
  should_be_sent_bulk_welcomes: Yup.boolean().required().default(false), 
  should_be_sent_bulk_thanks: Yup.boolean().required().default(false),
});

const formatFormValue = (formValue?: FormValues | SFCVenue): CreateFormValues => {
  return JSON.parse(JSON.stringify(formValue)) as CreateFormValues;
}

interface AddEditVenueProps {
  editing?: boolean;
}

export const AddEditVenue: React.FC<AddEditVenueProps> = ({ editing }) => {
  const params = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const navigate = useNavigate();
  
  const [createVenue] = useCreateVenueMutation();
  const [updateVenue] = useUpdateVenueMutation();
  const { data, error, isLoading }  = useGetVenueQuery(Number(params.id!), { skip: !editing || !params.id });
  
  const [claimedZips, setClaimedZips] = useState<string[]>([]);
  const { data: claimedZipCodes, error: claimedZipsError, isLoading: claimedZipsLoading } = useGetAllZipcodesQuery({scope: 'taken'});
  const [ fetchAvailableZipCodes ] = useLazyGetAllZipcodesQuery();

  useEffect(() => {
    if (claimedZipCodes && claimedZipCodes.results) {
      setClaimedZips(claimedZipCodes.results.map(z => z.code))
    }
  }, [claimedZipCodes]);

  const getZipAvailable = async (value: any, context: any) => {
    if (typeof value !== "string" || value.length !== 5)
      return false;
  
    const { location_id: {value: location_id} } = context.parent;
    let claimedZip = claimedZips.includes(value);
    if (claimedZip) { 
      if (location_id) {
        let payload = {data: {filterBy: [{field: 'location_id', operator: 'equals', value: location_id}]}};
        let availableZips = await fetchAvailableZipCodes(payload, true).unwrap();
        let available = availableZips?.results?.map(z => z.code).includes(value);
        if (available) {
          // claimed by this location/franchise
          return true;
        }
        else {
          // claimed by another location/franchise
          return context.createError({message: "This slice of the pie 🥧 has already been taken"});
        }
      }
      else {
        // claimed, but location has not been set
        return context.createError({message: "Please select a location before entering the zip code"});
      }
    }
    else {
      // unclaimed, warn, but ok
      return context.createError({message: "Warning: This zip is outside this location's purchased territories"});
    }
  }

  const [initialValues, setInitialValues] = useState<FormValues>({
    title: "",
    is_active: true,
    location_id: {value: 0, label: ''},
    organization_ids: [],
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    notes: "",
    facility_use_fee_payment_details: "",
    contacts: [],
  });

  const validationSchemas = [
    Yup.object({
      title: Yup.string().required('Title is required'),
      is_active: Yup.boolean().required().default(true),
      location_id: Yup.object({value: Yup.number().required(), label: Yup.string().optional()}).required('Location is required'),
      organization_ids: Yup.array().of(Yup.object({value: Yup.number().positive().required(), label: Yup.string().optional()})).optional(),
      address: Yup.string().optional(),
      address2: Yup.string().optional(),
      city: Yup.string().required('City is required'),
      state: Yup.string().required('State is required'),
      zip: Yup.string().matches(/^\d{5}$/, 'Value must be exactly 5 digits'), //TODO: is not working the zipcode validation: //test('is-available', 'Unknown error occurred', getZipAvailable).required('Zip Code is required'),
      phone: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .min(10, 'Phone number should have at least 10 digits')
        .optional(),
      notes: Yup.string().optional(),
      facility_use_fee_payment_details: Yup.string().optional(),
      contacts: Yup.array().of(ContactSchema).optional(),
    }),
  ];

  useEffect(() => {
    if (editing && error && !isLoading) {
      addToast('Error while loading session', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, error, isLoading]);

  useEffect(() => {
    if (data && data.venue) {
      const { venue } = data;
      let values = {
        ...venue, 
        ...{organization_ids: venue.organizations?.map(o => ({value: o.id, label: o.name})) ?? []}, 
        ...{location_id: {value: venue?.location?.id ?? 0, label: venue?.location?.title ?? ""}},
      };
      setInitialValues(values);
    }
  }, [data]);


  const handleAddVenue = async (formValue: FormValues) => {
    try {
      let submission = formatFormValue(formValue);
      submission.organization_ids = formValue.organization_ids.map(o => o.value) ?? [];
      submission.location_id = formValue.location_id.value;
      if (submission.facility_use_fee_payment_details === "") {
        delete submission.facility_use_fee_payment_details;
      }

      submission.contacts.forEach(c => {
        delete c.full_name;
      })

      try {
        if (editing) {
          let formattedVenue = formatFormValue(data?.venue);
          let update = getObjectDifference(formattedVenue, submission);
          let submitUpdate = Object.keys(update).length > 0;
          if (submitUpdate) {
            update.id = data?.venue.id;
            const res = await updateVenue(update as CreateFormValues).unwrap();
            if (res && res.venue) {
              addToast(`Venue updated succesfully`, 'success');
            }
          }
          else {
            addToast(`No changes to Venue`, 'info');
          }
          navigate(`/admin/places/venues/${data?.venue.id}`);
        } else {
          const res = await createVenue(submission).unwrap();
          if (res && res.venue) {
            navigate(`/admin/places/venues/${res.venue.id}`);
            addToast('Venue created succesfully', 'success');
          } else throw new Error('A problem happened while creating Venue');
        }
      } catch (e) {
        addToast(getErrorMessage(e), 'error');
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>, 
    setTouched: (touched: FormikTouched<FormValues>, shouldValidate?: boolean) => void,
    values: FormValues
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        title: true,
        is_active: true,
        location_id: {value: true},
        address: true,
        city: true,
        state: true,
        zip: true,
        phone: true,
        notes: true,
        facility_use_fee_payment_details: true,
        contacts: Array.isArray(errors.contacts) ? errors.contacts?.map(() => getNestedTouchedTrue(ContactSchema)) : [],
        organization_ids: Array.isArray(errors.organization_ids) ? errors.organization_ids?.map(() => getNestedTouchedTrue(Yup.object({value: Yup.number().positive()}))) : [],
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text={`Back to venue${editing ? ' details' : 's'}`}
            color="#2B4E64"
            url={editing ? `/admin/places/venues/${data?.venue.id}` : '/admin/places/venues'}
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: '2.25rem' }}
          >
            {editing ? 'Edit' : 'New'} Venue
          </h2>
          {steps.length > 1 && <Stepper steps={steps} currentStep={currentStep} editing={editing === true} onStepClick={(step) => setCurrentStep(step)} />}
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow: '0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)',
            border: '1px solid #EBEBEB',
            borderRadius: 10,
          }}
        >
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleAddVenue}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FormValues>) => (
                <FormikForm className="text-start">
                  <div style={{ minHeight: '65vh' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                      })
                    }
                  </div>

                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      {currentStep !== 0 && (
                        <SimpleButton
                          disabled={isSubmitting}
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                          className="w-100"
                          variant="outline-primary"
                        >
                          Back
                        </SimpleButton>
                      )}
                    </Col>

                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={currentStep === steps.length - 1 ? submitForm : () => handleNext(validateForm, setTouched, values) }
                        className="w-100"
                        variant="primary"
                      >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                      </SimpleButton>
                    </Col>                   
                  </Row>
                </FormikForm>
              )}
            </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
