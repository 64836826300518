import { ErrorMessage, Field, FieldProps } from 'formik';
import { FC } from 'react';
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

export interface FormSelectOptions {
  value: string | number | boolean;
  label: string;
}

interface FormSelectProps {
  name: string;
  label: string;
  options: FormSelectOptions[];
  multiple?: boolean;
  loadingError?: string;
  onClick?: (values: any[]) => void;
  selected_Value?: {value: any, label: string}
}

export const FormSelect: FC<FormSelectProps> = ({ name, label, options, multiple, loadingError, onClick, selected_Value }) => {
  
  return (
    <Form.Group controlId={name}>
      <Form.Label>{label}</Form.Label>
      <InputGroup>
        <Field
          name={name}
          >
          {({field, form}: FieldProps) => {
            const { onChange, onBlur, value, ...restField } = field;
            let error = form.errors[field.name] as string|string[]|{label: string, value: string};
            let err = Array.isArray(error) ? JSON.stringify(error) : (typeof error === "object") ? error.value : error;
            let values = Array.isArray(value) ? value : value ? [value] : [];
            return (
              <>
                <Typeahead
                  id={field.name}
                  multiple={multiple}
                  placeholder='Any'
                  options={options}
                  onChange={option => {
                    form.setFieldValue(field.name, multiple ? option : option?.[0])
                  }//.map(v => v.value))}
                  }
                  selected={selected_Value ? [selected_Value] : values}
                  className={err ? 'is-invalid' : ''}
                  {...restField}
                />
                <ErrorMessage name={field.name}>
                  {(msg: any) => {
                    return (
                      <FormControl.Feedback type="invalid">{`${msg?.value ?? msg}`}</FormControl.Feedback>
                    )
                  }}
                </ErrorMessage>
                { loadingError && <Form.Text className="text-danger">{loadingError}</Form.Text> }
                {onClick && <Button type="button" onClick={() => onClick(values)}>Apply</Button>}
              </>
            )
          }}
        </Field>
      </InputGroup>
    </Form.Group>
  )
}
