import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Payment } from '../../../types/finance.types';
import { sfcApi } from '../../api';

export interface PaymentCreateUpdateValues {
  id?: number;
  invoice_id: number;
  payment_method: string | undefined;
  check_number: number | undefined;
  amount?: number | undefined;
  notes: string;
  total?: number;
}

export interface PayPaymentsValues {
  ids: number[];
  check_number?: number;
}

export interface PayPaymentsResponse {
  count: number;
  rows: Payment[];
}

export const paymentApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllPayments: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `payments${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Payment']
    }),
    getPayment: builder.query<{ payment: Payment }, number>({
      query: (id) => `payments/${id}`,
      providesTags: (result, error, id) => [{ type: 'Payment', id }]
    }),
    createPayment: builder.mutation<{ payment: Payment }, PaymentCreateUpdateValues>({
      query: (data) => ({
        url: `payments/create`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Payment']
    }),
    updatePayment: builder.mutation<{ payment: Payment }, PaymentCreateUpdateValues>({
      query: (data) => {
        const { id } = data;
        delete data.id;
        return {
          url: `payments/update/${id}`,
          method: 'PUT',
          body: data
        }
      },
      invalidatesTags: (result, error) => [{ type: 'Payment', id: result?.payment?.id }, 'Payment']
    }),
  }),
});

export const {
  useGetAllPaymentsQuery,
  useGetPaymentQuery,
  useCreatePaymentMutation,
  useUpdatePaymentMutation,
} = paymentApi;