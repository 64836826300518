import {
  Container,
  Image,
} from "react-bootstrap";
import MapImg from "../../assets/images/nationwide-map.png";
import "./NationwideSection.css";
import Banner from "../../components/banner/Banner";
import { SFCCommunity } from "../home/commonComponents/SFCCommunity";
import StateAccordionComponent from "./StateAccordionComponent";
import { getCurrentUser } from "../../services/helper";
import { useToast } from "../../context";
import { useGetAllLocationsQuery } from "../../services/endpoints/places/location";
import { SFCLocation } from "../../types/place.types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { buttonLocations, franchiseLocations, usStates } from "./NationwideData";


export const NationwideSection = () => {
  const navigate = useNavigate();
  const currentUser = getCurrentUser();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetAllLocationsQuery();
  const [locations, setLocations] = useState<SFCLocation[]>();

  useEffect(() => {
    if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);
  
  useEffect(() => {
    if (data && data.results) {
      setLocations(data.results);
    }
  }, [data]);

  return (
    <>
      <div className="bg-white-header w-100">
        <Banner mainPageUrl="/sessions" mainPage="Find a cooking session" pageTitle="Nationwide" title="Finding a SFC Location near you!" subtitle="Everywhere you want to be"/>
      </div>

      <div className="nationwide-container">
        <Container>
          <div className="w-100 text-center">
            <p className="text-secondary fw-bold text-center map-welcome">Welcome to our home-grown mobile kids cooking school! Sticky Fingers Cooking does not have a fixed location; we are everywhere you want us to be.</p>
          </div>

          <div className="map-container">
            <Image className="w-100" src={MapImg} />

            {locations?.map((location, id) => {
              let buttonOptions = buttonLocations.filter(btn => btn.title === location.title);
              if(buttonOptions != null && buttonOptions.length > 0) {
                let button = buttonOptions[0];
                return (
                  <div
                    title={button.title}
                    key={id}
                    className={button.type === 'franchise' ? "map-button" : "map-button-outlet"}
                    style={{
                      left: `${button.x}%`,
                      top: `${button.y}%`,
                      backgroundImage: `url(${button.imageUrl})`
                    }}
                    onClick={() => navigate('/franchise/' + location.id)}
                  ></div>
                )
              }
              })}
          </div>
          
          <p className="explore-locations-title">Explore our Franchise Locations</p>
          
          <div className="w-100 states-locations">
            {usStates.map(state => {
              let stateLocations = franchiseLocations.filter(fl => fl.state == state)[0].franchises;
              return (
                <StateAccordionComponent stateName={state} stateFranchises={locations?.filter(l => stateLocations.includes(l.title))}></StateAccordionComponent>
              )
            }
            )}
          </div>
        </Container>

        <SFCCommunity></SFCCommunity>
      </div>

    </>
  );
};