import React, { useEffect, useState } from 'react';
import { MdLocationPin } from 'react-icons/md';
import { useToast } from '../../context';

interface Location {
  latitude: number;
  longitude: number;
}

interface BrowserLocationProps {
  isModal?: boolean;
}

const BrowserLocationComponent: React.FC<BrowserLocationProps> = ({
  isModal = false,
}) => {
  const [location, setLocation] = useState<Location | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { addToast } = useToast();
  const enabled = ("geolocation" in navigator);
  
  const getLocation = () => {
    if (location) return;
    if (enabled) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
        },
        (error) => {
          addToast(error.message, 'error');
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  let content = (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a className={`header-search-link mt-3 link-header ${isModal ? "text-blue" : ""}`} onClick={() => getLocation()}>
      <MdLocationPin size={16} className="me-2"/>
      Use current Location
    </a>
  );
    
  return enabled ? content : <s>{content}</s>;
};
          
export default BrowserLocationComponent;
          