import { Breadcrumb } from "react-bootstrap";
import "./Banner.css";

interface BannerProps {
    title: string;
    pageTitle: string;
    mainPage?: string;
    mainPageUrl?: string;
    subtitle?: string;
};

const Banner: React.FC<BannerProps> = ({
  title,
  pageTitle,
  mainPage,
  mainPageUrl,
  subtitle
}) => {
  return (
    <div className={subtitle ? "section-banner-style-2" : "section-banner-style-2 green"}>
      <div className="container">
        <Breadcrumb className="text-uppercase">
          <Breadcrumb.Item href={mainPageUrl ? mainPageUrl : '/'}>{ mainPage ? mainPage : 'Home' }</Breadcrumb.Item>
          <Breadcrumb.Item href="#" active>
            {pageTitle}
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="break"></div>
        <h1 className="text-info fw-bold text-center-mobile">{title}</h1>
        {subtitle && (<h5 className="text-secondary fw-bold text-subtitle text-center-mobile">{subtitle}</h5>)}
      </div>
    </div>
  );
};

export default Banner;