import { useEffect, useState } from "react";
import SFCTable from "../../../../../components/tables/SFCTable";
import { GetQueryParams } from "../../../../../types/api.type";
import { FormSelect } from "../../../../../components/tables/filterForms/FormSelect";
import {
  FilterField,
  FilterForm,
} from "../../../../../components/tables/filterForms/FilterForm";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { SimpleButton } from "../../../../../components/buttons/SimpleButton";
import { jokesColumns } from "../../../../../utils/consts/columnDefs/contentColumns";

import { currentUserHasRole } from "../../../../../services/helper";
import { isAdmin } from "../../../../../utils/consts";
import { useGetJokesQuery } from "../../../../../services/endpoints/content/joke";
import { JokeCategory } from "../../../../../types/content.type";
import { useGetJokeCategoriesQuery } from "../../../../../services/endpoints/content/jokeCategory";
import { selectJoke, setJokes } from "../../../../../services/slicers/jokeSlice";
import { useAppDispatch, useAppSelector } from "../../../../../services/hooks";
import { User } from "../../../../../services/endpoints/people/user";

const defaultScope = "all";

export const userCanEditCreateDeleteView_Joke = (user: User | null) => {
  if(user?.isAdmin && user?.isSuper) {
    return true;
  }
  if(user?.isWebContentEditor || user?.isSupremeAdmin || user?.isGlobal) {
    return true;
  }

  return false;
}

export const JokesView = () => {
  const [filters, setFilters] = useState<GetQueryParams>({
    scope: defaultScope,
  });
  const [jokeCategories, setJokeCategories] = useState<JokeCategory[]>([]);
  const { data, error, isLoading, isFetching } = useGetJokesQuery(filters);
  const { data: dataJokeCategories, error: jokeCategoriesError } =
  useGetJokeCategoriesQuery();
  const navigate: NavigateFunction = useNavigate();
  const isAdminUser = currentUserHasRole(isAdmin);
  const jokes = useAppSelector(selectJoke);
  const dispatch = useAppDispatch();
  const filterFormFields: FilterField[] = [
    {
      name: "name",
      label: "Name",
    },
    {
      name: "content",
      label: "Content",
    },
    {
      name: "Joke Category",
      element: (
        <FormSelect
          name="joke_category_id"
          label="Joke Category"
          options={jokeCategories.map((jokeCategory: JokeCategory) => ({
            value: jokeCategory.id,
            label: jokeCategory.name,
          }))}
        />
      ),
    },
  ];

  useEffect(() => {
    if (data?.results) {
      dispatch(setJokes(data.results));
    }
    if (dataJokeCategories?.results) {
      setJokeCategories(dataJokeCategories.results);
    }
  }, [data, dataJokeCategories]);

  const setFilterBy = (
    filterBy: { operator: string; field: string; value: string }[]
  ) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      },
    }));
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`Jokes`}
        columns={isAdminUser ? jokesColumns : jokesColumns.slice(1)}
        data={jokes}
        count={data?.count}
        defaultScope={defaultScope}
        isLoading={isLoading}
        isFetching={isFetching}
        // indexDownloadPath="jokes"
        filters={filters?.data?.filterBy}
        filterForm={
          <FilterForm fields={filterFormFields} onApplyFilters={setFilterBy} />
        }
        onFiltersChanged={setFilters}
        newItemEl={
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={250}
            onClick={() => navigate("/admin/content/jokes/new")}
          >
            New Joke
          </SimpleButton>
        }
      />
    </div>
  );
};
