import "./FranchiseLayout.css";
import { Container } from "react-bootstrap";
import { Tab, Tabs, Button } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa6";
import { AboutUsSubpage } from "./subpages/aboutUs";
import { InTheNewsSubpage } from "./subpages/inTheNews";
import { TestimonialsSubpage } from "./subpages/testimonials";
import { OurCrewSubpage } from "./subpages/ourCrew";
import { AvailableJobsSubpage } from "./subpages/availableJobs";
import { Franchise } from "../../types/franchise";
import { useToast } from "../../context/ToastContext";
import { useEffect, useState } from "react";
import { GetQueryParams } from "../../types/api.type";
import { useGetLocationQuery } from "../../services/endpoints/places/location";
import { formatResponse, SFCLocation } from "../../types/place.types";
import { getCurrentUser } from "../../services/helper";
import { useParams } from "react-router-dom";

export const FranchiseLayout = () => {
  const params = useParams();
  const currentUser = getCurrentUser();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetLocationQuery(Number(params.id!) ?? (currentUser?.location_id ?? 1));
  const [location, setLocation] = useState<SFCLocation>();
  
  useEffect(() => {
    if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);
  
  useEffect(() => {
    if (data && data.location) {
      setLocation(formatResponse(data?.location));
  
    }
  }, [data]);

  return (
    <div className="bg-white">
      <Container fluid className="text-blue bg-header d-flex gradient-background">
        <div className="fruit-background left"></div>
        <div className="d-flex justify-content-center align-items-center fanchise-title-container">
          <h1 className="align-items-center text-center">{location?.title}</h1>
        </div>
        <div className="fruit-background right"></div>
      </Container>

      <Container>
        <div className="mt-5 mb-5 ">
          <Tabs
            defaultActiveKey="aboutUs"
            id="uncontrolled-tab-example"
            className="mb-3 d-flex justify-content-sm-center tabs-container"
          >
            <Tab className="tab-button" eventKey="aboutUs" title="ABOUT US">
              <AboutUsSubpage location={location}/>
            </Tab>
            <Tab eventKey="inTheNews" title="IN THE NEWS">
              <InTheNewsSubpage/>
            </Tab>
            <Tab eventKey="testimonials" title="TESTIMONIALS">
              <TestimonialsSubpage/>
            </Tab>
            <Tab eventKey="ourCrew" title="OUR CREW">
              <OurCrewSubpage/>
            </Tab>
            <Tab eventKey="availableJobs" title="AVAILABLE JOBS">
              <AvailableJobsSubpage/>
            </Tab>
          </Tabs>
        </div>
      </Container>
    </div>
  );
};
