import { FormValues } from '../../../features/dash/views/finance/Invoices/AddEditInvoice';
import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Invoice } from '../../../types/finance.types';
import { sfcApi } from '../../api';
export interface StripeKey{
  id:number,
  publishableKey:string
}
export interface Intent{
  location_id:number,
  invoice_id: number,
  amount: number
}

export interface CreatePayment{
  invoice_id:number,
  payment_method:string,
  total:number,
  notes:string,
  transaction_id:string,
  cc_last_4:string,

}
export const stripeApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    createInvoiceIntent: builder.mutation<{ clientSecret: string }, Intent>({
      query: (data) => {
        return {
          url: `stripe/create-invoice-payment-intent`,
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: (result, error, { invoice_id }) => [{ type: 'Intent', id: result?.clientSecret }, 'Intent']
    }),
    createPayment: builder.mutation<{ createPayment: CreatePayment }, CreatePayment>({
      query: (data) => {
        return {
          url: `payments/create`,
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: (result, error, { invoice_id }) => [{ type: 'CreatePayment', id: result?.createPayment?.invoice_id }, 'CreatePayment']
    }),
    getStrypeKey: builder.query<{ publishableKey: string }, number>({
      query: (id) => `locations/get-stripe-publishable-key/${id}`,
      providesTags: (result, error, publishableKey) => [{ type: 'StripeKey', publishableKey }]
    }),
  }),
});

export const {
  useCreateInvoiceIntentMutation,
  useCreatePaymentMutation,
  useGetStrypeKeyQuery,

} = stripeApi;