import { GetQueryParamsWithFilterArray, GetQueryResponse } from '../../../types/api.type';
import { CreateOrder, UpsertOrder, Order, ManualOrder } from '../../../types/orders.type';
import { sfcApi } from '../../api';

export const ordersApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllOrders: builder.query<GetQueryResponse, GetQueryParamsWithFilterArray | void>({
      query: (params) => ({
        url: `orders${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Order']
    }),
    getOrder: builder.query<{ order: Order }, number>({
      query: (id) => `orders/${id}`,
      providesTags: (result, error, id) => [{ type: 'Order', id }]
    }),
    generateOrder: builder.query<{ order: Order }, number>({
      query: (id) => `orders/generate-invoice/${id}`,
    }),
    createOrder: builder.mutation<{ order: Order }, CreateOrder>({
      query: (data) => ({
        url: `orders/create`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Order']
    }),
    createOrders: builder.mutation<{ order: ManualOrder }, CreateOrder>({
      query: (data) => ({
        url: `orders/create`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Order']
    }),
    upsertOrder: builder.mutation<{ order: Order }, UpsertOrder>({
      query: (data) => ({
        url: `orders/upsert`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Order']
    }),
    updateOrderTransaction: builder.mutation<{ order: Order }, {ids: number[], transaction_id: string, last_4: number}>({
      query: (params) => ({
        url: `orders/updateTransactionId`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Order']
    }),
    deleteOrder: builder.mutation<{ order: Order }, number>({
      query: (id) => ({
        url: `orders/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Order', id }, 'Order']
    }),
  }),
});

export const {
  useGetAllOrdersQuery,
  useGetOrderQuery,
  useGenerateOrderQuery,
  useCreateOrderMutation,
  useCreateOrdersMutation,
  useUpsertOrderMutation,
  useUpdateOrderTransactionMutation,
  useDeleteOrderMutation,
} = ordersApi;