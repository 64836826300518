import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { allergyColumns } from '../../../../../utils/consts/columnDefs/allergyColumns';
import { useGetAllAllergiesQuery } from '../../../../../services/endpoints/people/allergy';
import { Allergy, User } from '../../../../../services/endpoints/people/user';
import { getCurrentUser } from '../../../../../services/helper';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { useGetAllChildrenQuery } from '../../../../../services/endpoints/people/child';

const defaultScope = '';
const currentUser = getCurrentUser();

export const userCanEditCreateDelete_Allergies = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin) {
    return true;
  }

  return false;
}

export const userCanView_Allergies = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin) {
    return true;
  }

  return false;
}

export const AllergiesView = () => {
  const { addToast } = useToast();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: {page: 1, entries: 10} });
  const { data, error, isLoading, isFetching } = useGetAllAllergiesQuery(filters);
  const { data: children, error: childrenError, isLoading: childrenLoading }  = useGetAllChildrenQuery({data: {orderBy: {field: 'first_name', order: 'asc'}}});
  const [allergies, setAllergies] = useState<Allergy[]>([]);
  const navigate: NavigateFunction = useNavigate();
  const isGlobalUser = (currentUser?.isGlobal);

  const filterFormFields: FilterField[] = [ 
    {
      name: 'child_id',
      type: 'number',
      element: (
        <FormSelect
          name="child_id"
          label="Child"
          options={children?.results.map(c => ({ value: c.id, label: c.full_name})) ?? []}
        />
      ),
    },
    {
      name: 'allergy',
      label: 'Allergy',
    },
    {
      name: 'severity',
      label: 'Severity',
    },
    {
      name: 'details',
      label: 'Details',
    },
  ];

  useEffect(() => {
    if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setAllergies(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`Allergies`}
        columns={allergyColumns}
        data={allergies}
        count={data?.count}
        defaultScope={defaultScope}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath='allergies'
        filters={filters?.data?.filterBy}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        newItemEl={userCanEditCreateDelete_Allergies(currentUser) && (
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={270}
            onClick={() => navigate('/admin/allergies/new')}
          >
            New Allergy
          </SimpleButton>
        )}
        onFiltersChanged={setFilters}
      />
    </div>
  );
}
