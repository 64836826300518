import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { DateField } from '../../../../../components/tables/filterForms/DateField';
import { Scopes, User } from '../../../../../services/endpoints/people/user';
import { paymentsColumns } from '../../../../../utils/consts/columnDefs/financeColumns';
import { useGetAllPaymentsQuery } from '../../../../../services/endpoints/finance/payment';
import { Payment } from '../../../../../types/finance.types';
import { getCurrentUser } from '../../../../../services/helper';
import { paymentMethodNumbers } from '../../../../../utils/consts/finance/finance';
import { DetailSection, LabelText } from '../../../../../components/generic';
import CurrencyCell from '../../../../../components/tables/cellComponents/CurrencyCell';
import { Form } from 'react-bootstrap';
import { Field } from 'formik';
import { useDataContext } from '../../../../../context';
import { useLocation } from 'react-router-dom';

const defaultScope = 'all';
const currentUser = getCurrentUser();

export const userCanViewEdit_Payments = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin || user?.isAdmin || user?.isLocationAdmin) {
    return true;
  }

  return false;
}

export const userCanDelete_Payments = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin) {
    return true;
  }

  return false;
}

export const PaymentsView = () => {
  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: {page: 1, entries: 10} });
  const [scopes, setScopes] = useState<Scopes>({});
  const { data, error, isLoading, isFetching } = useGetAllPaymentsQuery(filters);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const isGlobalUser = (currentUser?.isGlobal);
  const userCanSeeFinancials = (currentUser?.canSeeFinancials);
  // TODO: add invoice_clients endpoint to query from
  const { data: invoiceClients, error: invoiceClientsError, isLoading: invoiceClientsLoading } = useGetInvoiceClientsQuery();

  // Using URLSearchParams to parse query parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  
  const location_id = queryParams.get('location_id');
  const created_at_gteq_datetime = queryParams.get('created_at_gteq_datetime');
  const created_at_lteq_datetime = queryParams.get('created_at_lteq_datetime');

  const filterFormFields: FilterField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'number',
    },
    {
      name: 'total',
      label: 'Total',
      type: 'number',
    },
    {
      name: 'location_id',
      type: 'number',
      element: (
        location_id ? 
        <FormSelect
          name="location_id"
          label="Location"
          options={locationOptions}
          selected_Value={{value: location_id, label: locationOptions?.find(loc => loc.value.toString() === location_id.toString())?.label ?? ''}}
        />
        :
        <FormSelect
          name="location_id"
          label="Location"
          options={locationOptions}
        />
      ),
    },
    {
      name: 'invoice_client',
      type: 'number',
      element: (
        <FormSelect
          name="invoice_client"
          label="Invoice Client"
          options={invoiceClients?.results?.map((ic: {id: number, title: string}) => ({ value: ic.id, label: ic.title })) ?? []}
          loadingError={invoiceClientsError ? "Error loading Invoice Clients" : undefined}
        />
      ),
    },
    {
      name: 'payment_method',
      type: 'number',
      element: (
        // <FormSelect
        //   name="payment_method"
        //   label="Payment Method"
        //   options={Object.entries(paymentMethodNumbers).map((p) => ({ value: p[0], label: p[1] }))}
        // />
        <Form.Group controlId="payment_method">
          <Form.Label>Payment Method</Form.Label>
          <Field
            as={Form.Select}
            name="payment_method"
          >
            {Object.entries(paymentMethodNumbers).map((op) => (
              <option key={op[0]} value={op[0]} className="text-capitalize">
                {op[1]}
              </option>
            ))}
          </Field>
        </Form.Group>
      ),
    },
    {
      name: 'check_number',
      label: 'Check Number',
      type: 'number',
    },
    {
      name: 'created_at_from',
      id: 'created_at',
      operator: 'greater_equal_than',
      element: (
        <DateField name="created_at_from" label="Created At" default_Value={created_at_gteq_datetime ? formatDate(created_at_gteq_datetime) : undefined} />
      )
    },
    {
      name: 'created_at_to',
      id: 'created_at',
      operator: 'lesser_equal_than',
      element: (
        created_at_lteq_datetime ? <DateField name="created_at_to" default_Value={formatDate(created_at_lteq_datetime)} /> : <DateField name="created_at_to" />
      )
    },
  ];
  function formatDate(dateString: string) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US'); // 'en-US' locale uses MM/DD/YYYY format
  }

  if (!isGlobalUser) {
    filterFormFields.splice(4, 1);
  }

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => {
          let scope_count = 0;
          if(data.scope_counts != null) {
            scope_count = data.scope_counts[scope] ?? 0;
          }
          return {
            title: `${title} (${scope_count})`,
            key: scope,
          }
        })));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setPayments(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

   useEffect(() => {
      // Apply the location filter when location_id is present in the URL
      if (location_id && created_at_lteq_datetime && created_at_gteq_datetime) {
        setFilters((prev) => ({
          ...prev,
          data: {
            ...prev?.data,
            filterBy: [
              { field: 'location_id', operator: 'equals', value: location_id ?? '' },
              { field: 'created_at', operator: 'greater_equal_than', value: created_at_gteq_datetime ?? '' },
              { field: 'created_at', operator: 'lesser_equal_than', value: created_at_lteq_datetime ?? '' },
            ],
          },
        }));
      }
    }, [location_id,created_at_lteq_datetime, created_at_gteq_datetime]);

  return (
    <div className="py-2">
      <SFCTable
        name={`${scopes[filters?.scope!] ?? ''} Payments`}
        columns={paymentsColumns}
        data={payments}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath='payments'
        sidebars={userCanSeeFinancials ? [
          <DetailSection title="Summary" >
            <LabelText label="Total:">
              <CurrencyCell value={data?.totals?.total ?? 0} />
            </LabelText>
          </DetailSection>] : []}
        filters={filters?.data?.filterBy}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
      />
    </div>
  );
}

//TODO: delete this
function useGetInvoiceClientsQuery(): { data: any; error: any; isLoading: any; } {
  return { data: {results: [{id: 1, title: "A client"}]}, error: false, isLoading: false}
}

