import React, { useEffect, useState } from "react";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useCreateInvoiceIntentMutation,useCreatePaymentMutation } from "../../../../../services/endpoints/finance/stripe";
import { Button } from "react-bootstrap";

interface StripeProps {
  stripePromise: Promise<Stripe | null> | null;
  locationId: number;
  invoice_id: number;
  amount: string;
}

// Componente hijo para manejar la lógica del formulario de pago
const PaymentForm: React.FC<{ clientSecret: string,invoice_id:number }> = ({ clientSecret,invoice_id }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [createPayment] = useCreatePaymentMutation();
  const [last4, setLast4] = useState<string | null>(null);
  const [totalAmount, setTotalAmount] = useState<number | null>(null); // Example: Total amount to charge
  const [transactionID, setTransactionID] = useState<string | null>(null); // Example: Total amount to charge
  const [paymentMethodType, setPaymentMethodType] = useState<string>('credit_card'); // Set payment method type to 'credit_card'
  
  
  const handlePayment = async () => {
    if (!stripe || !elements) {
      setError("Stripe has not loaded yet.");
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setError("Card element is not found.");
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setError(error.message || "An error occurred.");
    } else {
      const { error: paymentError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod.id,
      });

      if (paymentError) {
        setError(paymentError.message || "Payment failed.");
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        if (paymentMethod) {
          const cardLast4 = paymentMethod.card?.last4;
          setTotalAmount(paymentIntent?.amount / 100);
          setLast4(cardLast4 ?? null); // Set the last 4 digits of the card
          setTransactionID(paymentIntent?.id ?? null);
         
        const createPaymentTransaction=await createPayment({
          invoice_id:invoice_id ,
          payment_method:paymentMethodType,
          total:totalAmount ?? 0,
          notes:"notes",
          transaction_id:transactionID ?? '',
          cc_last_4:last4 ?? "",
        }).unwrap();
        }
        
        setError(null);
        ///here we call the createPayment:

      alert("Payment successful!");

      } else {
        setError("Payment failed or status unknown.");
      }
    }
  };

  return (
    <form>
      <div>
        <label>Card Details</label>
        <CardElement />
      </div>
      <Button variant="primary" onClick={handlePayment}>
        Pay Now
      </Button>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </form>
  );
};

// Componente principal que envuelve con `Elements`
const InvoicesViewForm: React.FC<StripeProps> = ({ stripePromise, locationId, invoice_id, amount }) => {
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [createStripeIntent] = useCreateInvoiceIntentMutation();

  useEffect(() => {
    if (stripePromise) {
      stripePromise.then((resolvedStripe) => setStripe(resolvedStripe));
    }
  }, [stripePromise]);

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await createStripeIntent({
          location_id: Number(locationId),
          invoice_id,
          amount: parseFloat(amount),
        }).unwrap();

        setClientSecret(response.clientSecret);
      } catch (error) {
        console.error("Error fetching client secret:", error);
      }
    };

    fetchClientSecret();
  }, [createStripeIntent, locationId, invoice_id, amount]);

  if (!stripePromise) {
    return <span>Failed to load Stripe</span>;
  }

  if (!stripe || !clientSecret) {
    return <span>Loading Stripe...</span>;
  }

  return (
    <Elements stripe={stripe}>
      <PaymentForm clientSecret={clientSecret} invoice_id={invoice_id}/>
    </Elements>
  );
};

export default InvoicesViewForm;