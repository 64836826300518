import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { DateField } from '../../../../../components/tables/filterForms/DateField';

import { useGetAllDocumentsQuery } from '../../../../../services/endpoints/content/document';
import { Document } from '../../../../../types/content.type';

import { currentUserHasRole, getCurrentUser } from '../../../../../services/helper';
import { isGlobal } from '../../../../../utils/consts';
import { documentColumns } from '../../../../../utils/consts/columnDefs/contentColumns';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { User } from '../../../../../services/endpoints/people/user';

const defaultScope = '';

export const userCanEditCreateDelete_Documents = (user: User | null) => {
  if(user?.isAdmin && user?.isSuper) {
    return true;
  }
  if(user?.isWebContentEditor || user?.isSupremeAdmin) {
    return true;
  }

  return false;
}

export const DocumentsView = () => {
  const { addToast } = useToast();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: {page: 1, entries: 10} });
  const { data, error, isLoading, isFetching } = useGetAllDocumentsQuery(filters);

  const [documents, setDocuments] = useState<Document[]>([]);
  const isGlobalUser = currentUserHasRole(isGlobal);
  const navigate: NavigateFunction = useNavigate();

  const currentUser = getCurrentUser();

  const filterFormFields: FilterField[] = [
    {
      name: 'id',
      type: 'number',
      label: 'ID',
    },
    {
      name: 'title',
      label: 'Title',
    },
    {
      name: 'file_file_name',
      label: 'File Name',
    },
  ];

  if (!isGlobalUser) {
    filterFormFields.splice(4, 1);
  }

  useEffect(() => {
   
    if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setDocuments(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };



  return (
    <div className="py-2">
      <SFCTable
        name={`Documents`}
        columns={documentColumns}
        data={documents}
        count={data?.count}
        defaultScope={defaultScope}
        // scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath='documents'
        
        filters={filters?.data?.filterBy}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
        newItemEl={userCanEditCreateDelete_Documents(currentUser) && (
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={250}
            onClick={() => navigate("/admin/content/documents/new")}
          >
            New Document
          </SimpleButton>
        )
        }
      />

    </div>
  );
}
