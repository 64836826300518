import { getCurrentUser } from "../../../services/helper";
import { Recipe } from "../../../types/lessonPlan.type";
import { parseRecipeSteps } from "../../../utils/utils";
import SafeHTMLDisplay from "../../common/SafeHTMLDisplay";
import SafeHTMLHeader from "../../common/SafeHTMLHeader";
import "./RecipeSteps.css";

interface RecipeSteps {
  recipes: Recipe[];
}

const RecipeSteps: React.FC<RecipeSteps> = ({ recipes }) => {
  const currentUser = getCurrentUser();
  return (
    <>
      <div className="instructions" id="steps">
        <h3 className="text-info fw-bolder mb-4">Instructions</h3>
        <div className="instructions-recipe">
          {recipes.map((recipe, index) => {
            const recipeSteps = currentUser?.hasDashAccess ? parseRecipeSteps(recipe.recipe_steps_instructor_field) : parseRecipeSteps(recipe.recipe_steps_parent_field);
            return (
              <div key={`recipe-${index}`}>
                <h4 className="text-info fw-bold mb-2">{recipe.title}</h4>
                {recipeSteps.map((step, index) => (
                  <div className="instruction-steps" key={`step-${index}`}>
                    <div>
                      <div className="text-block-steps">{index + 1}</div>
                    </div>
                    <div>
                      {step.title && step.content !=="" ?
                      <>
                        <SafeHTMLHeader htmlContent={step.title} />
                        <SafeHTMLDisplay htmlContent={step.content} />
                      </>
                      :
                      <>
                      <SafeHTMLDisplay htmlContent={step.title} />
                      </>
                      }
                    </div>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
      <div className="dots recipe"></div>
    </>
  );
};

export default RecipeSteps;
