import { ColumnDef } from '@tanstack/react-table';
import { Lesson } from '../../../types/lesson.type';
import { LessonPlansCell } from '../../../components/tables/cellComponents/LessonPlansCell';
import { Audit, Course, InstructorCourseRequest, LessonPlan } from '../../../types/course.type';
import { InstructorsCell } from '../../../components/tables/cellComponents/InstructorsCell';
import { SimpleButton } from '../../../components/buttons/SimpleButton';
import { Button, Stack } from 'react-bootstrap';
import { FaArrowRight, FaCheck, FaHourglass } from 'react-icons/fa6';
import { LinkCell } from '../../../components/tables/cellComponents/LinkCell';
import { WarningCell } from '../../../components/tables/cellComponents/WarningCell';
import { ExpanderCell } from '../../../components/tables/cellComponents/ExpanderCell';
import { DatesCell } from '../../../components/tables/cellComponents/DatesCell';
import { BadgeCell } from '../../../components/tables/cellComponents/BadgeCell';
import { EnrollmentCell } from '../../../components/tables/cellComponents/EnrollmentCell';
import { AuditedChangesCell } from '../../../components/tables/cellComponents/AuditedChangesCell';
import { formatDate, getCurrentDateOnly, getDaysOffsetDateOnly, UTCToDate } from '../../dateUtils';
import { DateCell } from '../../../components/tables/cellComponents/DateCell';
import { instructorRolesMapping } from '..';
import CurrencyCell from '../../../components/tables/cellComponents/CurrencyCell';
import { SFCOrganization } from '../../../types/place.types';
import { TooltipLinkCell } from '../../../components/tables/cellComponents/TooltipLinkCell';
import { getCurrentUser } from '../../../services/helper';
import { lessonAssignmentActionFn } from '../../../features/dash/views/schedule/sessions/SessionDetail';
import { FaMapMarkerAlt } from 'react-icons/fa';
import WaitlistActionsCell from '../../../components/tables/cellComponents/WaitlistActionsCell';

const currentUser = getCurrentUser();
const usesAdvancedScheduling = (currentUser?.uses_advanced_scheduling === true);
const isAdminUser = (currentUser?.isAdmin);
const isGlobalUser = (currentUser?.isGlobal);

const canScheduleFor = (location_id: number) => {
  return (isAdminUser && usesAdvancedScheduling && (currentUser?.location_id === location_id || isGlobalUser));
}

export const sessionColumns: ColumnDef<Course>[] = [
  {
    id: 'expander',
    header: () => null,
    cell: ({ row }) => row.original?.lessons && row.original?.lessons?.length > 0 ? (
      <ExpanderCell row={row} />
    ) : null,
  },
  {
    header: 'ID',
    accessorKey: 'id',
    cell: ({ getValue, row }) => (
      <LinkCell content={getValue<string>()} url={`/admin/schedule/sessions/${row.original.id}`} />
    ),
  },
  {
    header: 'Session',
    accessorKey: 'title',
    cell: ({ getValue, row }) => (
      <LinkCell content={getValue<string>()} url={`/admin/schedule/sessions/${row.original.id}`} />
    ),
  },
  // {
  //   header: 'Dates',
  //   accessorKey: 'starts_on',
  //   cell: ({ row }) => {
  //     if (!row.original.formatted_time || !row.original.starts_on || !row.original.ends_on) {
  //       return 'Invalid date'
  //     }
  //     const times = row.original.formatted_time.split(' - ');
  //     return <DatesCell
  //       startDate={UTCToDate(`${row.original.starts_on!} ${times?.[0]}`, 'yyyy-MM-dd h:mma')}
  //       endDate={UTCToDate(`${row.original.ends_on!} ${times?.[1]}`, 'yyyy-MM-dd h:mma')}
  //       dayOfWeek={row.original.day_of_week}
  //     />
  //   },
  // },
  {
    header: 'Start Date',
    accessorKey: 'starts_on',
    cell: ({ row }) => {
      if (!row.original.starts_on) {
        return 'Invalid date'
      }
      return <DateCell date={row.original.starts_on} format={"MM/dd/yyyy"} /> 
    },
  },
  {
    header: 'End Date',
    accessorKey: 'ends_on',
    cell: ({ row }) => {
      if (!row.original.ends_on) {
        return 'Invalid date'
      }
      return <DateCell date={row.original.ends_on} format={"MM/dd/yyyy"} />
    },
  },
  // {
  //   header: 'Time',
  //   accessorKey: 'formatted_time',
  //   cell: ({ row }) => {
  //     if (!row.original.formatted_time || !row.original.starts_on || !row.original.ends_on) {
  //       return 'Invalid times'
  //     }
  //     const times = row.original.formatted_time.split(' - ');
  //     const startTime = UTCToDate(`${row.original.starts_on!} ${times?.[0]}`, 'yyyy-MM-dd h:mma');
  //     const endTime = UTCToDate(`${row.original.ends_on!} ${times?.[1]}`, 'yyyy-MM-dd h:mma');
  //     return <span>{`${formatDate(startTime, 'hh:mm a')} - ${formatDate(endTime, 'hh:mm a')}`}</span>;
  //   },
  // },
  {
    header: 'Day of Week',
    accessorKey: 'day_of_week',
    cell: ({ row }) => (
      <span>{row.original.day_of_week}</span>
    ),
  }
];

export const sessionOtherColumns: ColumnDef<Course>[] = [
  {
    header: 'Instructor',
    accessorKey: 'course_assignment.instructor_id',
    cell: ({ row }) => {
      if (!row.original?.course_assignments?.length) return;
      const { instructor } = row.original?.course_assignments?.[0];
      return (
        <span className="text-info fw-bolder text-truncate">
          {`${instructor?.first_name} ${instructor?.last_name}` }
        </span>
      );
    },
  },
  {
    header: 'Theme',
    accessorKey: 'theme',
    cell: ({ row }) => (
      <span className="text-truncate">
        {row.original?.theme?.name}
      </span>
    ),
  },
  {
    header: 'Enrollment',
    accessorKey: 'enrollment',
    cell: ({ getValue, row }) => (
      <EnrollmentCell type={row.original.payment_type} value={getValue<string>()} />
    ),
  },
  {
    header: 'Status',
    accessorKey: 'status',
    cell: ({ getValue }) => <BadgeCell active={getValue<string>() === 'open'} activePhrase="Open" inactivePhrase={getValue<string>() === 'offline' ? 'Offline' : 'Cancelled'} />
  },
];

export const sessionInvoiceColumns: ColumnDef<Course>[] = [
  {
    header: 'Amount Receivable',
    accessorKey: 'sum_enrollment_values',
    cell: ({ getValue }) => (
      <CurrencyCell value={getValue<string>()} />
    ),
  },
  {
    header: 'Amount Invoiced',
    accessorKey: 'sum_invoice_item_totals',
    cell: ({ getValue }) => (
      <CurrencyCell value={getValue<string>()} />
    ),
  },
  {
    header: 'Organizational\nInvoicing Contacts',
    accessorKey: 'venue.organizations',
    cell: ({ getValue }) => {
      let orgs = getValue<SFCOrganization[]>();
      let cells = orgs?.map(o => 
        <TooltipLinkCell 
          tooltip={`${o.contacts?.length} contacts should be sent invoices.`}
          key={o.id} 
          url={`/admin/places/organizations/${o.id}`} 
          content={o.name} 
        />
      );
      return <>{cells}</>
    }
  },
];

export const simpleScheduleColumns: ColumnDef<Lesson>[] = [
  {
    header: 'Number',
    id: 'lesson_id',
    accessorKey: 'id',
  },
  {
    header: 'Start Date',
    id: 'lesson_date',
    accessorKey: 'date',
    cell: ({ getValue, row }) => getValue<string>() && row.original.starts_at ? (
      <span className="text-truncate">
        {formatDate(UTCToDate(`${getValue<string>()} ${row.original.starts_at}`, 'yyyy-MM-dd hh:mm a'), 'MM/dd/yyyy')}
      </span>
    ) : 'Invalid date',
  },
  {
    header: 'Day Of Week',
    id: 'lesson_day_of_the_week',
    cell: ({ row }) => row.original.date && row.original.starts_at ? (
      <span className="text-truncate">
        {formatDate(UTCToDate(`${row.original.date} ${row.original.starts_at}`, 'yyyy-MM-dd hh:mm a'), 'EEEE')}
      </span>
    ) : 'Invalid date',
  },
  {
    header: 'Time',
    id: 'lesson_time',
    cell: ({ row }) => row.original.date && row.original.starts_at ? (
      <span className="text-truncate">
        {`${formatDate(UTCToDate(`${row.original.date} ${row.original.starts_at}`, 'yyyy-MM-dd hh:mm a'), 'hh:mm a')} -
        ${formatDate(UTCToDate(`${row.original.date} ${row.original.ends_at}`, 'yyyy-MM-dd hh:mm a'), 'hh:mm a')}`}
      </span>
    ) : 'Invalid date',
  },
  // {
  //   header: 'Instructors',
  //   id: 'lesson_instructors',
  //   accessorKey: 'lesson_assignments',
  //   cell: ({ getValue, row }) => (
  //     <InstructorsCell assigments={getValue<LessonAssignment[]>().filter((l) => l.instructor_role === 'instructor')} />
  //   ),
  // },
  {
    header: '',
    accessorKey: 'date',
    id: 'Actions',
    cell: ({ getValue }) => getValue<string>() ? (
      <SimpleButton variant="primary" height={28} className="pt-0">
        <Stack direction="horizontal" gap={1}>
          <span style={{ fontSize: '.875rem' }} >See Class</span>
          <span className="pb-1">
            <FaArrowRight size={14} />
          </span>
        </Stack>
      </SimpleButton>
    ) : <></>,
    enableSorting: false,
  },
];

export const getScheduleColumns: (fn: lessonAssignmentActionFn) => ColumnDef<Lesson>[] = (fn) => ([
  {
    header: 'Number',
    accessorKey: 'id',
    cell: ({ row }) => (row.index+1)
  },
  {
    header: 'Date',
    accessorKey: 'date',
    cell: ({ getValue, row }) => <DateCell date={getValue<string>()} />
  },
  {
    header: 'Day',
    id: 'day',
    accessorKey: 'date',
    cell: ({ getValue, row }) => <DateCell date={getValue<string>()} time={row.original.starts_at ?? row.original.course.starts_at} format="EEEE" />
  },
  {
    header: 'Time',
    id: 'lesson_time',
    cell: ({ row }) => (
      <>
        <DateCell date={row.original.date} time={row.original.starts_at ?? row.original.course.starts_at} format="hh:mm a" />
        {` - `}
        <DateCell date={row.original.date} time={row.original.ends_at ?? row.original.course.ends_at} format="hh:mm a" />
      </>
    )
  },
  {
    header: 'Class Notes',
    id: 'class_notes',
    accessorKey: 'notes'
  },
  {
    header: 'Lesson Plans',
    accessorKey: 'lesson_plans',
    cell: ({ row, getValue }) => {
      let latest_tlp;
      let lps = getValue<LessonPlan[]>();
      if (lps.length === 0 ) {
        latest_tlp =row.original.featured_tlps?.filter(tlp => tlp.theme_lesson_plans.featured_on === row.original.date);
      }
      return (<LessonPlansCell lessonPlans={getValue<LessonPlan[]>()} featuredLessonPlans={latest_tlp} />);
    },
  },
  {
    header: 'Instructors',
    accessorKey: 'lesson_assignments',
    cell: ({ row }) => {
      const mainInstructors = row.original.course.course_assignments.map((item) => ({
        id: item.instructor_id,
        name: `${item.instructor?.full_name}`,
        role: 'Main instructor',
      }));
      const instructors = row.original.lesson_assignments.filter(la => la.instructor).map((item) => ({
        id: item.instructor_id,
        name: `${item.instructor?.full_name}`,
        role: instructorRolesMapping[item.instructor_role]
      }));
      return <InstructorsCell instructors={[...mainInstructors, ...instructors]} />
    },
  },
  {
    header: '',
    id: 'Actions',
    enableSorting: false,
    cell: ({ row }) => (row.original.date >= getCurrentDateOnly() ? 
      <>
        {
          row.original.lesson_assignments.filter( la => la.instructor_role === "substitute" && la.instructor_id !== null).map(la => {
            let label = currentUser?.id === la.instructor_id ? "I" : la.instructor?.full_name;
            if (row.original.course.venue && (currentUser?.id === la.instructor_id || canScheduleFor(row.original.course.venue.location_id))) {
              return (<SimpleButton size="sm" variant="primary" onClick={() => fn('reneg', {id: la.id})}>{label}<br />can't sub</SimpleButton>);
            }
            return (<></>);
          })
        }
      </> : <></>)
  },
  {
    header: '',
    id: 'Actions More',
    enableSorting: false,
    cell: ({ row }) => (<>
      { row.original.course.course_assignments.map(ca => {
        let instructor = ca.instructor;
        let label = currentUser?.id === ca.instructor_id ? "my" : `${instructor.first_name}'s`;
        let result = <></>;
        let las = row.original.lesson_assignments.filter(la => la.requesting_instructor_id === ca.instructor_id);
        let hasSub = las.length > 0 ? las.some(la => la.instructor) : null;
        let id = las.length > 0 ? las[0].id : 0;
        if (row.original.course.venue && (currentUser?.id === ca.instructor_id || canScheduleFor(row.original.course.venue.location_id))) {
          if (hasSub) {
            result = <TooltipLinkCell tooltip={`A substitute instructor has been found for ${instructor.full_name}`} content={<FaCheck size={19} />} url={'#'} />;
          }
          else if (hasSub !== null) {
            let content = <><FaHourglass size={19} /><br />Cancel {label} Request</>;
            result = id ? 
              <TooltipLinkCell onClick={() => fn('delete', {id})} tooltip={`No substitute instructors have been found for ${instructor.full_name}`} content={content} url={'#'} />
              :
              <></>;

          }
          else if (row.original.date > getDaysOffsetDateOnly(-1)) {
            // <form action="/lessons/<%= lesson.id %>/lesson_assignments" method="post">
            //   <input type="submit" value="Request a Sub <%= ('for ' + instructor.name) unless current_user == instructor %>" />
            // </form>
            let data = {
              lesson_id: row.original.id,
              requesting_instructor_id: ca.instructor_id,
              course_id: row.original.course.id,
              location_id: row.original.course.venue.location_id,
            }
            result = 
            <SimpleButton variant="primary" height={28} className="pt-0" onClick={() => fn('create', data)}>
              <Stack direction="horizontal" gap={1}>
                <span className="text-truncate" style={{ fontSize: '.875rem' }}>Request a sub{` for ${instructor.full_name}`}</span>
                <span className="pb-1">
                  <FaArrowRight size={14} />
                </span>
              </Stack>
            </SimpleButton>;
          }
        }
        else {
          result = <></>;
        }
        return result;
      }) }
    </>)
  },
]);

export const rosterColumns: ColumnDef<any>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
  },
  {
    header: 'First Name',
    accessorKey: 'first_name',
    cell: ({ getValue, row }) => (
      <LinkCell content={getValue<string>()} url="" />
    ),
  },
  {
    header: 'Last Name',
    accessorKey: 'last_name',
    cell: ({ getValue, row }) => (
      <LinkCell content={getValue<string>()} url="" />
    ),
  },
  {
    header: `Allergy / Dietary\nRestrictions`,
    accessorKey: 'allergies',
    enableSorting: false,
    cell: () => (
      <WarningCell text="Allergy alert" />
    ),
  },
  {
    header: 'Social / Emotional\nNeeds',
    accessorKey: 'social_emotional_needs',
    enableSorting: false,
  },
  {
    header: 'Parent',
    accessorKey: 'parent_first_name',
    cell: ({ row }) =>
      <LinkCell
        content={`${row.original.parent.first_name} ${row.original.parent.last_name}`}
        url={`/admin/sfc-users/parents/${row.original.parent.id}`}
      />,
    enableSorting: false,
  },
  
  {
    header: 'Parent Email',
    accessorKey: 'parent_email',
    cell: ({ row }) =>
      <LinkCell
        content={`${row.original.parent.email}`}
        url={`/admin/sfc-users/parents/${row.original.parent.id}`}
      />,
    enableSorting: false,
  },
];

export const enrollmentColumns: ColumnDef<any>[] = [
  {
    id:'number_of_students_expected',
    header: 'Number of Students Expected',
    accessorKey: 'quantity',
  },
  {
    id:'tuition',
    header: 'Tuition (per student, per class)',
    accessorKey: 'tuition',
    cell: ({ getValue }) => (
      <>${getValue()}</>
    )
  },
  {
    id:'number_of_classes',
    header: 'Number of Classes',
    accessorKey: 'number_of_classes',
  },
  {
    id:'scholarship',
    header: 'Scholarship',
    accessorKey: 'scholarship.name',
  },
  {
    id:'notes',
    header: 'Notes',
    accessorKey: 'notes',
    enableSorting: false,
  },
  {
    id:'invoice_notes',
    header: 'Invoice Notes',
    accessorKey: 'invoice_notes',
  },
  {
    id: 'total',
    header: 'Total',
    accessorKey: 'value',
    cell: ({ getValue }) => (
      <>${parseFloat(getValue() as string).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
  },
  {
    id: 'actions',
    header: () => null,
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell content="Edit" url={`/admin/enrollments/${row.original.id}/edit`} underline />
        <LinkCell content="Delete" url={`/admin/enrollments/${row.original.id}/delete`} underline />
      </Stack>
    )
  },
  
];

export const flatRateenrollmentColumns: ColumnDef<any>[] = [
  {
    id:'number_of_students_expected',
    header: 'Number of Students Expected',
    accessorKey: 'quantity',
  },
  {
    id:'tuition',
    header: 'Flat Rate Tuition (per class)',
    accessorKey: 'tuition',
    cell: ({ getValue }) => (
      <>${getValue()}</>
    )
  },
  {
    id:'number_of_classes',
    header: 'Number of Classes',
    accessorKey: 'number_of_classes',
  },
  {
    id:'scholarship',
    header: 'Scholarship',
    accessorKey: 'scholarship.name',
  },
  {
    id:'notes',
    header: 'Notes',
    accessorKey: 'notes',
    enableSorting: false,
  },
  {
    id:'invoice_notes',
    header: 'Invoice Notes',
    accessorKey: 'invoice_notes',
  },
  {
    id: 'total',
    header: 'Total',
    accessorKey: 'value',
    cell: ({ getValue }) => (
      <>${parseFloat(getValue() as string).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
  },
  {
    id: 'actions',
    header: () => null,
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell content="Edit" url={`/admin/enrollments/${row.original.id}/edit`} underline />
        <LinkCell content="Delete" url={`/admin/enrollments/${row.original.id}/delete`} underline />
      </Stack>
    )
  },
  
];


export const lessonNotesColumns: ColumnDef<any>[] = [
  
  {
    header: 'Notes',
    accessorKey: 'notes',
    enableSorting: false,
  },
  
];




export const waitlistColumns: ColumnDef<any>[] = [
  ...rosterColumns,
  {
    header: 'Status',
    accessorKey: 'status',
    cell: ({ row }) =>
      <LinkCell
        content={`${row.original.waitlists.status}`}
        url={``}
      />,
    enableSorting: false,
  },
  {
    header: () => null,
    id: "actions",
    enableSorting: false,
    cell: ({ row }) => <WaitlistActionsCell row={row} />,
  },
];

export const waiversColumns: ColumnDef<any>[] = [
  {
    header: 'Child First Name',
    accessorKey: 'child_first_name',
  },
  {
    header: 'Child Last Name',
    accessorKey: 'child_last_name',

  },
  {
    header: 'Parent First Name',
    accessorKey: 'parent_first_name',
  },
  {
    header: 'Parent Last Name',
    accessorKey: 'parent_last_name',
  },
  {
    header: 'Parent Email Address',
    accessorKey: 'parent_email',
  },
  {
    header: 'Parent Mobile Number',
    accessorKey: 'parent_mobile_number',
  },
];

export const sessionExpensesColumns: ColumnDef<any>[] = [
  {
    header: 'Category',
    accessorKey: 'category',
  },
  {
    header: 'Amount',
    accessorKey: 'amount',
  },
  {
    header: 'Context',
    accessorKey: 'context',
  },
  {
    header: `Due On`,
    accessorKey: 'due_on',
  },
  {
    header: 'Paid On',
    accessorKey: 'paid_on',
  },
  {
    header: 'Notes',
    accessorKey: 'notes',
  },
  {
    header: 'Invoice number',
    accessorKey: 'invoice_number',
  },
  {
    header: 'Payment Method',
    accessorKey: 'payment_method',
  },
  {
    header: 'Total',
    accessorKey: 'total',
  },
];

export const revisionsColumns: ColumnDef<Audit>[] = [
  {
    header: 'User',
    accessorKey: 'username',
    cell: ({ getValue }) => (
      <span className="text-truncate">
        {getValue<string>() ? getValue<string>() : 'Not specified'}
      </span>
    ),
  },
  {
    header: 'Action',
    accessorKey: 'action',
    cell: ({ getValue }) => (
      <span className="text-capitalize">
        {getValue<string>()}
      </span>
    ),
  },
  {
    header: 'Changes',
    accessorKey: 'audited_changes',
    cell: ({ getValue }) => <AuditedChangesCell changes={getValue<string>()} />,
  },
  {
    header: `Comment`,
    accessorKey: 'comment',
  },
  {
    header: 'Time',
    accessorKey: 'created_at',
    cell: ({ getValue }) => (
      // TODO: add timezone
      <span>{getValue<string>()}</span>
    ),
  },
];

export const needsInstructorColumns: ColumnDef<Course>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
    cell: ({ getValue, row }) => (
      <LinkCell content={getValue<string>()} url={`/admin/schedule/sessions/${row.original.id}`} />
    ),
  },
  {
    header: 'Session',
    accessorKey: 'title',
    cell: ({ getValue, row }) => (
      <LinkCell content={getValue<string>()} url={`/admin/schedule/sessions/${row.original.id}`} />
    ),
  },
  {
    header: 'Venue',
    accessorKey: 'location',
    cell: ({ row }) => (
      <LinkCell content={row.original.venue.title} url={`/admin/places/venues/${row.original.venue.id}`} />
    ),
  },
  {
    header: 'Map',
    accessorKey: 'map',
    cell: ({ row }) => (
      <TooltipLinkCell tooltip={`${row.original.venue.address}, ${row.original.venue.city}, ${row.original.venue.state}, ${row.original.venue.zip}`} content={<FaMapMarkerAlt />} url=''
       onClick={() => {window.open(`https://www.google.com/maps?q=${encodeURIComponent(row.original.venue.address)}`, '_blank');}} underline />
    ),
  },
  {
    header: 'Dates',
    accessorKey: 'starts_on',
    cell: ({ row }) => {
      const times = row.original.formatted_time.split(' - ');
      const startDate = UTCToDate(`${row.original.starts_on!} ${times?.[0]}`, 'yyyy-MM-dd h:mma');
      const endDate = UTCToDate(`${row.original.ends_on!} ${times?.[1]}`, 'yyyy-MM-dd h:mma');
      return <DatesCell
        startDate={startDate}
        endDate={endDate}
        dayOfWeek={row.original.day_of_week}
      />
  },
  },
  {
    header: 'Time',
    accessorKey: 'formatted_time',
    cell: ({ row }) => {
      if (!row.original.formatted_time || !row.original.starts_on || !row.original.ends_on) {
        return 'Invalid times'
      }
      const times = row.original.formatted_time.split(' - ');
      const startTime = UTCToDate(`${row.original.starts_on!} ${times?.[0]}`, 'yyyy-MM-dd h:mma');
      const endTime = UTCToDate(`${row.original.ends_on!} ${times?.[1]}`, 'yyyy-MM-dd h:mma');
      return <span>{`${formatDate(startTime, 'hh:mm a')} - ${formatDate(endTime, 'hh:mm a')}`}</span>;
    },
  },
  {
    header: 'Enrollment',
    accessorKey: 'enrollment',
    cell: ({ getValue, row }) => (
      <EnrollmentCell type={row.original.payment_type} value={getValue<string>()} />
    ),
  },
  {
    header: 'Scheduled Instructors',
    accessorKey: 'scheduled_instructors',
  }
];

export const instructorSessionRequestsColumns: ColumnDef<InstructorCourseRequest>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
  },
  {
    header: 'Instructor',
    id: 'lesson_instructor',
    cell: ({ row }) => <LinkCell content={row.original.instructor.full_name} url={``} />,
  },
  {
    header: 'Session',
    id: 'session_title',
    accessorKey: 'course.title',
    cell: ({ getValue, row }) => (
      <LinkCell content={getValue<string>()} url={`/admin/schedule/sessions/${row.original.course_id}`} />
    ),
  },
  {
    header: 'Request Date',
    id: 'request_date',
    accessorKey: 'created_at',
    cell: ({ row }) => formatDate(new Date(row.original.created_at), 'MM/dd/yyyy h:mm zzz')
  },
];

