import React, { useEffect, useRef, useState } from 'react';
import * as powerbi from 'powerbi-client';
import PowerBIService from '../../../components/powerBi/PowerBIReports';

interface PowerBIReportsProps {
  accessToken: string;
  embedToken: string;
  workspaceId: string;
  reportId: string;
}

const PowerBIReports: React.FC<PowerBIReportsProps> = ({ accessToken, embedToken, workspaceId, reportId }) => {
  const [url, setUrl] = useState<string | null>(null);
  
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const reportContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const fetchReportEmbedUrl = async () => {
      setLoading(true);
      setError(null);

      try {
        const powerBIService = new PowerBIService(accessToken);
        const url = await powerBIService.getReportEmbedUrl(workspaceId, reportId);
        console.log('Embed URL:', url);

        setUrl(url);
      
         
       
        
      } catch (err: any) {
        setError(err.message || 'Failed to fetch report.');
      } finally {
        setLoading(false);
      }
    };

    fetchReportEmbedUrl();
  }, [accessToken, workspaceId, reportId]);
  
  useEffect(() => {
   
  if (reportContainerRef.current && url){
    const embedConfig={
      type: 'report',
      tokenType: powerbi.models.TokenType.Embed,
      accessToken: embedToken,
      embedUrl: url ?? '',
      settings: {
        panes: {
          filters: {
            visible: false,
          },
        },
      },
    }
    const powerbiService = new powerbi.service.Service(powerbi.factories.hpmFactory, powerbi.factories.wpmpFactory, powerbi.factories.routerFactory);
    powerbiService.embed(reportContainerRef?.current, embedConfig);
  }
},[reportContainerRef, url])

  if (loading) {
    return <div>Loading Power BI report...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return <div ref={reportContainerRef} style={{ height: '600px', width: '100%' }}></div>;
};

export default PowerBIReports;