import { ButtonData } from "../../../../../components/buttonGroup/ButtonGroup";
import { ReactNode, useEffect, useState } from "react";
import SFCTable from "../../../../../components/tables/SFCTable";
import { useToast } from "../../../../../context/ToastContext";
import {
  GetQueryParams,
  GetQueryParamsWithFilterArray,
} from "../../../../../types/api.type";

import { Invoice } from "../../../../../types/finance.types";
import { useGetAllInvoicesQuery } from "../../../../../services/endpoints/finance/invoice";

import CurrencyCell from "../../../../../components/tables/cellComponents/CurrencyCell";
import { useDataContext } from "../../../../../context";
import Table from "react-bootstrap/Table";
import { Button, Stack, Form, Card, Badge,Modal } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa6";
import { DateCell } from "../../../../../components/tables/cellComponents/DateCell";
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { getErrorMessage } from "../../../../../utils/utils";
import { useCreateInvoiceIntentMutation, useGetStrypeKeyQuery } from "../../../../../services/endpoints/finance/stripe";
import InvoicesViewForm from "./InvoicesViewForm";
import {  useLocation, useParams } from 'react-router-dom';
import InvoiceTable from "../../../../../components/tables/invoices/InvoicesTable";


export const InvoicesClientView = () => {
const location = useLocation();
 const urlParams = new URLSearchParams(location.search);
  const params = useParams();
  const id = +params.id!;
  const {access_code} = params;

  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  const [invoiceClientId, setInvoiceClientId] = useState<number | null>(null);

  const [filters, setFilters] = useState<GetQueryParams>({
    scope: "all",
    data: {
      ...{
        filterBy: [
          { field: "invoice_client_id", operator: "equals", value: id },
          ...(access_code !== undefined 
            ? [{ field: "access_code", operator: "equals", value: access_code }] 
            : [])
        ],
      },
      entries: 9,
    },
  });
  const { data, error, isLoading, isFetching } = useGetAllInvoicesQuery(
    filters,
    { skip: false}
  );
  const [locationId, setLocationId] = useState<number | null>(null);
  const [invoiceId, setInvoiceId] = useState<number | null>(null);
  const [amount, setAmount] = useState<string | null>(null);
  const [shouldFetch, setShouldFetch] = useState(false);

 // Trigger query only when shouldFetch is true
 const { data: StripeData, error: StripeError, isLoading: StripeLoading } = useGetStrypeKeyQuery(locationId as number, {
  skip: !shouldFetch,
});
   
   
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [errorStripe, setError] = useState<string | null>(null);

  const [showModal, setShowModal] = useState(false);
  const [createStripeIntent] = useCreateInvoiceIntentMutation();
  const [publicKey, setPublicKey] = useState<string>(''); 
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);


  
  


  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading invoice', 'error');
    }
    if (data && data.results) {
      setInvoices(data.results);
      //setLocationId(data.results?.)

    }
  }, [data,error]);

  

  useEffect(() => {
    if (StripeData) {
      const loadStripeAsync = async () => {
        const stripeInstance = loadStripe(StripeData.publishableKey);
        setStripePromise(stripeInstance);
      };

      loadStripeAsync();
    }
  }, [StripeData]);

  return (
    <div className="py-2">
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: "#EFEFEF" }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button
            className="btn btn-link p-0 text-info"
            onClick={() => window.history.back()}
          >
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: "#888888", fontSize: "1rem" }}
          >
            Sessions List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">
            Payment portal
          </h2>
        </Stack>
      </div>
      
      {/* <Badge title={row.original.status} color={invoiceStatusColors[row.original.status]} /> */}
      {isLoading ? (
        <div>Loading . . . </div>
      ) : 
      (invoices && invoices.length>0 ? (
     <InvoiceTable 
     setLocationId={setLocationId}
      setInvoiceId={setInvoiceId}
      setAmount={setAmount}
      setShouldFetch={setShouldFetch}
      setShowModal={setShowModal} 
      invoices={invoices}
                    />
       ) : (
        <div>No invoices found</div>
      )
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {StripeData?.publishableKey && stripePromise  ? (
        <InvoicesViewForm stripePromise={stripePromise} locationId={locationId ?? 0} invoice_id={invoiceId ?? 0} amount={amount ?? ''}/>
      ) : (
        <span>Failed to load Stripe</span>
      )}
        </Modal.Body>
      </Modal>
    </div>
  );
};
