import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { ColumnDef } from '@tanstack/react-table';
import { Scopes, User } from '../../../../../services/endpoints/people/user';
import { isAdmin, isGlobal, isLocal } from '../../../../../utils/consts';
import { formatDateString } from '../../../../../utils/utils';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { useToast } from '../../../../../context/ToastContext';
import { getCurrentUser } from '../../../../../services/helper';
import { GetQueryParams } from '../../../../../types/api.type';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import { timesheetOverride } from '../../../../../types/finance.types';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { DateField } from '../../../../../components/tables/filterForms/DateField';
import { SFCLocation, SFCVenue } from '../../../../../types/place.types';
import { useGetVenuesQuery } from '../../../../../services/endpoints/places/venue';
import { useGetAllOrganizationsQuery } from '../../../../../services/endpoints/places/organization';
import { useGetCoursesSimpleQuery } from '../../../../../services/endpoints/schedule/course';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import YesNoCell from '../../../../../components/tables/cellComponents/YesNoCell';
import { useDataContext } from '../../../../../context';

interface DataRow extends SFCVenue {
}

const defaultScope = 'active';

export const userCanEditCreateView_Venues = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin || user?.isGlobal || user?.isLocationAdmin) {
    return true;
  }

  return false;
}

export const VenuesView = () => {
  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  const navigate = useNavigate();
  const currentUser = getCurrentUser();
  const isGlobalUser = isGlobal(currentUser?.user_type_id!);
  const isLocalUser = isLocal(currentUser?.user_type_id!);
  const isAdminUser = isAdmin(currentUser?.user_type_id!);

  const defaultFilter = { scope: defaultScope, data: {page: 1, entries: 10} };

  const [filters, setFilters] = useState<GetQueryParams>(defaultFilter);
  const { data, error, isLoading, isFetching } = useGetVenuesQuery(filters, {skip: !isLocalUser});
  const {data: organizations, error: organizationsError, isLoading: organizationsLoading} = useGetAllOrganizationsQuery({data: {orderBy: {field: 'name', order: 'asc'}}}, {skip: !isAdminUser});
  const {data: courses, error: coursesError, isLoading: coursesLoading} = useGetCoursesSimpleQuery({data: {orderBy: {field: 'title', order: 'asc'}}}, {skip: !isAdminUser});
  const [scopes, setScopes] = useState<Scopes>({});
  const [overrides, setOverrides] = useState<timesheetOverride[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  
  const filterFormFields: FilterField[] = [
    {
      name: 'title',
      label: 'Title',
    },
    {
      name: 'id',
      label: 'ID',
      type: 'number',
    },
    {
      name: 'location_id',
      label: 'Location',
      type: 'number',
      element: (
        <FormSelect
          name="location_id"
          label="Location"
          options={locationOptions}
          
        />
      ),
    },
    {
      name: 'organization_id',
      label: 'Organization',
      type: 'number',
      element: (
        <FormSelect
          name="organization_id"
          label="Organization"
          options={organizations?.results.map(i => ({value: i.id, label: i.name})) ?? []}
          loadingError={organizationsError ? "Error loading organizations" : undefined}
        />
      )
    },
    {
      name: 'course_id',
      label: 'Session',
      type: 'number',
      element: (
        <FormSelect
          name="course_id"
          label="Session"
          options={courses?.results.map(r => ({value: r.id, label: r.title})) ?? []}
          loadingError={coursesError ? "Error loading courses" : undefined}
        />
      )
    },
    {
      name: 'phone',
      label: 'Phone',
    },
    {
      name: 'address',
      label: 'Address',
    },
    {
      name: 'city',
      label: 'City',
    },
    {
      name: 'state',
      label: 'State',
    },
    {
      name: 'zip',
      label: 'Zip',
    },
    {
      name: 'ends_on',
      id: 'date_from',
      operator: 'greater_equal_than',
      element: (
        <DateField name="date_from" label="Last Session End Date Start" />
      )
    },
    {
      name: 'ends_on',
      id: 'date_to',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="date_to" label="Last Session End Date End" />
      )
    },
  ];

  if (!isGlobalUser) {
    // remove location filter
    filterFormFields.splice(2,1);
  }

  const columns: ColumnDef<DataRow>[] = [
    {
      header: 'ID',
      accessorKey: 'id',
    },
    {
      header: 'Title',
      accessorKey: 'title',
    },
    {
      header: 'Organizations',
      accessorKey: 'organizations',
      enableSorting: false,
      cell: ({ row }) => (
        <>
          {row.original.organizations?.map(o => (
            <React.Fragment key={o.id}>
              <LinkCell content={o.name} url={`/admin/places/organizations/${o.id}`} /><br />
            </React.Fragment>
          ))
          }
        </>
      )
    },
    {
      header: 'Location',
      accessorKey: 'location',
      enableSorting: false,
      cell: ({ getValue }) => {
        let location = getValue() as SFCLocation;
        return <LinkCell content={location?.title ?? "error"} url={`/admin/places/locations/${location?.id ?? 0}`} />;
      }
    },
    {
      header: 'Address',
      accessorKey: 'address',
    },
    {
      header: 'City',
      accessorKey: 'city',
    },
    {
      header: 'State',
      accessorKey: 'state',
    },
    {
      header: 'Last Session End Date',
      accessorKey: 'ends_on',
      cell: ({ getValue }) => (formatDateString(getValue<any>()))
    },
    {
      header: 'Is Active',
      accessorKey: 'is_active',
      cell: ({ getValue }) => (<YesNoCell value={getValue<boolean>()} />)
    },
    {
      header: '',
      accessorKey: 'blank',
      enableSorting: false,
      cell: ({ row }) => (
        <>
          <LinkCell content='View' url={`/admin/places/venues/${row.original.id}`} />
          &nbsp;
          &nbsp;
          <LinkCell content='Edit' url={`/admin/places/venues/${row.original.id}/edit`} />
        </>
      )
    },
  ];

  useEffect(() => {
    if (data && data.results) {
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => {
          let scope_count = 0;
          if(data.scope_counts != null) {
            scope_count = data.scope_counts[scope] ?? 0;
          }
          return {
            title: `${title} (${scope_count})`,
            key: scope,
          }
        })));
      }
      setOverrides(data.results);
    }
  },[data]);
  
  useEffect(() => {
    if (error) {
      addToast(`Error while loading data: ${JSON.stringify(error)}`, 'error');
    }
  }, [error]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  if (!isLocalUser) {
    return <>Error: Forbidden</>
  }

  if (error || isLoading) {
    return <></>
  }

  return (
    <div className="py-2">
      <SFCTable
        name={scopes[filters?.scope!]}
        columns={columns}
        data={overrides}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath='venues'
        filters={filters?.data?.filterBy}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
        newItemEl={(
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            onClick={() => navigate(`/admin/places/venues/new`)}
          >
            New venue
          </SimpleButton>
        )}
      />
    </div>
  )
}

