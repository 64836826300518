import React from "react";
import { Table, Badge, Button } from "react-bootstrap";
import CurrencyCell from "../cellComponents/CurrencyCell";
import { DateCell } from "../cellComponents/DateCell";
import { Invoice } from "../../../types/finance.types";




interface InvoiceTableProps {
    invoices: Invoice[]
    setLocationId: React.Dispatch<React.SetStateAction<number | null>>;
    setInvoiceId: React.Dispatch<React.SetStateAction<number | null>>;
    setAmount: React.Dispatch<React.SetStateAction<string | null>>;
    setShouldFetch: React.Dispatch<React.SetStateAction<boolean>>;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  }

const InvoiceTable: React.FC<InvoiceTableProps> = ({
  invoices,
  setLocationId,
  setInvoiceId,
  setAmount,
  setShouldFetch,
  setShowModal,
}) => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Id</th>
          <th>Invoice Name</th>
          <th>Amount</th>
          <th>Payments</th>
          <th>Due Date</th>
          <th>Amount Due</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {invoices.map((invoice) => (
          <tr key={invoice.id}>
            <td>{invoice.id}</td>
            <td>{invoice.name}</td>
            <td>
              <CurrencyCell value={invoice.remaining_balance} />
            </td>
            <td>
              <DateCell date={invoice.issue_on} format={"MM/dd/yyyy"} />
            </td>
            <td>
              <DateCell date={invoice.due_on} format={"MM/dd/yyyy"} />
            </td>
            <td>
              <CurrencyCell value={invoice.total} />
            </td>
            <td>
              {invoice.status === "paid" ? (
                <Badge bg="danger" text="white">
                  Paid
                </Badge>
              ) : (
                <Button
                  onClick={() => {
                    setLocationId(Number(invoice?.location_id));
                    setInvoiceId(Number(invoice?.id));
                    setAmount(String(invoice.remaining_balance));
                    setShouldFetch(true);
                    setShowModal(true);
                  }}
                >
                  Pay
                </Button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default InvoiceTable;
