import FranchiseBtn from "../../assets/images/SFC-li-img.png";
import OutletBtn from "../../assets/images/location-pin.png";

export const usStates = ["California","Colorado","Illinois","Texas","Virginia"];
export const franchiseLocations = [
  {
    state: "California",
    franchises: ["San Diego", "3010 N Evergreen St"]
  },
  {
    state: "Colorado",
    franchises: ["Denver | Boulder"]
  },
  {
    state: "Illinois",
    franchises: ["Northern Chicago"]
  },
  {
    state: "Texas",
    franchises: ["Austin", "Dallas"]
  },
  {
    state: "Virginia",
    franchises: ["Northern Virginia"]
  }
];

export const buttonLocations = [
  { title: "San Diego", x: 10, y: 53, imageUrl: FranchiseBtn, type: 'franchise' },
  { title: "Austin", x: 47, y: 67, imageUrl: FranchiseBtn, type: 'franchise' },
  { title: "Dallas", x: 49, y: 59, imageUrl: FranchiseBtn, type: 'franchise' },
  { title: "Northern Virginia", x: 84, y: 34, imageUrl: FranchiseBtn, type: 'franchise' },
  { title: "Northern Chicago", x: 64, y: 30, imageUrl: FranchiseBtn, type: 'franchise' },
  { title: "Denver | Boulder", x: 37, y: 41, imageUrl: OutletBtn, type: 'outlet' },
];