/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SimpleButton } from "../../../../components/buttons/SimpleButton";
import { FaArrowLeft } from "react-icons/fa6";
import {
  Accordion,
  Button,
  Col,
  Form,
  Row,
  Spinner,
  Stack,
} from "react-bootstrap";
import { SFCAccordion } from "../../../../components/accordion/SFCAccordion";
import { useToast } from "../../../../context/ToastContext";
import { DetailSection, LabelText } from "../../../../components/generic";
import {
  Child,
  useGetAdminQuery,
  useGetInstructorQuery,
  useMimicMutation,
  User,
  UserType,
} from "../../../../services/endpoints/people/user";
import { useGetParentQuery } from "../../../../services/endpoints/people/parent";
import { SimpleTable } from "../../../../components/tables/SimpleTable";
import {
  childTableColumns,
  ordersTableColumns,
} from "../../../../utils/consts/columnDefs/parentColumns";
import { userCanEditCreateDelete_Instructors } from "./InstructorsView";
import { getCurrentUser } from "../../../../services/helper";
import { userCanEditCreateDelete_AdminUser } from "./AdminsView";
import { userCanEditCreateDelete_ParentUser } from "./ParentsView";

import {
  Formik,
  Form as FormikForm,
  FormikErrors,
  FormikTouched,
  FormikProps,
  Field,
} from "formik";
import { CreateFormValues, FormValues, OrdersForm } from "./OrdersForm";
import * as Yup from "yup";
import { FormSelect } from "../../../../components/tables/filterForms/FormSelect";
import Typeahead from "react-bootstrap-typeahead/types/core/Typeahead";
import { Discount } from "../../../../types/orders.type";
import { getErrorMessage } from "../../../../utils/utils";
import { useCreateOrderMutation } from "../../../../services/endpoints/orders/orders";
import { revisionsColumns } from "../../../../utils/consts/columnDefs/sessionColumns";

const LabelTextList = ({ label, list }: { label: string; list: string[] }) => (
  <Stack direction="vertical" gap={1}>
    <span className="fw-semibold" style={{ color: "rgba(94, 100, 105, .5)" }}>
      {label}
    </span>
    {list.map((text, index) => (
      <span key={index} className="text-secondary fw-semibold">
        {text}
      </span>
    ))}
  </Stack>
);

const userLinksMap = (user: UserType, id?: number | string) => {
  if (user === "customer") {
    return {
      new: `/admin/sfc-users/parents/new`,
      edit: `/admin/sfc-users/parents/${id}/edit`,
      back: `/admin/sfc-users/parents`,
    };
  }
  if (user === "instructor") {
    return {
      new: `/admin/dash-users/instructors/new`,
      edit: `/admin/dash-users/instructors/${id}/edit`,
      back: `/admin/dash-users/instructors`,
    };
  } else {
    return {
      new: `/admin/dash-users/admins/new`,
      edit: `/admin/dash-users/admins/${id}/edit`,
      back: `/admin/dash-users/admins`,
    };
  }
};

interface UserDetailsViewProps {
  userType: UserType;
}

export const UserDetailsView: React.FC<UserDetailsViewProps> = ({
  userType,
}) => {
  const currentUser = getCurrentUser();
  const params = useParams();
  const navigate = useNavigate();
  const userID = +params.id!;
  const { addToast } = useToast();
  const [mimic] = useMimicMutation();
  const [currentStep, setCurrentStep] = useState(0);
  const [checkedValues, setCheckedValues] = useState<string[]>([]);
  const [createOrder] = useCreateOrderMutation();

  const [initialValues, setInitialValues] = useState<FormValues>({
    user_id: { label: "" },
    scholarship_id: { label: "" },
    course_id: { label: "" },
    child_ids: [],
  });

  const validationSchema = Yup.object({
    // user_id: Yup.object({value: Yup.number().required('User selection is required'), label: Yup.string().optional()}).required(),
    scholarship_id: Yup.object({
      value: Yup.number().required("Scholarship selection is required"),
      label: Yup.string().optional(),
    }).required(),
    course_id: Yup.object({
      value: Yup.number().required("Course selection is required"),
      label: Yup.string().optional(),
    }).required(),
    // child_id: Yup.object({
    //   value: Yup.number().optional(),
    //   label: Yup.string().optional(),
    // }).optional(),
  });

  const {
    data: adminData,
    error: adminError,
    isLoading: loadingAdmin,
  } = useGetAdminQuery(userID, {
    skip: userType === "instructor" || userType === "customer",
  });
  const {
    data: instructorData,
    error: instructorError,
    isLoading: loadingInstructor,
  } = useGetInstructorQuery(userID, {
    skip: userType !== "instructor",
  });
  const {
    data: parentData,
    error: parentError,
    isLoading: loadingParent,
  } = useGetParentQuery(userID, {
    skip: userType !== "customer",
  });

  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (adminError && !loadingAdmin) {
      addToast("Error while loading admin", "error");
    }
  }, [adminData, adminError, loadingAdmin]);

  useEffect(() => {
    if (instructorError && !loadingInstructor) {
      addToast("Error while loading instructor", "error");
    }
  }, [instructorData, instructorError, loadingInstructor]);

  useEffect(() => {
    if (parentError && !loadingParent) {
      addToast("Error while loading parent", "error");
    }
  }, [parentData, parentError, loadingParent]);

  useEffect(() => {
    if (adminData) {
      setUser({
        ...adminData.admin,
        id: userID,
      });
    }

    if (instructorData) {
      setUser({
        ...instructorData.instructor,
        id: userID,
      });
    }

    if (parentData) {
      setUser({
        ...parentData.parent,
        id: userID,
      });
    }
  }, [adminData, instructorData, parentData]);

  const steps = [{ name: "Orders", form: OrdersForm }];

 
  const handleSumbit = async (formValue: FormValues) => {
   
    try {
          const submission:any = {};//@TODO: set the correct type.
          submission.parent_id = user?.id as number;
          submission.scholarship_id = formValue.scholarship_id?.value ?? 0;
          submission.course_id = (formValue.course_id?.value ?? 0) as number;
          submission.child_ids = checkedValues ? (checkedValues ?? []) : undefined;
          
         
            const res = await createOrder(submission).unwrap();
            if (res && res.order) {
              //navigate(`/admin/orders/discounts/${res.discount.id}`);
              addToast('Order created succesfully', 'success');
            } else {//throw new Error('A problem happened while creating and order');
              addToast(getErrorMessage(res), 'error');
            }
        } catch (e) {
          addToast(getErrorMessage(e), 'error');
        }
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
  
    let checkValues = [...checkedValues];
  
    if (checked) {
      if (!checkValues.includes(id)) { // Prevent duplicate entries
        checkValues.push(id);
      }
    } else {
      checkValues = checkValues.filter((value) => value !== id);
    }
  
    setCheckedValues(checkValues);
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>,
    setTouched: (
      touched: FormikTouched<FormValues>,
      shouldValidate?: boolean
    ) => void,
    values: FormValues
  ) => {
    const errors = await validateForm();
   
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        user_id: { value: true },
        scholarship_id: { value: true },
      });
    }
  };
  if (loadingAdmin || loadingInstructor || loadingParent) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    );
  }

  const accordion = (
    <SFCAccordion defaultActiveKey={["0", "1", "2"]}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Children</Accordion.Header>
        <Accordion.Body>
          <SimpleTable
            columns={childTableColumns}
            data={user?.children ?? []}
          />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Orders</Accordion.Header>
        <Accordion.Body>
          <SimpleTable columns={ordersTableColumns} data={user?.orders ?? []} />

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSumbit}
            enableReinitialize
          >
            {({
              submitForm,
              isSubmitting,
              validateForm,
              touched,
              errors,
              setTouched,
              values,
              setFieldValue,
            }: FormikProps<FormValues>) => (
              <FormikForm className="text-start">
                <div>
                  {currentStep < steps.length &&
                    React.createElement(steps[currentStep]?.form, {
                      errors,
                      touched,
                      values,
                      setFieldValue,
                    })}
                </div>

                <Form.Group className="mb-1 mb-lg-3" controlId="child">
                  {user?.children &&
                    Array.isArray(user.children) &&
                    user.children.length > 0 &&
                    user.children.map((child, idx) => (
                      <Form.Check
                        key={idx}
                        type="checkbox"
                        id={String(child?.id)}
                        label={child.full_name}
                        onChange={handleCheckboxChange}
                      />
                    ))}
                </Form.Group>
                <Row className="justify-content-end mt-3">
                  <Col xs={6} lg={3}>
                    {currentStep !== 0 && (
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={() => setCurrentStep(currentStep - 1)}
                        className="w-100"
                        variant="outline-primary"
                      >
                        Back
                      </SimpleButton>
                    )}
                  </Col>

                  <Col xs={6} lg={3}>
                    <SimpleButton
                      disabled={isSubmitting }
                      type="button"
                      onClick={
                        currentStep === steps.length - 1
                          ? submitForm
                          : () => handleNext(validateForm, setTouched, values)
                      }
                      className="w-100"
                      variant="primary"
                    >
                      {currentStep === steps.length - 1 ? "Create Order" : "Continue"}
                    </SimpleButton>
                  </Col>
                </Row>
              </FormikForm>
            )}
          </Formik>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>Revisions</Accordion.Header>
        <Accordion.Body>
        <SimpleTable
            columns={revisionsColumns.filter((column) => column.header !== 'Comment')}
            data={user?.audits ?? []}
          />
        </Accordion.Body>
      </Accordion.Item>
    </SFCAccordion>
  );

  const userDetails = (
    <>
      <DetailSection title="Details">
        <LabelText label="ID:">{user?.id}</LabelText>
        <LabelText label="Address:" direction="vertical">
          {user?.address}
          {user?.address2 ? `, ${user?.address2}` : ""}
          <br />
          {user?.city}
          {user?.state ? `, ${user?.state}` : ""}
          {user?.zip ? `, ${user?.zip}` : ""}
        </LabelText>
        <LabelText label="Email:">{user?.email}</LabelText>
        <LabelText label="Phone:">{user?.phone}</LabelText>

        {userType !== "customer" && (
          <>
            <hr />
            <LabelText label="In-Person Rate:">
              ${user?.instructor_session_rate?.toFixed(2)}
            </LabelText>
            <LabelText label="In-Person Reimbursement:">
              ${user?.instructor_session_reimbursement?.toFixed(2)}
            </LabelText>
            <LabelText label="Online Rate:">
              ${user?.instructor_online_rate?.toFixed(2)}
            </LabelText>
            <LabelText label="Online Reimbursement:">
              ${user?.instructor_online_reimbursement?.toFixed(2)}
            </LabelText>
          </>
        )}
        {/* TODO: add refunds summary */}
        <LabelText label="Total Refunds:">N/A</LabelText>
      </DetailSection>

      {/* TODO: add discounts link and discounts list items */}
      <DetailSection title="Scholarships">
        {/* <LinkCell content="Manage Contacts" url={`/admin/places/venues/${user?.courses?.[0]?.venue?.id}`} underline />
        {user?.courses.some((c) => c.venue.contacts.length > 0)
          ? user?.courses.map((c) => c.venue.contacts.map((contact) => (
          <LabelTextList label={`${contact.full_name}:`} list={[contact.email]} />
          ))) 
          : <span className="fst-italic">There are no contacts to show.</span>
        } */}
        Discounts
      </DetailSection>

      {/* TODO: add videos list */}
      <DetailSection title="Videos">
        {/* {user?.courses.map((c) => c.venue.organizations.map((o) => (
          <LabelTextList label={`${o.name} Notes`} list={[o.notes]} />
        )))} */}
        Videos
      </DetailSection>
    </>
  );

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: "#EFEFEF" }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button
            className="btn btn-link p-0 text-info"
            onClick={() =>
              navigate(userLinksMap(userType, user?.id)?.back ?? "")
            }
          >
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: "#888888", fontSize: "1rem" }}
          >
            Dash Users
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">
            {user?.first_name} {user?.last_name}
          </h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          {((userCanEditCreateDelete_Instructors(currentUser) &&
            userType === "instructor") ||
            (userCanEditCreateDelete_AdminUser(currentUser) &&
              userType === "admin") ||
            (userCanEditCreateDelete_ParentUser(currentUser) &&
              userType === "customer")) && (
            <SimpleButton
              variant="primary"
              height={36}
              onClick={() =>
                navigate(userLinksMap(userType, user?.id)?.edit ?? "")
              }
            >
              Edit {userType}
            </SimpleButton>
          )}
          <SimpleButton
            variant="secondary"
            height={36}
            onClick={() => {
              if (user?.id) {
                mimic(user.id)
                  .unwrap()
                  .then((r) => {
                    window.location.reload();
                  });
              } else {
                console.error("user Id is undefined");
              }
            }}
          >
            Mimic
          </SimpleButton>
        </Stack>
      </div>
      <div className="d-lg-none">
        <Stack gap={4} className="p-4">
          {userDetails}
          {accordion}
        </Stack>
      </div>
      <div className="d-none d-lg-flex">
        <div
          className="mt-5 w-100 px-5 py-4"
          style={{ maxWidth: "calc(100% - 400px)" }}
        >
          {accordion}
        </div>
        <Stack
          gap={4}
          className="p-4 vh-100"
          style={{ width: 400, background: "#F2F2F2" }}
        >
          {userDetails}
        </Stack>
      </div>
    </>
  );
};
