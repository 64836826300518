import { ButtonData } from '../../../../components/buttonGroup/ButtonGroup';
import { useEffect, useState } from 'react';
import SFCTable from '../../../../components/tables/SFCTable';
import { ColumnDef } from '@tanstack/react-table';
import { Scopes, useGetAdminsQuery, useGetInstructorsQuery } from '../../../../services/endpoints/people/user';
import { hasDashAccess, isGlobal, isLocal, timesheetOverrideTypes } from '../../../../utils/consts';
import { formatDateString } from '../../../../utils/utils';
import { FilterField, FilterForm } from '../../../../components/tables/filterForms/FilterForm';
import { FormSelect } from '../../../../components/tables/filterForms/FormSelect';
import { Form, InputGroup } from 'react-bootstrap';
import { useToast } from '../../../../context/ToastContext';
import { getCurrentUser } from '../../../../services/helper';
import { GetQueryParams } from '../../../../types/api.type';
import { Field, Form as FormikForm } from 'formik';
import { useGetTimesheetOverridesQuery, useUpdateTimesheetOverrideMutation } from '../../../../services/endpoints/finance/timesheets';
import { CourseTypeCell } from '../../../../components/tables/cellComponents/CourseTypeCell';
import { LinkCell } from '../../../../components/tables/cellComponents/LinkCell';
import { timesheetOverride } from '../../../../types/finance.types';
import CurrencyCell from '../../../../components/tables/cellComponents/CurrencyCell';
import { SimpleButton } from '../../../../components/buttons/SimpleButton';
import * as Yup from 'yup';
import { DateField } from '../../../../components/tables/filterForms/DateField';
import { OverrideLegend } from '../../../../components/generic';
import YesNoCell from '../../../../components/tables/cellComponents/YesNoCell';
import { useDataContext } from '../../../../context';

interface DataRow extends timesheetOverride {
}

const defaultScope = 'pending';

export const OverridesView = () => {
  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  const currentUser = getCurrentUser();
  const has_admin_access = hasDashAccess(currentUser?.user_type_id!);
  const isGlobalUser = isGlobal(currentUser?.user_type_id!);
  const isLocalUser = isLocal(currentUser?.user_type_id!);

  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope });
  const { data, error, isLoading, isFetching } = useGetTimesheetOverridesQuery(filters, {skip: !isLocalUser});
  const [ updateTimesheetOverride ] = useUpdateTimesheetOverrideMutation();
  const {data: instructors, error: instructorsError, isLoading: instructorsLoading} = useGetInstructorsQuery({data: {orderBy: {field: 'first_name', order: 'asc'}}}, {skip: !isLocalUser});
  const {data: admins, error: adminsError, isLoading: adminsLoading} = useGetAdminsQuery({data: {orderBy: {field: 'first_name', order: 'asc'}}}, {skip: !isLocalUser});
  const [scopes, setScopes] = useState<Scopes>({});
  const [overrides, setOverrides] = useState<timesheetOverride[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  
  const filterFormFields: FilterField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'number',
    },
    {
      name: 'location_id',
      label: 'Location',
      type: 'number',
      element: (
        <FormSelect
          name="location_id"
          label="Location"
          options={locationOptions}
        />
      ),
    },
    {
      name: 'instructor_id',
      label: 'Instructor',
      type: 'number',
      element: (
        <FormSelect
          name="instructor_id"
          label="Instructor"
          options={instructors?.results.map(i => ({value: i.id, label: i.full_name})) ?? []}
          loadingError={instructorsError ? "Error loading instructors" : undefined}
        />
      )
    },
    {
      name: 'admin_id',
      label: 'Admin',
      type: 'number',
      element: (
        <FormSelect
          name="admin_id"
          label="Admin"
          options={admins?.results.map(r => ({value: r.id, label: r.full_name})) ?? []}
          loadingError={adminsError ? "Error loading admins" : undefined}
        />
      )
    },
    {
      name: 'timesheet_override_type',
      label: 'Timesheet Override Type',
      type: 'number',
      element: (
        <FormSelect
          name="timesheet_override_type"
          label="Timesheet Override Type"
          options={timesheetOverrideTypes?.map((tot, idx) => ({ value: idx + 1, label: tot }))}
        />
      ),
    },
    {
      name: 'override_approved',
      label: 'Override Approved',
      type: 'number',
      element: (
        <FormSelect
          name="override_approved"
          label="Override Approved"
          options={[{value: 1, label: "Yes"}, {value: 0, label: "No"}]}
        />
      ),
    },
    {
      name: 'denial_notes',
      label: 'Denial Notes',
    },
    {
      name: 'override_request',
      label: 'Override Request',
    },
    {
      name: 'date',
      id: 'date_from',
      operator: 'greater_equal_than',
      element: (
        <DateField name="date_from" label="Class Date Start" />
      )
    },
    {
      name: 'date',
      id: 'date_to',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="date_to" label="Class Date End" />
      )
    },
    {
      name: 'approved_at',
      id: 'approved_from',
      operator: 'greater_equal_than',
      element: (
        <DateField name="approved_from" label="Approved At Start" />
      )
    },
    {
      name: 'approved_at',
      id: 'approved_to',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="approved_to" label="Approved At End" />
      )
    },
  ];

  if (!isGlobalUser) {
    // remove location filter
    filterFormFields.splice(1,1);
  }

  const columns: ColumnDef<DataRow>[] = [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: ({ getValue, row }) => {
        return (<>
        <FormikForm id={`form_${row.id}`}></FormikForm>
        {getValue()}
        </>);
      }
    },
    {
      header: 'Location',
      accessorKey: 'lesson.course.venue.location.title',
      enableSorting: false,
    },
    {
      header: 'Class',
      accessorKey: 'lesson.date',
      enableSorting: false,
      cell: ({ getValue }) => (formatDateString(getValue<string>()))
    },
    {
      header: 'Session',
      accessorKey: 'lesson.course',
      enableSorting: false,
      cell: ({ getValue, row }) => {
        let value: {id: number, is_online: boolean, title: string} = getValue() as any;
        if (value) {
          return (
            <>
              <CourseTypeCell is_online={value.is_online} value={
                <LinkCell content={value.title} url={`/admin/schedule/sessions/${value.id}`} />
              } />
            </>
          );
        }
      }
    },
    {
      header: 'Instructor',
      accessorKey: 'instructor.full_name',
      enableSorting: false,
      cell: ({ getValue, row }) => (`${getValue()} (${row.original.role ?? ''})`)
    },
    {
      header: 'Override Type/Request',
      accessorKey: 'timesheet_override_type',
      enableSorting: false,
      cell: ({ row }) => (
        <><b>{row.original.timesheet_override_type ? timesheetOverrideTypes[row.original.timesheet_override_type-1] : ''}:</b> {row.original.override_request}</>
      ),
    },
    {
      header: 'Standard\nReimbursement',
      accessorKey: 'standard_reimbursement',
      cell: ({ getValue }) => (
        <CurrencyCell value={getValue<string>()} />
      ),
    },
    {
      header: 'Override\nReimbursement',
      accessorKey: 'override_reimbursement',
      cell: ({ getValue, row }) => (
        has_admin_access && row.original.is_pending ? (
          <Form.Group controlId="override_reimbursement" className={"input-group input-group-sm"}>
            <InputGroup.Text>$</InputGroup.Text>
            <Field
              name="override_reimbursement"
              className={"form-control"}
            >
            </Field>
          </Form.Group>
        ) : <CurrencyCell value={getValue<string>()} />
      ),
    },
    {
      header: 'Standard\nRate',
      accessorKey: 'standard_rate',
      cell: ({ getValue }) => (
        <CurrencyCell value={getValue<string>()} />
      ),
    },
    {
      header: 'Override Rate',
      accessorKey: 'override_rate',
      cell: ({ getValue, row }) => (
        has_admin_access && row.original.is_pending ? (
          <Form.Group controlId="override_rate" className={"input-group input-group-sm"}>
            <InputGroup.Text>$</InputGroup.Text>
            <Field
              name="override_rate"
              className={"form-control"}
            />
          </Form.Group>
        ) : <CurrencyCell value={getValue<string>()} />
      ),
    },
    {
      header: 'Approved',
      accessorKey: 'override_approved',
      cell: ({ getValue, row }) => (
        has_admin_access && row.original.is_pending ? (
          <Form.Group controlId="override_approved" className={"input-group input-group-sm"}>
            <Field
              name="override_approved"
              as={Form.Select}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Field>
          </Form.Group>
        ) : <YesNoCell value={getValue<boolean>()} />)
    },
    {
      header: 'Denial Notes',
      accessorKey: 'denial_notes',
      cell: ({ getValue, row }) => (
        has_admin_access && row.original.is_pending ? (<Form.Group controlId="denial_notes">
        <Field
          as="textarea"
          rows={4}
          cols={55}
          name="denial_notes"
          className={"form-control"}
        >
        </Field>
      </Form.Group>) : getValue()
      )
    },
    {
      header: '',
      accessorKey: 'blank',
      enableSorting: false,
      cell: ({ getValue, row }) => (
        has_admin_access && row.original.is_pending ? (
        <SimpleButton height={45} type="submit" form={`form_${row.id}`} className="text-center btn-sm" variant="primary">
          Update
        </SimpleButton>) : getValue()
      )
    },
  ];

  useEffect(() => {
    if (data && data.results) {
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => {
          let scope_count = 0;
          if(data.scope_counts != null) {
            scope_count = data.scope_counts[scope] ?? 0;
          }
          return {
            title: `${title} (${scope_count})`,
            key: scope,
          }
        })));
      }
      setOverrides(data.results);
    }
  },[data]);
  
  useEffect(() => {
    if (error) {
      addToast(`Error while loading data: ${JSON.stringify(error)}`, 'error');
    }
  }, [error]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };
  
  const tableValidationSchema = Yup.object({
    override_id: Yup.number(),
    override_reimbursement: Yup.string(),
    override_rate: Yup.string(),
    override_approved: Yup.boolean(),
    denial_notes: Yup.string(),
  })

  const onTableFormSubmit = (values: any) => {
    updateTimesheetOverride(values).unwrap().then(() => {
      addToast("Successfully updated timesheet override", "success");
    }).catch((error: any) => {
      console.log(error);
      addToast(`Failed to update timesheet override: ${JSON.stringify(error)}`, "error");
    });
  }

  if (!isLocalUser) {
    return <>Error: Forbidden</>
  }

  if (error || isLoading) {
    return <></>
  }

  return (
    <div className="py-2">
      <SFCTable
        name={scopes[filters?.scope!]}
        columns={columns}
        data={overrides}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath='timesheet-overrides'
        filters={filters?.data?.filterBy}
        sidebars={[<OverrideLegend />]}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
        rowFormProps={{
          validationSchema: tableValidationSchema,
          onSubmit: onTableFormSubmit,
          getInitialValues: (row) => ({
            override_id: row.original.id,
            override_rate: row.original.override_rate ?? '',
            override_reimbursement: row.original.override_reimbursement ?? '',
            override_approved: true,
          })
        }}
      />
    </div>
  )
}
