import React, { useEffect, useState } from 'react';
import CustomButtonGroup, { ButtonData } from '../buttonGroup/ButtonGroup';
import { Stack } from 'react-bootstrap';

export interface FilterGroupProps {
  filterGroups: Array<ButtonData[]> | undefined;
  defaultScope?: string;
  onFilterSelect: (filter: string) => void;
}

export const FilterGroup: React.FC<FilterGroupProps> = ({ filterGroups, defaultScope, onFilterSelect }) => {

  const [activeIndex, setActiveIndex] = useState<string | null>(`${0}-${0}`);

  useEffect(() => {
    setActiveTabIndex(defaultScope ?? '');
  },[]);
  
  const setActiveTabIndex = (keyvalue:string) => {
    if (filterGroups) {
      for (let i = 0; i < filterGroups?.length; i++) {
        const innerIndex = filterGroups[i].findIndex(obj => obj.key === keyvalue);
        if (innerIndex !== -1) {
          setActiveIndex(`${i}-${innerIndex}`);
        }
      }
    }
  };
  if (!filterGroups) {
    return <></>;
  }

  const handleButtonClick = (group: number, index: number) => {
    setActiveIndex(`${group}-${index}`);
    onFilterSelect(filterGroups[group][index].key ?? '');
  };

  return (
    <Stack direction="horizontal" gap={4} className="mb-3 overflow-auto">
      {filterGroups.map((buttons: ButtonData[], index: number) => (
        <CustomButtonGroup
          key={index}
          group={index}
          buttons={buttons}
          activeIndex={activeIndex}
          onButtonClick={handleButtonClick}
        />
      ))}
    </Stack>
  );
};

