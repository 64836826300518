import { ReactNode } from "react";
import { Stack, Image } from "react-bootstrap";
import { StackDirection } from "react-bootstrap/esm/Stack";
import SFCDashLogo from '../../assets/images/SFC-dash-logo.png';
import "./index.css";

export const DetailSection = ({ title, children }: { title?: string, children: ReactNode }) => (
  <div className="p-2 rounded-1" style={{ background: '#FFFFFF' }}>
    <span className="fw-bold" style={{ color: 'rgba(94, 100, 105, 0.75)', fontSize: '1.125rem' }}>
      {title}
    </span>
    <Stack gap={2} className="mt-2">
      {children}
    </Stack>
  </div>
);

export const LabelText = ({ direction = 'horizontal', label, text, children }:
  { direction?: StackDirection, label: string, text?: string | number, children?: ReactNode }) => (
  <Stack direction={direction} gap={direction === 'horizontal' ? 2 : 0} className="align-items-start align-content-center">
    <span 
      className="fw-semibold" 
      style={{ color: 'rgba(94, 100, 105, .5)' }}
      dangerouslySetInnerHTML={{ __html: label }}
    />
    <span className="text-secondary fw-semibold">{text ?? children}</span>
  </Stack>
);

export const LabelTextSpread = ({ label, text, children, fontSize, opacity = .5 }:
  { label: string|ReactNode, text?: string | number, children?: ReactNode, fontSize?: string|number, opacity?: number }) => (
  <Stack direction={'horizontal'} className="align-items-start align-content-center" style={{ color: `rgba(94, 100, 105, ${opacity})`, fontSize: fontSize ?? '1rem' }}>
    <span className="fw-semibold" >{label}</span>
    <span className="fw-semibold ms-auto">{text ?? children}</span>
  </Stack>
);

export const TimesheetIntro = () => (
  <DetailSection title="Details">
    <p><b>Pay periods are the 1st-15th and 16th-end of the month.</b></p>
    <p>Timesheets are automatically submitted the day after each pay period ends.</p>
    <p>Additional reimbursement requests can be submitted for approval anytime during the pay period, and must be submitted before 5pm MST the day each pay period ends.</p>
    <p>Paychecks are paid on the 5th and 20th of each month.</p>
    <p>Follow these guidelines when making a request for additional reimbursement:
      <a href={`https://sfc-public.s3.us-west-2.amazonaws.com/SFC_Timesheet_%2B_Payroll_Information_-_DASH_DOC_(updated_8_29_23).pdf`} target="_blank"> Timesheet + Payroll Information</a></p>
  </DetailSection>
);


export const InvoiceLegend = () => {
  return (
    <DetailSection title="Legend">
      <div className="swatch draft">Draft</div>
      <div className="swatch sent">Sent</div>
      <div className="swatch late">Late</div>
      <div className="swatch paid">Paid</div>
      <span><strong>Open:</strong> This view groups Draft, Sent and Late statuses together.</span>
      <span><strong>Draft:</strong> The invoice has been created but not yet sent.</span>
      <span><strong>Sent:</strong> The invoice has been sent and is pending payment.</span>
      <span><strong>Late:</strong> The invoice has been sent and is past due for payment.</span>
      <span><strong>Paid:</strong> The invoice has been paid.</span>
    </DetailSection>
  )
}

export const WaitlistLegend = () => {
  return (
    <DetailSection title="Legend">
      <div className="pending swatch">Pending</div>
      <div className="invited swatch">Invited</div>
      <div className="expired swatch">Expired</div>
      <div className="denied swatch">Denied</div>
      <div className="enrolled swatch">Enrolled</div>

      <span><strong>Pending:</strong> Child added to waitlist.</span>
      <span><strong>Invited:</strong> Child invited to join className.</span>
      <span><strong>Expired:</strong> Child's spot has expired.</span>
      <span><strong>Denied:</strong> Class is full.</span>
      <span><strong>Enrolled:</strong> Child has been enrolled in the session.</span>
    </DetailSection>
  )
}

export const OverrideLegend = () => {
  return (
    <DetailSection title="Legend">
      <span><strong>Pending:</strong> Override has been requested by Instructor, pending review by Admin.</span>
      <span><strong>Expired:</strong> Request for Override has expired; neither approved nor denied during the appropriate pay period.</span>
      <span><strong>Approved:</strong> Request for Override has been approved.</span>
      <span><strong>Denied:</strong> Request for Override has been denied.</span>
    </DetailSection>
  )
}

export const InstructorLegend = () => {
  return (
    <DetailSection title="Legend">
      <div className="main swatch">Main Instructor</div>
      <div className="sub swatch">Substitute Instructor</div>
      <div className="additional swatch">Additional Instructor</div>
      <div className="trainee swatch">Trainee</div>
      <div className="observer swatch">Observer</div>
    </DetailSection>
  )
}

export const CalendarLegend = () => {
  return (
    <DetailSection title="Legend">
      <div className="main swatch">Main Instructor</div>
      <div className="sub swatch">Substitute Instructor</div>
      <div className="additional swatch">Additional Instructor</div>
      <div className="trainee swatch">Trainee</div>
      <div className="observer swatch">Observer</div>
    </DetailSection>
  );
}

export const DashLogo = () => {
  return (
    <DetailSection>
      <Image className="w-100 my-3" src={SFCDashLogo} />
    </DetailSection>
  )
}
