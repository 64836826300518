import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { ColumnDef, Getter, Row } from '@tanstack/react-table';
import { Child, Scopes, SFCLocation, useMimicMutation, User } from '../../../../../services/endpoints/people/user';
import { isSuper, usTimezones } from '../../../../../utils/consts';
import { ExpanderCell } from '../../../../../components/tables/cellComponents/ExpanderCell';
import { getErrorMessage } from '../../../../../utils/utils';
import { BadgeCell } from '../../../../../components/tables/cellComponents/BadgeCell';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Button, Stack } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { getCurrentUser } from '../../../../../services/helper';
import { GetQueryParams } from '../../../../../types/api.type';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import { useGetAllChildrenQuery } from '../../../../../services/endpoints/people/child';
import { DateField } from '../../../../../components/tables/filterForms/DateField';
import { userCanLoginAs } from '../AdminsView';

interface DataRow {
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  location: SFCLocation;
  user_type_id: number;
  active: string;
  children?: any[];
  log_in_as: (id: number) => void;
}

const defaultScope = '';

export const userCanEditCreateDelete_Children = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin) {
    return true;
  }

  return false;
}

export const userCanView_Children = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin || user?.isGlobal) {
    return true;
  }

  return false;
}

export const ChildrenView = () => {
  const { addToast } = useToast();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope });
  const [scopes, setScopes] = useState<Scopes>({});
  const { data, error, isLoading, isFetching } = useGetAllChildrenQuery(filters);
  const [users, setUsers] = useState<User[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const navigate: NavigateFunction = useNavigate();
  const [mimic] = useMimicMutation();
  const currentUser = getCurrentUser();
  const canMimic = userCanLoginAs(currentUser);

  const filterFormFields: FilterField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'number',
    },
    {
      name: 'first_name',
      label: 'First Name',
    },
    {
      name: 'last_name',
      label: 'Last Name',
    },
    {
      name: 'gender',
      label: 'Gender',
    },
    {
      name: 'dob_from',
      id: 'dob_formatted',
      operator: 'greater_equal_than',
      element: (
        <DateField name="dob_from" label="Dob (From-To)" />
      )
    },
    {
      name: 'dob_to',
      id: 'dob_formatted',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="dob_to" />
      )
    },
    {
      name: 'agree_to_terms',
      label: 'Agree to terms?'
    }
  ];

  const columns: ColumnDef<Child>[] = [
    {
      header: 'ID',
      accessorKey: 'id',
    },
    {
      header: 'First Name',
      accessorKey: 'first_name',
    },
    {
      header: 'Last Name',
      accessorKey: 'last_name',
    },
    {
      header: 'Dob',
      accessorKey: 'dob',
    },
    {
      header: () => null,
      id: 'actions',
      enableSorting: false,
      cell: ({ row }) => (
        <Stack direction="horizontal" gap={2}>
          <LinkCell content="View" url={`/admin/sfc-users/children/${row.original.id}`} underline />
          {userCanEditCreateDelete_Children(currentUser) && (
            <LinkCell content="Edit" url={`/admin/sfc-users/children/${row.original.id}/edit`} underline />
          )}
        </Stack>
      )
    },
  ];

  const loginAs = (userId: number) => {
    mimic(userId).then((r) => {
      navigate("/");
    }, 
    (error: any) => {
      addToast(getErrorMessage(error), 'error');
    })
  };

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => {
          let scope_count = 0;
          if(data.scope_counts != null) {
            scope_count = data.scope_counts[scope] ?? 0;
          }
          return {
            title: `${title} (${scope_count})`,
            key: scope,
          }
        })));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      const results = data.results.map((user) => ({
        ...user,
        log_in_as: loginAs,
      }))
      setUsers(results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };
  
  return (
    <div className="py-2">
      <SFCTable
        name="Children"
        columns={columns}
        data={users}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        filters={filters?.data?.filterBy}
        indexDownloadPath='children'
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        // TODO: add children expanded items
        onFiltersChanged={setFilters}
        newItemEl={userCanEditCreateDelete_Children(currentUser) && (
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={280}
            onClick={() => navigate('/admin/sfc-users/children/new')}
          >
            New child
          </SimpleButton>
        )}
      />
    </div>
  )
}
