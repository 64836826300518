import { Col, Form, Row } from 'react-bootstrap';
import { Field, FormikErrors, FormikTouched } from 'formik';
import { useGetAllParentsQuery } from '../../../../services/endpoints/people/parent';
import { useGetAllScholarshipsQuery } from '../../../../services/endpoints/orders/scholarship';
import { useGetCoursesQuery } from '../../../../services/endpoints/schedule/course';
import { useGetAllChildrenQuery } from '../../../../services/endpoints/people/child';
import { FormSelect } from '../../../../components/tables/filterForms/FormSelect';
import { Child, User } from '../../../../services/endpoints/people/user';
import { Scholarship } from '../../../../types/orders.type';
import { scholarshipTypes } from '../../../../utils/consts/orders/scholarships';

export interface FormValues {
  user_id: { value?: number, label: string };
  scholarship_id: { value?: number, label: string };
  course_id?: { value?: number, label: string };
  child_ids?: [];
}
export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values?: FormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
}
type Overwrite<T, NewT> = Omit<T, keyof NewT> & NewT;

export type CreateFormValues = Overwrite<FormValues, {
  parent_id: number,
  scholarship_id: number;
  course_id?: number, 
  childs_id?: [],
}>
export const OrdersForm = ({ touched, errors, values, setFieldValue }: StepProps) => {
  const { data: scholarshipData, error: scholarshipError } = useGetAllScholarshipsQuery();
  const { data: courseData, error: courseError } = useGetCoursesQuery({ scope: 'upcoming' });

  return (
    <Row>
      <Col xs={12} lg={6} className="pe-1">
        {/* <Form.Group className="mb-1 mb-lg-3" controlId="user_id">
          <Field 
            as={FormSelect}
            name="user_id"
            label="User"
            options={parentData?.results?.map((user: User) => ({
              value: user.id,
              label: `${user.first_name} ${user.last_name} (${user.email})`,
            })) ?? []}
            loadingError={parentError ? 'Error loading users': undefined}
          />
          <Form.Text>Enter first name, last name, or email address to find the relevant user.</Form.Text>
        </Form.Group> */}
        <h6>New Order</h6>
 <Form.Group className="mb-1 mb-lg-3" controlId="course_id">
          <Field 
            as={FormSelect}
            name="course_id"
            label="Upcoming Session"
            options={courseData?.results?.map((course: {id: number, title: string}) => ({ value: course.id, label: course.title })) ?? []}
            loadingError={courseError ? 'Error loading sessions': undefined}
          />
        </Form.Group>

        <Form.Group className="mb-1 mb-lg-3" controlId="scholarship_id">
          <Field 
            as={FormSelect}
            name="scholarship_id"
            label="Scholarship"
            options={scholarshipData?.results?.map((item: Scholarship) => ({
              value: item.id,
              label: `${scholarshipTypes[item.scholarship_type]} (${item.percent_discount}% off)`
            })) ?? []}
            loadingError={scholarshipError ? 'Error loading scholarships' : undefined}
          />
        </Form.Group>

       

       
      </Col>
    </Row>
  )
}
