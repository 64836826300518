import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { DateField } from '../../../../../components/tables/filterForms/DateField';
import { Scopes, useGetUsersQuery, User } from '../../../../../services/endpoints/people/user';
import { receiptsColumns } from '../../../../../utils/consts/columnDefs/financeColumns';
import { useApproveReceiptsMutation, useDeleteReceiptsMutation, useDenyReceiptsMutation, useGetAllReceiptsQuery } from '../../../../../services/endpoints/finance/receipt';
import { Receipt } from '../../../../../types/finance.types';
import { getErrorMessage } from '../../../../../utils/utils';
import InputModal from '../../../../../components/modals/InputModal';
import { getCurrentUser } from '../../../../../services/helper';
import { useGetCoursesSimpleQuery } from '../../../../../services/endpoints/schedule/course';
import { sessionTypeNumbers } from '../../../../../utils/consts/schedule/sessions';
import { DetailSection, LabelText } from '../../../../../components/generic';
import CurrencyCell from '../../../../../components/tables/cellComponents/CurrencyCell';
import { useDataContext } from '../../../../../context';

const defaultScope = 'pending';
const currentUser = getCurrentUser();

export const userCanAdmin_Receipts = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin || user?.isAdmin || user?.isGlobal || user?.isLocationAdmin || user?.isInstructor) {
    return true;
  }

  return false;
}

export const userCanApproveDeny_Receipts = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin || user?.isAdmin || user?.isGlobal || user?.isLocationAdmin) {
    return true;
  }

  return false;
}

export const ReceiptsView = () => {
  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: {page: 1, entries: 10} });
  const [scopes, setScopes] = useState<Scopes>({});
  const { data, error, isLoading, isFetching } = useGetAllReceiptsQuery(filters);
  const [ denyReceipts ] = useDenyReceiptsMutation();
  const [ approveReceipts ] = useApproveReceiptsMutation();
  const [ deleteReceipts ] = useDeleteReceiptsMutation();
  const [receipts, setReceipts] = useState<Receipt[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const navigate: NavigateFunction = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState<number[]>([]);
  const isGlobalUser = (currentUser?.isGlobal);
  const isLocalUser = (currentUser?.isLocal);
  const { data: courses, error: coursesError, isLoading: coursesLoading } = useGetCoursesSimpleQuery();
  const { data: users, error: usersError, isLoading: usersLoading } = useGetUsersQuery({scope: 'colleagues', data: { orderBy: { field: 'first_name', order: 'asc' } } });

  const filterFormFields: FilterField[] = [
    {
      name: 'course_id',
      type: 'number',
      element: (
        <FormSelect
          name="course_id"
          label="Session"
          options={courses?.results?.map((c: {id: number, title: string}) => ({ value: c.id, label: c.title })) ?? []}
          loadingError={coursesError ? "Error loading courses" : undefined}
        />
      ),
    },
    {
      name: 'user_id',
      type: 'number',
      element: (
        <FormSelect
          name="user_id"
          label="Instructor"
          options={users?.results?.map((u: {id: number, full_name: string}) => ({ value: u.id, label: u.full_name })) ?? []}
          loadingError={usersError ? "Error loading users" : undefined}
        />
      ),
    },
    {
      name: 'date_of_purchase_from',
      id: 'purchased_at',
      operator: 'greater_equal_than',
      element: (
        <DateField name="date_of_purchase_from" label="Date of Purchase" />
      )
    },
    {
      name: 'date_of_purchase_to',
      id: 'purchased_at',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="date_of_purchase_to" />
      )
    },
    {
      name: 'location_id',
      type: 'number',
      element: (
        <FormSelect
          name="location_id"
          label="Location"
          options={locationOptions}
        />
      ),
    },
    {
      name: 'course_type',
      type: 'number',
      element: (
        <FormSelect
          name="course_type"
          label="Session Type"
          options={Object.entries(sessionTypeNumbers).map((s) => ({ value: +s[0], label: s[1] }))}
        />
      ),
    },
    {
      name: 'total',
      label: 'Total',
      type: 'number',
    },
    {
      name: 'created_at_from',
      id: 'created_at',
      operator: 'greater_equal_than',
      element: (
        <DateField name="created_at_from" label="Created At" />
      )
    },
    {
      name: 'created_at_to',
      id: 'created_at',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="created_at_to" />
      )
    },
  ];

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => {
          let scope_count = 0;
          if(data.scope_counts != null) {
            scope_count = data.scope_counts[scope] ?? 0;
          }
          return {
            title: `${title} (${scope_count})`,
            key: scope,
          }
        })));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setReceipts(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  const handleDenyReceipts = async (reason: string) => {
    try {
      await denyReceipts({
        ids: selectedIDs,
        reason_for_denial: reason,
      }).unwrap();
      addToast(`Receipts #${selectedIDs.join(',')} denied`, 'success');
    } catch (e) {
      addToast(getErrorMessage(e), 'error');
    }
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`${scopes[filters?.scope!] ?? ''} Receipts`}
        columns={receiptsColumns}
        data={receipts}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath='receipts'
        sidebars={isLocalUser ? [
          <DetailSection title="Summary" >
            <LabelText label="Total:">
              <CurrencyCell value={data?.totals?.total ?? 0} />
            </LabelText>
          </DetailSection>] : []}
        filters={filters?.data?.filterBy}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
        selectableRows={filters.scope !== 'paid'}
        batchActions={[
          {
            name: 'Approve Selected',
            fn: (selectedRowsIDs: number[]) => {
              approveReceipts({ids: selectedRowsIDs}).unwrap().then(() => {
                addToast(`Receipts #${selectedRowsIDs.join(',')} approved`, 'success');
              }).catch((e: any) => {
                navigate('/admin/finance/receipts');
                addToast(getErrorMessage(e), 'error');
              });
            }
          },
          {
            name: 'Deny Selected',
            fn: (selectedRowsIDs: number[]) => {
              setShowModal(true);
              setSelectedIDs(selectedRowsIDs);
            }
          },
          {
            name: 'Delete Selected',
            fn: (selectedRowsIDs: number[]) => {
              deleteReceipts({ids: selectedRowsIDs}).unwrap().then(() => {
                addToast(`Receipts #${selectedRowsIDs.join(',')} deleted`, 'success');
              }).catch((e: any) => {
                navigate('/admin/finance/receipts');
                addToast(getErrorMessage(e), 'error');
              });
            }
          }
        ]}
      />
      <InputModal
        show={showModal}
        title="Reason for Denial"
        message=""
        placeholder=""
        onClose={() => setShowModal(false)}
        onSubmit={(value) => handleDenyReceipts(value)}
      />
    </div>
  );
}
