import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { BackButton } from '../../../../../components/buttons/BackButton';
import Stepper from '../../../../../components/stepper/Stepper';
import { Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FadeIn } from '../../../../animations/FadeIn';
import * as Yup from "yup";
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { getErrorMessage } from '../../../../../utils/utils';
import { useToast } from '../../../../../context/ToastContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PaymentForm } from './PaymentForm';
import { useCreatePaymentMutation, useGetPaymentQuery, useUpdatePaymentMutation } from '../../../../../services/endpoints/finance/payment';
import { paymentMethods } from '../../../../../utils/consts/finance/finance';
import { useGetInvoiceQuery } from '../../../../../services/endpoints/finance/invoice';

const steps = [
  { name: 'Details', form: PaymentForm },
];

export interface FormValues {
  id?: number;
  invoice_id: {value: number, label: string},
  payment_method: {value: string| undefined, label: string | undefined},
  check_number: number | undefined,
  total: number | undefined,
  notes: string,
}

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values?: FormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
}

interface AddEditPaymentProps {
  editing?: boolean;
  newPayment?: boolean;
}

export const AddEditPayment: React.FC<AddEditPaymentProps> = ({ editing, newPayment }) => {
  const params = useParams();
  const location = useLocation();
  const invoice_id = new URLSearchParams(location.search).get('invoice_id');
  const urlParams = new URLSearchParams(location.search); 
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const navigate = useNavigate();
  
  const [createPayment] = useCreatePaymentMutation();
  const [updatePayment] = useUpdatePaymentMutation();
  const { data, error, isLoading }  = useGetPaymentQuery(Number(params.id!), { skip: !editing || !params.id });
  const { data: invoice_Data, error: invoice_Error, isLoading: invoice_Loading } = useGetInvoiceQuery(+invoice_id!, { skip: !newPayment || !invoice_id });
  
  const [initialValues, setInitialValues] = useState<FormValues>({
    invoice_id: {value: 0, label: ""},
    payment_method: {value: undefined, label: undefined},
    check_number: undefined,
    total: undefined,
    notes: '',
  });

  const validationSchemas = [
    Yup.object({
      payment_method: Yup.object().shape({
        value: Yup.string().required('Payment method is required'),
        label: Yup.string().required(),
      }),
      total: Yup.number().required('Total is required'),
    }),
  ];
  useEffect(()=> {
    if (invoice_Data) {
      setInitialValues((prevInitialValues) => ({
        ...prevInitialValues,
        invoice_id: {value: invoice_Data.invoice.id, label: invoice_Data.invoice.name},
      }));
    }
  },[invoice_Data, invoice_Error, invoice_Loading]);

  useEffect(() => {
    if (editing && error && !isLoading) {
      addToast('Error while loading payment', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, error, isLoading]);

  useEffect(() => {
    if (data && data.payment) {
      const { payment } = data;
      setInitialValues({
        id: payment.id,
        invoice_id: { value: payment.invoice?.id ?? 0, label: payment.invoice?.name ?? ""},
        payment_method: {value: payment.payment_method, label: paymentMethods[payment.payment_method]},
        check_number: payment.check_number,
        total: payment.total,
        notes: payment.notes,
      });
    }
  }, [data]);


  const handleSubmit = async (formValue: FormValues) => {
    
    try {
      const paymentData = {
        ...formValue,
        invoice_id: Number(formValue.invoice_id.value ? formValue.invoice_id.value : formValue.invoice_id),
        payment_method: formValue.payment_method.value ?? 'check',
      };
      // console.log("paymentData ", paymentData);
      if (editing) {
        const res = await updatePayment(paymentData).unwrap();
        if (res && res.payment) {
          addToast(`Payment updated succesfully`, 'success');
          navigate(`/admin/finance/payments/${data?.payment.id}`);
        }
      } else {
        const res = await createPayment(paymentData).unwrap();
        if (res && res.payment) {
          navigate(`/admin/finance/payments/${res.payment.id}`);
          addToast('Payment created succesfully', 'success');
        } else throw new Error('A problem happened while creating payment');
      }
    } catch (e) {
      addToast(getErrorMessage(e), 'error');
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>, 
    setTouched: (touched: FormikTouched<FormValues>, shouldValidate?: boolean) => void,
    values: FormValues
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        invoice_id: {value: true, label: true},
        payment_method: {value: true, label: true},
        check_number: true,
        total: true,
        notes: true,
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text={`Back to Invoice Show`}
            color="#2B4E64"
            // url={editing ? `/admin/finance/payments/${data?.payment.id}` : '/admin/finance/payments'}
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: '2.25rem' }}
          >
            {editing ? 'Edit Payment #'+ data?.payment.id : 'New Payment'}
          </h2>
          {steps.length > 1 && <Stepper steps={steps} currentStep={currentStep} editing={editing === true} onStepClick={(step) => setCurrentStep(step)} />}
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow: '0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)',
            border: '1px solid #EBEBEB',
            borderRadius: 10,
          }}
        >
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FormValues>) => (
                <FormikForm className="text-start">
                  <div style={{ minHeight: '65vh' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                      })
                    }
                  </div>

                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      <SimpleButton
                        type="submit"
                        onClick={() => handleSubmit(values)}
                        className="w-100"
                        variant="primary"
                      >
                        Finish
                      </SimpleButton>
                    </Col>                   
                  </Row>
                </FormikForm>
              )}
            </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
