import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { ColumnDef } from '@tanstack/react-table';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import { Stack } from 'react-bootstrap';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { Refund } from '../../../../../types/orders.type';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { useGetAllRefundsQuery, useProcessRefundMutation } from '../../../../../services/endpoints/orders/refunds';
import { useGetCoursesSimpleQuery } from '../../../../../services/endpoints/schedule/course';
import { Course } from '../../../../../types/course.type';
import CurrencyCell from '../../../../../components/tables/cellComponents/CurrencyCell';
import { formatDate } from '../../../../../utils/dateUtils';
import { created_at_from_to } from '../../../../../utils/consts/filterFields/dates';
import { getCurrentUser } from '../../../../../services/helper';
import { DetailSection, LabelText } from '../../../../../components/generic';
import { useDataContext } from '../../../../../context';
import { User } from '../../../../../services/endpoints/people/user';
import { DateField } from '../../../../../components/tables/filterForms/DateField';

const defaultScope = '';

const currentUser = getCurrentUser();

export const userCanAdmin_Refunds = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin || user?.isAdmin || user?.isGlobal || user?.isLocationAdmin) {
    return true;
  }

  return false;
}

export const RefundsView = () => {
  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: {page: 1, entries: 10} });
  const { data, error, isLoading, isFetching } = useGetAllRefundsQuery(filters);
  const [ processRefund ] = useProcessRefundMutation();
  const [refunds, setRefunds] = useState<Refund[]>([]);;
  const navigate = useNavigate();
  const {data: courses, error: coursesError, isLoading: coursesLoading} = useGetCoursesSimpleQuery({data: {orderBy: {field: 'title', order: 'asc'}}}, {skip: !currentUser?.isAdmin});

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
    
  const location_id = queryParams.get('location_id');
  const created_at_gteq_datetime = queryParams.get('created_at_gteq_datetime');
  const created_at_lteq_datetime = queryParams.get('created_at_lteq_datetime');
  
  const filterFormFields: FilterField[] = [
    {
      name: 'id',
      type: 'number',
      label: 'Refund ID'
    },
    {
      name: 'course_title',
      label: 'Session Title',
    },
    {
      name: 'course_id',
      type: 'number',
      element: (
        <FormSelect
          name="course_id"
          label="Session"
          options={courses?.results?.map((course: Course) => ({ value: course.id, label: course.title })) ?? []}
          loadingError={coursesError ? 'Error loading sessions' : undefined}
        />
      ),
    },
    {
      name: 'parent_full_name',
      label: 'Parent Name'
    },
    {
      name: 'parent_email',
      label: 'Parent Email'
    },
    {
      name: 'created_at_from',
      id: 'created_at',
      operator: 'greater_equal_than',
      element: (
        <DateField name="created_at_from" label="Created At" default_Value={created_at_gteq_datetime ? format_Date(created_at_gteq_datetime) : undefined} />
      )
    },
    {
      name: 'created_at_to',
      id: 'created_at',
      operator: 'lesser_equal_than',
      element: (
        created_at_lteq_datetime ? <DateField name="created_at_to" default_Value={format_Date(created_at_lteq_datetime)} /> : <DateField name="created_at_to" />
      )
    }
  ];

  if(currentUser?.isGlobal) {
    filterFormFields.push({
      name: 'location_id',
      label: 'Location',
      element: (
        location_id ? <FormSelect
          name="location_id"
          label="Location"
          options={locationOptions}
          selected_Value={{value: location_id, label: locationOptions?.find(loc => loc.value.toString() === location_id.toString())?.label ?? ''}}
        />
        :
        <FormSelect
          name="location_id"
          label="Location"
          options={locationOptions}
        />
      ),
    });
  }
  function format_Date(dateString: string) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US'); // 'en-US' locale uses MM/DD/YYYY format
  }
  const refundColumns: ColumnDef<Refund>[] = [
    {
      header: 'ID',
      accessorKey: 'id',
    },
    {
      header: 'Order',
      accessorKey: 'order_id',
      cell: ({ row }) =>
        <LinkCell
          content={`Order #${row.original.order_id}`}
          url={`/admin/orders/order/${row.original.order_id}`}
        />,
    },
    {
      header: 'Parent Name',
      accessorKey: 'parent_id',
      cell: ({ row }) =>
        <LinkCell
          content={row.original.order?.parent.full_name}
          url={`/admin/sfc-users/parents/${row.original.order?.parent_id}`}
        />,
    },
    {
      header: 'Session',
      accessorKey: 'course_id',
      cell: ({ row }) => !row.original.order?.course ? '' :
        <LinkCell
          content={row.original.order?.course.title}
          url={`/admin/schedule/sessions/${row.original.order?.course.id}`}
        />,
    },
    {
      header: 'User',
      accessorKey: 'user_id',
      cell: ({ row }) =>
        <LinkCell
          content={`${row.original.user.first_name} ${row.original.user.last_name}`}
          url={`/admin/dash-users/users/${row.original.user_id}`}
        />,
    },
    {
      header: 'Total',
      accessorKey: 'total',
      cell: ({ getValue }) => <CurrencyCell value={getValue<number>()} />,
    },
    {
      header: 'Transaction',
      accessorKey: 'transaction_id',
      cell: ({ row }) => {
        return (!row.original?.transaction_id ? (
          <SimpleButton size="sm" height={26} variant={'primary'} onClick={() => runRefund(row.original.id)}>
            Process
          </SimpleButton>
        ) : (
          row.original?.transaction_id
        ))
      }
    },
    {
      header: 'Refund Notes',
      accessorKey: 'notes',
    },
    {
      header: 'Created At',
      accessorKey: 'created_at',
      cell: ({ row }) => formatDate(new Date(row.original.created_at), 'MM/dd/yyyy h:mm zzz'),
    },
    {
      header: () => null,
      id: 'actions',
      enableSorting: false,
      cell: ({ row }) => (
        <Stack direction="horizontal" gap={2}>
          <LinkCell content="View" url={`/admin/orders/refunds/${row.original.id}`} underline />
        </Stack>
      )
    },
  ];

  useEffect(() => {
    // Apply the location filter when location_id is present in the URL
    if (location_id && created_at_lteq_datetime && created_at_gteq_datetime) {
      setFilters((prev) => ({
        ...prev,
        data: {
          ...prev?.data,
          filterBy: [
            { field: 'location_id', operator: 'equals', value: location_id ?? '' },
            { field: 'created_at', operator: 'greater_equal_than', value: created_at_gteq_datetime ?? '' },
            { field: 'created_at', operator: 'lesser_equal_than', value: created_at_lteq_datetime ?? '' },
          ],
        },
      }));
    }
  }, [location_id,created_at_lteq_datetime, created_at_gteq_datetime]);
  
  const runRefund = async (id: number) => {
    try {
      let refund = await processRefund(id).unwrap();
      addToast('Refund processed successfully', 'success');
    } catch (error) {
      addToast('Error while processing refund', 'error');
    }
  }
  
  useEffect(() => {
    if (!isLoading && error) {
      addToast('Error while loading refunds', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isLoading]);

  useEffect(() => {
    if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setRefunds(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`Refunds`}
        columns={refundColumns}
        data={refunds}
        count={data?.count}
        defaultScope={defaultScope}
        isLoading={isLoading}
        isFetching={isFetching}
        filters={filters.data?.filterBy}
        indexDownloadPath='refunds'
        sidebars={currentUser?.canSeeFinancials ? [
          <DetailSection title="Summary" >
            <LabelText label="Total Refunds:">
              <CurrencyCell value={data?.totals?.total ?? 0} />
            </LabelText>
          </DetailSection>
        ] : []}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
      />
    </div>
  );
}
