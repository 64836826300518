import { Button, Card, Col, Form, FormControl, Row, Stack } from 'react-bootstrap';
import { ErrorMessage, Field, FieldArray, Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FormValues, StepProps } from './AddEditLocation';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { useGetAdminsQuery } from '../../../../../services/endpoints/people/user';
import { DocumentElement } from '../../schedule/sessions/NewSessionSteps/DocumentsAndFilesForm';
import { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useDropzone } from 'react-dropzone';
import { useToast } from '../../../../../context/ToastContext';

// export interface StepProps {
//   errors: Partial<FormikErrors<FormValues>>;
//   touched: Partial<FormikTouched<FormValues>>;
//   values?: FormValues;
//   setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
//   readOnly?: boolean;
// }

export const LocationForm = ({ touched, errors, values, editing, setFieldValue }: StepProps) => {

  const { data: admins, error: adminsError } = useGetAdminsQuery({}, {skip: !editing});
  const readOnly = false;
  const [fileName, setFileName] = useState<string>('');
  const [documentType, setDocumentType] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File>();
  const onDrop = (acceptedFiles: File[]) => {
    // console.log(...acceptedFiles, values);
    // setFieldValue('files', [...values!.files, ...acceptedFiles]);
    setSelectedFile(acceptedFiles[0]);
  };

  const { addToast } = useToast();
  
  return (
    <>
      <Row>
        <Col xs={6} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="title">
            <Form.Label>Title</Form.Label>
            <Field
              name="title"
              type="text"
              as={Form.Control}
              isInvalid={touched.title && !!errors.title}
            />
            <ErrorMessage name="title">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="business_entity_name">
            <Form.Label>Business Entity Name</Form.Label>
            <Field
              name="business_entity_name"
              type="text"
              as={Form.Control}
              isInvalid={touched.business_entity_name && !!errors.business_entity_name}
            />
            <ErrorMessage name="business_entity_name">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="phone_number">
            <Form.Label>Business Phone Number</Form.Label>
            <Field
              name="phone_number"
              type="text"
              as={Form.Control}
              isInvalid={touched.phone_number && !!errors.phone_number}
            />
            <ErrorMessage name="phone_number">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="primary_city">
            <Form.Label>Primary City</Form.Label>
            <Field
              name="primary_city"
              type="text"
              as={Form.Control}
              isInvalid={touched.primary_city && !!errors.primary_city}
            />
            <ErrorMessage name="primary_city">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>


          <Form.Group className="mb-1 mb-lg-3" controlId="is_active">
            <Form.Label>Is Active</Form.Label>
            <Field
              name="is_active"
              checked={values?.is_active}
              as={Form.Check}
              isInvalid={touched.is_active && !!errors.is_active}
            />
            <ErrorMessage name="is_active">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

        </Col>
        <Col xs={6} className="ps-1">
          
          {editing && <Form.Group className="mb-1 mb-lg-3" controlId="manager_id">
            <FormSelect
              name="manager_id"
              label="Area Director"
              options={admins?.results?.map((admin) => ({ value: admin.id, label: admin.full_name })) ?? []}
              loadingError={adminsError ? "Error loading admins" : undefined}
            />
            <ErrorMessage name="manager_id">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>}

          <Form.Group className="mb-1 mb-lg-3" controlId="scheduling_email">
            <Form.Label>Scheduling Email</Form.Label>
            <Field
              name="scheduling_email"
              type="text"
              as={Form.Control}
              isInvalid={touched.scheduling_email && !!errors.scheduling_email}
            />
            <ErrorMessage name="scheduling_email">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="invoicing_email">
            <Form.Label>Invoicing Email</Form.Label>
            <Field
              name="invoicing_email"
              type="text"
              as={Form.Control}
              isInvalid={touched.invoicing_email && !!errors.invoicing_email}
            />
            <ErrorMessage name="invoicing_email">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="hotline">
            <Form.Label>Chef Hotline Number</Form.Label>
            <Field
              name="hotline"
              type="text"
              as={Form.Control}
              isInvalid={touched.hotline && !!errors.hotline}
            />
            <ErrorMessage name="hotline">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="tax_id_number">
            <Form.Label>Tax ID Number</Form.Label>
            <Field
              name="tax_id_number"
              type="text"
              as={Form.Control}
              isInvalid={touched.tax_id_number && !!errors.tax_id_number}
            />
            <ErrorMessage name="tax_id_number">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

        </Col>
      </Row>

      <Row>
        <Col xs={12} className="pe-1">
          <Form.Label className="h4">Location Information</Form.Label>
        </Col>

        <Col xs={6} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="location_information_facebook">
            <Form.Label>Facebook link</Form.Label>
            <Field
              name="location_information_facebook"
              type="text"
              as={Form.Control}
              isInvalid={touched.location_information_facebook && !!errors.location_information_facebook}
            />
            <ErrorMessage name="location_information_facebook">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="location_information_franchise_owner_first_name">
            <Form.Label>Owner First name</Form.Label>
            <Field
              name="location_information_franchise_owner_first_name"
              type="text"
              as={Form.Control}
              isInvalid={touched.location_information_franchise_owner_first_name && !!errors.location_information_franchise_owner_first_name}
            />
            <ErrorMessage name="location_information_franchise_owner_first_name">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="location_information_franchise_owner_email">
            <Form.Label>Owner Email</Form.Label>
            <Field
              name="location_information_franchise_owner_email"
              type="text"
              as={Form.Control}
              isInvalid={touched.location_information_franchise_owner_email && !!errors.location_information_franchise_owner_email}
            />
            <ErrorMessage name="location_information_franchise_owner_email">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>

        <Col xs={6} className="ps-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="location_information_instagram">
            <Form.Label>Instagram link</Form.Label>
            <Field
              name="location_information_instagram"
              type="text"
              as={Form.Control}
              isInvalid={touched.location_information_instagram && !!errors.location_information_instagram}
            />
            <ErrorMessage name="location_information_instagram">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="location_information_franchise_owner_last_name">
            <Form.Label>Owner Last name</Form.Label>
            <Field
              name="location_information_franchise_owner_last_name"
              type="text"
              as={Form.Control}
              isInvalid={touched.location_information_franchise_owner_last_name && !!errors.location_information_franchise_owner_last_name}
            />
            <ErrorMessage name="location_information_franchise_owner_last_name">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="location_information_jobs_url">
            <Form.Label>Jobs URL</Form.Label>
            <Field
              name="location_information_jobs_url"
              type="text"
              as={Form.Control}
              isInvalid={touched.location_information_jobs_url && !!errors.location_information_jobs_url}
            />
            <ErrorMessage name="location_information_jobs_url">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>

        <Col xs={12} lg={12} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="location_information_who_we_are">
            <Form.Label>Who we are</Form.Label>
            <Field
              name="location_information_who_we_are"
              as="textarea"
              className={`form-control ${touched.location_information_who_we_are && !!errors.location_information_who_we_are ? 'is-invalid' : ''}`}
            />
            <ErrorMessage name="location_information_who_we_are">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>
      </Row>

      <FieldArray 
        name="addresses"
        render={(arrayHelpers) => (
          <Form.Group className="mb-1 mb-lg-3" controlId="addresses">
            <Form.Label className="h4">Remittance Mailing Addresses</Form.Label>
            <br />
            <div className="multi-content-scroll mt-2">
              <Row>
              {values?.addresses && values?.addresses.length > 0 ? (
                values.addresses.map((a, index) => {
                  let meta = {touched: Array.isArray(touched.addresses) ? touched.addresses[index] : {}, error: Array.isArray(errors.addresses) ? (errors.addresses[index] ?? {}) : {}};
                  return (
                    <Col  key={index} lg={6} className="mb-2">
                    <Card border="primary">
                    {typeof errors.addresses === "string" ? <div>{errors.addresses}</div> : null}

                    <Card.Body>
                      <Col className="ms-1">
                        <Row>
                          <Col xs={6} className="pe-1">
                            <Form.Group className="mb-1 mb-lg-3" controlId={`addresses.${index}.street`}>
                              <Form.Label>Street</Form.Label>
                              <Field
                                name={`addresses.${index}.street`}
                                as={Form.Control}
                                isInvalid={meta.touched?.street && !!(typeof meta.error === "string" ? meta.error : meta.error.street)}
                              />
                              <ErrorMessage name={`addresses.${index}.street`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                            <Form.Group className="mb-1 mb-lg-3" controlId={`addresses.${index}.unit`}>
                              <Form.Label>Unit</Form.Label>
                              <Field
                                name={`addresses.${index}.unit`}
                                type="text"
                                as={Form.Control}
                                isInvalid={meta.touched?.unit && !!(typeof meta.error === "string" ? meta.error : meta.error.unit)}
                              />
                              <ErrorMessage name={`addresses.${index}.unit`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                            <Form.Group className="mb-1 mb-lg-3" controlId={`addresses.${index}.city`}>
                              <Form.Label>City</Form.Label>
                              <Field
                                name={`addresses.${index}.city`}
                                type="text"
                                as={Form.Control}
                                isInvalid={meta.touched?.city && !!(typeof meta.error === "string" ? meta.error : meta.error.city)}
                              />
                              <ErrorMessage name={`addresses.${index}.city`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                            <Button
                              variant="primary"
                              size="sm"
                              className="btn-box-shadow border-0 text-uppercase"
                              style={{ width: 84, background: '#B81F69', fontSize: '.75rem' }}
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              Remove  
                            </Button>
                            
                          </Col>
                          <Col xs={6} className="ps-1">
                            <Form.Group className="mb-1 mb-lg-3" controlId={`addresses.${index}.state`}>
                              <Form.Label>State</Form.Label>
                              <Field
                                name={`addresses.${index}.state`}
                                as={Form.Control}
                                isInvalid={meta.touched?.state && !!(typeof meta.error === "string" ? meta.error : meta.error.state)}
                              />
                              <ErrorMessage name={`addresses.${index}.state`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>

                            <Form.Group className="mb-1 mb-lg-3" controlId={`addresses.${index}.zip_code`}>
                              <Form.Label>Zip Code</Form.Label>
                              <Field
                                name={`addresses.${index}.zip_code`}
                                type="tel"
                                as={Form.Control}
                                isInvalid={meta.touched?.zip_code && !!(typeof meta.error === "string" ? meta.error : meta.error.zip_code)}
                              />
                              <ErrorMessage name={`addresses.${index}.zip_code`}>
                                {(msg) => (
                                  <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
                                )}
                              </ErrorMessage>
                            </Form.Group>
                            
                          </Col>
                        </Row>
                      </Col>
                    </Card.Body>
                  </Card>
              </Col>
                )})
              ) : (
                <></>
              )}
              </Row>
            </div>
            <button
              type='button'
              className="btn btn-sm btn-link fw-bolder text-uppercase link-primary link-underline-opacity-0"
              onClick={() => arrayHelpers.insert(values?.addresses.length ?? 0, {
                street: "", 
                unit: "", 
                city: "", 
                state: "", 
                zip: "",
              })}
            >
              + Add New Address
            </button>
          </Form.Group>
        )}
      />

      <Row>
        <Col xs={6} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="latitude">
            <Form.Label>Latitude</Form.Label>
            <Field
              name="latitude"
              as={Form.Control}
              isInvalid={touched.latitude && !!errors.latitude}
            />
            <ErrorMessage name="latitude">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="zoom">
            <Form.Label>Zoom</Form.Label>
            <Field
              name="zoom"
              as={Form.Control}
              isInvalid={touched.zoom && !!errors.zoom}
            />
            <ErrorMessage name="zoom">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>

        <Col xs={6} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="longitude">
            <Form.Label>Longitude</Form.Label>
            <Field
              name="longitude"
              as={Form.Control}
              isInvalid={touched.longitude && !!errors.longitude}
            />
            <ErrorMessage name="longitude">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>
      </Row>


      <Row>
        <Col xs={12} className="pe-1">
          <Form.Label className="h4">Location images</Form.Label>
        </Col>
      {!readOnly && (
        <Col xs={12} lg={6}>
          <Stack gap={2}>
            <h6
              className="fw-bold mb-0"
              style={{ fontSize: '1.125rem', color: '#5E6469' }}
            >
              Add an image
            </h6>

            <Form.Group controlId="document_type">
            <Form.Label>Image type</Form.Label>
            <Field
              name="document_type"
              as={Form.Select}
              disabled={readOnly}

              value={documentType}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                const selected = event.target.value;
                setDocumentType(selected)
              }
              }
            >
              <option>Select</option>
              <option value="images">Images</option>
              <option value="franchise_images">Franchise Images</option>
              <option value="contact_image">Contact Image</option>
            </Field>
            <ErrorMessage name="document_type">
              {(msg) => (
                <FormControl.Feedback type="invalid">
                  {msg}
                </FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

            <div>
              <Form.Label>File</Form.Label>
              <Dropzone onDrop={onDrop} />
            </div>

            {selectedFile && (
              <>
                <div className="mt-3">
                  <h6 className="text-info fw-semibold">Selected File:</h6>
                  <p className="mb-0 text-truncate">{selectedFile.name}</p>
                </div>
                <Button
                  variant="primary"
                  size="sm"
                  className="btn-box-shadow border-0 text-uppercase"
                  style={{ width: 150 }}
                  onClick={() => {
                    let error = '';
                    let count = 0;
                    switch(documentType) {
                      case 'images':
                        count = values!.images!.length;
                        if(count >= 3) {
                          error = 'Images max size is 3';
                        }
                        else {
                          setFieldValue!('images', [...values!.images!, selectedFile]);
                        }
                        break;
                      case 'franchise_images':
                        count = values!.franchise_images!.length;
                        if(count >= 3) {
                          error = 'Franchise Images max size is 3';
                        }
                        else {
                          setFieldValue!('franchise_images', [...values!.franchise_images!, selectedFile]);
                        }
                        break;
                      case 'contact_image':
                        setFieldValue!('contact_image', selectedFile);
                        break;
                      default:
                        error = 'Document type is not selected';
                        break;
                    }

                    if(!error) {
                      setFileName('');
                      setDocumentType('');
                      setSelectedFile(undefined);
                    }
                    else {
                      addToast(error, 'error');
                    }
                  }}
                >
                  Add image
                </Button>
              </>
            )}
          </Stack>
        </Col>
      )}
      <Col xs={12} lg={6}>
        <Stack gap={2}>
          <h6
          className="fw-bold mb-0"
          style={{ fontSize: '1.125rem', color: '#5E6469' }}
        >
          Images
        </h6>

        {values!.images!.length === 0 && (
          <Form.Label>NOTHING ADDED YET</Form.Label>
        )}
        {values!.images!.map((file, index) => (
          <DocumentElement
            key={index}
            file={file}
            name={file.name}
            onRemove={() => {
              setFieldValue!('images', values!.images!.filter((_, i) => i !== index));
            }}
            disabled={readOnly ?? false}
          />
        ))}
        </Stack>

        <Stack gap={2}>
          <h6
          className="fw-bold mb-0"
          style={{ fontSize: '1.125rem', color: '#5E6469' }}
        >
          Franchise Images
        </h6>

        {values!.franchise_images!.length === 0 && (
          <Form.Label>NOTHING ADDED YET</Form.Label>
        )}
        {values!.franchise_images!.map((file, index) => (
          <DocumentElement
            key={index}
            file={file}
            name={file.name}
            onRemove={() => {
              setFieldValue!('franchise_images', values!.franchise_images!.filter((_, i) => i !== index));
            }}
            disabled={readOnly ?? false}
          />
        ))}
        </Stack>

        <Stack gap={2}>
          <h6
          className="fw-bold mb-0"
          style={{ fontSize: '1.125rem', color: '#5E6469' }}
        >
          Contact Image
        </h6>

        {!values!.contact_image && (
          <Form.Label>NOTHING ADDED YET</Form.Label>
        )}
        {values!.contact_image && (
          <DocumentElement
            file={values!.contact_image}
            name={values!.contact_image.name}
            onRemove={() => {
              setFieldValue!('contact_image', undefined);
            }}
            disabled={readOnly ?? false}
          />
        )}
        </Stack>
      </Col>
    </Row>
    </>
  )
}

interface DropzoneProps {
  onDrop: Function;
}

const Dropzone: React.FC<DropzoneProps> = ({ onDrop }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => onDrop(acceptedFiles),
    multiple: false,
  });

  return (
    <div {...getRootProps({ className: 'dropzone' })} style={dropzoneStyle}>
      <input {...getInputProps()} />
      <Stack style={{ color: 'rgba(94, 100, 105, .5)' }}>
        <span>Drag and drop here</span>
        <span>or</span>
        <span className="fw-bold" style={{ color: '#6098B8' }}>
          Browse
        </span>
      </Stack>
    </div>
  );
};

const dropzoneStyle: React.CSSProperties = {
  border: '1px dashed rgba(94, 100, 105, 0.5)',
  borderRadius: 8,
  padding: 16,
  textAlign: 'center',
  cursor: 'pointer'
};