import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Receipt } from '../../../types/finance.types';
import { sfcApi } from '../../api';

export interface ReceiptCreateUpdateValues {
  id?: number;
  course_id: number;
  store_name: string;
  total: number;
  image?:File
}

export interface PayReceiptsValues {
  ids: number[];
  check_number?: number;
}

export interface PayReceiptsResponse {
  count: number;
  rows: Receipt[];
}

export const receiptApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllReceipts: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `receipts${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Receipt']
    }),
    getReceipt: builder.query<{ receipt: Receipt }, number>({
      query: (id) => `receipts/${id}`,
      providesTags: (result, error, id) => [{ type: 'Receipt', id }]
    }),
    createReceipt: builder.mutation<{ receipt: Receipt }, ReceiptCreateUpdateValues>({
      
      query: (data) => {
        const formData = new FormData();
        let payload: any = {
          url: `receipts/create`,
        method: 'POST',
        body: data
        };
        Object.entries(data).forEach(([key, value]) => formData.append(key, value));
        if (data.image?.name) {
          payload.formData = true;
        }
        if(!data?.image?.name){
            formData.delete('image');
        }
        payload.body = formData;

        return payload;
      },
      invalidatesTags: ['Receipt']
    }),
    updateReceipt: builder.mutation<{ receipt: Receipt }, ReceiptCreateUpdateValues>({
      query: (data) => {
        const { id } = data;
        delete data.id;
        return {
          url: `receipts/update/${id}`,
          method: 'PUT',
          body: data
        }
      },
      invalidatesTags: (result, error) => [{ type: 'Receipt', id: result?.receipt?.id }, 'Receipt']
    }),
    approveReceipts: builder.mutation<{ receipt: PayReceiptsResponse }, {ids: number[]}>({
      query: (params) => ({
        url: `receipts/approve`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: () => ['Receipt']
    }),
    denyReceipts: builder.mutation<{ receipt: Receipt }, {ids: number[], reason_for_denial: string}>({
      query: (params) => ({
        url: `receipts/deny`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Receipt'], //(result, error, params) => [...params.ids.map(id => ({ type: 'Receipt', id })), 'Receipt']
    }),
    deleteReceipts: builder.mutation<{ receipt: Receipt }, {ids: number[]}>({
      query: (params) => ({
        url: `receipts`,
        method: 'DELETE',
        body: params,
      }),
      invalidatesTags: ['Receipt'], //(result, error, params) => [...params.ids.map(id => ({ type: 'Receipt', id })), 'Receipt']
    }),
  }),
});

export const {
  useGetAllReceiptsQuery,
  useGetReceiptQuery,
  useCreateReceiptMutation,
  useUpdateReceiptMutation,
  useApproveReceiptsMutation,
  useDenyReceiptsMutation,
  useDeleteReceiptsMutation,
} = receiptApi;