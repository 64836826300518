
import { User } from "../../services/endpoints/people/user";
import { Card } from "react-bootstrap";
import { PhoneNumber } from "../common/PhoneNumber";
import { BsPencil } from "react-icons/bs";
import { formatUserName } from '../../services/helper';

const UserProfileCard = (props: {user: User, edit: () => void }) => {
  const { user, edit } = props;

  return (
    <Card className="text-left shadow">
      <Card.Body>
        <Card.Text className={"edit-card-btn"} >
          <button onClick={edit} className="btn btn-sm btn-link fw-bolder text-uppercase link-primary link-underline-opacity-0"><BsPencil /> Edit Profile</button>
        </Card.Text>
        <Card.Text className={"mb-3 h1 text-info"} >{formatUserName(user)}</Card.Text>
        <Card.Subtitle className="text-muted">Email</Card.Subtitle>
        <Card.Text className="fw-bold">{user.email}</Card.Text>
        <Card.Subtitle className="text-muted">Address</Card.Subtitle>
        <Card.Text className="fw-bold">{user.address}</Card.Text>
        <Card.Subtitle className="text-muted">Phone Number</Card.Subtitle>
        <Card.Text className="fw-bold"><PhoneNumber number={user.phone} /></Card.Text>
      </Card.Body>
    </Card>
  );
};

export default UserProfileCard;
