import React from 'react';
import { createRoot } from 'react-dom/client';
import { store } from './services/store';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import './css/custom.css'; // custom compiled SFC CSS
import './index.css';
import App from './App';
import { DashNavbarProvider, ToastProvider, DataProvider, MyLocationProvider, CartProvider } from './context';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import * as Sentry from '@sentry/react'; // Import Sentry

// Initialize Sentry
// Sentry.init({
//   dsn: "https://ea04d391645115595726d0af6140127b@o1146573.ingest.us.sentry.io/4508592530194432",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: [
//     "localhost:3001", // Local environment
//     /^https:\/\/testing\.stickyfingerscooking\.com/ // QA environment
//   ],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });


const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <DashNavbarProvider>
        <ToastProvider>
          <DataProvider>
            <MyLocationProvider>
              <CartProvider>
                <>
                  <App />
                  <ToastContainer
                    position="bottom-right"
                    autoClose={2000} // Close after 3 seconds
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme='colored'
                  />
                </>
              </CartProvider>
            </MyLocationProvider>
          </DataProvider>
        </ToastProvider>
      </DashNavbarProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
