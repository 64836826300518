/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from './AddEditEnrollment';
import { useToast } from '../../../../context/ToastContext';
import { useEffect } from 'react';
import { FormSelect } from '../../../../components/tables/filterForms/FormSelect';
import { useGetCourseQuery, useGetCoursesSimpleQuery } from '../../../../services/endpoints/schedule/course';
import { useGetAllScholarshipsQuery } from '../../../../services/endpoints/orders/scholarship';
import { Scholarship } from '../../../../types/orders.type';
import { scholarshipTypes } from '../../../../utils/consts/orders/scholarships';
import { useState } from 'react';
export const EnrollmentForm = ({ touched, errors, values, setFieldValue }: StepProps) => {
  const { data: scholarships, error: scholarshipsError, isLoading: scholarshipsLoading } = useGetAllScholarshipsQuery();
  const { addToast } = useToast();
  const { data: courses, error: coursesError, isLoading: coursesLoading } = useGetCoursesSimpleQuery();

  const { data: cource_data, error: cource_error, isLoading: cource_loading, refetch } = useGetCourseQuery(Number(values?.course_id?.value));
  
  const [flatRateHint, setFlatRateHint] = useState('');

  useEffect(()=> {
    if (values?.course_id) {
      let payType = courses?.results?.find((course: {id: number, title: string}) => course.id === values?.course_id?.value).invoice_type;
      setFlatRateHint(payType === 'flat_rate' ? 'Enter the tuition, per class.' : 'Enter the tuition, per student, per class.');
    }
  },[values])

  useEffect(() => {
    if (cource_data) {
      let payType = cource_data.course.invoice_type
      setFlatRateHint(payType === 'flat_rate' ? 'Enter the tuition, per class.' : 'Enter the tuition, per student, per class.');
    }
  },[cource_data, cource_error, cource_loading]);
  
  useEffect(() => {
    if (coursesError && !coursesLoading) {
      addToast('Error while loading courses', 'error');
    } else if (scholarshipsError && !scholarshipsLoading) {
      addToast('Error while loading scholarships', 'error');
    }
  },[coursesError, coursesLoading, scholarshipsError, scholarshipsLoading]);

  return (
    <>
      <Row>
        <Col xs={12} lg={6} className="pe-1">

          <Form.Group className="mb-1 mb-lg-3" controlId="course_id">
            <Field 
              as={FormSelect}
              name="course_id"
              label="Session Title"
              options={courses?.results?.map((course: {id: number, title: string}) => ({ value: course.id, label: course.title })) ?? []}
              loadingError={coursesError ? "Error loading sessions"
                : (!!errors.course_id ? 'Course selection is required' : undefined)}
            />
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="quantity">
            <Form.Label>Quantity<abbr title="required">*</abbr></Form.Label>
            <Field
              name="quantity"
              type="number"
              as={Form.Control}
              isInvalid={touched.quantity && !!errors.quantity}
              required
            />
            <ErrorMessage name="quantity">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="tuition">
            <Form.Label>Tuition<abbr title="required">*</abbr></Form.Label>
            <Field
              name="tuition"
              type="number"
              as={Form.Control}
              isInvalid={touched.tuition && !!errors.tuition}
              required
            />
            <small>
            <Form.Text muted>{flatRateHint}</Form.Text>
            </small>
            
            <ErrorMessage name="tuition">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="number_of_classes">
            <Form.Label>Number of classes<abbr title="required">*</abbr></Form.Label>
            <Field
              name="number_of_classes"
              type="number"
              as={Form.Control}
              isInvalid={touched.number_of_classes && !!errors.number_of_classes}
              required
            />
            <ErrorMessage name="number_of_classes">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="scholarship_id">
            <Form.Label>Scholarship</Form.Label>
            <Field
              name="scholarship_id"
              as={Form.Select}
              isInvalid={touched.scholarship_id && !!errors.scholarship_id}
            >
              <option value={""}>Select</option>
              {scholarships?.results?.map((scholarship: Scholarship, idx: number) => (
                <option key={idx} value={scholarship.id}>
                  {scholarshipTypes[scholarship.scholarship_type]} {`(${scholarship.percent_discount}% off)`}
                </option>
              ))}
            </Field>
            <ErrorMessage name="scholarship_id">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="notes">
            <Form.Label>Notes (Optional)</Form.Label>
            <Field
              name="notes"
              as="textarea"
              className="form-control"
              rows={4}
            />
            <small>
            <Form.Text muted>
              These notes are internal, visible only to admin on the SFC Dashboard. These notes are not visible on the invoice.
            </Form.Text>
            </small>
            <ErrorMessage name="notes">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="invoice_notes">
            <Form.Label>Invoice Notes (Optional)</Form.Label>
            <Field
              name="invoice_notes"
              as="textarea"
              className="form-control"
              rows={4}
            />
            <small>
            <Form.Text muted>
            Invoice Notes automatically propagate onto the invoice PDF.
            </Form.Text>
            </small>
            <ErrorMessage name="invoice_notes">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}