import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { Scopes, User } from '../../../../../services/endpoints/people/user';
import { useGetLessonPlansQuery } from '../../../../../services/endpoints/content/lessonPlan';
import { LessonPlan } from '../../../../../types/lessonPlan.type';
import { lessonPlanColumns } from '../../../../../utils/consts/columnDefs/contentColumns';
import { getCurrentUser } from '../../../../../services/helper';

const defaultScope = 'all';

export const userCanEditCreateDelete_LessonPlan = (user: User | null) => {
  if(user?.isAdmin && user?.isSuper) {
    return true;
  }
  if(user?.isWebContentEditor || user?.isSupremeAdmin) {
    return true;
  }

  return false;
}

export const LessonPlansView = () => {
  const { addToast } = useToast();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: {page: 1, entries: 10} });
  const [scopes, setScopes] = useState<Scopes>({});
  const { data, error, isLoading, isFetching } = useGetLessonPlansQuery(filters);
  const [lessonPlans, setLessonPlans] = useState<LessonPlan[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const navigate: NavigateFunction = useNavigate();
  const currentUser = getCurrentUser();

  console.log(currentUser);

  const filterFormFields: FilterField[] = [
    {
      name: 'name',
      label: 'Name',
    },
  ];

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => {
          let scope_count = 0;
          if(data.scope_counts != null) {
            scope_count = data.scope_counts[scope] ?? 0;
          }
          return {
            title: `${title} (${scope_count})`,
            key: scope,
          }
        })));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
        setLessonPlans(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`${scopes[filters?.scope!] ?? ''} Lesson Plans`}
        columns={lessonPlanColumns}
        data={lessonPlans}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath='lesson-plans'
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
        newItemEl={userCanEditCreateDelete_LessonPlan(currentUser) ? (
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={250}
            onClick={() => navigate('/admin/content/lesson-plans/new')}
          >
            New Lesson plan
          </SimpleButton>
        ) : <></>}
      />
    </div>
  );
}
