import { Col, Form, FormControl, Row, Stack } from "react-bootstrap";
import { StepProps } from "../AddEditRecipe";
import { useGetJokesQuery } from "../../../../../../services/endpoints/content/joke";
import { useGetJokeCategoriesQuery } from "../../../../../../services/endpoints/content/jokeCategory";
import { ErrorMessage, Field } from "formik";
import { Joke, JokeCategory } from "../../../../../../types/content.type";
import Editor from "../../../../../../components/editor/Editor";

export const RecipeListsForm = ({
  touched,
  errors,
  values,
  setFieldValue,
  readOnly,
}: StepProps) => {
  const { data: dataJokeCategories, error: errorJokeCategories } =
    useGetJokeCategoriesQuery();
  const { data: dataJokes, error: errorJokes } = useGetJokesQuery();
  return (
    <Row>
      <h6
        className="fw-bold mb-3"
        style={{ fontSize: "1.125rem", color: "#5E6469" }}
      >
        Recipe Lists
      </h6>
      <Col xs={12} lg={6}>
        <Stack gap={3}>
          <Form.Group controlId="ingredients">
            <Form.Label>Ingredients</Form.Label>
            <Field
              name="ingredients"
              as="textarea"
              className="form-control"
              rows={4}
              isInvalid={touched.ingredients && !!errors.ingredients}
            />
            <Form.Text>
              <p>
                This will be broken up based on line breaks. Place scaled items
                in double curly brackets. <br />
                Example:
                <br />
                &#123;&#123;1&#125;&#125; carrot
                <br />
                &#123;&#123;3&#125;&#125; oz. milk
              </p>
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="substitutions">
            <Form.Label>Food Allergen Substitutions</Form.Label>
            <Field name="substitutions" component={Editor} />
          </Form.Group>
          <Form.Group controlId="recipe_joke_ids">
            <Form.Label>Jokes</Form.Label>
            <Field
              name="recipe_joke_ids"
              as={Form.Select}
              isInvalid={touched.recipe_joke_ids && !!errors.recipe_joke_ids}
              disabled={readOnly}
              multiple
            >
              {dataJokeCategories?.results?.slice()
                .sort((a, b) => a.name.localeCompare(b.name)) 
                .map((jokeCategory: JokeCategory) => (
                <optgroup key={jokeCategory.id} label={jokeCategory.name}>
                  {dataJokes?.results
                    .filter(
                      (joke: Joke) => joke.joke_category_id === jokeCategory.id
                    )?.slice()
                    .sort((a, b) => a.name.localeCompare(b.name)) 
                    .map((joke) => (
                      <option key={joke.id} value={joke.id}>
                        {joke.name}
                      </option>
                    ))}
                </optgroup>
              ))}
            </Field>
            <ErrorMessage name="recipe_joke_ids">
              {(msg) => (
                <FormControl.Feedback type="invalid">
                  {msg}
                </FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
          <Form.Group controlId="parent_equipments">
            <Form.Label>Parent Equipments</Form.Label>
            <Field
              name="parent_equipments"
              as="textarea"
              className="form-control"
              rows={4}
              isInvalid={
                touched.parent_equipments && !!errors.parent_equipments
              }
            />
            <Form.Text>
              <p>
                This will be broken up based on line breaks. Example:
                <br />
                Toaster oven
                <br />
                Parchment paper
              </p>
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="instructor_equipments">
            <Form.Label>Instructor Equipments</Form.Label>
            <Field
              name="instructor_equipments"
              as="textarea"
              className="form-control"
              rows={4}
              isInvalid={
                touched.instructor_equipments && !!errors.instructor_equipments
              }
            />
            <Form.Text>
              <p>
                This will be broken up based on line breaks. Example:
                <br />
                Toaster oven
                <br />
                Parchment paper
              </p>
            </Form.Text>
          </Form.Group>
        </Stack>
      </Col>
    </Row>
  );
};
