import React, { useEffect, useState } from "react";
import { Button, Image, Stack } from "react-bootstrap";
import { Formik, Form as FormikForm, FormikProps, FormikErrors, FormikTouched } from "formik";
import * as Yup from "yup";
import { Allergy, Caregiver, Child, useAddChildMutation, useUpdateChildMutation } from "../../services/endpoints/people/user";
import { FadeIn } from '../animations/FadeIn';
import { SimpleButton } from '../../components/buttons/SimpleButton';
import Stepper from '../../components/stepper/Stepper';
import { FaArrowLeft } from "react-icons/fa";
import { BasicInfoForm, ContactInfoForm, HealthInfoForm, WaiverForm } from './AddChildFormSteps';
import { allergyOptions, genderOptions, gradeLevelOptions, phoneRegExp, pickupOptions } from '../../utils/consts';
import SFCLogo from '../../assets/images/sticky-fingers-logo-stacked-2.png';
import { getErrorMessage, getNestedTouchedTrue, getObjectDifference } from "../../utils/utils";
import { BackButton } from "../../components/buttons/BackButton";
import { useGetChildQuery } from "../../services/endpoints/people/child";
import { useNavigate, useParams } from "react-router-dom";

const steps = [
  {name: 'Basic Info', form: BasicInfoForm},
  {name: 'Health Info', form: HealthInfoForm},
  {name: 'Contact Info', form: ContactInfoForm},
  {name: 'Waiver', form: WaiverForm},
];
  
export interface FormValues {
  id?: number;
  first_name: string;
  last_name: string;
  dob: string;
  grade_level: string;
  gender: string;
  origination: string;
  allergies: Allergy[];
  social_emotional_needs?: string;
  caregivers: Partial<Caregiver>[];
  pickup_type: string;
  pickup_other?: string;
  agree_to_terms: boolean;
}

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values: FormValues;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<FormValues>>
}

const AllergySchema = Yup.object({
  allergy: Yup.string().oneOf(allergyOptions, 'Allergy is required').required(),
  severity: Yup.string().optional(),
  details: Yup.string().optional(),
})

export const CaregiverSchema = Yup.object({
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  relation: Yup.string().optional(),
  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(10, 'Phone number should have at least 10 digits')
    .required('Phone is required'),
  email: Yup.string().required('Email is required'),
  pickup_allowed: Yup.boolean().required().default(false),
  receive_auto_notifications: Yup.boolean().required().default(false),
  is_emergency_contact: Yup.boolean().required().default(false),
});

const validationSchemas = [
  Yup.object({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    dob: Yup.date().required('Birth date is required'),
    grade_level: Yup.string().oneOf(gradeLevelOptions, 'Grade level is required').required(),
    gender: Yup.string().oneOf(genderOptions.map(o => o.value), 'Gender is required').required(),
    origination: Yup.string().optional(),
  }),
  Yup.object({
    allergies: Yup.array().of(AllergySchema).optional(),
    social_emotional_needs: Yup.string().optional(),
  }),
  Yup.object({
    caregivers: Yup.array().min(1).of(CaregiverSchema).required('At least one Emergency Contact is required'),
    pickup_type: Yup.string().oneOf(pickupOptions,'Pick-up type is required').required(),
    pickup_other: Yup.string().optional(),
  }),
  Yup.object({
    agree_to_terms: Yup.boolean().oneOf([true], 'You must accept Terms & Conditions in waiver'),
  }),
];

const formatFormValue = (formValue: FormValues | Child) => {
  return {
    ...formValue,
    caregivers: formValue.caregivers.map(c => ({
        ...c,
        child_caregivers: {
          relation: c.relation ?? "",
          pickup_allowed: c.pickup_allowed ?? false,
          receive_auto_notifications: c.receive_auto_notifications ?? false,
          is_emergency_contact: c.is_emergency_contact ?? false,
          ...(c.id ? {caregiver_id: c.id} : {}) }
      })
    ),
  };
}

function getValues(child?: Child): FormValues | Child {
  return {
    first_name: child?.first_name || "",
    last_name: child?.last_name || "",
    dob: child?.dob || "",
    grade_level: child?.grade_level || "",
    gender: child?.gender || "",
    origination: child?.origination || "",
    allergies: child?.allergies || [],
    social_emotional_needs: child?.social_emotional_needs || "",
    caregivers: child?.caregivers && child?.caregivers.length>0 ? child?.caregivers : [{first_name: "", last_name: "", relation: "", email: "", phone: "", pickup_allowed: false, is_emergency_contact: false, receive_auto_notifications: false}],
    pickup_type: child?.pickup_type || "",
    pickup_other: child?.pickup_other || "",
    agree_to_terms: child?.agree_to_terms || false,
  }
}

interface AddChildProps {
  editing?: boolean;
  isModal?: boolean;
  hideOverlay?: (refresh: boolean) => void;
}

const AddChild: React.FC<AddChildProps> = ({
  editing,
  isModal,
  hideOverlay,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [successful, setSuccessful] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [child, setChild] = useState<Child | null>(null);
  const [initialValues, setInitialValues] = useState<FormValues | Child>(
    getValues()
  );
  const params = useParams();
  const [addChild] = useAddChildMutation();
  const [updateChild] = useUpdateChildMutation();
  const { data, error, isLoading } = useGetChildQuery(Number(params.id!), {
    skip: !editing || !params.id,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.child) {
      setChild(data.child);
      setInitialValues(getValues(data.child));
    }
  }, [data]);

  const handleAddChild = async (formValue: FormValues) => {
    try {
      let submission = formatFormValue(formValue);
      if (submission.social_emotional_needs === "") {
        delete submission.social_emotional_needs;
      }
      if (submission.pickup_other === "") {
        delete submission.pickup_other;
      }
      if (child) {
        let formattedChild = formatFormValue(child);
        let update = getObjectDifference(formattedChild, submission);
        let submitUpdate = Object.keys(update).length > 0;
        if (submitUpdate) {
          update.id = child.id;
          await updateChild(update).unwrap();
        }
        setSubmitted(submitUpdate);
      } else {
        await addChild(submission).unwrap();
        setSubmitted(true);
        if (isModal && hideOverlay) {
          hideOverlay(true);
        }
      }
      setCurrentStep(currentStep + 1);
      setSuccessful(true);
    } catch (error: any) {
      setMessage(getErrorMessage(error));
      setSuccessful(false);
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>,
    setTouched: (
      touched: FormikTouched<FormValues>,
      shouldValidate?: boolean
    ) => void
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        first_name: true,
        last_name: true,
        dob: true,
        grade_level: true,
        gender: true,
        origination: true,
        social_emotional_needs: true,
        pickup_type: true,
        pickup_other: true,
        agree_to_terms: true,
        caregivers: Array.isArray(errors.caregivers)
          ? errors.caregivers?.map(() => getNestedTouchedTrue(CaregiverSchema))
          : [],
        allergies: Array.isArray(errors.allergies)
          ? errors.allergies?.map(() => getNestedTouchedTrue(AllergySchema))
          : [],
      });
    }
  };

  const handlePrev = () => {
    setSuccessful(false);
    setSubmitted(false);
    setCurrentStep((prevStep: number) =>
      prevStep > 0 ? prevStep - 1 : prevStep
    );
  };
  return (
    <FadeIn className="w-100">
      {!isModal && (
        <BackButton
          text="Back to my Account"
          url="/user/profile"
          color={"#2b4e64"}
        />
      )}
      <FaArrowLeft
        onClick={() => handlePrev()}
        className={`text-info mb-2 my-sm-2 me-2 ${
          currentStep === 0 || (currentStep === steps.length && submitted)
            ? "opacity-0"
            : ""
        }`}
        style={{
          fontSize: 22,
          cursor: "pointer",
          transition: "opacity ease-in 0.2s",
        }}
      />
      <div className="w-100 d-flex flex-column flex-sm-row justify-content-center align-items-center">
        <h2
          className="text-info fw-bold text-start pe-lg-5"
          style={{ fontSize: 32 }}
        >
          {successful ? `Success!` : `${editing ? "Edit" : "Add New"} Child`}
        </h2>

        <Stepper
          steps={steps}
          currentStep={currentStep}
          editing={editing === true}
          onStepClick={(step) => setCurrentStep(step)}
        />
      </div>
      <div className="w-100 pt-3 d-flex justify-content-center align-items-center">
        {successful ? (
          <div
            className="mt-4 text-secondary text-center"
            style={{ minHeight: "calc(20vh + 220px)" }}
          >
            <Image
              src={SFCLogo}
              className="d-block d-lg-none w-50 mb-4 m-auto"
              alt="Sticky Fingers Logo"
            />
            <h3 className="fw-bold">
              {submitted
                ? `Your child info has been ${editing ? "updated" : "added"}`
                : "No changes detected!"}
            </h3>
            {submitted && <Button size="sm"
            onClick={()=>navigate(`/user/profile`)}
            >Done</Button>}
          </div>
        ) : (
          <Stack gap={1} style={{ maxWidth: "80%" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleAddChild}
              enableReinitialize
            >
              {({
                submitForm,
                isSubmitting,
                validateForm,
                touched,
                errors,
                setTouched,
                values,
                setFieldValue,
              }: FormikProps<FormValues>) => (
                <FormikForm className="text-start mt-3">
                  <div style={{ minHeight: "calc(20vh + 220px)" }}>
                    {currentStep < steps.length &&
                      React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                      })}
                  </div>

                  {message && (
                    <div className="form-group">
                      <div className="alert alert-danger" role="alert">
                        {message}
                      </div>
                    </div>
                  )}

                  <SimpleButton
                    disabled={isSubmitting}
                    type="button"
                    onClick={
                      currentStep === steps.length - 1
                        ? () => submitForm()
                        : () => handleNext(validateForm, setTouched)
                    }
                    className="mt-3 float-end"
                    variant="primary"
                  >
                    {currentStep === steps.length - 1
                      ? `${editing ? "Update" : "Add"} Child`
                      : "Continue"}
                  </SimpleButton>
                </FormikForm>
              )}
            </Formik>
          </Stack>
        )}
      </div>
    </FadeIn>
  );
};

export default AddChild;
