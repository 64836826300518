import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { feedbackColumns } from '../../../../../utils/consts/columnDefs/websiteColumns';
import { useDeleteFeedbacksMutation, useGetAllFeedbacksQuery } from '../../../../../services/endpoints/website/feedback';
import { Feedback } from '../../../../../types/website.types';
import { getCurrentUser } from '../../../../../services/helper';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { created_updated_at_from_to } from '../../../../../utils/consts/filterFields/dates';
import { getErrorMessage } from '../../../../../utils/utils';
import { User } from '../../../../../services/endpoints/people/user';

const defaultScope = '';
const currentUser = getCurrentUser();

export const userCanEditCreateDelete_Feedbacks = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin || user?.isWebContentEditor) {
    return true;
  }

  return false;
}

export const userCanView_Feedbacks = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin || user?.isWebContentEditor || user?.isGlobal) {
    return true;
  }

  return false;
}

export const FeedbacksView = () => {
  const { addToast } = useToast();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: {page: 1, entries: 10} });
  const { data, error, isLoading, isFetching } = useGetAllFeedbacksQuery(filters);
  const [ deleteFeedbacks ] = useDeleteFeedbacksMutation();
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  const navigate: NavigateFunction = useNavigate();
  const isGlobalUser = (currentUser?.isGlobal);

  const filterFormFields: FilterField[] = [
    {
      name: 'first_name',
      label: 'First Name',
    },
    {
      name: 'last_name',
      label: 'Last Name',
    },
    {
      name: 'location',
      label: 'Location',
    },
    {
      name: 'email',
      label: 'Email',
    },
    {
      name: 'phone_number',
      label: 'Phone Number',
    },
    {
      name: 'message',
      label: 'Message',
    },
    ...created_updated_at_from_to,
  ];

  if (!isGlobalUser) {
    filterFormFields.splice(4, 1);
  }

  useEffect(() => {
    if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setFeedbacks(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`Feedbacks`}
        columns={feedbackColumns}
        data={feedbacks}
        count={data?.count}
        defaultScope={defaultScope}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath='feedbacks'
        selectableRows={true}
        batchActions={[
          {
            name: 'Delete Selected',
            fn: (selectedRowsIDs: number[]) => {
              deleteFeedbacks({ids: selectedRowsIDs}).unwrap().then(() => {
                addToast(`Feedbacks #${selectedRowsIDs.join(',')} deleted`, 'success');
              }).catch((e: any) => {
                navigate('/admin/website/feedbacks');
                addToast(getErrorMessage(e), 'error');
              });
            }
          }
        ]}
        filters={filters?.data?.filterBy}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        newItemEl={userCanEditCreateDelete_Feedbacks(currentUser) && (
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={270}
            onClick={() => navigate('/admin/website/feedbacks/new')}
          >
            New Feedback
          </SimpleButton>
        )}
        onFiltersChanged={setFilters}
      />
    </div>
  );
}
