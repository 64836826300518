import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { Course } from '../../../types/course.type';
import { LedgerParams, LedgerResponse } from '../../../types/place.types';
import { sfcApi } from '../../api';

interface GenerateCourseValues {
  id: number;
  mode: string;
}
export interface AddCourseFormValues {
  id?: number;
  title: string;
  is_online: boolean;
  video_url?: string;
  payment_type: string;
  price_per_class: string;
  can_be_prorated?: boolean;
  course_type: string;
  theme_id: string;
  status: string;
  venue_id: string;
  is_hidden: string;
  opts_out_of_roster_emails: string;
  room?: string;
  capacity: string;
  instructor_ids: string[];
  min_instructors: string;
  instructor_notes?: string;
  pay_type: string;
  flat_rate?: string;
  admin_notes?: string;
  description: string;
  registered_information: string;
  day_of_week: string;
  starts_at: string;
  ends_at: string;
  starts_on?: string;
  ends_on?: string;
  no_class?: string;
  registration_starts_on?: string;
  registration_starts_at?: string;
  registration_ends_on?: string;
  registration_ends_at?: string;
  classes: any[];
  files?: File[];
  file_metadata?: any[];
  document_ids?: string[],
}

export interface UpdateEmailsValues {
  id?:          number;
  id_course:    number;
  emails:       string;

 
}
export interface CoursesEmails {
  id?:          number;
  id_course:    number;
  emails:       string;
  course:Course;
}

export interface CoursesEmailSend {
  id:    number;
  type:  string;
}

export const courseApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getCourses: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `courses${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Course'],
    }),
    searchCourses: builder.query<GetQueryResponse, GetQueryParams & {scopes: string[]} | void>({
      query: (params) => ({
        url: `courses/search?omit_scope_count=true&scope[]=${params?.scopes.join('&scope[]=')}`,
        method: 'POST',
        body: params?.data,
      }),
      providesTags: ['Course'],
    }),
    getCoursesSimple: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `courses/simple${params?.scope ? `?scope=${params.scope}` : ''}`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    getCourse: builder.query<{ course: Course }, number>({
      query: (id) => `courses/${id}`,
      providesTags: (result, error, id) => [{ type: 'Course', id }]
    }),
    createCourse: builder.mutation<{ course: Course }, AddCourseFormValues>({
      query: (data) => ({
        url: 'courses/create',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Course'],
    }),
    generateCourse: builder.mutation<Blob, GenerateCourseValues>({
      query: (data) => ({
        url: `courses/generate-course/${data.id}?mode=${data.mode}`,
        method: 'GET',
        responseHandler: (response) => response.blob(), // Correctly handle binary data
      }),
    }),
    updateCourse: builder.mutation<{ course: Course }, AddCourseFormValues>({
      query: (data) => {
        const { id } = data;
        delete data.id;
        return {
          url: `courses/update/${id}`,
          method: 'PUT',
          body: data
        }
      },
      invalidatesTags: (result, error) =>
        [{ type: 'Course', id: result?.course.id }, 'Course']
    }),
    
    getFavoriteCourse: builder.query<{ courseFavorited: boolean }, number>({
      query: (id) => `courses/favorite/${id}`,
    }),
    toggleFavoriteCourse: builder.query<{ courseFavorited: boolean }, number>({
      query: (id) => `courses/toggleFavorite/${id}`,
    }),
    getFavoriteCourses: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: () => 'courses/favorites',
    }),
    getCourseLedger: builder.query<LedgerResponse, LedgerParams>({
      query: (params) => ({
        url: `courses/ledger/${params.id}`,
      }),
    }),
    updateEmails: builder.mutation<{ updaterEmails:CoursesEmails }, UpdateEmailsValues>({
      query: (data) => {
        const { id_course,emails } = data;
        return {
          url: `/courses/update-emails/${id_course}`,
          method: 'PUT',
          body: {emails:emails}
        }
      },
      invalidatesTags: (result, error) =>
        [{ type: 'Course', id: result?.updaterEmails?.course?.id }, 'Course']
    }),
    sendTextReminder: builder.mutation<{ updater:CoursesEmailSend }, CoursesEmailSend>({
      query: (data) => {
        const { id,type } = data;
        return {
          url: `/courses/${type}/${id}`,
          method: 'GET',
          responseHandler: async (response) => {
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) { 
              return await response.json();    
            }
            if ( contentType?.includes('application/octet-stream') || contentType?.includes('application/pdf') || contentType?.includes('text/html')) {
              return { blob: await response.blob() };
            }
            
            // Otherwise, parse JSON
            return { blob: await response.blob() };  
          },
        }
      },
      invalidatesTags: (result, error) =>
        [{ type: 'Course', id: result?.updater?.id }, 'Course']
    }),
  }),
  
});

export const {
  useGetCoursesQuery,
  useSearchCoursesQuery,
  useGetCoursesSimpleQuery,
  useGetCourseQuery,
  useGenerateCourseMutation,
  useCreateCourseMutation,
  useUpdateCourseMutation,
  useGetFavoriteCourseQuery,
  useLazyToggleFavoriteCourseQuery,
  useGetFavoriteCoursesQuery,
  useGetCourseLedgerQuery,
  useUpdateEmailsMutation,
  useSendTextReminderMutation
} = courseApi;
