import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { BackButton } from '../../../../../components/buttons/BackButton';
import Stepper from '../../../../../components/stepper/Stepper';
import { Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FadeIn } from '../../../../animations/FadeIn';
import * as Yup from "yup";
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { getErrorMessage, getNestedTouchedTrue } from '../../../../../utils/utils';
import { useToast } from '../../../../../context/ToastContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { NewsItemForm } from './NewsItemForm';
import { useCreateNewsItemMutation, useGetNewsItemQuery, useUpdateNewsItemMutation } from '../../../../../services/endpoints/website/newsItem';
import { ImageDetails, NewsItem } from '../../../../../types/website.types';
import { dateToUTC } from '../../../../../utils/dateUtils';
import { userCanEditCreateDelete_NewsItems } from './NewsItemsView';
import { getCurrentUser } from '../../../../../services/helper';

const steps = [
  { name: 'Details', form: NewsItemForm },
];

export interface FormValues extends ImageDetails{
  id?: number;
  name: string;
  details: string;
  published_on: string;
  news_item_links: {
    text: string;
    target: string;
    link_type: string;
  }[];
}

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values?: FormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
}

interface AddEditNewsItemProps {
  editing?: boolean;
}

export const NewsItemLinkSchema = Yup.object({
  text: Yup.string().optional(),
  target: Yup.string().optional(),
  link_type: Yup.string().oneOf(['article','link']).optional(),
});

export const AddEditNewsItem: React.FC<AddEditNewsItemProps> = ({ editing }) => {
  const params = useParams();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search); 
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const navigate = useNavigate();
  const currentUser = getCurrentUser();
  
  const [createNewsItem] = useCreateNewsItemMutation();
  const [updateNewsItem] = useUpdateNewsItemMutation();
  const { data, error, isLoading }  = useGetNewsItemQuery(Number(params.id!), { skip: !editing || !params.id });

  const [initialValues, setInitialValues] = useState<FormValues>({
    name: '',
    details: '',
    published_on: '',
    news_item_links: [],
    image_file_name: '',
  });

  const validationSchemas = [
    Yup.object({
      name: Yup.string().required('Name is required'),
      details: Yup.string().required('Details is required'),
      image: Yup.string().required('Image is required'),
      published_on: Yup.string().required('Published On is required'),
      news_item_links: Yup.array().of(NewsItemLinkSchema).optional(),
    }),
  ];

  useEffect(() => {
    if (editing && error && !isLoading) {
      addToast('Error while loading News Item', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, error, isLoading]);

  useEffect(() => {
    if (data && data.newsItem) {
      const { newsItem } = data;
      setInitialValues({
        id: newsItem.id,
        name: newsItem.name,
        details: newsItem.details,
        image_file_name: newsItem.image_file_name,
        published_on: newsItem.published_on,
        news_item_links: newsItem.news_item_links,
      });
    }

    if(!userCanEditCreateDelete_NewsItems(currentUser)) {
      addToast('You have no permissions to edit this data', 'error');
      navigate(`/admin/website/news-items`);
    }
  }, [data]);


  const handleSubmit = async (formValue: FormValues) => {
    try {
      const newsItemData: NewsItem = {
        ...formValue,
        image: formValue.image ? formValue.image : undefined,
        published_on: dateToUTC(new Date(formValue.published_on), 'MM/dd/yyyy')
      };
      delete newsItemData.image_file_name;
      if (editing) {
        const res = await updateNewsItem(newsItemData).unwrap();
        if (res && res.newsItem) {
          addToast(`News Item updated succesfully`, 'success');
          navigate(`/admin/website/news-items/${data?.newsItem.id}`);
        }
      } else {
        const res = await createNewsItem(newsItemData).unwrap();
        if (res && res.newsItem) {
          navigate(`/admin/website/news-items/${res.newsItem.id}`);
          addToast('News Item created succesfully', 'success');
        } else throw new Error('A problem happened while creating News Item');
      }
    } catch (e) {
      addToast(getErrorMessage(e), 'error');
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>, 
    setTouched: (touched: FormikTouched<FormValues>, shouldValidate?: boolean) => void,
    values: FormValues
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        name: true,
        details: true,
        image_file_name: true,
        published_on: true,
        news_item_links: Array.isArray(errors.news_item_links) ? errors.news_item_links?.map(() => getNestedTouchedTrue(NewsItemLinkSchema)) : [],
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text={`Back to News Item${editing ? ' details' : 's'}`}
            color="#2B4E64"
            url={editing ? `/admin/website/news-items/${data?.newsItem.id}` : '/admin/website/news-items'}
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: '2.25rem' }}
          >
            {editing ? 'Edit' : 'New'} News Item
          </h2>
          {steps.length > 1 && <Stepper steps={steps} currentStep={currentStep} editing={editing === true} onStepClick={(step) => setCurrentStep(step)} />}
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow: '0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)',
            border: '1px solid #EBEBEB',
            borderRadius: 10,
          }}
        >
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FormValues>) => (
                <FormikForm className="text-start">
                  <div style={{ minHeight: '65vh' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                      })
                    }
                  </div>

                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      {currentStep !== 0 && (
                        <SimpleButton
                          disabled={isSubmitting}
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                          className="w-100"
                          variant="outline-primary"
                        >
                          Back
                        </SimpleButton>
                      )}
                    </Col>

                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={currentStep === steps.length - 1 ? submitForm : () => handleNext(validateForm, setTouched, values) }
                        className="w-100"
                        variant="primary"
                      >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                      </SimpleButton>
                    </Col>                   
                  </Row>
                </FormikForm>
              )}
            </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
