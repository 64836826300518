import { GetQueryParams, GetQueryParamsWithFilterArray, GetQueryResponse } from '../../../types/api.type';
import { sfcApi } from '../../api';

export interface PowerBi {
  accessToken:string;
  embedToken:string;
}
export const powerBiTokensApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    
    getPowerBiTokens: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: () => 'dashboard/power-bi-tokens',
    }),
  }),
});

export const {
  useGetPowerBiTokensQuery
} = powerBiTokensApi;