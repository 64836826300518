import { Badge, ButtonGroup, Col, Form, Row, Spinner, Stack, ToggleButton } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from '../AddEditSession';
import { useToast } from '../../../../../../context/ToastContext';
import { useGetVenuesQuery } from '../../../../../../services/endpoints/places/venue';
import { getErrorMessage } from '../../../../../../utils/utils';
import { TimeField } from '../../../../../../components/tables/filterForms/TimeField';
import { DateField } from '../../../../../../components/tables/filterForms/DateField';
import { IoCloseSharp } from 'react-icons/io5';
import { SchedulerCalendar } from '../../../../../../components/calendar/SchedulerCalendar/SchedulerCalendar';
import { dateFromString, formatDate } from '../../../../../../utils/dateUtils';

const daysOfTheWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const ScheduleForm = ({ touched, errors, values, setFieldValue, readOnly }: StepProps) => {
  const { data, error, isLoading } = useGetVenuesQuery();
  const toast = useToast();

  if (isLoading) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  }

  if (error || !data || !data.results) {
    toast.addToast(getErrorMessage(error) ?? 'Error loading venues', 'error');
    return <></>;
  }

  return (
    <Row>
      <h6
        className="fw-bold mb-3"
        style={{ fontSize: '1.125rem', color: '#5E6469' }}
      >
        Schedule
      </h6>
      <Col xs={12} lg={6}>
        <Stack gap={3}>
          <Form.Group controlId="day_of_week">
            <Form.Label>Days of the week</Form.Label>
            <ButtonGroup className="w-100 overflow-auto">
              {daysOfTheWeek.map((day, index) => (
                <Field
                  name="day_of_week"
                  as={Form.Control}
                  key={index}
                  isInvalid={touched.day_of_week && !!errors.day_of_week}
                  disabled={readOnly}
                >
                  {({ field }: any) => (
                    <ToggleButton
                      id={String(index)}
                      type="checkbox"
                      className="fw-normal py-2"
                      variant="outline-secondary"
                      checked={values!.day_of_week.includes(day)}
                      disabled={readOnly}
                      value={day}
                      onChange={() => {
                        const newDaysOfWeek = values!.day_of_week?.includes(day)
                          ? values!.day_of_week.filter((d) => d !== day)
                          : [...values!.day_of_week, day];
                        setFieldValue!(field.name, newDaysOfWeek);
                      }}
                      style={{
                        borderColor: 'rgba(94, 100, 105, .5)',
                        borderWidth: 1,
                        borderLeftWidth: index === 0 ? 1 : 0,
                      }}
                    >
                      {day}
                    </ToggleButton>
                  )}
                </Field>
              ))}
            </ButtonGroup>
            {!readOnly && <Form.Text>Select all that apply</Form.Text>}
            <ErrorMessage name="day_of_week" component="span" className="text-danger fs-small" />
          </Form.Group>

          <Row>
            <Col xs={12} lg={6}>
              <TimeField
                name="starts_at"
                label="Start Time"
                isInvalid={touched.starts_at && !!errors.starts_at}
                disabled={readOnly}
              />
            </Col>
            <Col xs={12} lg={6}>
              <TimeField
                name="ends_at"
                label="End Time"
                isInvalid={touched.ends_at && !!errors.ends_at}
                disabled={readOnly}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
             
               <Form.Group controlId={'starts_on'} className="w-100">
                    <Form.Label>Start Date</Form.Label>
                    <Field name={'starts_on'}>
                      {({ field }: { field: any }) => (
                        <Form.Control
                          type="date"
                          
                          {...field}
                          disabled={readOnly}
                          isInvalid={touched.starts_on && !!errors.starts_on}
                        />
                      )}
                    </Field>
              
                    <ErrorMessage
                      name={'starts_on'}
                      component="span"
                      className="text-danger fs-small"
                    />
                  </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
            <Form.Group controlId={'ends_on'} className="w-100">
                    <Form.Label>End Date</Form.Label>
                    <Field name={'ends_on'}>
                      {({ field }: { field: any }) => (
                        <Form.Control
                          type="date"
                          
                          {...field}
                          disabled={readOnly}
                          isInvalid={touched.ends_on && !!errors.ends_on}
                        />
                      )}
                    </Field>
              
                    <ErrorMessage
                      name={'ends_on'}
                      component="span"
                      className="text-danger fs-small"
                    />
                  </Form.Group>

              {/* <DateField
                name="ends_on"
                label="End Date"
                isInvalid={touched.ends_on && !!errors.ends_on}
                disabled={readOnly}
              /> */}
            </Col>
          </Row>

          <Form.Group controlId="no_class" className="w-100">
            <Form.Label>No Class Dates (Optional)</Form.Label>
            {!readOnly && (
              <>
                <Field
                  name="no_class_selector"
                  as={Form.Control}
                  type="date"
                  onKeyDown={(e: React.ChangeEvent<HTMLInputElement>) => e.preventDefault()}
                  onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
                    const day = v.target.value;
                    if (!values?.no_class?.includes(day))
                      setFieldValue!('no_class', [...values!.no_class ?? [], day]);
                  }}
                />

                <Form.Text>Select all that apply</Form.Text>
              </>  
            )}

            <div>
              {values?.no_class?.map((date, index) => (
                <Badge key={index} pill className="me-2 mt-2 text-light" style={{ fontSize: '1rem' }}>
                  {formatDate(dateFromString(`${date} ${values?.starts_at ?? '00:00'}`, 'yyyy-MM-dd HH:mm'))}
                  {!readOnly && <IoCloseSharp
                    size={16}
                    className="ms-1"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setFieldValue!('no_class', values!.no_class!.filter((d) => d !== date))}
                  />}
                </Badge>
              ))}
            </div>
          </Form.Group>

          <Row className="mb-2">
            <Col xs={12} lg={6} className="mb-2">
            <Form.Group controlId={'registration_starts_on'} className="w-100">
                    <Form.Label>Registration Start Date (Optional)</Form.Label>
                    <Field name={'registration_starts_on'}>
                      {({ field }: { field: any }) => (
                        <Form.Control
                          type="date"
                          
                          {...field}
                          isInvalid={touched.registration_starts_on && !!errors.registration_starts_on}
                          disabled={readOnly}
                         
                        />
                      )}
                    </Field>
              
                    
                  </Form.Group>
              
            </Col>
            <Col xs={12} lg={6} className="mb-2">
              <TimeField
                name="registration_starts_at"
                label="Registration Start Time (Optional)"
                isInvalid={touched.registration_starts_at && !!errors.registration_starts_at}
                disabled={readOnly}
              />
            </Col>

            <Col xs={12} lg={6} className="mb-2">


            <Form.Group controlId={'registration_ends_on'} className="w-100">
                    <Form.Label>Registration End Date (Optional)</Form.Label>
                    <Field name={'registration_ends_on'}>
                      {({ field }: { field: any }) => (
                        <Form.Control
                          type="date"
                          
                          {...field}
                          isInvalid={touched.registration_ends_on && !!errors.registration_ends_on} 
                          disabled={readOnly}
                         
                        />
                      )}
                    </Field>
              
                   
                  </Form.Group>
             
            </Col>
            <Col xs={12} lg={6} className="mb-2">
              <TimeField
                name="registration_ends_at"
                label="Registration End Time (Optional)"
                isInvalid={touched.registration_ends_at && !!errors.registration_ends_at}
                disabled={readOnly}
              />
            </Col>
          </Row>
        </Stack>
      </Col>

      <Col xs={12} lg={6}>
        <SchedulerCalendar
          daysOfWeek={values?.day_of_week.map((day) => daysOfTheWeek.indexOf(day))!}
          noClassDates={values?.no_class ?? []}
          startDate={dateFromString(`${values?.starts_on} 00:00`, 'yyyy-MM-dd HH:mm')}
          endDate={dateFromString(`${values?.ends_on} ${values?.ends_at ?? '00:00'}`, 'yyyy-MM-dd HH:mm')}
        />
      </Col>
    </Row>
  )
}
