import React, { ReactNode } from 'react';
import { Button, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface IconButtonProps {
  icon: ReactNode;
  text?: string;
  handleFunction?: () => void;
  children?: ReactNode;
}

export const IconButton: React.FC<IconButtonProps> = ({ icon, text, handleFunction, children }) => {
  return (
    <Stack direction="horizontal" gap={2} className="text-info py-1">
      {icon}
      {text && <Button variant="light" className="text-info fw-semibold" style={{ maxWidth: '80%' }} onClick={handleFunction} >{text}</Button>}
      {children}
    </Stack>
  );
};
