import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { Button, Spinner, Stack } from "react-bootstrap";
import { PaymentIntent } from "@stripe/stripe-js";
import { Discount, LocationOrders, PromotionalCode } from "../../../../types/orders.type";
import { useCreateStripeIntentMutation } from "../../../../services/endpoints/stripe/stripe";
import { useToast } from "../../../../context/ToastContext";
import CurrencyCell from "../../../../components/tables/cellComponents/CurrencyCell";
import { BsCheck } from "react-icons/bs";

interface PaymentFormProps {
  dpmCheckerLink?: string;
  location_id: number;
  locationOrders?: LocationOrders;
  appliedDiscounts?: Discount[];
  promoCode?: PromotionalCode;
}

const PaymentForm: React.FC<PaymentFormProps> = ({dpmCheckerLink, location_id, locationOrders, appliedDiscounts, promoCode}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [ createPaymentIntent ] = useCreateStripeIntentMutation();
  const [ paymentIntent, setPaymentIntent ] = useState<{clientSecret: string, amount: number}>();
  const { addToast } = useToast();

  const [message, setMessage] = useState<string|null|undefined>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const getPaymentIntent = async () => {
    try {
      if (!locationOrders) return;
      let payload = {
        location_id: locationOrders?.location.id,
        orders: locationOrders?.orders.map(o => ({id: o.id, total: o.cost.price})),
        promoCodeId: promoCode?.id,
        appliedDiscountIds: appliedDiscounts?.map(d => d.id),
      }
      const pi = await createPaymentIntent(payload).unwrap();
      setPaymentIntent(pi);
      setCanSubmit(!!(pi.clientSecret))
      console.log(pi);
    } catch (error) {
      console.log(error);
      addToast('Error initiating payment intent', 'error');
    }
  }

  const handleError = (error: any) => {
    setIsLoading(false);
    setMessage(error.message);
  }

  const handleSubmitCard = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    await getPaymentIntent();
    setIsLoading(false);
  }

  const handleSubmitPayment = async () => {

    if (!stripe || !elements) {
      return;
    }

    if (!paymentIntent || !(paymentIntent.clientSecret)) return;

    const order_ids = locationOrders?.orders.map(o => o.id).join(',');
    const course_ids = locationOrders?.orders.map(o => o.course.id).join(',');

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret: paymentIntent.clientSecret,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/cart?location_id=${location_id}&order_ids=${order_ids}&course_ids=${course_ids}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  let confirmContent = (canSubmit) ? (
    <Stack direction='horizontal' gap={2} className="mt-3">
      <Button disabled variant="primary" className="p-0"><BsCheck size={28} /></Button>
      <h4>Confirm Payment of</h4>
      <h4><CurrencyCell value={(paymentIntent?.amount ?? 0)/100} /></h4>
    </Stack>
  ) : (
    <></>
  );

  return (
    <>
      <form id="payment-form">
        <Stack>
          <PaymentElement id="payment-element" options={{readOnly: canSubmit}} />
          {/* Show any error or success messages */}
          {message && <div className="text-danger" id="payment-message">{message}</div>}
          {confirmContent}
          <Button
            id="submit"
            disabled={isLoading || !stripe || !elements} 
            type="button"
            className="mt-3 text-uppercase"
            variant="primary"
            onClick={canSubmit ? handleSubmitPayment : handleSubmitCard}
          >
            <span id="button-text">
              {isLoading ? <Spinner /> : canSubmit ? "Confirm Payment" : "Save"}
            </span>
          </Button>
        </Stack>
      </form>
      {/* [DEV]: Display dynamic payment methods annotation and integration checker */}
      {/* <div id="dpm-annotation">
        <p>
          Payment methods are dynamically displayed based on customer location, order amount, and currency.&nbsp;
          <a href={dpmCheckerLink} target="_blank" rel="noopener noreferrer" id="dpm-integration-checker">Preview payment methods by transaction</a>
        </p>
      </div> */}
    </>
  );
}

export default PaymentForm;