import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Spinner, Stack } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { DetailSection, LabelText } from '../../../../../components/generic';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';
import { PromotionalCode } from '../../../../../types/orders.type';
import { useDeletePromotionalCodeMutation, useGetPromotionalCodeQuery } from '../../../../../services/endpoints/orders/promotionalCode';
import CheckmarkCell from '../../../../../components/tables/cellComponents/CheckmarkCell';
import { formatDate } from '../../../../../utils/dateUtils';

export const PromotionalCodeDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetPromotionalCodeQuery(+params.id!);
  const [promoCode, setPromoCode] = useState<PromotionalCode>();
  const [deletePromoCode] = useDeletePromotionalCodeMutation();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading promotional code', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setPromoCode(data.promotionalCode);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  if (!promoCode) return <></>;

  const handleDelete = async () => {
    try {
      await deletePromoCode(Number(params.id!));
      addToast('Promotional code deleted successfully', 'success');
      navigate(`/admin/orders/promotional-codes`);
    } catch (e) {
      addToast('Error while deleting promotional code', 'error');
    } 
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate('/admin/orders/promotional-codes')}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Promotional Codes List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">
            {promoCode?.name}
          </h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/orders/promotional-codes/${promoCode.id}/edit`)}
          >
            Edit
          </SimpleButton>
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => setShowModal(true)}
          >
            Delete
          </SimpleButton>
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <DetailSection>
            <LabelText label="Name:" text={promoCode?.name} />
            <LabelText label="Code:" text={promoCode?.code} />
            <LabelText label="Percent Discount:" text={`${promoCode?.percent_discount}%`} />
            <LabelText label="Is Active:">
              <CheckmarkCell value={promoCode?.is_active} />
            </LabelText>
            <LabelText label="Expiration date::" text={formatDate(new Date(promoCode?.expiration_date), 'MM/dd/yyyy h:mm a zzz')} />
            <LabelText label="Created At:" text={formatDate(new Date(promoCode?.created_at), 'MM/dd/yyyy h:mm a zzz')} />
            <LabelText label="Updated At:" text={formatDate(new Date(promoCode?.created_at), 'MM/dd/yyyy h:mm a zzz')} />
          </DetailSection>
        </Stack>
      </div>

      <ConfirmationModal
        show={showModal}
        title="Delete Promotional code"
        message="Are you sure you want to remove this Promotional code?"
        confirmText="Yes, Delete"
        cancelText="Cancel"
        onConfirm={() => handleDelete()}
        onCancel={() => setShowModal(false)}
      />
    </>
  );
};
