import { Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from './AddEditInvoiceItem';
import { useGetAllInvoicesQuery } from '../../../../../services/endpoints/finance/invoice';
import { useToast } from '../../../../../context/ToastContext';
import { useEffect, useState  } from 'react';
import { Invoice } from '../../../../../types/finance.types';

export const InvoiceItemForm = ({ touched, errors, values, setFieldValue }: StepProps) => {
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetAllInvoicesQuery();
  const [selectedInvoiceType, setSelectedInvoiceType] = useState<'Flat Rate' | 'Per Student'>('Flat Rate');

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading invoices', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[error, isLoading]);

  // Update selected invoice type when invoice selection changes
  useEffect(() => {
    if (values?.invoice_id && data?.results) {
      const selectedInvoice = data.results.find(
        (invoice: Invoice) => invoice.id === Number(values.invoice_id)
      );
      if (selectedInvoice) {
        setSelectedInvoiceType(selectedInvoice.invoice_type);
      }
    }
  }, [values?.invoice_id, data?.results]);

  const unitPriceLabel = selectedInvoiceType === 'Flat Rate'
    ? "Unit Price (Flat Rate Tuition Per Class)"
    : "Unit Price (# of classes for this session<br>X the tuition price per student)";


  return (
    <>
      <Row>
        <Col xs={12} lg={6} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="invoice_id">
            <Form.Label>Invoice</Form.Label>
            <Field
              name="invoice_id"
              as={Form.Select}
              value={values?.invoice_id}
              isInvalid={touched.invoice_id && !!errors.invoice_id}
            >
              <option>Select</option>
              {data?.results?.map((invoice: Invoice, idx) => (
                <option key={idx} value={invoice.id}>{invoice.name ?? 'N/A'}</option>
              ))}
            </Field>
            <ErrorMessage name="invoice_id">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="quantity">
            <Form.Label>Quantity</Form.Label>
            <Field
              name="quantity"
              type="number"
              as={Form.Control}
              isInvalid={touched.quantity && !!errors.quantity}
            />
            <ErrorMessage name="quantity">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="unit_price">
            <Form.Label dangerouslySetInnerHTML={{ __html: unitPriceLabel }} />
            <Field
              name="unit_price"
              type="number"
              as={Form.Control}
              isInvalid={touched.unit_price && !!errors.unit_price}
            />
            <ErrorMessage name="unit_price">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          {/* TODO: enable enrollment field and fill with current invoice sessions enrollments */}

          {/* <Form.Group className="mb-1 mb-lg-3" controlId="enrollment_id">
            <Form.Label>Enrollment</Form.Label>
            <Field
              name="enrollment_id"
              as={Form.Select}
              value={values?.enrollment_id}
              isInvalid={touched.enrollment_id && !!errors.enrollment_id}
            >
              <option>Select</option>
              {data?.results?.map((invoice: Invoice, idx) => (
                <option key={idx} value={invoice.id}>{invoice.name ?? 'N/A'}</option>
              ))}
            </Field>
            <ErrorMessage name="enrollment_id">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group> */}

          <Form.Group className="mb-1 mb-lg-3" controlId="description">
            <Form.Label>Description (Optional)</Form.Label>
            <Field
              name="description"
              as="textarea"
              className="form-control"
              rows={4}
            />
            <ErrorMessage name="description">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group className="mb-1 mb-lg-3" controlId="invoice_notes">
            <Form.Label>Invoice Notes (Optional)</Form.Label>
            <Field
              name="invoice_notes"
              as="textarea"
              className="form-control"
              rows={4}
            />
            <ErrorMessage name="invoice_notes">
              {(msg) => (
                <FormControl.Feedback type="invalid">{(msg)}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}
