import React from 'react';
import DOMPurify from 'dompurify';

const SafeHTMLHeader: React.FC<{ htmlContent: string }> = ({ htmlContent }) => {
  const regex = /\{\{(.*?)\}\}/g;
  const boldRegex = /\*\*(.*?)\*\*/g; // Handle **bold text**

  let formattedLine = htmlContent.replace(regex, (match, value) => {
    return `<strong>${value}</strong>`;
  });
  formattedLine = formattedLine.replace(boldRegex, (match, value) => {
    return `<b>${value}</b>`;
  });
  const sanitizedHTML = DOMPurify.sanitize(formattedLine);
  return (
    <h5 className='text-info step-title'
      dangerouslySetInnerHTML={{ __html: sanitizedHTML.trim() }}></h5>
  );


};

export default SafeHTMLHeader;
