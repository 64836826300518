import { Col, Container, Row, Form } from 'react-bootstrap';
import { Event, SFCCalendar } from '../../../components/calendar/SFCCalendar/SFCCalendar';
import { Filters } from '../../../components/calendar/SFCCalendar/Filters';
import { eventColors, eventTypeMapping, eventTypes, isAdmin } from '../../../utils/consts/index';
import { SFCMobileCalendar } from '../../../components/calendar/SFCCalendar/SFCMobileCalendar';
import { useGetInstructorLessonsQuery, useGetLessonsCountQuery, useGetLessonsQuery } from '../../../services/endpoints/schedule/lesson';
import { Lesson } from '../../../types/lesson.type';
import { useEffect, useRef, useState } from 'react';
import { GetQueryParams } from '../../../types/api.type';
import { useToast } from '../../../context/ToastContext';
import { getCurrentUser, LatLng } from '../../../services/helper';
import { formatDate, dateInTimeZone, UTCToDate } from '../../../utils/dateUtils';
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import {APIProvider, Map, MapEvent, AdvancedMarker } from '@vis.gl/react-google-maps';

const EventKeys = () => (
  <div className="mt-3 border rounded-3 p-2 px-lg-3 py-lg-2" style={{ width: 'fit-content' }}>
    <span className="text-info text-uppercase fw-semibold">Key</span>
    <Row className="align-items-center">
      {Object.keys(eventTypes).map((type: string) => {
        const key = type as keyof typeof eventTypes;
        return (
          <Col key={type}  xs={12} lg="auto" className="d-flex align-items-center me-4">
            <div className="rounded-circle me-1" style={{ width: 8, height: 8, background: eventColors[key]}}></div>
            <span>{eventTypes[key]}</span>
          </Col>
        )
      })}
    </Row>
  </div>
);

const defaultFilters = [
  {
    operator: 'greater_equal_than',
    field: 'date',
    value: formatDate(dateInTimeZone(startOfWeek(startOfMonth(new Date()))), 'yyyy-MM-dd'),
  },
  {
    operator: 'lesser_equal_than',
    field: 'date',
    value: formatDate(dateInTimeZone(endOfWeek(endOfMonth(new Date()))), 'yyyy-MM-dd'),
  }
];

export const CalendarView = () => {
  const currentUser = getCurrentUser();
  const isAdminUser = currentUser?.isAdmin;
  const [ center, setCenter ] = useState<LatLng>({ lat: 40.7128, lng: -74.006 })
    const mapRef = useRef<any>(null);
    const embassyIcon = "https://cdn-icons-png.flaticon.com/128/684/684908.png"; // Example embassy icon URL

  const [filters, setFilters] = useState<GetQueryParams>({
    data: {
      filterBy: [...defaultFilters, ...(!isAdminUser ? [{
        operator: 'equals',
        field: 'additional_instructor_id',
        value: String(currentUser?.id),
      }]: [])]
    }
  });
  
  const [events, setEvents] = useState<Event[]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [showFullEvents, setShowFullEvents] = useState<boolean>(!isAdminUser);
  const [fetchEvents, setFetchEvents] = useState<boolean>(!isAdminUser);
  const { data: lessons, error: lessonsError, isLoading: lessonsLoading, isFetching: lessonsFetching } = useGetInstructorLessonsQuery(filters, { skip: !fetchEvents});
  const { data, error, isLoading, isFetching } = useGetLessonsCountQuery({start: dates[0], end: dates[1]}, {skip: fetchEvents || showFullEvents || !isAdminUser || dates[0] === undefined || dates[1] === undefined});
  const { addToast } = useToast();
  const [position, setPosition] = useState({ lat: 39.8283, lng: -98.5795 });
    const [marker, setMarkers] = useState<[]>([]);
   const [showVenues, setShowVenues] = useState<boolean>(true);
   const zoom = 4; // Adjusted to show the full country
   const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY || 'undefined';

  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading events', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    
    if (lessons) {
     
      const lessonsEvents: Event[] = [];
      const marker:any = [];
      lessons.forEach(async (item) => {
          item.lessons.forEach((lesson,idx) => {
            lessonsEvents.push({
              title: lesson.course.title,
              start: new Date((lesson.starts_on ? lesson.starts_on : lesson.course.starts_on) + ':'),//UTCToDate(`${lesson.date} ${lesson.starts_at}`, 'yyyy-MM-dd h:mm a'),
              end: new Date((lesson.ends_on ? lesson.ends_on : lesson.course.ends_on) + ':'), //UTCToDate(`${lesson.date} ${lesson.ends_at}`, 'yyyy-MM-dd h:mm a'),
              type: eventTypeMapping[item.label!],
              lesson,
            });

            if(lesson?.course?.venue?.latitude && lesson?.course?.venue?.longitude){
              marker.push({
                id: idx, lat: lesson?.course?.venue?.latitude, lng: lesson?.course?.venue?.longitude, label: lesson?.course?.venue?.title
              })
            }
          })
      });
      setFetchEvents(false);
      setEvents(lessonsEvents);
      setShowFullEvents(true);
      setMarkers(marker)
    }
  }, [lessons]);

  useEffect(() => {
    if (data && !showFullEvents) {
      const lessons: Event[] = [];
      data.lessonsCount.forEach((item) => {
        let start = new Date(item.lesson_date + ':');
        let end = new Date(item.lesson_date + ':');
        end.setHours(23);

        for (let index = 0; index < item.count_all; index++) {
          lessons.push({
            title: '',
            start,
            end,
            type: eventTypeMapping['main_instructor_id'],
          });
        }
      });
      setEvents(lessons);
    }
  }, [data, showFullEvents]);

  useEffect(() => {
    if (filters && filters.data?.filterBy) {
      let filterArr = filters.data.filterBy;
      const newDates: string[] = filterArr.filter((filter) => filter.field === 'date')
        .map((filter) => filter.value as string);
      setDates(newDates);
      const fetchEventFlag = filterArr?.find(f => f.field === 'additional_instructor_id') !== undefined;
      setFetchEvents(fetchEventFlag);
    }
  }, [filters]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="px-0 px-lg-5">
      <h2
        className="d-block d-lg-none text-secondary text-uppercase px-3 py-2"
        style={{ background: '#EFEFEF', fontSize: '1.5rem', fontWeight: 900 }}
      >
        Calendar
      </h2>
      <h2
        className="d-none d-lg-block text-info fw-bold pt-4 ps-2"
        style={{ fontSize: '2.25rem' }}
      >
        Calendar
      </h2>

      <Container fluid>
        <Filters onApplyFilters={setFilterBy} disabled={isLoading || isFetching} />
        {/* Mobile calendar */}
        <div
          className="d-block d-lg-none mb-4"
        >
          <SFCMobileCalendar
            events={events}
            showFullEvents={showFullEvents}
            loading={isLoading || isFetching || lessonsFetching}
            currentDates={dates}
          />
          <EventKeys />
        </div>

        {/* Desktop */}
        <div
          className="d-none d-lg-block p-5 rounded-4 mb-5"
          style={{
            boxShadow: '2px -2px 8px 2px rgba(0, 0, 0, 0.1), -2px 2px 8px 2px rgba(0, 0, 0, 0.1)',
          }}
        >
          <SFCCalendar
            events={events}
            loading={isLoading || isFetching || lessonsFetching}
            showFullEvents={showFullEvents}
            currentDates={dates}
          />
          <EventKeys />
        </div>
        <APIProvider apiKey={googleApiKey}>
            
        <div style={{ width: "100%", height: "500px" }}>
        <Form>
      
        <div  className="mb-3">
          <Form.Check
            inline
            label="Venues"
            name="group1"
            type="checkbox"
            checked={showVenues}
            onChange={() => setShowVenues(!showVenues)}
          />
          
        </div>
      
    </Form>
        <Map
        mapId="7914a7f3b7361cce"
          defaultCenter={position}
          defaultZoom={4}
          gestureHandling="greedy"
          disableDefaultUI={false}
          onClick={(e) => {
            if (e.detail.latLng) {
              setPosition({
                lat: e.detail.latLng.lat,
                lng: e.detail.latLng.lng,
              });
            }
          }}
        >
          {/* Render markers */}
          {showVenues && marker.length>0 && marker?.map((marker:any) => (
            <AdvancedMarker key={marker.id} position={{ lat: marker?.lat, lng: marker?.lng }}>
              <div style={{ background: "white", padding: "5px", borderRadius: "5px", fontSize: "12px" }}>
              <img src={embassyIcon} alt="Embassy Icon" width="30" height="30" />
                {marker.label}
              </div>
            </AdvancedMarker>
          ))}
        </Map>
      </div>
                 </APIProvider>
      </Container>
    </div>
  )
}
