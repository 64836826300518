export const expensesCategories: { [key: string]: string } = {
  facility_use_fee: 'Facility Use Fee',
  on_site_helper_pay: 'On Site Helper Pay',
  cleaning_repair_fee: 'Cleaning Repair Fee',
  craft_or_supply_costs_for_students: 'Craft or Supply Costs for Students',
  adjustment: 'Adjustment',
  general_location_expense: 'General Location Expense'
};

export const paymentMethods: { [key: string]: string } = {
  check: 'Check', 
  credit_card: 'Credit Card', 
  ach: 'ACH', 
};

export const paymentMethodNumbers: { [key: number]: string } = {
  1: 'Check', 
  2: 'Credit Card', 
  3: 'ACH', 
};

export const receiptStatuses: { [key: number]: string } = {
  0: 'pending', 
  1: 'denied', 
  2: 'approved', 
};

export const expensesContext: { [key: string]: string } = {
  per_session: 'Per Session', 
  per_class: 'Per Class',
};

export const invoiceStatusColors: { [key: string]: string; } = {
  draft: '#7F9BB4',
  sent: '#953E6A',
  late: '#CE4D2D',
  paid: '#BFC662',
};