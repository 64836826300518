import { ColumnDef } from '@tanstack/react-table';
import { Lesson, LessonAssignment } from '../../../types/lesson.type';
import CheckmarkCell from '../../../components/tables/cellComponents/CheckmarkCell';
import { LinkCell } from '../../../components/tables/cellComponents/LinkCell';
import { EnrollmentCell } from '../../../components/tables/cellComponents/EnrollmentCell';
import { VenueCell } from '../../../components/tables/cellComponents/VenueCell';
import { DateCell } from '../../../components/tables/cellComponents/DateCell';

export const classColumns: ColumnDef<Lesson>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
  },
  {
    header: 'Date',
    accessorKey: 'date',
    cell: ({ row }) => <DateCell date={row.original.date} time={row.original.starts_at} />
  },
  {
    header: 'Time',
    id: 'lesson_time',
    cell: ({ row }) => (
      <>
        <DateCell date={row.original.date} time={row.original.starts_at} format="h:mm a" />
        {' - '}
        <DateCell date={row.original.date} time={row.original.ends_at} format="h:mm a" />
      </>
    ),
  },
  {
    header: 'First Class',
    accessorKey: 'first_lesson',
    cell: ({ getValue }) => (
      <CheckmarkCell value={!!getValue<number>()} />
    ),
  },
  {
    header: 'Last Class',
    accessorKey: 'last_lesson',
    cell: ({ getValue }) => (
      <CheckmarkCell value={!!getValue<number>()} />
    ),
  },
  {
    header: 'Session',
    accessorKey: 'course.title',
    cell: ({ row }) => (
      <LinkCell content={row.original.course.title} url={`/admin/schedule/sessions/${row.original.course.id}`} />
    ),
  },
  {
    header: 'Location',
    accessorKey: 'course.venue.title',
    cell: ({ row }) => (
      <VenueCell venue={row.original.course.venue} />
    ),
  },
  {
    header: 'Needs Sub',
    accessorKey: 'needs_sub',
    cell: ({ getValue }) => (
      <CheckmarkCell value={getValue<boolean>()} />
    ),
  },
  {
    header: 'Enrollment',
    accessorKey: 'enrollment',
    cell: ({ getValue, row }) => (
      <EnrollmentCell value={getValue<string>()} type={row.original.course.payment_type} />
    ),
  },
  {
    header: 'Instructor',
    id: 'lesson_instructor',
    accessorKey: 'lesson_assignments',
    accessorFn: (data) => {
      const subbed = data.lesson_assignments.some(l => l.instructor_role === "substitute");
      return data.lesson_assignments.map(l => l.instructor?.full_name).concat(subbed ? [] : data.course.course_assignments.map(l => l.instructor.full_name));
    },
    cell: ({ row }) => {
      const instructors = row.original.lesson_assignments.map(l => (
        <div key={l.id} className={`swatch ${l.instructor_role === "instructor" ? "additional" : l.instructor_role}`}>{`${l.instructor?.first_name} ${l.instructor?.last_name}`}</div>
      ));

      const mains = row.original.course.course_assignments.map(l => (
        <div key={l.id} className={`swatch main`}>{`${l.instructor?.first_name} ${l.instructor?.last_name}`}</div>
      ));
      const subbed = row.original.lesson_assignments.some(l => l.instructor_role === "substitute");
      return (<>{subbed ? <></> : mains}{instructors}</>);
    },
  },
];

export const needSubClassesColumns: ColumnDef<LessonAssignment>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
  },
  {
    header: 'Requesting Instructor',
    id: 'lesson_instructor',
    accessorKey: 'lesson.requesting_instructor.full_name',
    cell: ({ row }) => {
      const { id, full_name } = row.original.requesting_instructor;
      return <LinkCell content={`${full_name}`} url={`/admin/dash-users/instructors/${id}`} />
    },
  },
  {
    header: 'Session',
    accessorKey: 'lesson.course.title',
    cell: ({ row, getValue }) => (
      <LinkCell content={getValue<string>()} url={`/admin/schedule/sessions/${row.original.course_id}`} />
    ),
  },
  {
    header: 'Venue',
    accessorKey: 'lesson.course.venue.title',
    cell: ({ row }) => (
      <VenueCell venue={row.original.lesson.course.venue} />
    ),
  },
  {
    header: 'Date',
    accessorKey: 'lesson.date',
    cell: ({ row }) => <DateCell date={row.original.lesson.date} time={row.original.lesson.starts_at} />
  },
  {
    header: 'Day Of Week',
    accessorKey: 'lesson.course.day_of_week',
  },
  {
    header: 'Time',
    id: 'lesson.formatted_time',
    cell: ({ row }) => (
      <p className='text-nowrap'>
        {row.original.lesson.formatted_time}
      </p>
    ),
  },
];
