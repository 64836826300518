import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { DateField } from '../../../../../components/tables/filterForms/DateField';
import { Scopes, User } from '../../../../../services/endpoints/people/user';
import { recipesColumns } from '../../../../../utils/consts/columnDefs/contentColumns';

import { currentUserHasRole, getCurrentUser } from '../../../../../services/helper';
import { isAdmin } from '../../../../../utils/consts';
import { Recipe } from '../../../../../types/lessonPlan.type';
import { useGetRecipesQuery } from '../../../../../services/endpoints/content/recipe';
import { cookMethods, cuisineTypes, mealTypes, seasonValues } from '../../../../../utils/consts/content/recipes';
import Recipes from '../../../../home/recipes/Recipes';
import RecipeTable from '../../../../../components/tables/RecipeTable';

const defaultScope = 'all';

export const userCanEditCreateDelete_Recipes = (user: User | null) => {
  if(user?.isAdmin && user?.isSuper) {
    return true;
  }
  if(user?.isWebContentEditor || user?.isSupremeAdmin) {
    return true;
  }

  return false;
}

export const userCanView_Recipes = (user: User | null) => {
  if(user?.isInstructor) {
    return false;
  }

  return true;
}

export const RecipesView = () => {
  const { addToast } = useToast();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: {
    "filterBy": [],
    "orderBy": {"field": "position", "order": "asc"},
} });

  const [scopes, setScopes] = useState<Scopes>({});
  const { data, error, isLoading, isFetching } = useGetRecipesQuery(filters);
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const navigate: NavigateFunction = useNavigate();
  const isAdminUser = currentUserHasRole(isAdmin);
  const currentUser = getCurrentUser();
  
  const filterFormFields: FilterField[] = [
    {
      name: "title",
      label: "Title",
    },
    {
      name: "author",
      label: "Author",
    },
    {
      name: "ingredient",
      label: "Ingredient",
    },
    {
      name: "season",
      element: (
        <FormSelect
          name="season"
          label="Season"
          options={Object.entries(seasonValues).map((p) => ({
            value: p[0],
            label: p[1],
          }))}
        />
      ),
    },
    {
      name: "Cuisine Type",
      element: (
        <FormSelect
          name="cuisine_type"
          label="Cuisine Type"
          options={Object.entries(cuisineTypes).map((p) => ({
            value: p[0],
            label: p[1],
          }))}
        />
      ),
    },
    {
      name: "Cook Method",
      element: (
        <FormSelect
          name="cook_method"
          label="Cook Method"
          options={Object.entries(cookMethods).map((p) => ({
            value: p[0],
            label: p[1],
          }))}
        />
      ),
    },
    {
      name: "Meal Type",
      element: (
        <FormSelect
          name="meal_type"
          label="Meal Type"
          options={Object.entries(mealTypes).map((p) => ({
            value: p[0],
            label: p[1],
          }))}
        />
      ),
    },
    {
      name: "prep_time",
      label: "Prep Time",
      type: "number",
    },
    {
      name: "cook_time",
      label: "Cook Time",
      type: "number",
    },
    {
      name: "created_at_from",
      id: "created_at",
      operator: "greater_equal_than",
      element: <DateField name="created_at_from" label="Created At" />,
    },
    {
      name: "created_at_to",
      id: "created_at",
      operator: "lesser_equal_than",
      element: <DateField name="created_at_to" />,
    },
  ];

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => {
          let scope_count = 0;
          if(data.scope_counts != null) {
            scope_count = data.scope_counts[scope] ?? 0;
          }
          return {
            title: `${title} (${scope_count})`,
            key: scope,
          }
        })));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setRecipes(data.results);
    }
  }, [data]);

 

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <RecipeTable
        key={recipes.length}
        name={`${scopes[filters?.scope!] ?? ""} Recipes`}
        columns={isAdminUser ? recipesColumns : recipesColumns.slice(1)}
        data={recipes}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath="recipes"
        filters={filters?.data?.filterBy}
        filterForm={
          <FilterForm fields={filterFormFields} onApplyFilters={setFilterBy} />
        }
        onFiltersChanged={setFilters}
        newItemEl={userCanEditCreateDelete_Recipes(currentUser) && (
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={250}
            onClick={() => navigate("/admin/content/recipes/new")}
          >
            New Recipe
          </SimpleButton>
        )
        }
      />
    </div>
  );
}
