import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { teamProfileColumns } from '../../../../../utils/consts/columnDefs/websiteColumns';
import { useGetAllTeamProfilesQuery } from '../../../../../services/endpoints/website/teamProfile';
import { TeamProfile } from '../../../../../types/website.types';
import { getCurrentUser } from '../../../../../services/helper';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { FormStaticSelect } from '../../../../../components/tables/filterForms/FormStaticSelect';
import { useDataContext } from '../../../../../context';
import { User } from '../../../../../services/endpoints/people/user';

const defaultScope = '';
const currentUser = getCurrentUser();

export const userCanEditCreateDelete_TeamProfiles = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin || user?.isWebContentEditor) {
    return true;
  }

  return false;
}

export const userCanView_TeamProfiles = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin || user?.isWebContentEditor || user?.isGlobal) {
    return true;
  }

  return false;
}

export const TeamProfilesView = () => {
  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: {page: 1, entries: 10} });
  const { data, error, isLoading, isFetching } = useGetAllTeamProfilesQuery(filters);
  const [teamProfiles, setTeamProfiles] = useState<TeamProfile[]>([]);
  const navigate: NavigateFunction = useNavigate();

  const filterFormFields: FilterField[] = [
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'title',
      label: 'Title',
    },
    {
      name: 'is_hq',
      label: 'Is HQ',
      element: (
        <FormStaticSelect
          name="is_hq"
          label="Is HQ"
          options={[{ value: 1, label: 'Yes' }, { value: 0, label: 'No' }]}
        />
      ),
    },
    {
      name: 'description',
      label: 'Description',
    },
  ];

  if(currentUser?.isGlobal) {
    filterFormFields.push({
      name: 'location_id',
      label: 'Location',
      element: (
        <FormSelect
          name="location_id"
          label="Location"
          options={locationOptions}
        />
      ),
    });
  }

  useEffect(() => {
    if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setTeamProfiles(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`Team Profiles`}
        columns={teamProfileColumns}
        data={teamProfiles}
        count={data?.count}
        defaultScope={defaultScope}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath='team-profiles'
        filters={filters?.data?.filterBy}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        newItemEl={userCanEditCreateDelete_TeamProfiles(currentUser) && (
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={270}
            onClick={() => navigate('/admin/website/team-profiles/new')}
          >
            New Team Profile
          </SimpleButton>
        )}
        onFiltersChanged={setFilters}
      />
    </div>
  );
}
