import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { Spinner, Stack, Accordion } from 'react-bootstrap';
import { useToast } from '../../../../../context/ToastContext';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import { dateToUTC, formatDate } from '../../../../../utils/dateUtils';
import { DetailSection, LabelText } from '../../../../../components/generic';
import { useGetPaymentQuery } from '../../../../../services/endpoints/finance/payment';
import { Payment } from '../../../../../types/finance.types';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';
import CurrencyCell from '../../../../../components/tables/cellComponents/CurrencyCell';
import { paymentMethods } from '../../../../../utils/consts/finance/finance';
import { revisionsColumns } from '../../../../../utils/consts/columnDefs/sessionColumns';
import { SimpleTable } from '../../../../../components/tables/SimpleTable';
import { SFCAccordion } from '../../../../../components/accordion/SFCAccordion';

export const PaymentDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetPaymentQuery(+params.id!);
  const [ payment, setPayment] = useState<Payment>();
  const [showModal, setShowModal] = useState(false);
  
  useEffect(() => {
    if (error && !isLoading) {
      addToast('Error while loading waitlist', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data) {
      setPayment(data.payment);
    }
  }, [data]);

  if (isLoading || !payment) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: '#EFEFEF' }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button className="btn btn-link p-0 text-info" onClick={() => navigate('/admin/finance/payments')}>
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: '#888888', fontSize: '1rem' }}
          >
            Payments List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">{`Payment #${payment?.id}`}</h2>
        </Stack>
        <Stack direction="horizontal" gap={1} className="d-none d-lg-flex">
          <SimpleButton
            variant="primary"
            height={36}
            onClick={() => navigate(`/admin/finance/payments/${payment?.id}/edit`)}
          >
            Edit payment
          </SimpleButton>
        </Stack>
      </div>
      <div>
        <Stack gap={4} className="p-4">
          <DetailSection>
            <LabelText label="Invoice:">
              <LinkCell
                content={payment?.invoice?.name}
                url={`/admin/finance/invoices/${payment?.invoice?.id}`}
                underline
              />
            </LabelText>
            <LabelText label="Transaction:" text={payment?.transaction_id} />
            <LabelText label="Payment Method:" text={paymentMethods[payment?.payment_method]} />
            <LabelText label="Check Number:" text={payment?.check_number} />
            <LabelText label="Total:">
              <CurrencyCell value={payment?.total} />
            </LabelText>
            <LabelText label="Payment Notes:" text={payment?.notes} />
            <LabelText label="Paid At:" text={payment?.created_at ? dateToUTC(new Date(payment?.created_at), 'MM/dd/yyyy') : 'N/A'} />
          </DetailSection>
        </Stack>
        
      </div>
      <SFCAccordion defaultActiveKey={["1"]} >
        <Accordion.Item eventKey="1">
          <Accordion.Header style={{ paddingLeft: '35px', paddingRight: '35px' }}>Revisions</Accordion.Header>
          <Accordion.Body>
            <div className="d-none d-lg-block">
              <SimpleTable
                columns={revisionsColumns.filter((col) => col.header !== 'Comment')}
                data={
                  payment?.audits ?? []
                }
              />
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </SFCAccordion>
      <ConfirmationModal
        show={showModal}
        title="Delete Payment"
        message="Are you sure you want to remove this payment?"
        confirmText="Yes, Delete"
        cancelText="Cancel"
        onConfirm={() => navigate(`/admin/finance/payments/${payment?.id}/delete`)}
        onCancel={() => setShowModal(false)}
      />
    </>
  );
};
