export default class PowerBIService {
    private apiUrl = 'https://api.powerbi.com/v1.0/myorg';
  
    constructor(private accessToken: string) {}
  
    // Get report embed URL
    async getReportEmbedUrl(workspaceId: string, reportId: string): Promise<string> {
      const url = `${this.apiUrl}/groups/${workspaceId}/reports/${reportId}`;
  
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch report: ${response.statusText}`);
      }
  
      const result = await response.json();
      return result.embedUrl;
    }
  }