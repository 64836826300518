import './App.css';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import Login from './features/user/Login';
import Register from './features/user/Register';
import Profile from './features/user/Profile';
import { UserLayout } from './features/user/UserLayout';
import { Welcome } from './features/user/Welcome';
import ForgotPassword from './features/user/ForgotPassword';
import { DashLayout } from './features/dash/layouts/DashLayout';
import { CalendarView } from './features/dash/views/CalendarView';
import { TableLayout } from './features/dash/layouts/TableLayout';
import {
  getCurrentUser,
  isAuthenticated,
} from "./services/helper";
import ResetPassword from './features/user/ResetPassword';
import { SFCLayout } from './features/home/SFCLayout';
import { SessionsView } from './features/dash/views/schedule/sessions/SessionsView';
import { UserProfileLayout } from './features/user/UserProfileLayout';
import { AdminsView } from './features/dash/views/people/AdminsView';
import { Error404 } from './features/errors/Error404';
import { InstructorsView } from './features/dash/views/people/InstructorsView';
import { ClassesView } from './features/dash/views/schedule/classes/ClassesView';
import { WaitlistView } from './features/dash/views/schedule/waitlist/WaitlistView';
import { SessionDetail } from './features/dash/views/schedule/sessions/SessionDetail';
import { AddEditSession } from './features/dash/views/schedule/sessions/AddEditSession';
import { MasterTimesheetView } from './features/dash/views/finance/MasterTimesheetView';
import { CurrentTimesheetView } from './features/dash/views/finance/CurrentTimesheetView';
import { OverridesView } from './features/dash/views/finance/OverridesView';
import { PastTimesheetsView } from './features/dash/views/finance/PastTimesheetsView';
import { TimesheetView } from './features/dash/views/finance/TimesheetView';
import { OrganizationsView, VenuesView, LocationsView, ZipCodesView } from './features/dash/views/places';
import { WaitlistDetail } from './features/dash/views/schedule/waitlist/WaitlistDetails';
import { ArchiveWaitlist } from './features/dash/views/schedule/waitlist/ArchiveWaitlist';
import { ExpensesView } from './features/dash/views/finance/Expenses/ExpensesView';
import { InvoicesView } from './features/dash/views/finance/Invoices/InvoicesView';
import { AddEditVenue } from './features/dash/views/places/venues/AddEditVenue';
import { VenueDetails } from './features/dash/views/places/venues/VenueDetails';
import { AddEditLocation } from './features/dash/views/places/locations/AddEditLocation';
import { LocationDetails } from './features/dash/views/places/locations/LocationDetails';
import { InvoiceDetails } from './features/dash/views/finance/Invoices/InvoiceDetails';
import { AddEditInvoice } from './features/dash/views/finance/Invoices/AddEditInvoice';
import { AddEditOrganization } from './features/dash/views/places/organizations/AddEditOrganization';
import { OrganizationDetails } from './features/dash/views/places/organizations/OrganizationDetails';
import { ExpenseDetails } from './features/dash/views/finance/Expenses/ExpenseDetails';
import { AddEditExpense } from './features/dash/views/finance/Expenses/AddEditExpense';
import { NeedsView } from './features/dash/views/schedule/needs/NeedsView';
import { NeedSubstituteView } from './features/dash/views/schedule/needs/NeedSubstituteView';
import { InstructorSessionRequestView } from './features/dash/views/schedule/needs/InstructorSessionRequestView';
import { HandleExpenseAction } from './features/dash/views/finance/Expenses/HandleExpenseAction';
import { UserDetailsView } from './features/dash/views/people/UserDetailsView';
import { AddEditUser } from './features/dash/views/people/AddEditUser';
import { AddEditInvoiceItem } from './features/dash/views/finance/InvoiceItems/AddEditInvoiceItem';
import { InvoiceItemDetails } from './features/dash/views/finance/InvoiceItems/InvoiceItemDetails';
import { DeleteInvoiceItem } from './features/dash/views/finance/InvoiceItems/DeleteInvoiceItem';
import { EnrollmentsView } from './features/dash/views/enrollment/EnrollmentsView';
import { EnrollmentDetails } from './features/dash/views/enrollment/EnrollmentDetails';
import { AddEditEnrollment } from './features/dash/views/enrollment/AddEditEnrollment';
import { OrdersView } from './features/dash/views/orders/OrdersView';
import { ScholarshipsView } from './features/dash/views/orders/Scholarships/ScholarshipsView';
import { ScholarshipDetails } from './features/dash/views/orders/Scholarships/ScholarshipDetail';
import { AddEditScholarship } from './features/dash/views/orders/Scholarships/AddEditScholarship';
import { DiscountsView } from './features/dash/views/orders/Discounts/DiscountsView';
import { DiscountDetails } from './features/dash/views/orders/Discounts/DiscountDetails';
import { AddEditDiscount } from './features/dash/views/orders/Discounts/AddEditDiscount';
import { OrderDetails } from './features/dash/views/orders/OrderDetails';
import { PromotionalCodesView } from './features/dash/views/orders/PromotionalCodes/PromotionalCodesView';
import { PromotionalCodeDetails } from './features/dash/views/orders/PromotionalCodes/PromotionalCodeDetails';
import { AddEditPromotionalCode } from './features/dash/views/orders/PromotionalCodes/AddEditPromotionalCode';
import { ParentsView } from './features/dash/views/people/ParentsView';
import { ChildrenView } from './features/dash/views/people/Children/ChildrenView';
import { ChildDetails } from './features/dash/views/people/Children/ChildDetails';
import { AddEditChild } from './features/dash/views/people/Children/AddEditChild';
import { AddEditInvoiceClient } from './features/dash/views/client/InvoiceClients/AddEditInvoiceClient';
import { InvoiceClientDetails } from './features/dash/views/client/InvoiceClients/InvoiceClientDetails';
import { HandleInvoiceClientAction } from './features/dash/views/client/InvoiceClients/HandleInvoiceClientAction';
import { ReceiptsView } from './features/dash/views/finance/Receipts/ReceiptsView';
import { PaymentsView } from './features/dash/views/finance/Payments/PaymentsView';
import { AddEditReceipt } from './features/dash/views/finance/Receipts/AddEditReceipt';
import { ReceiptDetails } from './features/dash/views/finance/Receipts/ReceiptDetails';
import { HandleReceiptAction } from './features/dash/views/finance/Receipts/HandleReceiptAction';
import { AddEditPayment } from './features/dash/views/finance/Payments/AddEditPayment';
import { PaymentDetails } from './features/dash/views/finance/Payments/PaymentDetails';
import { RecipesView } from './features/dash/views/content/Recipes/RecipesView';
import { RecipeDetails } from './features/dash/views/content/Recipes/RecipeDetails';
import { AddEditRecipe } from './features/dash/views/content/Recipes/AddEditRecipe';
import { DocumentsView } from './features/dash/views/content/Documents/DocumentsView';
import { AddEditDocument } from './features/dash/views/content/Documents/AddEditDocument';
import { DocumentDetails } from './features/dash/views/content/Documents/DocumentDetails';
import { HandleDocumentAction } from './features/dash/views/content/Documents/HandleDocumentAction';
import { AddEditLessonPlan } from './features/dash/views/content/LessonPlans/AddEditLessonPlan';
import { HandleLessonPlanAction } from './features/dash/views/content/LessonPlans/HandleLessonPlanAction';
import { LessonPlansView } from './features/dash/views/content/LessonPlans/LessonPlansView';
import { HandleRecipeAction } from './features/dash/views/content/Recipes/HandleRecipeAction';
import { LessonPlanDetails } from './features/dash/views/content/LessonPlans/LessonPlanDetails';
import { NewsItemsView } from './features/dash/views/website/NewsItems/NewsItemsView';
import { AddEditNewsItem } from './features/dash/views/website/NewsItems/AddEditNewsItem';
import { NewsItemDetails } from './features/dash/views/website/NewsItems/NewsItemDetails';
import { HandleNewsItemAction } from './features/dash/views/website/NewsItems/HandleNewsItemAction';
import { DeleteObjectWrapper } from './features/common/DeleteObjectWrapper';
import { PagesView } from './features/dash/views/website/Pages/PagesView';
import { SnippetsView } from './features/dash/views/website/Snippets/SnippetsView';
import { TeamProfilesView } from './features/dash/views/website/TeamProfiles/TeamProfilesView';
import { FeedbacksView } from './features/dash/views/website/Feedbacks/FeedbacksView';
import { GalleriesView } from './features/dash/views/website/Galleries/GalleriesView';
import { AddEditFeedback } from './features/dash/views/website/Feedbacks/AddEditFeedback';
import { FeedbackDetails } from './features/dash/views/website/Feedbacks/FeedbackDetails';
import { HandleFeedbackAction } from './features/dash/views/website/Feedbacks/HandleFeedbackAction';
// import { FranchiseeLeadsView } from './features/dash/views/website/FranchiseeLeads/FranchiseeLeadsView';
// import { AddEditFranchiseeLead } from './features/dash/views/website/FranchiseeLeads/AddEditFranchiseeLead';
// import { FranchiseeLeadDetails } from './features/dash/views/website/FranchiseeLeads/FranchiseeLeadDetails';
// import { HandleFranchiseeLeadAction } from './features/dash/views/website/FranchiseeLeads/HandleFranchiseeLeadAction';
import { AddEditGallery } from './features/dash/views/website/Galleries/AddEditGallery';
import { GalleryDetails } from './features/dash/views/website/Galleries/GalleryDetails';
import { HandleGalleryAction } from './features/dash/views/website/Galleries/HandleGalleryAction';
import { AddEditPage } from './features/dash/views/website/Pages/AddEditPage';
import { HandlePageAction } from './features/dash/views/website/Pages/HandlePageAction';
import { PageDetails } from './features/dash/views/website/Pages/PageDetails';
import { AddEditSnippet } from './features/dash/views/website/Snippets/AddEditSnippet';
import { HandleSnippetAction } from './features/dash/views/website/Snippets/HandleSnippetAction';
import { SnippetDetails } from './features/dash/views/website/Snippets/SnippetDetails';
import { AddEditTeamProfile } from './features/dash/views/website/TeamProfiles/AddEditTeamProfile';
import { HandleTeamProfileAction } from './features/dash/views/website/TeamProfiles/HandleTeamProfileAction';
import { TeamProfileDetails } from './features/dash/views/website/TeamProfiles/TeamProfileDetails';
import { ThemesView } from './features/dash/views/content/Themes/ThemesView';
import { AddEditTheme } from './features/dash/views/content/Themes/AddEditTheme';
import { ThemeDetails } from './features/dash/views/content/Themes/ThemesDetails';
import { HandleThemeAction } from './features/dash/views/content/Themes/HandleThemesAction';
import { AllergiesView } from './features/dash/views/people/Allergy/AllergiesView';
import { AddEditAllergy } from './features/dash/views/people/Allergy/AddEditAllergy';
import { AllergyDetails } from './features/dash/views/people/Allergy/AllergyDetails';
import { HandleAllergyAction } from './features/dash/views/people/Allergy/HandleAllergyAction';
import { WaiversView } from './features/dash/views/people/Waiver/WaiversView';
import { WaiverDetails } from './features/dash/views/people/Waiver/WaiverDetails';
import { HandleWaiverAction } from './features/dash/views/people/Waiver/HandleWaiverAction';
import { RefundsView } from './features/dash/views/orders/Refunds/RefundsView';
import { RefundDetails } from './features/dash/views/orders/Refunds/RefundDetails';
import { JokesView } from './features/dash/views/content/Jokes/JokesView';
import { JokeDetails } from './features/dash/views/content/Jokes/JokeDetails';
import { AddEditJoke } from './features/dash/views/content/Jokes/AddEditJoke';
import { HandleJokeAction } from './features/dash/views/content/Jokes/HandleJokeAction';
import { AddEditJokeCategory } from './features/dash/views/content/JokeCategories/AddEditJokeCategory';
import { JokeCategoryDetails } from './features/dash/views/content/JokeCategories/JokeCategoryDetails';
import { HandleJokeCategoryAction } from './features/dash/views/content/JokeCategories/HandleJokeCategoryAction';
import { JokeCategoriesView } from './features/dash/views/content/JokeCategories/JokeCategoriesView';
import AddChild from './features/user/AddChild';
import { AddEditFeaturedIngredient } from './features/dash/views/content/FeaturedIngredients/AddEditFeaturedIngredient';
import { FeaturedIngredientDetails } from './features/dash/views/content/FeaturedIngredients/FeaturedIngredientsDetails';
import { HandleFeaturedIngredientAction } from './features/dash/views/content/FeaturedIngredients/HandleFeaturedIngredientsAction';
import { FeaturedIngredientsView } from './features/dash/views/content/FeaturedIngredients/FeaturedIngredientsView';
import CourseSearch from './features/home/search/CourseSearch';
import { CountryFactsView } from './features/dash/views/content/CountryFacts/CountryFactsView';
import { AddEditCountryFacts } from './features/dash/views/content/CountryFacts/AddEditCountryFact';
import { HandleCountryFactAction } from './features/dash/views/content/CountryFacts/CountryFactsAction';
import { CountryFacttDetails } from './features/dash/views/content/CountryFacts/CountryFactDetails';
import Orders from './features/user/Orders';
import { FoodHistoriesView } from './features/dash/views/content/FoodHistories/FoodHistoriesView';
import { KitchenSkillsView } from './features/dash/views/content/KitchenSkills/KitchenSkillsView';
import { AddEditFoodHistories } from './features/dash/views/content/FoodHistories/AddEditFoodHistory';
import { HandleFoodHistoryAction } from './features/dash/views/content/FoodHistories/FoodHistoriesAction';
import { FoodHistoryDetails } from './features/dash/views/content/FoodHistories/FoodHistoryDetails';
import { AddEditKitchenSkills } from './features/dash/views/content/KitchenSkills/AddEditKitchenSkill';
import { KitchenSkillsDetails } from './features/dash/views/content/KitchenSkills/KitchenSkillDetails';
import { HandleKitchenSkillAction } from './features/dash/views/content/KitchenSkills/KitchenSkillsAction';
import { QuestionsView } from './features/dash/views/content/Questions/QuestionsView';
import { AddEditQuestions } from './features/dash/views/content/Questions/AddEditQuestion';
import { QuestionsDetails } from './features/dash/views/content/Questions/QuestionDetails';
import { HandleQuestionAction } from './features/dash/views/content/Questions/QuestionsAction';
import { FranchiseLayout } from './features/franchise/FranchiseLayout';
import { TrainingVideosView } from './features/dash/views/training/TrainingVideo/TrainingVideosView';
import { AddEditTrainingVideos } from './features/dash/views/training/TrainingVideo/AddEditTrainingVideo';
import { TrainingVideosDetails } from './features/dash/views/training/TrainingVideo/TrainingVideoDetails';
import { HandleTrainingVideoAction } from './features/dash/views/training/TrainingVideo/TrainingVideosAction';
import { HomeSection } from './features/home/HomeSection';
import CourseDetails from './features/home/search/details/CourseDetails';
import Checkout from './features/home/cart/Checkout';
import { RegistrationsView } from './features/dash/views/sessions/Registrations/RegistrationsView';
import { RegistrationsDetails } from './features/dash/views/sessions/Registrations/RegistrationDetails';
import { AddEditRegistrations } from './features/dash/views/sessions/Registrations/AddEditRegistration';
import { HandleRegistrationAction } from './features/dash/views/sessions/Registrations/RegistrationAction';
import { HandleEnrollmentAction } from './features/dash/views/enrollment/EnrollmentAction';
import ClassDetailsRecipes from './features/user/ClassDetailsRecipes/ClassDetailsRecipes';
import { TerritoriesView } from './features/dash/views/places/territories/TerritoriesView';
import { AddEditTerritory } from './features/dash/views/places/territories/AddEditTerritory';
import { TerritoryDetails } from './features/dash/views/places/territories/TerritoryDetails';
import Favorites from './features/user/Favorites';
import { LocationWeeklyReport } from './features/dash/views/places/locations/LocationWeeklyReport';
import { ResourceLedger } from './features/dash/views/places/common/ResourceLedger';
import { useGetTerritoryLedgerQuery } from './services/endpoints/places/territory';
import { useGetLocationLedgerQuery } from './services/endpoints/places/location';
import { useGetVenueLedgerQuery } from './services/endpoints/places/venue';
import { useGetCourseLedgerQuery } from './services/endpoints/schedule/course';
import { WhyWeAreGrapeSection } from './features/whyWeAreGrape/whyWeAreGrapeSection';
import { ContactForm } from './features/user/ContactForm';
import { StickyNotesSection } from './features/stickyNotes/StickyNotes';
import Recipes from './features/home/recipes/Recipes';
import SingleRecipe from './features/home/SingleRecipe/SingleRecipe';
import { ContentVideosView } from './features/dash/views/content/ContentVideos/ContentVideoView';
import { NationwideSection } from './features/nationwide/NationwideSection';
import { CurrentEntriesView } from './features/dash/views/finance/CurrentEntries/CurrentEntriesView';
import { PowerBiView } from './features/dash/views/finance/PowerBi/PowerBiView';
import { AvailableJobsSection } from './features/availableJobs/AvailableJobsSection';
import { InvoicerView } from './features/dash/views/finance/Invoicer/InvoicerView';
import { InvoicesClientView } from './features/dash/views/finance/Invoices/InvoiceClients';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useState } from 'react';
import { getAllowedTabsContent, getAllowedTabsFinance, getAllowedTabsOrders, getAllowedTabsPeople_DashUsers, getAllowedTabsPeople_SFCUsers, getAllowedTabsPlaces, getAllowedTabsSchedule_ScheduleNeeds, getAllowedTabsSchedule_SessionsClasses, getAllowedTabsTimesheets, getAllowedTabsTraining, getAllowedTabsWebsite } from './utils/utils';
import { SessionWaitlist } from './features/dash/views/schedule/sessions/SessionWaitlist';

const currentUser = getCurrentUser();
// Load your Stripe public key

// Used for routes that require authentication
const ProtectedRoute = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }
  return <Outlet />;
};

const contentTabs = getAllowedTabsContent(currentUser);
const placesTabs = getAllowedTabsPlaces(currentUser);
const trainingTabs = getAllowedTabsTraining(currentUser);
const websiteTabs = getAllowedTabsWebsite(currentUser);
const sessionsClassesTabs = getAllowedTabsSchedule_SessionsClasses(currentUser);
const scheduleNeedsTabs = getAllowedTabsSchedule_ScheduleNeeds(currentUser);
const dashUsersTabs = getAllowedTabsPeople_DashUsers(currentUser);
const sfcUsersTabs = getAllowedTabsPeople_SFCUsers(currentUser);
const financeTabs = getAllowedTabsFinance(currentUser);
const timesheetsTabs = getAllowedTabsTimesheets(currentUser);
const ordersTabs = getAllowedTabsOrders(currentUser);

const router = createBrowserRouter([
  // TODO: change home page component, using dash layout as of now for testing without authentication
  {
    path: "/",
    element: <SFCLayout />,
    errorElement: <Error404 />,
    children: [
      {
        path: "sessions",
        element: <CourseSearch />,
      },
      {
        path: "sessions/:id",
        element: <CourseDetails />,
        loader: () => ({ message: "CourseDetails...loading" }),
      },
      {
      path: "why-we-are-grape",
      element: <WhyWeAreGrapeSection />,
      loader: () => ({ message: "Why we are grape ...loading" }),
      },
      {
        path: "contact",
        element: <ContactForm />,
      },
      {
        path: "franchise",
        element: <FranchiseLayout />,
        loader: () => ({ message: "Franchise...loading" }),
        children: [
          {
            path: ":id",
            element: <FranchiseLayout />,
          },
        ],
      },
      {
        path: "nationwide",
        element: <NationwideSection />,
        loader: () => ({ message: "Nationwide map ...loading" }),
      },
      {
        path: "availableJobs",
        element: <AvailableJobsSection />,
        loader: () => ({ message: "Available Jobs ...loading" }),
      },
      {
        path: "sticky-notes",
        element: <StickyNotesSection />,
        loader: () => ({ message: "Sticky notes ...loading" }),
        },
        
      {
        path: "",
        element: <HomeSection />,
        loader: () => ({ message: "Home...loading" }),
      },
      {
        path: "cart",
        element: <Checkout />,
        loader: () => ({ message: "Checkout...loading" }),
      },
      {
        path: "recipes",
        element: <Recipes />,
        loader: () => ({ message: "Recipes...loading" }),
      },
      {
        path: "recipes/:id",
        element: <SingleRecipe />,
        loader: () => ({ message: "Recipes...loading" }),
      },
      {
        path: "lesson-plans/:id",
        element: <SingleRecipe isLessonPlan={true} />,
        loader: () => ({ message: "Lesson Plan...loading" }),
      },
    ],
  },
  {
    element: <ProtectedRoute isAuthenticated={isAuthenticated()} />, // Protected route that requires authentication
    errorElement: <Error404 />,
    children: [
      {
        path: "/",
        element: <SFCLayout />,
        children: [
          {
            path: "user",
            element: (
              <UserProfileLayout
                tabs={[
                  { title: "Profile", url: "/user/profile" },
                  { title: "Favorites", url: "/user/favorites" },
                  { title: "My Orders", url: "/user/orders" },
                  {
                    title: "Class Details & Recipes",
                    url: "/user/class-details-recipes",
                  },
                ]}
              />
            ),
            loader: () => ({ message: "Profile...loading" }),
            children: [
              {
                path: "profile",
                element: <Profile />,
                loader: () => ({ message: "Profile...loading" }),
              },
              {
                path: "orders",
                element: <Orders />,
                loader: () => ({ message: "Orders...loading" }),
              },
              {
                path: "class-details-recipes",
                element: <ClassDetailsRecipes />,
                loader: () => ({
                  message: "Class Details & Recipes...loading",
                }),
              },
              {
                path: "class-details-recipes/:date",
                element: <ClassDetailsRecipes />,
                loader: () => ({
                  message: "Class Details & Recipes...loading",
                }),
              },
              {
                path: "favorites",
                element: <Favorites />,
                loader: () => ({ message: "Favorites...loading" }),
              },
            ],
          },
          {
            path: "children",
            element: <UserProfileLayout tabs={[]} showTitle={false} />,
            loader: () => ({ message: "Children...loading" }),
            children: [
              {
                path: "new",
                element: <AddChild />,
                loader: () => ({ message: "Add Children...loading" }),
              },
              {
                path: ":id/edit",
                element: <AddChild editing />,
              },
            ],
          },
        ],
      },
      {
        path: "admin",
        element: <DashLayout />,
        loader: () => ({ message: "Dash...loading" }),
        children: [
          {
            path: "dashboard",
            element: <CalendarView />,
            loader: () => ({ message: "Calendar...loading" }),
          },
          {
            path: "enrollments",
            element: <TableLayout title="Enrollments" tabs={[]} />,
            children: [{ path: "", element: <EnrollmentsView /> }],
          },
          {
            path: "enrollments",
            element: <Outlet />,
            children: [
              { path: "new/:parent_courceId", element: <AddEditEnrollment /> },
              { path: "new", element: <AddEditEnrollment /> },
              { path: ":id/edit", element: <AddEditEnrollment editing /> },
              { path: ":id", element: <EnrollmentDetails /> },
              {
                path: ":id/delete",
                element: <HandleEnrollmentAction action="delete" />,
              },
            ],
          },
          {
            path: "registrations",
            element: (
              <TableLayout
                title="Registrations"
                tabs={[]}
              />
            ),
            loader: () => ({ message: "Registrations...loading" }),
            children: [
              { path: "", element: <RegistrationsView /> },
            ],
          },
          {
            path: "registrations",
            element: <Outlet />,
            loader: () => ({ message: "Registrations...loading" }),
            children: [
              { path: "new", element: <AddEditRegistrations /> },
              { path: ":id/edit", element: <AddEditRegistrations editing /> },
              { path: ":id", element: <RegistrationsDetails /> },
              {
                path: ":id/delete",
                element: <HandleRegistrationAction action={'delete'}/>,
              },
            ],
          },
          {
            path: "schedule",
            element: (
              <TableLayout
                title="Sessions & Classes"
                tabs={sessionsClassesTabs}
              />
            ),
            loader: () => ({ message: "Schedule...loading" }),
            children: [
              { path: "sessions", element: <SessionsView /> },
              { path: "classes", element: <ClassesView /> },
              { path: "waitlists", element: <WaitlistView /> },
            ],
          },
          {
            path: "schedule/sessions",
            element: <Outlet />,
            loader: () => ({ message: "Sessions...loading" }),
            children: [
              { path: "new", element: <AddEditSession /> },
              { path: ":id/edit", element: <AddEditSession editing /> },
              { path: ":id", element: <SessionDetail /> },
              { path: ":id/waitlist/:action", element: <SessionWaitlist /> },
              { path: "invoicer/:id", element: <InvoicerView /> },
              { path: ":id/ledger", 
                element: <ResourceLedger 
                  resource="sessions"
                  resourcePlural="sessions"
                  resourcePath="schedule"
                  noFilters={true}
                  ledgerQuery={useGetCourseLedgerQuery} 
                /> 
              },
            ],
          },
          {
            path: "schedule/waitlists",
            element: <Outlet />,
            loader: () => ({ message: "Waitlists...loading" }),
            children: [
              { path: ":id", element: <WaitlistDetail /> },
              { path: ":id/archive", element: <ArchiveWaitlist /> },
            ],
          },
          {
            path: "schedule-needs",
            element: (
              <TableLayout
                title="Schedule Needs"
                tabs={scheduleNeedsTabs}
              />
            ),
            loader: () => ({ message: "Schedule Needs...loading" }),
            children: [
              { path: "need-instructor", element: <NeedsView /> },
              { path: "need-substitute", element: <NeedSubstituteView /> },
              {
                path: "instructor-session-requests",
                element: <InstructorSessionRequestView />,
              },
            ],
          },
          {
            path: "dash-users",
            element: (
              <TableLayout
                title="Dash Users"
                tabs={dashUsersTabs}
              />
            ),
            loader: () => ({ message: "Dash Users...loading" }),
            children: [
              { path: "admins", element: <AdminsView /> },
              { path: "instructors", element: <InstructorsView /> },
              { path: "", element: <Navigate to="admins" replace /> },
            ],
          },
          {
            path: "dash-users/admins",
            element: <Outlet />,
            loader: () => ({ message: "Admins...loading" }),
            children: [
              { path: "new", element: <AddEditUser userType="admin" /> },
              {
                path: ":id/edit",
                element: <AddEditUser editing userType="admin" />,
              },
              { path: ":id", element: <UserDetailsView userType="admin" /> },
            ],
          },
          {
            path: "dash-users/instructors",
            element: <Outlet />,
            loader: () => ({ message: "Instructors...loading" }),
            children: [
              { path: "new", element: <AddEditUser userType="instructor" /> },
              {
                path: ":id/edit",
                element: <AddEditUser editing userType="instructor" />,
              },
              {
                path: ":id",
                element: <UserDetailsView userType="instructor" />,
              },
            ],
          },
          {
            path: "sfc-users",
            element: (
              <TableLayout
                title="SFC Users"
                tabs={sfcUsersTabs}
              />
            ),
            loader: () => ({ message: "Dash Users...loading" }),
            children: [
              { path: "parents", element: <ParentsView /> },
              { path: "children", element: <ChildrenView /> },
              { path: "", element: <Navigate to="parents" replace /> },
            ],
          },
          {
            path: "sfc-users/parents",
            element: <Outlet />,
            loader: () => ({ message: "Parents...loading" }),
            children: [
              { path: "new", element: <AddEditUser userType="customer" /> },
              {
                path: ":id/edit",
                element: <AddEditUser editing userType="customer" />,
              },
              { path: ":id", element: <UserDetailsView userType="customer" /> },
            ],
          },
          {
            path: "sfc-users/children",
            element: <Outlet />,
            loader: () => ({ message: "Children...loading" }),
            children: [
              { path: "new", element: <AddEditChild /> },
              { path: ":id/edit", element: <AddEditChild editing /> },
              { path: ":id", element: <ChildDetails /> },
            ],
          },
          {
            path: "allergies",
            element: (
              <TableLayout
                title="SFC Users"
                tabs={[
                  { title: "Parents", url: "/admin/sfc-users/parents" },
                  { title: "Children", url: "/admin/sfc-users/children" },
                  {
                    title: "Allergies",
                    url: "/admin/allergies",
                    hidden: !currentUser?.isSuper,
                  },
                  {
                    title: "Waivers",
                    url: "/admin/waivers",
                    hidden: !currentUser?.isSuper,
                  },
                ]}
              />
            ),
            loader: () => ({ message: "Allergies...loading" }),
            children: [{ path: "", index: true, element: <AllergiesView /> }],
          },
          {
            path: "allergies/",
            element: <Outlet />,
            loader: () => ({ message: "Allergies...loading" }),
            children: [
              { path: "new", element: <AddEditAllergy /> },
              { path: ":id/edit", element: <AddEditAllergy editing /> },
              { path: ":id", element: <AllergyDetails /> },
              {
                path: ":id/delete",
                element: (
                  <DeleteObjectWrapper
                    label="Allergy"
                    children={<HandleAllergyAction action="delete" />}
                  />
                ),
              },
            ],
          },
          {
            path: "waivers",
            element: (
              <TableLayout
                title="SFC Users"
                tabs={[
                  { title: "Parents", url: "/admin/sfc-users/parents" },
                  { title: "Children", url: "/admin/sfc-users/children" },
                  {
                    title: "Allergies",
                    url: "/admin/allergies",
                    hidden: !currentUser?.isSuper,
                  },
                  {
                    title: "Waivers",
                    url: "/admin/waivers",
                    hidden: !currentUser?.isSuper,
                  },
                ]}
              />
            ),
            loader: () => ({ message: "Waivers...loading" }),
            children: [{ path: "", index: true, element: <WaiversView /> }],
          },

          {
            path: "waivers/",
            element: <Outlet />,
            loader: () => ({ message: "Waivers...loading" }),
            children: [
              { path: ":id", element: <WaiverDetails /> },
              {
                path: ":id/delete",
                element: (
                  <DeleteObjectWrapper
                    label="Waiver"
                    children={<HandleWaiverAction action="delete" />}
                  />
                ),
              },
            ],
          },
          {
            path: "invoice-clients",
            element: <Outlet />,
            loader: () => ({ message: "Invoice Clients...loading" }),
            children: [
              // { path: '/', element: <InvoiceClientsView /> },
              { path: ":id/edit", element: <AddEditInvoiceClient editing /> },
              { path: ":id", element: <InvoiceClientDetails /> },
              {
                path: ":id/delete",
                element: <HandleInvoiceClientAction action="delete" />,
              },
            ],
          },
          {
            path: "content",
            element: (
              <TableLayout
                title="Content"
                tabs={contentTabs}
              />
            ),
            loader: () => ({ message: "Documents...loading" }),
            children: [
              { path: "lesson-plans", element: <LessonPlansView /> },
              { path: "recipes", element: <RecipesView /> },
              { path: "documents", element: <DocumentsView /> },
              { path: "videos", element: <ContentVideosView /> },
              { path: "themes", element: <ThemesView /> },
              { path: "jokes", element: <JokesView /> },
              { path: "joke-categories", element: <JokeCategoriesView /> },
              {
                path: "featured-ingredients",
                element: <FeaturedIngredientsView />,
              },
              { path: "country-facts", element: <CountryFactsView /> },
              { path: "food-histories", element: <FoodHistoriesView /> },
              { path: "kitchen-skills", element: <KitchenSkillsView /> },
              { path: "questions", element: <QuestionsView /> },
            ],
          },
          {
            path: "content/lesson-plans",
            element: <Outlet />,
            loader: () => ({ message: "Lesson plans...loading" }),
            children: [
              { path: "new", element: <AddEditLessonPlan /> },
              { path: ":id/edit", element: <AddEditLessonPlan editing /> },
              { path: ":id", element: <LessonPlanDetails /> },
              {
                path: ":id/delete",
                element: <HandleLessonPlanAction action="delete" />,
              },
            ],
          },
          {
            path: "content/documents",
            element: <Outlet />,
            loader: () => ({ message: "Documents...loading" }),
            children: [
              { path: "new", element: <AddEditDocument /> },
              { path: ":id/edit", element: <AddEditDocument editing /> },
              { path: ":id", element: <DocumentDetails /> },
              {
                path: ":id/delete",
                element: <HandleDocumentAction action="delete" />,
              },
            ],
          },
          {
            path: "content/themes",
            element: <Outlet />,
            loader: () => ({ message: "Themes...loading" }),
            children: [
              { path: "new", element: <AddEditTheme /> },
              { path: ":id/edit", element: <AddEditTheme editing /> },
              { path: ":id", element: <ThemeDetails /> },
              {
                path: ":id/delete",
                element: <HandleThemeAction action={"delete"} />,
              },
            ],
          },
          {
            path: "content/recipes",
            element: <Outlet />,
            loader: () => ({ message: "Expenses...loading" }),
            children: [
              { path: "new", element: <AddEditRecipe /> },
              { path: ":id/edit", element: <AddEditRecipe editing={true} /> },
              { path: ":id", element: <RecipeDetails /> },
              {
                path: ":id/delete",
                element: <HandleRecipeAction />,
              },
            ],
          },
          {
            path: "content/jokes",
            element: <Outlet />,
            loader: () => ({ message: "Jokes...loading" }),
            children: [
              { path: "new", element: <AddEditJoke /> },
              { path: ":id/edit", element: <AddEditJoke editing /> },
              { path: ":id", element: <JokeDetails /> },
              {
                path: ":id/delete",
                element: <HandleJokeAction />,
              },
            ],
          },
          {
            path: "content/joke-categories",
            element: <Outlet />,
            loader: () => ({ message: "Joke Categories...loading" }),
            children: [
              { path: "new", element: <AddEditJokeCategory /> },
              { path: ":id/edit", element: <AddEditJokeCategory editing /> },
              { path: ":id", element: <JokeCategoryDetails /> },
              {
                path: ":id/delete",
                element: <HandleJokeCategoryAction />,
              },
            ],
          },
          {
            path: "content/featured-ingredients",
            element: <Outlet />,
            loader: () => ({ message: "Featured ingredients...loading" }),
            children: [
              { path: "new", element: <AddEditFeaturedIngredient /> },
              {
                path: ":id/edit",
                element: <AddEditFeaturedIngredient editing />,
              },
              { path: ":id", element: <FeaturedIngredientDetails /> },
              {
                path: ":id/delete",
                element: <HandleFeaturedIngredientAction action={"delete"} />,
              },
            ],
          },
          {
            path: "content/country-facts",
            element: <Outlet />,
            loader: () => ({ message: "Country Facts...loading" }),
            children: [
              { path: "new", element: <AddEditCountryFacts /> },
              { path: ":id/edit", element: <AddEditCountryFacts editing /> },
              { path: ":id", element: <CountryFacttDetails /> },
              {
                path: ":id/delete",
                element: <HandleCountryFactAction action={"delete"} />,
              },
            ],
          },
          {
            path: "content/food-histories",
            element: <Outlet />,
            loader: () => ({ message: "Food histories...loading" }),
            children: [
              { path: "new", element: <AddEditFoodHistories /> },
              { path: ":id/edit", element: <AddEditFoodHistories editing /> },
              { path: ":id", element: <FoodHistoryDetails /> },
              {
                path: ":id/delete",
                element: <HandleFoodHistoryAction action={"delete"} />,
              },
            ],
          },
          {
            path: "content/kitchen-skills",
            element: <Outlet />,
            loader: () => ({ message: "Kitchen Skills...loading" }),
            children: [
              { path: "new", element: <AddEditKitchenSkills /> },
              { path: ":id/edit", element: <AddEditKitchenSkills editing /> },
              { path: ":id", element: <KitchenSkillsDetails /> },
              {
                path: ":id/delete",
                element: <HandleKitchenSkillAction action={"delete"} />,
              },
            ],
          },
          {
            path: "content/questions",
            element: <Outlet />,
            loader: () => ({ message: "Questions...loading" }),
            children: [
              { path: "new", element: <AddEditQuestions /> },
              { path: ":id/edit", element: <AddEditQuestions editing /> },
              { path: ":id", element: <QuestionsDetails /> },
              {
                path: ":id/delete",
                element: <HandleQuestionAction action={"delete"} />,
              },
            ],
          },
          {
            path: "finance",
            element: (
              <TableLayout
                title="Finance"
                tabs={financeTabs}
              />
            ),
            loader: () => ({ message: "Finance...loading" }),
            children: [
              { path: "invoices", element: <InvoicesView /> },
              { path: "expenses", element: <ExpensesView /> },
              { path: "receipts", element: <ReceiptsView /> },
              { path: "payments", element: <PaymentsView /> },
              { path: "powerBi", element: <PowerBiView /> },
              { path: "current-entries", element: <CurrentEntriesView /> },
            ],
          },
          {
            path: "finance/invoices",
            element: <Outlet />,
            loader: () => ({ message: "Invoices...loading" }),
            children: [
              { path: ":id/edit", element: <AddEditInvoice editing /> },
              { path: ":id", element: <InvoiceDetails /> },
            ],
          },
          {
            path: "finance/invoice_client",
            element: <Outlet />,
            loader: () => ({ message: "Invoices...loading" }),
            children: [
              // { path: ":id/edit", element: <AddEditInvoice editing /> },
              { path: ":id", element: <InvoicesClientView /> },
              { path: ":id/:access_code", element: <InvoicesClientView /> },
            ],
          },
          {
            path: "finance/invoice-items",
            element: <Outlet />,
            loader: () => ({ message: "Invoice Items...loading" }),
            children: [
              { path: "new", element: <AddEditInvoiceItem /> },
              { path: ":id/edit", element: <AddEditInvoiceItem editing /> },
              { path: ":id/delete", element: <DeleteInvoiceItem /> },
              { path: ":id", element: <InvoiceItemDetails /> },
            ],
          },
          {
            path: "finance/expenses",
            element: <Outlet />,
            loader: () => ({ message: "Expenses...loading" }),
            children: [
              { path: "new", element: <AddEditExpense /> },
              { path: ":id/edit", element: <AddEditExpense editing /> },
              { path: ":id", element: <ExpenseDetails /> },
              {
                path: ":id/delete",
                element: <HandleExpenseAction action="delete" />,
              },
              {
                path: ":id/archive",
                element: <HandleExpenseAction action="archive" />,
              },
              {
                path: ":id/unarchive",
                element: <HandleExpenseAction action="unarchive" />,
              },
            ],
          },
          {
            path: "finance/receipts",
            element: <Outlet />,
            loader: () => ({ message: "Receipts...loading" }),
            children: [
              { path: "new", element: <AddEditReceipt /> },
              { path: ":id/edit", element: <AddEditReceipt editing /> },
              { path: ":id", element: <ReceiptDetails /> },
              {
                path: ":id/delete",
                element: <HandleReceiptAction action="delete" />,
              },
            ],
          },
          {
            path: "finance/payments",
            element: <Outlet />,
            loader: () => ({ message: "Payments...loading" }),
            children: [
              { path: 'new', element: <AddEditPayment newPayment/> },
              { path: 'new/:invoice_id', element: <AddEditPayment /> },
              { path: ":id/edit", element: <AddEditPayment editing /> },
              { path: ":id", element: <PaymentDetails /> },
            ],
          },
          {
            path: "finance/powerBi",
            element: <Outlet />,
            loader: () => ({ message: "PowerBi...loading" }),
           
          },
          {
            path: "timesheets",
            element: (
              <TableLayout
                title="Timesheets"
                tabs={timesheetsTabs}
              />
            ),
            loader: () => ({ message: "Timesheets...loading" }),
            children: [
              { path: "master-timesheet", element: <MasterTimesheetView /> },
              { path: "current-timesheet", element: <CurrentTimesheetView /> },
              { path: "past-timesheets", element: <PastTimesheetsView /> },
              { path: "timesheet-overrides", element: <OverridesView /> },
              {
                path: "timesheet-entries",
                element: <Outlet />,
                children: [
                  { path: ":timesheetId", element: <TimesheetView /> },
                ],
              },
            ],
          },
          {
            path: "orders",
            element: (
              <TableLayout
                title="Orders"
                tabs={ordersTabs}
              />
            ),
            loader: () => ({ message: "Orders...loading" }),
            children: [
              { path: "orders", element: <OrdersView /> },
              { path: "refunds", element: <RefundsView /> },
              { path: "scholarships", element: <ScholarshipsView /> },
              { path: "discounts", element: <DiscountsView /> },
              { path: "promotional-codes", element: <PromotionalCodesView /> },
            ],
          },
          {
            path: "orders/orders",
            element: <Outlet />,
            loader: () => ({ message: "Orders...loading" }),
            children: [
              { path: "new", element: <AddEditScholarship /> },
              { path: ":id/edit", element: <AddEditScholarship editing /> },
              { path: ":id", element: <OrderDetails /> },
            ],
          },
          {
            path: "orders/refunds",
            element: <Outlet />,
            loader: () => ({ message: "Refund...loading" }),
            children: [{ path: ":id", element: <RefundDetails /> }],
          },
          {
            path: "orders/scholarships",
            element: <Outlet />,
            loader: () => ({ message: "Scholarships...loading" }),
            children: [
              { path: "new", element: <AddEditScholarship /> },
              { path: ":id/edit", element: <AddEditScholarship editing /> },
              { path: ":id", element: <ScholarshipDetails /> },
            ],
          },
          {
            path: "orders/discounts",
            element: <Outlet />,
            loader: () => ({ message: "Discounts...loading" }),
            children: [
              { path: "new", element: <AddEditDiscount /> },
              { path: ":id/edit", element: <AddEditDiscount editing /> },
              { path: ":id", element: <DiscountDetails /> },
            ],
          },
          {
            path: "orders/promotional-codes",
            element: <Outlet />,
            loader: () => ({ message: "Promotional Codes...loading" }),
            children: [
              { path: "new", element: <AddEditPromotionalCode /> },
              { path: ":id/edit", element: <AddEditPromotionalCode editing /> },
              { path: ":id", element: <PromotionalCodeDetails /> },
            ],
          },
          {
            path: "places",
            element: (
              <TableLayout
                title="Places"
                tabs={placesTabs}
              />
            ),
            loader: () => ({ message: "Places...loading" }),
            children: [
              { path: "venues", element: <VenuesView /> },
              { path: "locations", element: <LocationsView /> },
              { path: "territories", element: <TerritoriesView /> },
              { path: "organizations", element: <OrganizationsView /> },
              { path: "zip-codes", element: <ZipCodesView /> },
            ],
          },
          {
            path: "places/venues",
            element: <Outlet />,
            loader: () => ({ message: "Venues...loading" }),
            children: [
              { path: "new", element: <AddEditVenue /> },
              { path: ":id/edit", element: <AddEditVenue editing /> },
              { path: ":id", element: <VenueDetails /> },
              { path: ":id/ledger", 
                element: <ResourceLedger 
                  resource="venue" 
                  resourcePlural="venues"
                  noFilters={true}
                  ledgerQuery={useGetVenueLedgerQuery} 
                /> 
              },
            ],
          },
          {
            path: "places/locations",
            element: <Outlet />,
            loader: () => ({ message: "Locations...loading" }),
            children: [
              { path: "new", element: <AddEditLocation /> },
              { path: ":id/edit", element: <AddEditLocation editing /> },
              { path: ":id/ledger", 
                element: <ResourceLedger 
                  resource="location" 
                  resourcePlural="locations" 
                  ledgerQuery={useGetLocationLedgerQuery}
                  showClaimedStats={true}
                /> 
              },
              { path: ":id/weekly-report", element: <LocationWeeklyReport /> },
              { path: ":id", element: <LocationDetails /> },
            ],
          },

          {
            path: "places/territories",
            element: <Outlet />,
            loader: () => ({ message: "Territories...loading" }),
            children: [
              ...(currentUser?.isSuper ? [
                { path: "new", element: <AddEditTerritory /> },
                { path: ":id/edit", element: <AddEditTerritory editing /> },
              ] : ([])),
              { path: ":id/ledger", 
                element: <ResourceLedger 
                  resource="territory" 
                  resourcePlural="territories" 
                  ledgerQuery={useGetTerritoryLedgerQuery} 
                /> 
              },
              // { path: ":id/weekly-report", element: <TerritoryWeeklyReport /> },
              { path: ":id", element: <TerritoryDetails /> },
            ],
          },
          {
            path: "places/organizations",
            element: <Outlet />,
            loader: () => ({ message: "Organizations...loading" }),
            children: [
              { path: "new", element: <AddEditOrganization /> },
              { path: ":id/edit", element: <AddEditOrganization editing /> },
              { path: ":id", element: <OrganizationDetails /> },
            ],
          },
          {
            path: "training",
            element: (
              <TableLayout
                title="Training"
                tabs={trainingTabs}
              />
            ),
            loader: () => ({ message: "Documents...loading" }),
            children: [
              // { path: "Guides", element: <GuidesView /> },
              // { path: "Steps", element: <StepsView /> },
              { path: "Videos", element: <TrainingVideosView /> },
            ],
          },
          // {
          //   path: "training/guides",
          //   element: <Outlet />,
          //   loader: () => ({ message: "Guides...loading" }),
          //   children: [
          //     { path: "new", element: <AddEditGuides /> },
          //     { path: ":id/edit", element: <AddEditGuides editing /> },
          //     { path: ":id", element: <GuidesDetails /> },
          //     {
          //       path: ":id/delete",
          //       element: <HandleGuideAction action={"delete"} />,
          //     },
          //   ],
          // },
          // {
          //   path: "training/steps",
          //   element: <Outlet />,
          //   loader: () => ({ message: "Steps...loading" }),
          //   children: [
          //     { path: "new", element: <AddEditSteps /> },
          //     { path: ":id/edit", element: <AddEditSteps editing /> },
          //     { path: ":id", element: <StepsDetails /> },
          //     {
          //       path: ":id/delete",
          //       element: <HandleTrainingVideoAction action={"delete"} />,
          //     },
          //   ],
          // },
          {
            path: "training/videos",
            element: <Outlet />,
            loader: () => ({ message: "Videos...loading" }),
            children: [
              { path: "new", element: <AddEditTrainingVideos /> },
              { path: ":id/edit", element: <AddEditTrainingVideos editing /> },
              { path: ":id", element: <TrainingVideosDetails /> },
              {
                path: ":id/delete",
                element: <HandleTrainingVideoAction action={"delete"} />,
              },
            ],
          },
          {
            path: "website",
            element: (
              <TableLayout
                title="Website"
                tabs={websiteTabs}
              />
            ),
            loader: () => ({ message: "Places...loading" }),
            children: [
              { path: "news-items", element: <NewsItemsView /> },
              { path: "pages", element: <PagesView /> },
              { path: "snippets", element: <SnippetsView /> },
              { path: "team-profiles", element: <TeamProfilesView /> },
              { path: "feedbacks", element: <FeedbacksView /> },
              // { path: 'franchisee-leads', element: <FranchiseeLeadsView />, },
              { path: "galleries", element: <GalleriesView /> },
            ],
          },
          {
            path: "website/news-items",
            element: <Outlet />,
            loader: () => ({ message: "News Items...loading" }),
            children: [
              { path: "new", element: <AddEditNewsItem /> },
              { path: ":id/edit", element: <AddEditNewsItem editing /> },
              { path: ":id", element: <NewsItemDetails /> },
              {
                path: ":id/delete",
                element: (
                  <DeleteObjectWrapper
                    label="News Item"
                    children={<HandleNewsItemAction action="delete" />}
                  />
                ),
              },
            ],
          },
          {
            path: "website/pages",
            element: <Outlet />,
            loader: () => ({ message: "Pages...loading" }),
            children: [
              { path: "new", element: <AddEditPage /> },
              { path: ":id/edit", element: <AddEditPage editing /> },
              { path: ":id", element: <PageDetails /> },
              {
                path: ":id/delete",
                element: (
                  <DeleteObjectWrapper
                    label="Page"
                    children={<HandlePageAction action="delete" />}
                  />
                ),
              },
            ],
          },
          {
            path: "website/snippets",
            element: <Outlet />,
            loader: () => ({ message: "Snippets...loading" }),
            children: [
              { path: "new", element: <AddEditSnippet /> },
              { path: ":id/edit", element: <AddEditSnippet editing /> },
              { path: ":id", element: <SnippetDetails /> },
              {
                path: ":id/delete",
                element: (
                  <DeleteObjectWrapper
                    label="Snippet"
                    children={<HandleSnippetAction action="delete" />}
                  />
                ),
              },
            ],
          },
          {
            path: "website/team-profiles",
            element: <Outlet />,
            loader: () => ({ message: "Team Profiles...loading" }),
            children: [
              { path: "new", element: <AddEditTeamProfile /> },
              { path: ":id/edit", element: <AddEditTeamProfile editing /> },
              { path: ":id", element: <TeamProfileDetails /> },
              {
                path: ":id/delete",
                element: (
                  <DeleteObjectWrapper
                    label="Team Profile"
                    children={<HandleTeamProfileAction action="delete" />}
                  />
                ),
              },
            ],
          },
          {
            path: "website/feedbacks",
            element: <Outlet />,
            loader: () => ({ message: "Feedbacks...loading" }),
            children: [
              { path: "new", element: <AddEditFeedback /> },
              { path: ":id/edit", element: <AddEditFeedback editing /> },
              { path: ":id", element: <FeedbackDetails /> },
              {
                path: ":id/delete",
                element: (
                  <DeleteObjectWrapper
                    label="Feedback"
                    children={<HandleFeedbackAction action="delete" />}
                  />
                ),
              },
            ],
          },
          // {
          //   path: "website/franchisee-leads",
          //   element: <Outlet />,
          //   loader: () => ({message: "Franchisee Leads...loading"}),
          //   children: [
          //     { path: 'new', element: <AddEditFranchiseeLead /> },
          //     { path: ':id/edit', element: <AddEditFranchiseeLead editing /> },
          //     { path: ':id', element: <FranchiseeLeadDetails /> },
          //     { path: ':id/delete', element: <DeleteObjectWrapper label="Franchisee Lead" children={<HandleFranchiseeLeadAction action="delete" /> } /> },
          //   ]
          // },
          {
            path: "website/galleries",
            element: <Outlet />,
            loader: () => ({ message: "Galleries...loading" }),
            children: [
              { path: "new", element: <AddEditGallery /> },
              { path: ":id/edit", element: <AddEditGallery editing /> },
              { path: ":id", element: <GalleryDetails /> },
              {
                path: ":id/delete",
                element: (
                  <DeleteObjectWrapper
                    label="Gallery"
                    children={<HandleGalleryAction action="delete" />}
                  />
                ),
              },
            ],
          },
          {
            path: "",
            element: <Navigate to="dashboard" replace />,
          },
        ],
      },
      {
        path: "admins",
        element: (
          <>
            <br />
            <br />
            <Outlet />
          </>
        ),
        loader: () => ({ message: "Admins...loading" }),
        children: [
          {
            // TODO: combine admins and instructors ( along with other Dash users)
            path: "instructors",
            element: (
              <>
                <br />
                <br />
                <Outlet />
              </>
            ),
            children: [
              {
                path: ":id",
                element: <></>,
                loader: () => ({ message: "Admins ...loading" }),
              },
              {
                path: ":id/edit",
                element: <></>,
                loader: () => ({ message: "Admins...loading" }),
              },
            ],
          },
          {
            path: "profile",
            element: <Profile />,
            loader: () => ({ message: "Profile...loading" }),
          },
        ],
      },
    ],
  },
  {
    path: "users",
    element: <UserLayout />,
    loader: () => ({ message: "Welcome...loading" }),
    children: [
      {
        path: "",
        element: <Welcome />,
      },
      {
        path: "login",
        element: <Login />,
        loader: () => ({ message: "Login...loading" }),
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
        loader: () => ({ message: "Forgot password...loading" }),
      },
      {
        path: "reset-password/:token",
        element: <ResetPassword />,
        loader: () => ({ message: "Edit password...loading" }),
      },
      {
        path: "register",
        element: <Register />,
        loader: () => ({ message: "Register...loading" }),
      },
    ],
  },
]);

const App = () => (
  <div className="App">
    <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
  </div>
);

export default App;
