import React from 'react';
import { Stack } from 'react-bootstrap';
import { FaCheck } from "react-icons/fa6";
import './Stepper.css';
interface StepperProps {
  steps: { name: string, form: any }[];
  currentStep: number;
  editing: boolean;
  onStepClick: (step: number) => void;
}

const Stepper: React.FC<StepperProps> = ({ steps, currentStep, editing, onStepClick }) => {
  return (
    <>
     <div className='d-none d-lg-block d-inline-flex'>
    <Stack direction="horizontal" className="stepper overflow-auto" gap={4}>
      {steps.map((step, index) => (
          <div key={index} className="text-center" style={{ position: 'relative' }}>
            {index > 0 && <div className={`left-line ${index <= currentStep ? 'completed' : ''}`}></div>}
            <div
              className={`circle ${currentStep === index ? 'active' : ''} ${index < currentStep ? 'completed' : ''}`}
              onClick={() => {
                if (index < currentStep || editing) onStepClick(index)
              }}
              style={{ cursor: 'pointer' }}
            >
              {index < currentStep ? (
                <FaCheck />
              ) : index + 1}
            </div>
            <div className={`label ${currentStep === index ? 'active' : ''} text-uppercase`}>{step.name}</div>
            {index < steps.length - 1 && <div className={`right-line ${index < currentStep ? 'completed' : ''}`}></div>}
          </div>
      ))}
    </Stack>
    </div>
    <div className='d-lg-none '>
    <Stack direction="horizontal" className="stepper overflow-auto" gap={5}>
      {steps.map((step, index) => (
          <div key={index} className="text-center" style={{ position: 'relative' }}>
            {index > 0 && <div className={`left-line ${index <= currentStep ? 'completed' : ''}`}></div>}
            <div
              className={`circle ${currentStep === index ? 'active' : ''} ${index < currentStep ? 'completed' : ''}`}
              onClick={() => {
                if (index < currentStep || editing) onStepClick(index)
              }}
              style={{ cursor: 'pointer' }}
            >
              {index < currentStep ? (
                <FaCheck />
              ) : index + 1}
            </div>
            <div className={`label ${currentStep === index ? 'active' : ''} text-uppercase font-mobile`} >{step.name}</div>
            {index < steps.length - 1 && <div className={`right-line ${index < currentStep ? 'completed' : ''}`}></div>}
          </div>
      ))}
    </Stack>
    </div>
    </>

   
  );
};

export default Stepper;