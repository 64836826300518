import { ColumnDef } from '@tanstack/react-table';
import { Waitlist } from '../../../types/waitlist.type';
import { LinkCell } from '../../../components/tables/cellComponents/LinkCell';
import { waitlistStatusColors } from '..';
import { Stack } from 'react-bootstrap';
import { formatDate } from '../../dateUtils';
import { Badge } from '../../../components/badge/Badge';
import { userCanEditCreateDeleteView_Waitlist } from '../../../features/dash/views/schedule/waitlist/WaitlistView';
import { getCurrentUser } from '../../../services/helper';

const currentUser = getCurrentUser();
export const waitlistColumns: ColumnDef<Waitlist>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
  },
  {
    header: 'Session',
    accessorKey: 'course.title',
    cell: ({ row }) => (
      <LinkCell content={row.original.course.title} url={`/admin/schedule/sessions/${row.original.course.id}`} />
    ),
  },
  {
    header: 'Child',
    accessorKey: 'child.full_name',
    cell: ({ row }) => (
      <span className="text-info fw-bolder text-truncate">
        {row.original.child.first_name} {row.original.child.last_name}
      </span>
    ),
  },
  {
    header: 'Status',
    accessorKey: 'status',
    cell: ({ getValue }) => (
      <Badge title={getValue<string>()} color={waitlistStatusColors[getValue<string>()]} />
    ),
  },
  {
    header: 'Position',
    accessorKey: 'position',
  },
  {
    header: 'Total',
    accessorKey: 'total',
  },
  {
    header: 'Created At',
    accessorKey: 'created_at',
    cell: ({ getValue }) => formatDate(new Date(getValue<string>()), 'yyyy/MM/dd hh:mm z'),
  },
  {
    header: 'Actions',
    id: 'actions',
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell content="View" url={`/admin/schedule/waitlists/${row.original.id}`} underline />
        {row.original.status !== 'archived' && userCanEditCreateDeleteView_Waitlist(currentUser) && <LinkCell content="Archive" url={`/admin/schedule/waitlists/${row.original.id}/archive`} underline />}
      </Stack>
    ),
  },
];