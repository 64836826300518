import { ColumnDef } from '@tanstack/react-table';
import { LinkCell } from '../../../components/tables/cellComponents/LinkCell';
import { Stack } from 'react-bootstrap';
import { Allergy } from '../../../services/endpoints/people/user';
import { getCurrentUser } from '../../../services/helper';
import { userCanEditCreateDelete_Allergies } from '../../../features/dash/views/people/Allergy/AllergiesView';

const currentUser = getCurrentUser();
export const allergyColumns: ColumnDef<Allergy>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
    cell: ({ getValue }) => <LinkCell content={getValue<number>()} url={`/admin/allergies/${getValue<number>()}`} />
  },
  {
    header: 'Child',
    accessorKey: 'child.full_name',
    cell: ({ row }) => <LinkCell content={row.original.child?.full_name} url={`/admin/sfc-users/children/${row.original.child?.id}`} />
  },
  {
    header: 'Allergy',
    accessorKey: 'allergy',
  },
  {
    header: 'Details',
    accessorKey: 'details',
  },
  {
    header: () => null,
    id: 'actions',
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell content="View" url={`/admin/allergies/${row.original.id}`} underline />
        {userCanEditCreateDelete_Allergies(currentUser) && (
          <>
            <LinkCell content="Edit" url={`/admin/allergies/${row.original.id}/edit`} underline />
            <LinkCell content="Delete" url={`/admin/allergies/${row.original.id}/delete`} underline />
          </>
        )}
      </Stack>
    )
  },
];
