import { Col, Form, FormControl, Row } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from '../AddChild';
import { genderOptions, gradeLevelOptions } from '../../../utils/consts';

export const BasicInfoForm = ({ touched, errors }: StepProps) => {
  return (
    <>
      <Row>
        <Col xs={6} className="pe-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="first_name">
            <Form.Label>First Name</Form.Label>
            <Field
              name="first_name"
              type="text"
              as={Form.Control}
              isInvalid={touched.first_name && !!errors.first_name}
            />
            <ErrorMessage name="first_name">
              {(msg) => (
                <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>
        <Col xs={6} className="ps-1">
          <Form.Group className="mb-1 mb-lg-3" controlId="last_name">
            <Form.Label>Last Name</Form.Label>
            <Field
              name="last_name"
              type="text"
              as={Form.Control}
              isInvalid={touched.last_name && !!errors.last_name}
            />
            <ErrorMessage name="last_name">
              {(msg) => (
                <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Col>
      </Row>

      <Form.Group className="mb-1 mb-lg-3" controlId="dob">
        <Form.Label>Date of Birth</Form.Label>
        <Field name="dob" type="date" as={Form.Control} isInvalid={touched.dob && !!errors.dob} />
        <ErrorMessage name="dob">
          {(msg) => (
            <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
          )}
        </ErrorMessage>
      </Form.Group>
      
      <Form.Group className="mb-1 mb-lg-3" controlId="grade_level">
        <Form.Label>Grade Level</Form.Label>
        <Field
          name="grade_level"
          as={Form.Select}
          options={['2', '3']}
          placeholder="Select"
          isInvalid={touched.grade_level && !!errors.grade_level}
        >
          <option>Select</option>
          {gradeLevelOptions.map((opt) => (
            <option value={opt} key={opt}>{opt}</option>
          ))}
        </Field>
        <ErrorMessage name="grade_level">
          {(msg) => (
            <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
          )}
        </ErrorMessage>
      </Form.Group>

      <Form.Group className="mb-1 mb-lg-3" controlId="gender">
        <Form.Label>Gender</Form.Label>
        <Field
          name="gender"
          as={Form.Select}
          options={['2', '3']}
          placeholder="Select"
          isInvalid={touched.gender && !!errors.gender}
        >
          <option>Select</option>
          {genderOptions.map((opt) => (
            <option value={opt.value} key={opt.value}>{opt.name}</option>
          ))}
        </Field>
        <ErrorMessage name="gender">
          {(msg) => (
            <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
          )}
        </ErrorMessage>
      </Form.Group>

      <Form.Group className="mb-1 mb-lg-3" controlId="origination">
        <Form.Label>Teacher / Classroom (Optional)</Form.Label>
        <Field
          name="origination"
          as={Form.Control}
          isInvalid={touched.origination && !!errors.origination}
        />
        <ErrorMessage name="origination">
          {(msg) => (
            <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
          )}
        </ErrorMessage>
      </Form.Group>
    </>
  )
}
