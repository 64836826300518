import { Col, Form, FormControl, Row, Spinner, Stack } from 'react-bootstrap';
import { ErrorMessage, Field } from 'formik';
import { StepProps } from '../AddEditSession';
import { useToast } from '../../../../../../context/ToastContext';
import { useGetVenuesQuery } from '../../../../../../services/endpoints/places/venue';
import { getErrorMessage } from '../../../../../../utils/utils';
import { Venue } from '../../../../../../types/venue.type';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useState } from 'react';
import { FormSelect } from '../../../../../../components/tables/filterForms/FormSelect';

export const VenueForm = ({ touched, errors, readOnly }: StepProps) => {
  const { data, error, isLoading } = useGetVenuesQuery();
    const [idVenue, setIdVenue] = useState<number>();
  
  const toast = useToast();

  if (isLoading) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  }

  if (error || !data || !data.results) {
    toast.addToast(getErrorMessage(error) ?? 'Error loading venues', 'error');
  }

  return (
    <Row>
      <Col xs={12} lg={6}>
        <Stack gap={3}>
          <h6
            className="fw-bold mb-0"
            style={{ fontSize: '1.125rem', color: '#5E6469' }}
          >
            Venue Details
          </h6>
          <Form.Group controlId="venue_id">
            <Form.Label></Form.Label>


            <Field 
                            as={FormSelect}
                            name="venue_id"
                            label="Venue"
                            options={data?.results.map((venue: Venue) => ({ value: venue.id, label: venue.title })) ?? []}
                            loadingError={error ? "Error loading venues" : undefined}

                            // options={locationOptions}
                          />





            {/* <Field
              name="venue_id"
              as={Form.Select}
              isInvalid={touched.venue_id && !!errors.venue_id}
              disabled={readOnly}
            >
              <option>Select</option>
              {data?.results.map((venue: Venue) => (
                <option key={venue.id} value={venue.id}>{venue.title}</option>
              ))}
            </Field> */}
            <Form.Text>Select a pre-existing venue to auto-fill address</Form.Text>
            <ErrorMessage name="venue_id">
              {(msg) => (
                <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group controlId="room">
            <Form.Label>Room</Form.Label>
            <Field
              name="room"
              type="text"
              as={Form.Control}
              isInvalid={touched.room && !!errors.room}
              disabled={readOnly}
            />
            <ErrorMessage name="room">
              {(msg) => (
                <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>

          <Form.Group controlId="capacity">
            <Form.Label>Capacity</Form.Label>
            <Field
              name="capacity"
              type="number"
              min="0"
              as={Form.Control}
              style={{ width: 85 }}
              isInvalid={touched.capacity && !!errors.capacity}
              disabled={readOnly}
            />
            <ErrorMessage name="capacity">
              {(msg) => (
                <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        </Stack>
      </Col>
    </Row>
  )
}
