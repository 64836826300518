import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { BackButton } from '../../../../../components/buttons/BackButton';
import Stepper from '../../../../../components/stepper/Stepper';
import { Formik, Form as FormikForm, FormikErrors, FormikTouched, FormikProps } from 'formik';
import { FadeIn } from '../../../../animations/FadeIn';
import * as Yup from "yup";
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { getErrorMessage } from '../../../../../utils/utils';
import { useToast } from '../../../../../context/ToastContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GalleryForm } from './GalleryForm';
import { useCreateGalleryMutation, useGetGalleryQuery, useUpdateGalleryMutation } from '../../../../../services/endpoints/website/gallery';
import { Gallery, GalleryImage } from '../../../../../types/website.types';
import { getCurrentUser } from '../../../../../services/helper';
import { userCanEditCreateDelete_Galleries } from './GalleriesView';

const steps = [
  { name: 'Details', form: GalleryForm },
];

export interface FormValues {
  id?: number;
  name: string;
  pictures?: GalleryImage[];
}

export interface StepProps {
  errors: Partial<FormikErrors<FormValues>>;
  touched: Partial<FormikTouched<FormValues>>;
  values?: FormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
}

interface AddEditGalleryProps {
  editing?: boolean;
}

export const AddEditGallery: React.FC<AddEditGalleryProps> = ({ editing }) => {
  const params = useParams();
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(0);
  const { addToast } = useToast();
  const navigate = useNavigate();
  const currentUser = getCurrentUser();

  const [createGallery] = useCreateGalleryMutation();
  const [updateGallery] = useUpdateGalleryMutation();
  const { data, error, isLoading }  = useGetGalleryQuery(Number(params.id!), { skip: !editing || !params.id });

  const [initialValues, setInitialValues] = useState<FormValues>({
    name: '',
  });

  const validationSchemas = [
    Yup.object({
      name: Yup.string().required('Name is required'),
    }),
  ];

  useEffect(() => {
    if (editing && error && !isLoading) {
      addToast('Error while loading Gallery', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editing, error, isLoading]);

  useEffect(() => {
    if (data && data.gallery) {
      const { gallery } = data;
      setInitialValues({
        id: gallery.id,
        name: gallery.name,
        pictures: (gallery.pictures?.map(p => ({...p, image: new File([], "empty.png")}))) ?? [],
      });
    }

    if(!userCanEditCreateDelete_Galleries(currentUser)) {
      addToast('You have no permissions to edit this data', 'error');
      navigate(`/admin/website/galleries`);
    }
  }, [data]);


  const handleSubmit = async (formValues: FormValues) => {
    try {
      const galleryData: Gallery = {
        ...formValues,
      };
      
      if (editing) {
        const res = await updateGallery(galleryData).unwrap();
        if (res && res.gallery) {
          addToast(`Gallery updated succesfully`, 'success');
          navigate(`/admin/website/galleries/${data?.gallery.id}`);
        }
      } else {
        const res = await createGallery(galleryData).unwrap();
        if (res && res.gallery) {
          navigate(`/admin/website/galleries/${res.gallery.id}`);
          addToast('Gallery created succesfully', 'success');
        } else throw new Error('A problem happened while creating Gallery');
      }
    } catch (e) {
      console.error(e);
      addToast(getErrorMessage(e), 'error');
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<FormValues>>, 
    setTouched: (touched: FormikTouched<FormValues>, shouldValidate?: boolean) => void,
    values: FormValues
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        name: true,
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text={`Back to Gallery${editing ? ' details' : 's'}`}
            color="#2B4E64"
            url={editing ? `/admin/website/galleries/${data?.gallery.id}` : '/admin/website/galleries'}
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: '2.25rem' }}
          >
            {editing ? 'Edit' : 'New'} Gallery
          </h2>
          {steps.length > 1 && <Stepper steps={steps} currentStep={currentStep} editing={editing === true} onStepClick={(step) => setCurrentStep(step)} />}
        </div>

        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow: '0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)',
            border: '1px solid #EBEBEB',
            borderRadius: 10,
          }}
        >
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas[currentStep]}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ submitForm, isSubmitting, validateForm, touched, errors, setTouched, values, setFieldValue,  }: FormikProps<FormValues>) => (
                <FormikForm className="text-start">
                  <div style={{ minHeight: '65vh' }}>
                    {currentStep < steps.length
                      && React.createElement(steps[currentStep]?.form, {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                      })
                    }
                  </div>

                  <Row className="justify-content-end mt-3">
                    <Col xs={6} lg={3}>
                      {currentStep !== 0 && (
                        <SimpleButton
                          disabled={isSubmitting}
                          type="button"
                          onClick={() => setCurrentStep(currentStep - 1)}
                          className="w-100"
                          variant="outline-primary"
                        >
                          Back
                        </SimpleButton>
                      )}
                    </Col>

                    <Col xs={6} lg={3}>
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={currentStep === steps.length - 1 ? submitForm : () => handleNext(validateForm, setTouched, values) }
                        className="w-100"
                        variant="primary"
                      >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                      </SimpleButton>
                    </Col>                   
                  </Row>
                </FormikForm>
              )}
            </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
