/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react';
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
  getPaginationRowModel,
  SortingState,
  Column,
  Row as RTRow,
  RowSelectionState,
  ColumnSort,
} from '@tanstack/react-table';
import {  Button, Col, Dropdown, DropdownButton, Form, InputGroup, Row, Spinner, Stack } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import './SFCTable.css';
import './DragDrop.css';
import { ButtonData } from '../buttonGroup/ButtonGroup';
import { FilterGroup } from '../filterGroup/FilterGroup';
import { Filter, GetQueryParams } from '../../types/api.type';
import { useNavbar } from '../../context/DashNavbarContext';
import { FadeIn } from '../../features/animations/FadeIn';
import { downloadIndex } from '../../services/api';
import { DashLogo } from '../generic';
import { Link } from 'react-router-dom';
import { userCanView_Recipes, userCanEditCreateDelete_Recipes } from '../../features/dash/views/content/Recipes/RecipesView';
import { currentUser } from '../../features/dash/views/schedule/needs/NeedsView';
import { generateFileUrlSegment } from '../../utils/utils';
import { LinkCell } from './cellComponents/LinkCell';
import { debounce } from 'lodash';
import { useMoveRecipeMutation } from '../../services/endpoints/content/recipe';
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Recipe } from '../../types/lessonPlan.type';
import { FaGripVertical } from 'react-icons/fa';

export interface RowFormProps {
  validationSchema: any;
  onSubmit: (values: any) => void;
  getInitialValues: (row: RTRow<any>) => any;
}

// Custom checkbox component for selecting rows
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef: any = ref || defaultRef;

    React.useEffect(() => {
      if (resolvedRef.current) {
        resolvedRef.current.indeterminate = indeterminate;
      }
    }, [resolvedRef, indeterminate]);

    return (
      <Form.Check
        type="checkbox"
        ref={resolvedRef}
        {...rest}
        style={{ margin: 0 }} // Optional styling to remove any margin
      />
    );
  }
);

const DraggableRow = ({ row, index, moveRow }: any) => {
    const [, dragRef] = useDrag({
        type: "row",
        item: { index },
    });

    const [, dropRef] = useDrop({
        accept: "row",
        hover: (draggedItem: any) => {
            if (draggedItem.index !== index) {
                moveRow(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    return (
        <tr ref={(node) => dragRef(dropRef(node))}>
            <td>
                <FaGripVertical style={{ cursor: 'grab' }} />
            </td>
            <td>{row.id}</td>
            <td>
                <img
                    src={`${generateFileUrlSegment(row.recipe_images[0]?.id, row.recipe_images[0]?.s3_base_url, 'thumb')}/${row.recipe_images[0]?.image_file_name}`}
                    width="50"
                    height="50"
                    alt={row.id}
                />
            </td>
            <td>{row.recipe_type}</td>
            <td>{row.title}</td>
            <td>
                <Stack direction="horizontal" gap={2}>
                    <Link to="/recipes">
                        <i className="fa fa-external-link" aria-hidden="true"></i>
                    </Link>
                </Stack>
            </td>
            <td>{row.status}</td>
            <td>
                <Stack direction="horizontal" gap={2}>

                    {userCanView_Recipes(currentUser) && (
                        <LinkCell
                            content="View"
                            url={`/admin/content/recipes/${row.id}`}
                            underline
                        />
                    )}

                    {userCanEditCreateDelete_Recipes(currentUser) && (
                        <>
                            <LinkCell
                                content="Edit"
                                url={`/admin/content/recipes/${row.id}/edit`}
                                underline
                            />
                            <LinkCell
                                content="Delete"
                                url={`/admin/content/recipes/${row.id}/delete`}
                                underline
                            />
                        </>
                    )}
                </Stack>
            </td>
        </tr>
    );
};

interface RecipeTableProps {
  name?: string;
  columns: ColumnDef<any>[];
  data: Recipe[];
  count?: number;
  defaultScope?: string;
  scopes?: ButtonData[][];
  isLoading?: boolean;
  isFetching?: boolean;
  expandedItem?: { columns: ColumnDef<any>[], rowKey: string, filterFn: Function };
  filterForm?: ReactElement;
  onFiltersChanged?: React.Dispatch<React.SetStateAction<GetQueryParams>>;
  newItemEl?: ReactNode;
  rowFormProps?: RowFormProps;
  selectableRows?: boolean;
  batchActions?: { name: string, fn: Function }[];
  indexDownloadPath?: string;
  filters?: Filter[];
  sidebars?: ReactNode[];
  defaultSort?: ColumnSort;
  searchbarActive?: boolean;
}

const RecipeTable: React.FC<RecipeTableProps> = ({
  name,
  columns,
  data,
  count,
  defaultScope,
  scopes,
  isLoading,
  isFetching,
  expandedItem,
  filterForm,
  onFiltersChanged,
  newItemEl,
  rowFormProps,
  selectableRows,
  indexDownloadPath,
  filters,
  batchActions,
  sidebars,
  defaultSort,
  searchbarActive = true,
}) => {
  const { activeNavbar, setActiveNavbar, setFilterNavContent, setFilterNavOverlap } = useNavbar();
  const [globalFilter, setGlobalFilter] = useState('');
  const [scope, setScope] = useState(defaultScope ?? '');
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<SortingState>(defaultSort ? [defaultSort] : []);
  const [orderBy, setOrderBy] = useState<{ field: string, order: string } | undefined>();
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  
  const [recipes, setRecipes] = useState(data);
  const [ moveRecipe ] = useMoveRecipeMutation();
  
      const debouncedMoveRecipe = useCallback(
          debounce((updatedRows) => {
            moveRecipe({
              data: updatedRows.map((recipe: any, index: number) => ({
                id: recipe.id,
                position: index + 1,
              })),
            });
          }, 500),
          [moveRecipe]
        );
  
        const moveRow = useCallback(
          (fromIndex: number, toIndex: number, draggedItem: any) => {
            console.log("move row");
            const updatedRows = [...recipes];
            const [movedRow] = updatedRows.splice(fromIndex, 1);
            updatedRows.splice(toIndex, 0, movedRow);
            setRecipes(updatedRows);
        
            if (fromIndex !== toIndex) {
              debouncedMoveRecipe(updatedRows);
            }
          },
          [recipes, debouncedMoveRecipe]
        );

  useEffect(()=>{
    if(!isLoading && defaultSort){
      let id = defaultSort.id;
      let order = !defaultSort.desc ? 'asc' : 'desc';
      setOrderBy({ field: id, order });
    }
  }, [isLoading]);

  useEffect(() => {

    // Set filter nav content to null on unmount
    return () => {
      setFilterNavContent(null);
      setFilterNavOverlap(false);
    };
  }, []); 

  useEffect(() => {
    if (filterForm) {
      setFilterNavContent(filterForm);
      setFilterNavOverlap(!!sidebars?.length);
    }
  }, [filterForm]);

  useEffect(() => {
    onFiltersChanged?.((old) => ({
      ...old,
      scope,
      data: {
        ...old?.data,
        orderBy: orderBy?.field ? orderBy : old.data?.orderBy,
        page: pagination.pageIndex + 1,
        entries: pagination.pageSize,
      }
    }));
  }, [pagination, scope, orderBy]);

  const download = (format: 'json' | 'csv') => {
    if (indexDownloadPath) {
      downloadIndex({
        path: indexDownloadPath,
        scope: scope,
        body: { orderBy, filterBy: filters },
        format,
        method: "POST",
      });
    }
  }

  const {
    getHeaderGroups,
    getRowModel,
    getState,
    getPageCount,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    previousPage,
    nextPage,
    firstPage,
    lastPage,
    setPageIndex,
    setPageSize,
    getSelectedRowModel,
    getIsAllRowsSelected,
    getIsSomeRowsSelected,
    getToggleAllPageRowsSelectedHandler,
  } = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      pagination,
      sorting,
      rowSelection,
    },
    manualPagination: true,
    onPaginationChange: setPagination, //update the pagination state when internal APIs mutate the pagination state
    rowCount: count,
    manualSorting: true,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
  });

  const getCanLastPage = () => {
    return getState().pagination.pageIndex !== getPageCount()-1;
  }

  const getCanFirstPage = () => {
    return getState().pagination.pageIndex !== 0;
  }

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const pageIndex = getState().pagination.pageIndex;

    if (getPageOptions().length <= 3) {
      // If total pages are less than or equal to 3, show all pages
      for (let i = 0; i < getPageOptions().length; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => setPageIndex(i)}
            className={`rounded-1 border-0 mx-1 ${i === pageIndex ? 'active' : ''}`}
            style={{
              background: i === pageIndex ? '#F2F2F2' : 'transparent',
              color: i === pageIndex ? '#282828' : '#888888',
              fontSize: '.75rem',
            }}
          >
            {i + 1}
          </button>
        );
      }
    } else {
      // If more than 3 pages, dynamically adjust the page numbers displayed
      const startPage = pageIndex === 0 ? pageIndex : pageIndex === getPageOptions().length - 1 ? pageIndex - 2 : pageIndex - 1;
      const endPage = pageIndex === 0 ? pageIndex + 2 : pageIndex === getPageOptions().length - 1 ? pageIndex : pageIndex + 1;

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => setPageIndex(i)}
            className={`rounded-1 border-0 mx-1 ${i === pageIndex ? 'active' : ''}`}
            style={{
              background: i === pageIndex ? '#F2F2F2' : 'transparent',
              color: i === pageIndex ? '#282828' : '#888888',
              fontSize: '.75rem',
            }}
          >
            {i + 1}
          </button>
        );
      }
    }

    return pageNumbers;
  };

  const handleSortingChange = (id: string, column: Column<any, unknown>) => {
    const nextSorting = column.getNextSortingOrder();
    if (!nextSorting) setOrderBy(undefined);
    else setOrderBy({ field: id, order: nextSorting });
    column.toggleSorting();
  };

  const selectedRowCount = getSelectedRowModel().rows.length;
  const isAtLeastOneRowSelected = selectedRowCount > 0;

  const selectedRowsIDs = getRowModel().rows.filter((row) =>
    Object.keys(rowSelection).includes(row.id)
  ).map((el) => el.original.id);

  if (isLoading) return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
      <Spinner animation="border" variant="primary" />
    </div>
  );

  const tableContent = (
      <React.Fragment>
        <Stack className="d-lg-none mb-3" gap={3}>
          <Stack direction="horizontal" gap={2}>
              <h6 className="fw-bolder text-secondary mb-0 mb-lg-0 ps-0 ps-lg-2" style={{ fontSize: '1.125rem' }}>
                {name || scopes?.[0]?.[0]?.title}
              </h6>
              <span
                className="rounded-4 px-2 py-1 fw-bold"
                style={{ background: '#F2F4F7', color: '#888888', fontSize: '.75rem' }}
              >
                {count} items
              </span>
          </Stack>

          <Stack direction="horizontal" gap={2}>
            {searchbarActive &&
              <InputGroup>
                <InputGroup.Text>
                  <BsSearch />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  aria-label="Search"
                  name="search"
                  style={{ height: 40 }}
                  onChange={(e) => {
                    setGlobalFilter(e.target.value);
                  }}
                />
              </InputGroup>
            }
            {filterForm && <Button
              variant="outline-primary"
              onClick={() => setActiveNavbar('filter')}
              className={`d-flex align-items-center text-uppercase ${activeNavbar === 'filter' ? 'active' : ''}`}
            >
              <HiOutlineAdjustmentsHorizontal size={20} className="me-2" />
              Filter
            </Button>}
          </Stack>
        </Stack>

        <Row className="d-none d-lg-flex p-4 align-items-center justify-content-between">
          <Col xs={12} lg={6} className="p-0">
            <Stack direction="horizontal" gap={2}>
              <h6 className="fw-bolder text-secondary mb-0 mb-lg-0" style={{ fontSize: '1.125rem' }}>
                {name || scopes?.[0]?.[0]?.title}
              </h6>
              <span
                className="rounded-4 px-2 py-1 fw-bold"
                style={{ background: '#F2F4F7', color: '#888888', fontSize: '.75rem' }}
              >
                {count} items
              </span>
            </Stack>
          </Col>

          <Col xs={12} lg={searchbarActive ? 6 : 3} className="p-0">
            <Stack direction="horizontal" gap={2}>
                {searchbarActive &&
                  <InputGroup>
                    <InputGroup.Text>
                      <BsSearch />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Search (only visible column data)"
                      aria-label="Search"
                      name="search"
                      style={{ height: 40 }}
                      onChange={(e) => {
                        setGlobalFilter(e.target.value);
                      }}
                    />
                  </InputGroup>
                }
                {filterForm && <Button
                  variant="outline-primary"
                  onClick={() => setActiveNavbar('filter')}
                  className={`d-flex align-items-center text-uppercase ${activeNavbar === 'filter' ? 'active' : ''}`}
                >
                  <HiOutlineAdjustmentsHorizontal size={20} className="me-2" />
                  Filter
                </Button>}
              {newItemEl}
            </Stack>
          </Col>
        </Row>
        
        { isFetching ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
          <Spinner animation="border" variant="primary" />
        </div>
        ) : (
          <DndProvider backend={HTML5Backend}>
          <table
                style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    marginTop: "20px",
                }}
                border={1}
            >
                <thead>
                    <tr style={{ backgroundColor: "#f0f0f0" }}>
                        <th style={{ width: "30px" }}>Move</th>
                        <th>ID</th>
                        <th>Image</th>
                        <th>Recipe Type</th>
                        <th>Title</th>
                        <th>Live</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody key={recipes.length}>
                    {recipes.map((row:Recipe, index: number) => (
                        <DraggableRow key={row.id} row={row} index={index} moveRow={moveRow} />
                    ))}
                </tbody>
            </table>
          </DndProvider>
        ) }
        { recipes.length > 0 && 
          (
            <Row className="align-items-center p-lg-4 my-3 my-lg-0">
              <Col xs={6}>
                <Stack direction="horizontal" gap={3}>
                  { indexDownloadPath && (
                    <div className="d-none d-lg-block" style={{ fontSize: '.875rem', color: '#344054' }}>
                      <span className={"p-1"}>Download:</span>
                      <a href="#" className={"p-1"} onClick={() => download?.('json')}>JSON</a>
                      <a href="#" className={"p-1"} onClick={() => download?.('csv')}>CSV</a>
                    </div>
                  )}
                </Stack>
              </Col>
            </Row>
          )
        }
    </React.Fragment>
  );

  return (
    <FadeIn>
      <Row>
              {batchActions && (
                <Col xs="auto" lg="auto">
                  <DropdownButton id="dropdown-basic-button" title="Batch actions" size="sm" variant="info" disabled={!selectableRows || !isAtLeastOneRowSelected}>
                    {batchActions.map((action) => (
                      <Dropdown.Item key={action.name} onClick={() => action.fn(selectedRowsIDs)}>{action.name}</Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Col>
              )}
      
              <Col xs="auto" lg="auto">
                {scopes && scopes.length > 0 && (
                  <FilterGroup
                    filterGroups={scopes}
                    onFilterSelect={(filter: string) => {
                      setScope(filter);
                      setPagination((prev) => ({
                        ...prev,
                        pageIndex: 0,
                      }));
                    }}
                  />
                )}
              </Col>
            </Row>
            <div className="d-lg-none d-block">
      
              <div className="overflow-y-scroll p-3 h-100" style={{maxHeight: '300px'}}>
                <div className="d-block justify-content-between border-bottom pb-1 mb-3">
                  {sidebars}
                </div>
              </div>
              <div className={'w-100 sfc-table'}>
                {tableContent}
              </div>
            </div>
            <div className="d-none d-lg-flex">
            <div className={(sidebars?.length ?? 0) ? 'w-100 px-2 py-1 sfc-table' : 'w-100 sfc-table'} style={(sidebars?.length ?? 0) ? { maxWidth: 'calc(100% - 300px)' } : {}}>
              {tableContent}
            </div>
            {(sidebars?.length ?? 0) > 0 && (
              <div className="ps-3 py-0 d-none d-lg-flex">
                <Stack gap={4} className="p-3" style={{ width: 300, background: '#F2F2F2', borderRadius: '10px', boxShadow: "0 0 8px 0 rgba(0, 0, 0, .1), 0 0 8px 0 rgba(0, 0, 0, .1)" }}>
                  {sidebars}
                  <DashLogo />
                </Stack>
              </div>
            )}
            </div>
          </FadeIn>
  );
};

export default RecipeTable;
