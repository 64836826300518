import {
  Button,
  Container,
  Image,
} from "react-bootstrap";
import MapImg from "../../assets/images/nationwide-map.png";
import "./AvailableJobsSection.css";
import Banner from "../../components/banner/Banner";
import { SFCCommunity } from "../home/commonComponents/SFCCommunity";
import StateAccordionComponent from "../nationwide/StateAccordionComponent";
import { getCurrentUser } from "../../services/helper";
import { useToast } from "../../context";
import { useGetAllLocationsQuery } from "../../services/endpoints/places/location";
import { SFCLocation } from "../../types/place.types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { buttonLocations, franchiseLocations, usStates } from "../nationwide/NationwideData";


export const AvailableJobsSection = () => {
  const navigate = useNavigate();
  const currentUser = getCurrentUser();
  const { addToast } = useToast();
  const { data, error, isLoading } = useGetAllLocationsQuery();
  const [locations, setLocations] = useState<SFCLocation[]>();

  useEffect(() => {
    if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);
  
  useEffect(() => {
    if (data && data.results) {
      setLocations(data.results);
    }
  }, [data]);

  return (
    <div className="bg-white availableJobs">
      <Container fluid className="text-white bg-header d-flex">
        <div className="d-flex justify-content-center align-items-center fanchise-title-container">
          <h1 className="align-items-center text-center">Available Jobs</h1>
        </div>
      </Container>

      <Container>
        <div className="map-container">
          <Image className="w-100" src={MapImg} />

          {locations?.map((location, id) => {
            let buttonOptions = buttonLocations.filter(btn => btn.title === location.title);
            if(buttonOptions != null && buttonOptions.length > 0) {
              let button = buttonOptions[0];
              return (
                <div
                  title={button.title}
                  key={id}
                  className={button.type === 'franchise' ? "map-button" : "map-button-outlet"}
                  style={{
                    left: `${button.x}%`,
                    top: `${button.y}%`,
                    backgroundImage: `url(${button.imageUrl})`
                  }}
                  onClick={() => {
                    let url = location?.location_information?.jobs_url;
                    if(url) {
                      if (!url.startsWith('http://') && !url.startsWith('https://')) {
                        url = 'https://' + url;
                      }
                      window.open(url, '_blank');
                    }
                    else {
                      addToast('URL is not available', 'error');
                    }
                  }}
                ></div>
              )
            }
            })}
        </div>
      </Container>

      <Container>
        <div className="column-content available-jobs-content">
          <h2 className="text-info">Now hiring!</h2>
          <p>Join our team! <strong>Thyme to Turnip the Beet on What Kids Eat!®</strong><br/><br/>We are on the lookout for high-energy, creative chef instructors to lead children’s cooking classes (including after-school programs, summer camps, and weekend classes and parties) for children ages 4 to 18. All positions are part-time.</p>
          <div className="div-break"></div>
          <h3 className="text-info">Qualifications</h3>
          <p>Sticky Fingers Cooking is looking for experienced teachers and/or chefs who have a love for cooking and are committed to working with children in public, private, and charter schools.</p>
          <ul className="w-list-unstyled">
            <li className="list-item">Enthusiasm for teaching and cooking</li>
            <li className="list-item">Dedication to children who have diverse backgrounds and academic levels</li>
            <li className="list-item">Interest in cooking and healthy alternatives to traditional recipes</li>
            <li className="list-item">Experience working with large groups of children in a school, camp, etc.</li>
            <li className="list-item">Effective classroom management skills, with teaching experience preferred</li>
            <li className="list-item">Punctuality and reliability, with access to reliable transportation</li>
            <li className="list-item">Love for the messy, magical combination of cooking and children</li>
            <li className="list-item">Flexibility for a fast-paced and high-energy environment </li>
            <li className="list-item">Availability at least 2 days a week Monday-Friday between the hours of 2-5 pm for one-hour classes for 6-8 week sessions</li>
          </ul>
          <p>Serious applicants only please! If considered, Sticky Fingers Cooking will require a background check.</p>
          <Button className="  text-uppercase button-radius mb-5" onClick={() => window.open('https://sfc.workbrightats.com/pages/JobsbyLocation/', '_blank')}>
            apply now
          </Button>
          <div className="div-break"></div>
          <h3 className="text-info">Responsibilities</h3>
          <ul className="list w-list-unstyled">
            <li className="list-item">Lead Pre-K, Elementary and/or middle school students in exploring fun &amp; creative recipes</li>
            <li className="list-item">Facilitate mobile cooking classes. Teach cutting, stirring, measuring, and nutrition.</li>
            <li className="list-item">Actively communicate with administration staff</li>
            <li className="list-item">Work with large groups of children in a school, camp, etc.</li>
            <li className="list-item">Interact with parents and school staff</li>
            <li className="list-item">Shop independently for weekly recipes</li>
            <li className="list-item">Travel to classroom location</li>
          </ul>
          <p>Serious applicants only please! If considered, Sticky Fingers Cooking will require a background check.</p>
          <Button className="text-uppercase button-radius mb-5" onClick={() => window.open('https://sfc.workbrightats.com/pages/JobsbyLocation/', '_blank')}>
            apply now
          </Button>
          <div className="div-break"></div>
          <h3 className="text-info">What Our Chefs Have to Say</h3>
          <div className="d-flex about-us-container">
            <div className="child-50-rounded margin-right">
              <div className="text-quotation-mark-testimonial margin-top">“</div>
              <div className="text-quotation">The best part of the cooking class today was when I introduced the special ingredient as artichokes. One kid asked very seriously, &quot;What if I don&#x27;t eat vegetables?&quot; to which I replied, &quot;Let&#x27;s just wait and see if you eat them in the ravioli we make.&quot; After he had gobbled up all of his ravioli at the end of class, I asked him, &quot;So how were those vegetables?&quot; He smiled bashfully and said, &quot;They were pretty great!&quot;</div>
              <div className="text-quotation bold">Chef Ariel, Texas<br/></div>
            </div>
            <div className="child-50-rounded">
              <div className="text-quotation-mark-testimonial margin-top">“</div>
              <div className="text-quotation">I love sharing my passion for cooking (and eating) with my Sticky Fingers students! There is nothing better than the look of pride on a child&#x27;s face when they taste a dish they just made and discover how delicious it is. It is amazing what the pickiest eater will try if they had a hand in making it: Tofu Picatta, Watermelon Poke, Kale Ravioli. My heart melts when they ask if they can eat some raw kale!</div>
              <div className="text-quotation bold">Chef Dani, Colorado<br/></div>
            </div>
          </div>
        </div>
      
      </Container>

      <div className="section-video">
        <Container>
          <div className="d-flex instructorsSection">
            <div className="instructorsText">
              <div className="subheader">Part-Time Job Opportunity</div>
              <h2 className="zero-margin-top text-info">Meet Our Amazing Chef Instructors</h2>
              <p>Make a difference in your community by becoming a Sticky Fingers Cooking Chef Instructor! In this video, our staff spill the beans about working with kids and food in our after-school programs. Watch now to learn more about this exciting part-time job opportunity.</p>

              <Button className="text-uppercase button-radius mb-5" onClick={() => window.open('https://sfc.workbrightats.com/pages/JobsbyLocation/', '_blank')}>
                apply now
              </Button>
            </div>
            <div className="instructorsVideo">
              <iframe
                  title="chefInstructors"
                  className="video-container"
                  src="https://player.vimeo.com/video/492596964?loop=1"
                  allow="autoplay; fullscreen; picture-in-picture"></iframe>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};