import { Button, Col, Form, FormControl, Row, Spinner, Stack } from 'react-bootstrap';
import { StepProps } from '../AddEditSession';
import { useDropzone } from 'react-dropzone';
import { useState } from 'react';
import {
  BsFileEarmarkCode,
  BsFileEarmarkExcel,
  BsFileEarmarkImage,
  BsFileEarmarkRichtext,
  BsFileEarmarkText,
  BsFileEarmarkWord,
  BsFileEarmarkZip,
  BsFiletypePdf,
  BsTrash3,
} from 'react-icons/bs';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ErrorMessage } from 'formik';
import { useGetAllDocumentsQuery } from '../../../../../../services/endpoints/content/document';
import { useToast } from '../../../../../../context/ToastContext';
import { getErrorMessage } from '../../../../../../utils/utils';
import { Document } from '../../../../../../types/content.type';

export const getFileIcon = (file: File): JSX.Element => {
  const mimeType = file.type ?? '';//file.image_content_type;

  if (mimeType === 'application/pdf') {
    return <BsFiletypePdf size={48} color="#5E6469" />;
  } else if (mimeType === 'application/msword' || mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    return <BsFileEarmarkWord size={48} color="#5E6469" />;
  } else if (mimeType === 'application/vnd.ms-excel' || mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    return <BsFileEarmarkExcel size={48} color="#5E6469" />;
  } else if (mimeType.startsWith('image/')) {
    return <BsFileEarmarkImage size={48} color="#5E6469" />;
  } else if (mimeType === 'text/plain') {
    return <BsFileEarmarkText size={48} color="#5E6469" />;
  } else if (mimeType === 'application/zip' || mimeType === 'application/x-rar-compressed') {
    return <BsFileEarmarkZip size={48} color="#5E6469" />;
  } else if (mimeType.startsWith('text/') || mimeType.startsWith('application/javascript') || mimeType === 'application/typescript') {
    return <BsFileEarmarkCode size={48} color="#5E6469" />;
  } else {
    return <BsFileEarmarkRichtext size={48} color="#5E6469" />;
  }
};

export const formatFileSize = (file: File): string => {
  const sizeInBytes = file.size;

  if (sizeInBytes < 1024) {
    return `${sizeInBytes} bytes`;
  } else if (sizeInBytes < 1024 * 1024) {
    const sizeInKb = sizeInBytes / 1024;
    return `${sizeInKb.toFixed(2)} KB`;
  } else {
    const sizeInMb = sizeInBytes / (1024 * 1024);
    return `${sizeInMb.toFixed(2)} MB`;
  }
};

export const DocumentElement =
  ({ file, name, onRemove, disabled }: { file: File, name: string, onRemove: Function, disabled: boolean }) => (
  <div
    className="d-flex align-items-center justify-content-between px-2 px-lg-4 py-2"
    style={{
      background: '#F2F2F2',
      border: '1px solid rgba(94, 100, 105, .5)',
      borderRadius: 8,
    }}
  >
    <div className="d-flex align-items-center" style={{ width: '90%' }}>
      {getFileIcon(file)}
      <Stack className="ms-2" style={{ maxWidth: '80%' }}>
        <span className="d-lg-none text-secondary fw-semibold text-truncate">
          {name}
        </span>
        <span className="d-none d-lg-block text-secondary fw-semibold text-truncate" style={{ fontSize: '1.425rem' }}>
          {name}
        </span>
        <span className="text-secondary">{formatFileSize(file)}</span>
      </Stack>
    </div>

    {!disabled && <BsTrash3 size={24} color="#B81F69" style={{ cursor: 'pointer' }} onClick={() => onRemove()} />}
  </div>
);

export const DocumentsAndFilesForm = ({ touched, errors, values, setFieldValue, readOnly }: StepProps) => {
  const { data, error, isLoading } = useGetAllDocumentsQuery();
  const toast = useToast();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [fileName, setFileName] = useState<string>('');
  const onDrop = (acceptedFiles: File[]) => {
    // console.log(...acceptedFiles, values);
    // setFieldValue('files', [...values!.files, ...acceptedFiles]);
    setSelectedFile(acceptedFiles[0]);
  };

  if (isLoading) {
    return (
      <div className="w-100 vh-100 d-inline-flex">
        <Spinner variant="primary" className="m-auto" />
      </div>
    )
  }

  if (error || !data || !data.results) {
    toast.addToast(getErrorMessage(error) ?? 'Error loading documents', 'error');
  }

  return (
    <Row>
      {!readOnly && (
        <Col xs={12} lg={6}>
          <Stack gap={2}>
            <h6
              className="fw-bold mb-0"
              style={{ fontSize: '1.125rem', color: '#5E6469' }}
            >
              Add a Document or file
            </h6>

            <Form.Group>
              <Form.Label>Existing Files</Form.Label>
              <Typeahead 
                id="document_ids"
                multiple
                disabled={readOnly}
                options={data?.results?.map((document: Document) => ({ id: document.id, label: document.title })) ?? []}
                selected={values?.document_ids}
                onChange={(selected) => setFieldValue!('document_ids', selected)}
                isInvalid={touched.document_ids && !!errors.document_ids}
              />
              <ErrorMessage name="document_ids">
                {(msg) => (
                  <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
                )}
              </ErrorMessage>
            </Form.Group>

            <div>
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" placeholder="Enter" value={fileName} onChange={(e) => setFileName(e.target.value)} />
              <Form.Text>If title is not entered, file name will be used</Form.Text>
            </div>

            <div>
              <Form.Label>Document</Form.Label>
              <Dropzone onDrop={onDrop} />
            </div>

            {selectedFile && (
              <>
                <div className="mt-3">
                  <h6 className="text-info fw-semibold">Selected File:</h6>
                  <p className="mb-0 text-truncate">{selectedFile.name}</p>
                </div>
                <Button
                  variant="primary"
                  size="sm"
                  className="btn-box-shadow border-0 text-uppercase"
                  style={{ width: 150 }}
                  onClick={() => {
                    setFieldValue!('files', [...values!.files!, selectedFile]);
                    const fileMetadata = {
                      title: fileName?.trim() === '' ? selectedFile.name : fileName,
                      destroy: false,
                    };
                    setFieldValue!('file_metadata', [...values!.file_metadata!, fileMetadata]);
                    setFileName('');
                    setSelectedFile(undefined);
                  }}
                >
                  Add document
                </Button>
              </>
            )}
          </Stack>
        </Col>
      )}
      <Col xs={12} lg={6}>
        <Stack gap={2}>
          <h6
          className="fw-bold mb-0"
          style={{ fontSize: '1.125rem', color: '#5E6469' }}
        >
          Documents
        </h6>
        {readOnly && (
          <Form.Group>
            <Form.Label>Existing Files</Form.Label>
            <Typeahead 
              id="document_ids"
              multiple
              disabled={readOnly}
              options={data?.results?.map((document: Document) => ({ id: document.id, label: document.title })) ?? []}
              selected={values?.document_ids}
              onChange={(selected) => setFieldValue!('document_ids', selected)}
              isInvalid={touched.document_ids && !!errors.document_ids}
            />
            <ErrorMessage name="document_ids">
              {(msg) => (
                <FormControl.Feedback type="invalid">{msg}</FormControl.Feedback>
              )}
            </ErrorMessage>
          </Form.Group>
        )}
        {values!.files!.map((file, index) => (
          <DocumentElement
            key={index}
            file={file}
            name={values!.file_metadata![index].title}
            onRemove={() => {
              setFieldValue!('files', values!.files!.filter((_, i) => i !== index));
              setFieldValue!('file_metadata', values!.file_metadata!.filter((_, i) => i !== index));
            }}
            disabled={readOnly ?? false}
          />
        ))}
        </Stack>
      </Col>
    </Row>
  )
}

interface DropzoneProps {
  onDrop: Function;
}

const Dropzone: React.FC<DropzoneProps> = ({ onDrop }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => onDrop(acceptedFiles),
    multiple: false,
  });

  return (
    <div {...getRootProps({ className: 'dropzone' })} style={dropzoneStyle}>
      <input {...getInputProps()} />
      <Stack style={{ color: 'rgba(94, 100, 105, .5)' }}>
        <span>Drag and drop here</span>
        <span>or</span>
        <span className="fw-bold" style={{ color: '#6098B8' }}>
          Browse
        </span>
      </Stack>
    </div>
  );
};

const dropzoneStyle: React.CSSProperties = {
  border: '1px dashed rgba(94, 100, 105, 0.5)',
  borderRadius: 8,
  padding: 16,
  textAlign: 'center',
  cursor: 'pointer'
};
