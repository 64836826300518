import { ReactNode } from "react";
import { Image } from "react-bootstrap";
import {
  BsCalendar3,
  BsCart3,
  BsCashCoin,
  BsFileEarmarkRichtext,
  BsFillGeoAltFill,
  BsGlobe,
  BsMortarboardFill,
  BsPeopleFill,
  BsStopwatch,
} from "react-icons/bs";
import { LuFileClock } from "react-icons/lu";
import PinWiskLogo from "../../assets/images/pin_wisk.png";
import { getCurrentUser, MenuOption } from "../../services/helper";
import { getAllowedTabsContent, getAllowedTabsFinance, getAllowedTabsOrders, getAllowedTabsPeople_DashUsers, getAllowedTabsPeople_SFCUsers, getAllowedTabsPlaces, getAllowedTabsSchedule_ScheduleNeeds, getAllowedTabsSchedule_SessionsClasses, getAllowedTabsTimesheets, getAllowedTabsTraining, getAllowedTabsWebsite } from "../../utils/utils";

const currentUser = getCurrentUser();

export interface NavItem {
  icon?: ReactNode;
  title: string;
  url?: string;
  urls?: string[];
  subItems?: NavItem[];
  hidden?: boolean;
}

const contentItems =  getAllowedTabsContent(currentUser);
const placesItems = getAllowedTabsPlaces(currentUser);
const trainingItems = getAllowedTabsTraining(currentUser);
const websiteItems = getAllowedTabsWebsite(currentUser);
const sessionsClassesItems = getAllowedTabsSchedule_SessionsClasses(currentUser);
const scheduleNeedsItems = getAllowedTabsSchedule_ScheduleNeeds(currentUser);
const dashUsersTabs = getAllowedTabsPeople_DashUsers(currentUser);
const sfcUsersTabs = getAllowedTabsPeople_SFCUsers(currentUser);
const financeItems = getAllowedTabsFinance(currentUser);
const timesheetsItems = getAllowedTabsTimesheets(currentUser);
const ordersItems = getAllowedTabsOrders(currentUser);

let scheduleItems:NavItem[] = [];
if(sessionsClassesItems.length > 0) {
  scheduleItems.push({
    title: "Sessions & Classes",
    url: "/admin/schedule/sessions",
    urls: ["/admin/schedule/classes", "/admin/schedule/waitlists"],
  });
}

if(scheduleNeedsItems.length > 0) {
  scheduleItems.push({
    title: "Schedule Needs",
    url: "/admin/schedule-needs/need-instructor",
    urls: [
      "/admin/schedule-needs/need-substitute",
      "/admin/schedule-needs/instructor-session-requests",
    ],
  });
}

let dashUsersItems: NavItem[] = [];
if(dashUsersTabs.length > 0) {
  dashUsersItems.push({
    title: "Dash Users",
    url: "/admin/dash-users",
  });
}

if(sfcUsersTabs.length > 0) {
  dashUsersItems.push({
    title: "SFC Users",
    url: "/admin/sfc-users",
  });
}

export const NavItems = (isAdmin: boolean) => [
  {
    icon: <Image src={PinWiskLogo} width={24} height={24} />,
    title: "SFC Home",
    url: "/",
  },
  {
    icon: <BsCalendar3 size={24} />,
    title: "Calendar",
    url: "/admin/dashboard",
  },
  {
    icon: <BsStopwatch size={24} />,
    title: "Schedule",
    subItems: isAdmin
      ? scheduleItems
      : [
          {
            title: "My Sessions",
            url: "/admin/schedule/sessions",
          },
          {
            title: "Available Sessions",
            url: "/admin/schedule-needs/need-substitute",
          },
        ],
  },
  {
    icon: <BsPeopleFill size={24} />,
    title: "People",
    subItems: dashUsersItems,
  },
  ...(isAdmin && contentItems.length > 0
    ? [
        {
          icon: <BsFileEarmarkRichtext size={24} />,
          title: "Content",
          subItems: contentItems,
        },
      ]
    : []),
  ...(isAdmin && financeItems.length > 0
    ? [
        {
          icon: <BsCashCoin size={24} />,
          title: "Finance",
          subItems: financeItems,
        },
      ]
    : []),
  ...(isAdmin && timesheetsItems.length > 0
    ? [
        {
          icon: <LuFileClock size={24} />,
          title: "Timesheets",
          subItems: timesheetsItems,
        },
      ]
    : []),
  ...(isAdmin && ordersItems.length > 0
    ? [
        {
          icon: <BsCart3 size={24} />,
          title: "Orders",
          subItems: ordersItems,
        },
      ]
    : []),
  ...(isAdmin && placesItems.length > 0
    ? [
        {
          icon: <BsFillGeoAltFill size={24} />,
          title: "Places",
          subItems: placesItems,
        },
      ]
    : []),
  ...(isAdmin && trainingItems.length > 0 
    ? [
        {
          icon: <BsMortarboardFill size={24} />,
          title: "Training",
          subItems: trainingItems,
        },
    ]
    : []),
  ...(isAdmin && websiteItems.length > 0
    ? [
        {
          icon: <BsGlobe size={24} />,
          title: "Website",
          subItems: websiteItems,
        },
      ]
    : []),
];

export const SFCNavItems = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: `Why we're grape`,
    url: "/why-we-are-grape",
  },
  {
    title: 'Recipes',
    url: '/recipes',
  },
  {
    title: "Find a cooking session",
    url: "/sessions",
    subItems: [
      {
        title: "School Programs",
        url: "/sessions?session_type=after_school",
      },
      {
        title: "Cooking Camps",
        url: "/sessions?session_type=camps",
      },
      {
        title: "Private Events",
        url: "/sessions?session_type=events",
      },
      {
        title: "Online Classes",
        url: "/sessions?session_type=online",
      }
    ],
  },
  {
    title: `Own a Franchise`,
    subItems: [
      {
        title: "Own a Franchise",
        url: "https://www.stickyfingerscookingfranchise.com/",
      },
      {
        title: "Find Locations Nationwide",
        url: "/nationwide",
      }
    ],
  },
  {
    title: `Work with us`,
    url: "/availableJobs",
  },
  {
    title: `Sticky Notes`,
    url: "/sticky-notes",
  },
  {
    title: `Shop`,
    url: "https://www.amazon.com/stores/author/B093YJHWSM/allbooks?ingress=0&visitId=40d233ce-6f77-44b4-88e1-623a39c5edc5&store_ref=ap_rdr&ref_=ap_rdr",
    target:'_blank'
  },
  {
    title: `Contact us`,
    url: "/contact",
  },
  // {
  //   title: `School Programs`,
  //   url: '#school',
  //   subItems: [
  //     {
  //       title: 'Denver | Boulder School Programs',
  //       url: '#denver'
  //     },
  //     {
  //       title: 'Northern Chicago School Programs',
  //       url: '#chicago'
  //     },
  //     {
  //       title: 'Austin School Programs',
  //       url: '#austin'
  //     },
  //     {
  //       title: 'Northern Virginia School Programs',
  //       url: '#virginia'
  //     },
  //     {
  //       title: 'San Diego School Programs',
  //       url: '#sandiego'
  //     },
  //     {
  //       title: 'Dallas School Programs',
  //       url: '#dallas'
  //     },
  //   ]
  // },
];
