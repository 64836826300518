import { useState } from "react";
import { FaChevronRight, FaChevronDown } from "react-icons/fa6";
import "./StateAccordionComponent.css";
import { SFCLocation } from "../../types/place.types";
import { useNavigate } from "react-router-dom";

interface StateAccordionParams {
  stateName: string;
  stateFranchises: SFCLocation[] | undefined;
}

const StateAccordionComponent: React.FC<StateAccordionParams> = ({
  stateName,
  stateFranchises,
}) => {
  const [toggleContent, setToggleContent] = useState(false);
  const navigate = useNavigate();
  
  const handleToggle = () => {
    const toggleValue = !toggleContent;
    setToggleContent(toggleValue);
  };

  let className = "nav-item";
  if (toggleContent) {
    className += " active";
  }

  return (
    <div className={className}>
      <div className="nav-link" onClick={handleToggle}>
        <span className="state-name">{stateName}</span>
        <span className="arrow">
          {toggleContent ? <FaChevronDown /> : <FaChevronRight />}
        </span>
      </div>
      {toggleContent &&
        stateFranchises?.map((franchise) => {
          return <p className="location-text" onClick={() => navigate('/franchise/' + franchise.id)}>{franchise.title}</p>;
        })}
    </div>
  );
};

export default StateAccordionComponent;
