import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { DateField } from '../../../../../components/tables/filterForms/DateField';
import { pageColumns } from '../../../../../utils/consts/columnDefs/websiteColumns';
import { useGetAllPagesQuery } from '../../../../../services/endpoints/website/page';
import { Page } from '../../../../../types/website.types';
import { getCurrentUser } from '../../../../../services/helper';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { imageFilterFields } from '../../../../../utils/consts/filterFields/image';
import { FormStaticSelect } from '../../../../../components/tables/filterForms/FormStaticSelect';
import { created_updated_at_from_to } from '../../../../../utils/consts/filterFields/dates';
import { User } from '../../../../../services/endpoints/people/user';

const defaultScope = '';
const currentUser = getCurrentUser();

export const userCanEditCreateDelete_Pages = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin || user?.isWebContentEditor) {
    return true;
  }

  return false;
}

export const userCanView_Pages = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin || user?.isWebContentEditor || user?.isGlobal) {
    return true;
  }

  return false;
}

export const PagesView = () => {
  const { addToast } = useToast();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: {page: 1, entries: 10} });
  const { data, error, isLoading, isFetching } = useGetAllPagesQuery(filters);
  const [pages, setPages] = useState<Page[]>([]);
  const navigate: NavigateFunction = useNavigate();
  const isGlobalUser = (currentUser?.isGlobal);

  const filterFormFields: FilterField[] = [
    // TODO: add Slugs and Gallery filters - not working well on Dash 2.0
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'content',
      label: 'Content',
    },
    {
      name: 'slug',
      label: 'Slug',
    },
    {
      name: 'is_featured',
      type: 'number',
      operator: 'equals',
      element: (
        <FormStaticSelect
          name="is_featured"
          label="Is Featured"
          options={[{ value: 1, label: 'Yes' }, { value: 0, label: 'No' }]}
        />
      ),
    },
    {
      name: 'author',
      label: 'Author',
    },
    {
      name: 'button_text',
      label: 'Button Text',
    },
    {
      name: 'button_target',
      label: 'Button Target',
    },
    {
      name: 'button_opens_in_new_window',
      type: 'number',
      operator: 'equals',
      element: (
        <FormStaticSelect
          name="button_opens_in_new_window"
          label="Button Opens In New Window"
          options={[{ value: 1, label: 'Yes' }, { value: 0, label: 'No' }]}
        />
      ),
    },
    ...imageFilterFields,
    {
      name: 'published_on_from',
      id: 'published_on',
      operator: 'greater_equal_than',
      element: (
        <DateField name="published_on_from" label="Published On" />
      )
    },
    {
      name: 'published_on_to',
      id: 'published_on',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="published_on_to" />
      )
    },
    ...created_updated_at_from_to,
  ];

  if (!isGlobalUser) {
    filterFormFields.splice(4, 1);
  }

  useEffect(() => {
    if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setPages(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`Pages`}
        columns={pageColumns}
        data={pages}
        count={data?.count}
        defaultScope={defaultScope}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath='page'
        filters={filters?.data?.filterBy}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        newItemEl={userCanEditCreateDelete_Pages(currentUser) && (
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={270}
            onClick={() => navigate('/admin/website/pages/new')}
          >
            New Page
          </SimpleButton>
        )}
        onFiltersChanged={setFilters}
      />
    </div>
  );
}
