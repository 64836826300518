import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { isGlobal } from '../../../../../utils/consts';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { Scopes } from '../../../../../services/endpoints/people/user';
import { registrationsColumns } from '../../../../../utils/consts/columnDefs/registrations';
import { useGetRegistrationsQuery } from '../../../../../services/endpoints/content/registrations';
import { registration } from '../../../../../types/registrations.type';


export const RegistrationsView = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const course_id = queryParams.get('course_id');
  const defaultScope = course_id ? 'active' : 'all';
  const { addToast } = useToast();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: { page: 1, entries: 10 } });
  const [scopes, setScopes] = useState<Scopes>({});
  const [couseFilter , setCourseFilter] = useState<GetQueryParams>({ scope: defaultScope, data: { page: 1, entries: 10, filterBy: [{ field: 'course_id', value: course_id ?? '', operator: 'equals' }] } })
  const { data, error, isLoading, isFetching } = useGetRegistrationsQuery(course_id ? couseFilter : filters);
  const [registration, setRegistration] = useState<registration[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const navigate: NavigateFunction = useNavigate();

  const filterFormFields: FilterField[] = [
    {
      name: 'id',
      type: 'number',
      label: 'Id',
    },
  ];

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({ ...acc, ...curr }), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => {
          let scope_count = 0;
          if (data.scope_counts != null) {
            scope_count = data.scope_counts[scope] ?? 0;
          }
          return {
            title: `${title} (${scope_count})`,
            key: scope,
          }
        })));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {

      setRegistration(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    console.log('Page changes', filterBy)
    if (course_id) {
      filterBy.push({ field: 'course_id', value: course_id ?? '', operator: 'equals' });
    }
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`${scopes[filters?.scope!] ?? ''} Registration`}
        columns={registrationsColumns}
        data={registration}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        filters={filters?.data?.filterBy}
        indexDownloadPath='registrations'
        filterForm={(
          <FilterForm
            fields={filterFormFields}
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={(newfilters) => {
          // Check if newfilters is a function
          if (typeof newfilters === 'function') {
            // Call the function with current filters
            const modifiedFilters = newfilters(filters);

            // Ensure modifiedFilters is an object before modifying
            if (modifiedFilters?.data) {
              modifiedFilters.data.filterBy = modifiedFilters.data.filterBy || [];

              // Add course_id if available
              if (course_id) {
                const courseFilter = {
                  field: 'course_id',
                  value: course_id ?? '',
                  operator: 'equals',
                };

                // Avoid duplicate course_id filter
                const isCourseFilterPresent = modifiedFilters.data.filterBy.some(
                  (filter) =>
                    filter.field === 'course_id' && filter.value === course_id
                );

                if (!isCourseFilterPresent) {
                  modifiedFilters.data.filterBy.push(courseFilter);
                }
              }
            }

            // Set modified filters
            setFilters(modifiedFilters);
            setCourseFilter(modifiedFilters);
          } else {
            console.error('newfilters is not a function or object!');
          }
        }}

      />
    </div>
  );
}
