import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { LocationSetterNoForm } from '../../features/maps/LocationSetter';
import { IoIosSearch } from 'react-icons/io';
import BrowserLocationComponent from '../../features/maps/BrowserLocationComponent';
import { LocationSelection, SavedLocationSelection } from '../../services/helper';
import { useMyLocationContext } from '../../context';
import { useNavigate } from 'react-router-dom';

interface LocationModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  title: string;
  message: string;
  placeholder: string;
  onClose: () => void;
}

const LocationModal: React.FC<LocationModalProps> = ({
  show,
  setShow,
  title,
  message,
  placeholder,
  onClose,
}) => {
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate();
  const { myLocation, saveMyLocation } = useMyLocationContext();
  const [ locationSelected, setLocationSelected ] = useState(false);
  
  const [location, setLocation] = useState<SavedLocationSelection | null>(
    myLocation
  );
  const [search, setSearch] = useState<string>("");
  
  const savePreferredLocation = (ls: LocationSelection, valid: boolean) => {
    if (valid && ls?.value) {
      try {
        let loc = JSON.parse(ls.value) as SavedLocationSelection;
        setLocationSelected(true);
        saveMyLocation(ls.value, !myLocation);
        setLocation(loc);
      } catch (error) {
        console.log("Error trying to set location");
        setLocationSelected(false)
      }
    } else {
      setLocationSelected(false)
    }
  };

  const onSearchClick = () => {
    if (!locationSelected) return;
    setShow(false);
    navigate(0);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setInputValue(''); // Clear the input after submission
    onClose(); // Close the modal
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
        <Form>
          <Form.Group controlId="formBasicText">
            <LocationSetterNoForm
              setLocation={savePreferredLocation}
              button={
                <Button
                  className="button-input"
                  onClick={() => onSearchClick()}
                >
                  <IoIosSearch size={18} className="me-2" />
                  Search
                </Button>
              }
            />
            <BrowserLocationComponent isModal={true}/>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default LocationModal;