import React, { useEffect, useState } from 'react';
import { To, useNavigate, useParams } from 'react-router-dom';
import { AddRecipeFormValues, useCreateRecipeMutation, useGetRecipeQuery, useUpdateRecipeMutation } from '../../../../../services/endpoints/content/recipe';
import { useToast } from '../../../../../context/ToastContext';
import {
  Formik,
  Form as FormikForm,
  FormikErrors,
  FormikTouched,
  FormikProps,
} from "formik";
import { FadeIn } from '../../../../animations/FadeIn';
import { Col, Row, Stack } from "react-bootstrap";
import { BackButton } from "../../../../../components/buttons/BackButton";
import Stepper from "../../../../../components/stepper/Stepper";
import * as Yup from "yup";
import { SimpleButton } from "../../../../../components/buttons/SimpleButton";
import { RecipeDetailsForm } from './NewRecipeSteps/RecipeDetailsForm';
import { RecipeImagesForm } from "./NewRecipeSteps/RecipeImagesForm";
import { RecipeStatsForm } from './NewRecipeSteps/RecipeStatsForm';
import { RecipeListsForm } from './NewRecipeSteps/RecipeListsForm';
import { RecipePreparationForm } from './NewRecipeSteps/RecipePreparationForm';
import { getErrorMessage } from '../../../../../utils/utils';
import { Recipe } from '../../../../../types/lessonPlan.type';
import { userCanEditCreateDelete_Recipes } from './RecipesView';
import { getCurrentUser } from '../../../../../services/helper';
import { Ingredient } from '../../../../../types/content.type';

const steps = [
  { name: "Details", form: RecipeDetailsForm },
  { name: "Images", form: RecipeImagesForm },
  { name: "Stats", form: RecipeStatsForm },
  { name: "Lists", form: RecipeListsForm },
  { name: "Preparation", form: RecipePreparationForm },
];

export interface StepProps {
  errors: Partial<FormikErrors<AddRecipeFormValues>>;
  touched: Partial<FormikTouched<AddRecipeFormValues>>;
  values: AddRecipeFormValues;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  readOnly?: boolean;
  recipe?: Recipe;
}

function clearPayload(payload: AddRecipeFormValues) {
  const newPayload = { ...payload };
  newPayload.image_metadata?.forEach((img) => {
    img.source = img.source || "recipe";
  });
  const publicFile = newPayload.public_file;
  const privateFile = newPayload.private_file;
  if (publicFile && publicFile instanceof File && publicFile.size === 0) {
    delete newPayload.public_file;
  }
  if (privateFile && privateFile instanceof File && privateFile.size === 0) {
    delete newPayload.private_file;
  }
  for (const key in payload) {
    if (Object.prototype.hasOwnProperty.call(payload, key)) {
      const element = newPayload[key as keyof AddRecipeFormValues];
      if (
        element === undefined ||
        element === null ||
        element === "" ||
        (Array.isArray(element) && element.length === 0)
      ) {
        delete newPayload[key as keyof AddRecipeFormValues];
      }
    }
  }
  return newPayload;
}

const validationSchemas = [
  Yup.object({
    title: Yup.string().required("Title is required"),
    recipe_type: Yup.string().required("Recipe Type is required"),
    kitchen_skill_ids: Yup.array()
      .min(1, "Must select one Kitchen Skill")
      .required("Kitchen Skills are required"),
    }),
  Yup.object({}),
  Yup.object({}),
  Yup.object({
    recipe_joke_ids: Yup.array()
      .min(1, "Must select one Recipe Joke")
      .required("Recipe Jokes are required"),
  }),
];

interface AddEditRecipeProps {
  editing?: boolean;
}

function formatValuesForTextarea(items: any[], prop: string): string {
  return items.map((item) => item[prop]).join("\n");
}

function getValues(recipe?: Recipe): AddRecipeFormValues {
  let familyMethodSteps = "";
  let chefMethodSteps = "";
  let images: any = [];
  let image_metadata: any = [];
  if (recipe?.recipe_steps?.length) {
    // familyMethodSteps = recipe.recipe_steps.filter(
    //   (step) => step.recipe_step_type === 0
    // ).length
    //   ? formatValuesForTextarea(
    //       recipe.recipe_steps.filter((step) => step.recipe_step_type === 0),
    //       "content"
    //     )
    //   : "";
    familyMethodSteps = recipe.recipe_steps_parent_field ?? '';
    chefMethodSteps = recipe.recipe_steps_instructor_field ?? '';
    // chefMethodSteps = recipe.recipe_steps.filter(
    //   (step) => step.recipe_step_type === 1
    // ).length
    //   ? formatValuesForTextarea(
    //       recipe.recipe_steps.filter((step) => step.recipe_step_type === 1),
    //       "content"
    //     )
    //   : "";
  }
  if (recipe?.recipe_images?.length) {
    images = recipe.recipe_images.map(
      (img) => new File([], img.image_file_name)
    );
    image_metadata = recipe.recipe_images.map((img) => ({
      id: img.id,
      source: img.source,
      destroy: false,
    }));
  }


  return {
    ...(recipe && { id: recipe.id }),
    title: recipe?.title || "",
    author: recipe?.author || "",
    publish_status: recipe?.publish_status || "",
    recipe_type: recipe?.recipe_type || "",
    story: recipe?.story || "",
    featured_ingredient_id: recipe?.featured_ingredient_id ?? undefined,
    food_history_id: recipe?.food_history_id ?? undefined,
    country_fact_id: recipe?.country_fact_id ?? undefined,
    season: recipe?.season || "",
    cuisine_type: recipe?.cuisine_type || "",
    meal_type: recipe?.meal_type || "",
    cook_method: recipe?.cook_method || "",
    cook_time: recipe?.cook_time || undefined,
    prep_time: recipe?.prep_time || undefined,
    min_served: recipe?.min_served || undefined,
    max_served: recipe?.max_served || undefined,
    substitutions: recipe?.substitutions || "",
    images,
    image_metadata,
    kitchen_skill_ids: recipe?.kitchen_skills?.length
      ? recipe.kitchen_skills.map((skill) => skill.id)
      : [],
    recipe_joke_ids: recipe?.jokes?.length
      ? recipe.jokes.map((joke) => joke.id)
      : [],
    ingredients: recipe?.ingredients_field
      ? recipe?.ingredients_field
      : "",
    parent_equipments: recipe?.parent_equipments?.length
      ? formatValuesForTextarea(recipe?.parent_equipments, "name")
      : "",
    instructor_equipments: recipe?.instructor_equipments?.length
      ? formatValuesForTextarea(recipe?.instructor_equipments, "name")
      : "",
    recipe_steps_parent: familyMethodSteps,
    recipe_steps_instructor: chefMethodSteps,
    ...(recipe?.private_file_file_name && {
      private_file: new File([], recipe.private_file_file_name),
    }),
    ...(recipe?.public_file_file_name && {
      public_file: new File([], recipe.public_file_file_name),
    })
  };
}

export const AddEditRecipe: React.FC<AddEditRecipeProps> = ({ editing }) => {
  const currentUser = getCurrentUser();
  const params = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  // Create - Edit Endpoints
  const { data, error, isLoading, refetch }  = useGetRecipeQuery(Number(params.id!), {skip: !editing} );
  const [createRecipe] = useCreateRecipeMutation();
  const [updateRecipe] = useUpdateRecipeMutation();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<AddRecipeFormValues>(getValues());

  useEffect(() => {
    if (editing)
    {
      refetch();
    }
  },[])
  useEffect(() => {
    console.log('data', data, 'isLoading', isLoading, 'error', error);
    if (data?.recipe) {
      setInitialValues(getValues(data.recipe));
    }

    if(!userCanEditCreateDelete_Recipes(currentUser)) {
      addToast('You have no permissions to edit this data', 'error');
      navigate(`/admin/content/recipes`);
    }
  }, [data, isLoading, error]);

  const handleRegister = async (formValues: AddRecipeFormValues) => {
    const recipePayload = clearPayload(formValues);
    try {
      if (editing) {
        const res = await updateRecipe(recipePayload).unwrap();
        if (res && res.recipe) {
          navigate( -1 as To);
          addToast("Recipe updated successfully", "success");
        }
      } else {
        const res = await createRecipe(recipePayload).unwrap();

        if (res && res.recipe) {
          navigate( -1 as To);
          addToast("Recipe created successfully", "success");
        } else {
          throw new Error("A problem happened while creating recipe");
        }
      }
    } catch (e) {
      addToast(getErrorMessage(e), "error");
    }
  };

  const handleNext = async (
    validateForm: () => Promise<FormikErrors<AddRecipeFormValues>>,
    setTouched: (
      touched: FormikTouched<AddRecipeFormValues>,
      shouldValidate?: boolean
    ) => void,
    values: AddRecipeFormValues
  ) => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      setTouched({
        title: true,
        recipe_type: true,
        kitchen_skill_ids: true,
        recipe_joke_ids: true,
      });
    }
  };

  return (
    <FadeIn className="p-lg-4">
      <Stack gap={3} className="py-2 py-lg-0 px-4">
        <div>
          <BackButton
            text={`Back to recipe${editing ? " details" : "s"}`}
            color="#2B4E64"
          />
          <h2
            className="d-none d-lg-block text-info fw-bold mb-0"
            style={{ fontSize: "2.25rem" }}
          >
            {editing ? "Edit" : "New"} Recipe
          </h2>
          <Stepper
            steps={steps}
            currentStep={currentStep}
            editing={editing === true}
            onStepClick={(step) => setCurrentStep(step)}
          />
        </div>
        <div
          className="p-3 p-lg-5"
          style={{
            boxShadow:
              "0 4px 8px -2px rgba(16, 24, 40, .1),  0 4px 4px 0 rgba(0, 0, 0, .25)",
            border: "1px solid #EBEBEB",
            borderRadius: 10,
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemas[currentStep]}
            onSubmit={handleRegister}
            enableReinitialize
          >
            {({
              submitForm,
              isSubmitting,
              validateForm,
              touched,
              errors,
              setTouched,
              values,
              setFieldValue,
            }: FormikProps<AddRecipeFormValues>) => (
              <FormikForm className="text-start">
                <div style={{ minHeight: "65vh" }}>
                  {currentStep < steps.length &&
                    React.createElement(steps[currentStep]?.form, {
                      errors,
                      touched,
                      values,
                      setFieldValue,
                      ...(data && { recipe: data.recipe }),
                    })}
                </div>

                <Row className="justify-content-end mt-3">
                  <Col xs={6} lg={3}>
                    {currentStep !== 0 && (
                      <SimpleButton
                        disabled={isSubmitting}
                        type="button"
                        onClick={() => setCurrentStep(currentStep - 1)}
                        className="w-100"
                        variant="outline-primary"
                      >
                        Back
                      </SimpleButton>
                    )}
                  </Col>

                  <Col xs={6} lg={3}>
                    <SimpleButton
                      disabled={isSubmitting}
                      type="button"
                      onClick={
                        currentStep === steps.length - 1
                          ? submitForm
                          : () => handleNext(validateForm, setTouched, values)
                      }
                      className="w-100"
                      variant="primary"
                    >
                      {currentStep === steps.length - 1 ? "Finish" : "Continue"}
                    </SimpleButton>
                  </Col>
                </Row>
              </FormikForm>
            )}
          </Formik>
        </div>
      </Stack>
    </FadeIn>
  );
};
