import { CreateFormValues } from '../../../features/dash/views/places/locations/AddEditLocation';
import { GetQueryParams, GetQueryResponse } from '../../../types/api.type';
import { LedgerParams, LedgerResponse, LocationResponse, SimpleSFCLocation, WeeklyReportResponse } from '../../../types/place.types';
import { sfcApi } from '../../api';
import { objectToFormData } from '../../helper';

export const locationsApi = sfcApi.injectEndpoints({
  endpoints: (builder) => ({
    getLocation: builder.query<LocationResponse, number>({
      query: (id) => ({
        url: `locations/${id}`,
      }),
      providesTags: ['Location'],
    }),
    getAllLocations: builder.query<GetQueryResponse, GetQueryParams | void>({
      query: (params) => ({
        url: `locations`,
        method: 'POST',
        body: params?.data,
      }),
    }),
    getAllLocationsSimple: builder.query<SimpleSFCLocation[], void>({
      query: () => ({
        url: `locations/simple`,
        method: 'POST',
      }),
    }),
    createLocation: builder.mutation<LocationResponse, CreateFormValues>({

      query: (data) => {
        delete data.id;
        let payload: any = {
          url: `locations/create`,
          method: 'POST',
          body: objectToFormData(data),
        }
        return payload;
      },
    }),
    updateLocation: builder.mutation<LocationResponse, CreateFormValues>({
      query: (data) => {
        const { id } = data;
        // delete data.id;
        let payload: any = {
          url: `locations/update/${id}`,
          method: 'PUT',
          body: objectToFormData(data),
        }
        return payload;
      },
      invalidatesTags: ['Location'],
    }),
    getLocationLedger: builder.query<LedgerResponse, LedgerParams>({
      query: (params) => ({
        url: `locations/ledger`,
        method: 'POST',
        body: params,
      }),
    }),
    getLocationWeeklyReport: builder.query<WeeklyReportResponse, {id: number}>({
      query: (params) => ({
        url: `locations/weekly-report`,
        method: 'POST',
        body: params,
      }),
    }),
  }),
});

export const {
  useGetLocationQuery,
  useGetAllLocationsQuery,
  useGetAllLocationsSimpleQuery,
  useCreateLocationMutation,
  useUpdateLocationMutation,
  useGetLocationLedgerQuery,
  useGetLocationWeeklyReportQuery,
} = locationsApi;
