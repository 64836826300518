import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { isGlobal } from '../../../../../utils/consts';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { Scopes, User } from '../../../../../services/endpoints/people/user';
import { currentUserHasRole, getCurrentUser } from '../../../../../services/helper';
import { trainingVideo } from '../../../../../types/trainingVideos.type';
import { useGetTrainingVideosQuery } from '../../../../../services/endpoints/content/trainingVideo';
import { Button, Modal, Stack } from 'react-bootstrap';
import { LinkCell } from '../../../../../components/tables/cellComponents/LinkCell';
import { ColumnDef } from '@tanstack/react-table';
import { Document } from '../../../../../types/content.type';
import VideoModal from '../../../../../components/modals/VideoModal';
// import { trainingVideosColumns } from '../../../../../utils/consts/columnDefs/trainingVideos';

const defaultScope = 'active';

export const userCanEditCreateArchive_TrainingVideos = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin || user?.isGlobal) {
    return true;
  }

  return false;
}

export const userCanView_TrainingVideos = (user: User | null) => {
  if(user?.isAdmin || user?.isSuper || user?.isSupremeAdmin || user?.isGlobal || user?.isLocationAdmin) {
    return true;
  }

  return false;
}

export const TrainingVideosView = () => {
  const { addToast } = useToast();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: {page: 1, entries: 10} });
  const [scopes, setScopes] = useState<Scopes>({});
  const { data, error, isLoading, isFetching } = useGetTrainingVideosQuery(filters);
  const [trainingVideo, setTrainingVideo] = useState<trainingVideo[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const navigate: NavigateFunction = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [urlVideo,setUrlVideo]=useState("");
  const currentUser = getCurrentUser();

const trainingVideosColumns: ColumnDef<Document>[] = [
  {
    header: "ID",
    accessorKey: "id",
    cell: ({ getValue }) => (
      <LinkCell
        content={getValue<number>()}
        url={`/admin/training/videos/${getValue<number>()}`}
      />
    ),
  },
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Category",
    accessorKey: "category",
  },
  {
    header: "Code",
    accessorKey: "code",
    cell: ({ row }) => (
      <Button variant="primary" onClick={() => {
        setUrlVideo('https://www.youtube.com/embed/'+row.original.code)
      setModalShow(true)
      }
      }>
      View video
    </Button>
    ),
  },
  {
    header: () => null,
    id: "actions",
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell
          content="View"
          url={`/admin/training/videos/${row.original.id}`}
          underline
        />

        {userCanEditCreateArchive_TrainingVideos(currentUser) && (
          <>
            <LinkCell
              content="Edit"
              url={`/admin/training/videos/${row.original.id}/edit`}
              underline
            />
            <LinkCell
              content="Delete"
              url={`/admin/training/videos/${row.original.id}/delete`}
              underline
            />
          </>
        )}
      </Stack>
    ),
  },
];






  const filterFormFields: FilterField[] = [
    {
      name: 'name',
      label: 'Name',
    },
  ];

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => {
          let scope_count = 0;
          if(data.scope_counts != null) {
            scope_count = data.scope_counts[scope] ?? 0;
          }
          return {
            title: `${title} (${scope_count})`,
            key: scope,
          }
        })));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
       
        setTrainingVideo(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <VideoModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        urlVideo={urlVideo}
      />
      <SFCTable
        name={`${scopes[filters?.scope!] ?? ''} Videos`}
        columns={trainingVideosColumns}
        data={trainingVideo}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
        newItemEl={(
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={250}
            onClick={() => navigate('/admin/training/videos/new')}
          >
            New Video
          </SimpleButton>
        )}
      />
    </div>
  );
}
