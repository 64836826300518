import React from 'react';
import { Button, Stack } from 'react-bootstrap';
import { useActionsWaitlistMutation } from '../../../services/endpoints/schedule/waitlist';
// import { useToast } from '../../../context';
import { toast } from 'react-toastify';

// import { useToast } from '../../../context';
const WaitlistActionsCell = ({ row }: { row: any }) => {
  const [actionsWaitlistMutation] = useActionsWaitlistMutation();
  // const { addToast } = useToast();
  const handleDeny = async () => {
    console.log('Deny clicked');
    try {
      const orderPayload = {
        id: Number(row.original.waitlists.id),
        action: 'deny',
      };
      const response = await actionsWaitlistMutation(orderPayload).unwrap();
      if (response) {
        toast.success('Waitlist action Deny completed!');
      } else {
        toast.error('Waitlist action Deny failed!');
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const handleInvite = async () => {
    console.log('Deny clicked');
    try {
      const orderPayload = {
        id: Number(row.original.waitlists.id),
        action: 'invite',
      };
      const response = await actionsWaitlistMutation(orderPayload).unwrap();
      if (response) {
        toast.success('Waitlist action Invite completed!');
      } else {
        toast.error('Waitlist action Invite failed!');
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  return (
    <Stack direction="horizontal" gap={2}>
      <Button onClick={handleInvite}>Invite</Button>
      <Button onClick={handleDeny}>Deny</Button>
    </Stack>
  );
};

export default WaitlistActionsCell;
