import { ButtonData } from "../../../../../components/buttonGroup/ButtonGroup";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import SFCTable from "../../../../../components/tables/SFCTable";
import { useToast } from "../../../../../context/ToastContext";
import { GetQueryParams } from "../../../../../types/api.type";
import { Scopes } from "../../../../../services/endpoints/people/user";
import { Invoice } from "../../../../../types/finance.types";
import { useGetVenuesQuery } from "../../../../../services/endpoints/places/venue";
import { getCurrentUser } from "../../../../../services/helper";
import { useDataContext } from "../../../../../context";
import { Button, Stack, Form, Card, Badge } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa6";
import { Link, useNavigate, useParams, useLocation, To } from "react-router-dom";
import { SimpleButton } from "../../../../../components/buttons/SimpleButton";
import {
  useAddClientInvoicerMutation,
  useAddParentMutation,
  useAddRemitInvoceMutation,
  useAppendRemitInvoceMutation,
  useGetAllInvoicersQuery,
  useGetCourseInvoicerQuery,
} from "../../../../../services/endpoints/finance/invoicer";
import { Course } from "../../../../../types/course.type";
import { InvoiceClient } from "../../../../../types/content.type";
import Table from "react-bootstrap/Table";
import { Organization } from "../../../../../types/venue.type";
import { userCanAdmin_Invoices } from "../Invoices/InvoicesView";
import { number } from "yup";

const defaultScope = "open";
const currentUser = getCurrentUser();

export const InvoicerView = () => {
  const params = useParams();

  const { addToast } = useToast();
  const navigate = useNavigate();

  const { locationOptions } = useDataContext();
  const [filters, setFilters] = useState<GetQueryParams>({
    scope: defaultScope,
  });
  const [scopes, setScopes] = useState<Scopes>({});
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const isGlobalUser = currentUser?.isGlobal;
  const isFinanceAdmin = currentUser?.canSeeFinanceMenu;
  const location = useLocation();

  const { data } = useGetCourseInvoicerQuery(location?.state?.course?.id || +params?.id!);
  const [course, setCourse] = useState<Course>(location?.state?.course || {});
  const [createInvoicerClient] = useAddClientInvoicerMutation();
  const [addParentInvoicer] = useAddParentMutation();
  const [addRemit] = useAddRemitInvoceMutation();
  const [appendRemit] = useAppendRemitInvoceMutation();
  const [selectedOption, setSelectedOption] = useState<number>();
  const [invoiceClients, setInvoiceClients] = useState<InvoiceClient[]>();
  const [organizations, setOrganizations] = useState<Organization[]>();
  const [sendInvoicer, setSendInvoicer] = useState<boolean>(false);

  // Handle the radio button change event
  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(Number(event.target.value));
  };

  // Handle form submission to retrieve the selected option
  const handleSubmit = () => {
    alert(`Selected option: ${selectedOption}`);
  };

  useEffect(() => {
    if (data && data?.course?.venue?.invoice_clients) {
      setInvoiceClients(data?.course?.venue?.invoice_clients);
      setOrganizations(data?.course?.venue?.organizations);
    }
  }, [data]);

  // const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
  //   setFilters((prev) => ({
  //     ...prev,
  //     data: {
  //       ...prev!.data,
  //       filterBy,
  //     }
  //   }))
  // };

  useEffect(() => {
    if (course && course.id) {
      setCourse(course);
    }
  }, [course]);

  const addClient = async () => {
    try {
      const res = await createInvoicerClient({
        course_id: course?.id,
        invoiceable_id: course?.venue_id,
        invoiceable_type: "Venue",
      }).unwrap();
      // console.log('res', res);
      window.location.reload();
    } catch (e) {
      addToast(e.message, 'error');
    }
    
  };

  const addOrganization = async (org_id: any) => {
    try{
      const res = await createInvoicerClient({
        course_id: course?.id,
        invoiceable_id: org_id,
        invoiceable_type: "Organization",
      }).unwrap();
      // console.log('res', res);
      window.location.reload();
    } catch (e) {
      addToast(e.message, 'error');
    }
    
  };

  const addParent = async () => {
    const res = await addParentInvoicer({
      course_id: course?.id,
      invoiceable_id: course?.venue_id,
      invoiceable_type: "Venue",
    }).unwrap();
    window.location.reload();

  };


  const addRemitInvocer = async () => {
    setSendInvoicer(true);
    if (!selectedOption) {
      addToast("Select a client", "error");
      setSendInvoicer(false)
      return;
    }

    const res = await addRemit({
      course_id: course?.id,
      invoice_client_id: Number(selectedOption),
    }).unwrap();

    addToast("Invoice added correctly", "success");
    window.location.reload();
  };

  const appendInvoice = async (id: number) => {
    const res = await appendRemit({
      course_id: course?.id,
      invoice_id: Number(id),
    }).unwrap();
    addToast(`InvoiceClient updated succesfully`, "success");
    window.location.reload();
  };
  const countPaidInvoices = () => {
    let paidInvoices = 0;
    organizations?.forEach((organization) => {
      organization.invoice_clients?.forEach((invoiceClient) => {
        paidInvoices = paidInvoices + invoiceClient.invoices?.filter(invoice => invoice.status === "paid").length
      });
    });

    return paidInvoices;
  };
  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4 px-lg-5 py-3 border-bottom"
        style={{ background: "#EFEFEF" }}
      >
        <Stack direction="horizontal" gap={2} className="ps-0 ps-lg-3">
          <button
            className="btn btn-link p-0 text-info"
            onClick={() => navigate(-1 as To)}
          >
            <FaArrowLeft size={20} className="d-lg-none" />
            <FaArrowLeft size={24} className="d-none d-lg-block" />
          </button>
          <h2
            className="d-lg-none text-secondary fw-semibold mb-0 pt-1 text-uppercase"
            style={{ color: "#888888", fontSize: "1rem" }}
          >
            Sessions List
          </h2>
          <h2 className="d-none d-lg-block text-info fw-bold mb-0">Invoicer</h2>
        </Stack>
        <Stack direction="horizontal" className="d-none d-lg-flex">
          {/* <SimpleButton variant="primary" height={36}>
            Generate invoice
          </SimpleButton> */}
        </Stack>
      </div>

      <div className="py-2">
        <Card>
          <Card.Body>
            <p>
              <a href="#" onClick={() => {

                const queryParams = new URLSearchParams({
                  status: 'paid',
                  course_invoices_course_id_eq: `${course.id}`,
                });
                navigate(`/admin/finance/invoices/?${queryParams.toString()}`);

              }} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>
                {countPaidInvoices()} Invoices
              </a>
              {"  have been paid for this session."}
            </p>

            {(organizations && organizations?.length > 0) &&
            organizations.map(org => (
              <Card.Title>
                Organization: {org.name}{" "}
                <Button variant="primary" onClick={() => addOrganization(org.id?? 0)}>
                  Add client
                </Button>
              </Card.Title>
            ))
              }

            {(organizations && organizations?.length > 0) && <Form>
              {organizations &&
                organizations?.map((organization) => {
                  const organizationsInvoiceClients =
                    organization?.invoice_clients;
                  return (
                    <>
                      {organizationsInvoiceClients &&
                        organizationsInvoiceClients?.map((client) => {
                          return (
                            <>
                              <Form.Check
                                type="radio"
                                label={`Client # ${client?.id}: ${client?.name}`}
                                value={`${client?.id}`}
                                name="radioGroup"
                                checked={selectedOption === client?.id}
                                onChange={handleRadioChange}
                                style={{ color: "black" }}
                              />
                              {client?.invoices?.length > 0 && (
                                <Table striped bordered hover>
                                  <thead>
                                    <tr>
                                      <th>Invoice</th>
                                      <th>Sessions</th>
                                      <th>Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {client?.invoices?.length &&
                                      client?.invoices?.map((invoice) => {
                                        return (
                                          <tr>
                                            <td>{`Invoice: # ${invoice?.id}: ${invoice?.name}`}</td>
                                            <td>{invoice?.name}</td>
                                            <td>
                                              <Stack direction="horizontal" gap={2}>
                                                <Button>{invoice?.status}</Button>
                                                <Button
                                                  onClick={() =>
                                                    appendInvoice(invoice?.id)
                                                  }
                                                >
                                                  <span style={{ marginRight: '5px', fontWeight: 'bold', fontSize: '15px' }}>+</span> Append
                                                </Button>
                                              </Stack>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </Table>
                              )}
                            </>
                          );
                        })}
                    </>
                  );
                })}
            </Form>}
            <hr></hr>
            <Card.Title>
              Venue: {course?.venue?.title}{" "}
              <Button variant="primary" onClick={() => addClient()}>
                Add client
              </Button>
            </Card.Title>

            <Form>
              {invoiceClients &&
                invoiceClients?.map((client) => {
                  return (
                    <>
                      <Form.Check
                        type="radio"
                        label={`Client # ${client?.id}: ${client?.name}`}
                        value={`${client?.id}`}
                        name="radioGroup"
                        checked={selectedOption === client?.id}
                        onChange={handleRadioChange}
                        style={{ color: "black" }}
                      />
                      {client?.invoices?.length > 0 && (
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Invoice</th>
                              <th>Sessions</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {client?.invoices?.length &&
                              client?.invoices?.map((invoice) => {
                                return (
                                  <tr>
                                    <td>{`Invoice: # ${invoice?.id}: ${invoice?.name}`}</td>
                                    <td>{invoice?.name}</td>
                                    <td>

                                      {invoice?.status == 'late' &&
                                        (
                                          <Badge bg="danger"> <h3 style={{ color: 'white' }}>Late</h3> </Badge>
                                        )
                                      }
                                      {invoice?.status == 'draft' &&
                                        (
                                          <Badge bg="info"> <h3 style={{ color: 'white' }}>Draft</h3> </Badge>
                                        )
                                      }

                                      {/* <Button>{invoice?.status}</Button> */}
                                      <Button
                                        onClick={() =>
                                          appendInvoice(invoice?.id)
                                        }
                                      >
                                        Append
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                      )}
                    </>
                  );
                })}
            </Form>

            <Card.Text>
              Parents: {course?.title} Clients{" "}
              <Button variant="primary" onClick={() => addParent()}>
                Add parents
              </Button>
            </Card.Text>
            {userCanAdmin_Invoices(currentUser) && (
              <Button variant="primary" onClick={addRemitInvocer} disabled={sendInvoicer}>
                Generate invoice
              </Button>
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
