import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { waiverColumns } from '../../../../../utils/consts/columnDefs/waiverColumns';
import { useGetAllWaiversQuery } from '../../../../../services/endpoints/people/waiver';
import { User, Waiver } from '../../../../../services/endpoints/people/user';
import { getCurrentUser } from '../../../../../services/helper';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { FormSelect } from '../../../../../components/tables/filterForms/FormSelect';
import { useGetAllChildrenQuery } from '../../../../../services/endpoints/people/child';
import { useGetCoursesSimpleQuery } from '../../../../../services/endpoints/schedule/course';

const defaultScope = '';
const currentUser = getCurrentUser();

export const userCanEditCreateDelete_Waviers = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin) {
    return true;
  }

  return false;
}

export const userCanView_Waviers = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin || user?.isInstructor) {
    return true;
  }

  return false;
}

export const WaiversView = () => {
  const { addToast } = useToast();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: {page: 1, entries: 10} });
  const { data, error, isLoading, isFetching } = useGetAllWaiversQuery(filters);
  const { data: courses, error: coursesError, isLoading: coursesLoading } = useGetCoursesSimpleQuery();
  const [waivers, setWaivers] = useState<Waiver[]>([]);
  const navigate: NavigateFunction = useNavigate();
  const isGlobalUser = (currentUser?.isGlobal);

  const filterFormFields: FilterField[] = [ 
    {
      name: 'course_id',
      type: 'number',
      element: (
        <FormSelect
          name="course_id"
          label="Session"
          options={courses?.results.map(c => ({ value: c.id, label: c.title})) ?? []}
        />
      ),
    },
    {
      name: 'child_first_name',
      label: 'Child First Name',
    },
    {
      name: 'child_last_name',
      label: 'Child Last Name',
    },
    {
      name: 'parent_first_name',
      label: 'Parent First Name',
    },
    {
      name: 'parent_last_name',
      label: 'Parent Last Name',
    },
  ];

  useEffect(() => {
    if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
      setWaivers(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`Waivers`}
        columns={waiverColumns}
        data={waivers}
        count={data?.count}
        defaultScope={defaultScope}
        isLoading={isLoading}
        isFetching={isFetching}
        indexDownloadPath='waivers'
        filters={filters?.data?.filterBy}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
      />
    </div>
  );
}
