import "../FranchiseLayout.css";
import { Container } from "react-bootstrap";
import { Button, Image } from "react-bootstrap";
import About1 from "../../../assets/images/about-us.png";
import About2 from "../../../assets/images/about-us.png";
import About3 from "../../../assets/images/about-us.png";
import Dots from "../../../assets/images/dots.png";
import Inspiration from "../../../assets/images/inspiration.png";
import Innovation from "../../../assets/images/innovation.png";
import Community from "../../../assets/images/community.png";
import Impact from "../../../assets/images/impact.png";
import Trust from "../../../assets/images/trust.png";
import Recipe from "../../../assets/images/recipe-impact.png";
import Pic from "../../../assets/images/Pic.png";
import IconInstagram from "../../../assets/images/instagram.png";
import IconFacebook from "../../../assets/images/facebook.png";
import IconMail from "../../../assets/images/mail.png";
import IconTelephone from "../../../assets/images/telephone.png";
import { FaArrowRight } from "react-icons/fa6";
import { Franchise } from "../../../types/franchise";
import { SFCLocation } from "../../../types/place.types";
import Slider from "react-slick";

interface FranchiseProps {
  location: SFCLocation | undefined;
}

const carouselSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
};

const imgSlides = [About1, About2, About3];
export const AboutUsSubpage: React.FC<FranchiseProps> = ({location}) => {
  return (
    <Container className="pb-5 text-blue ">
      <div className="col-12 d-flex about-us-container">
        <div className="col-md-5 col-sm-12 align-content-center welcome-text">
          <p className="mt-5 text-weight-700  ">
            Welcome to Sticky Fingers Cooking!
          </p>
          <h1 className="my-3  text-weight-700 text-blue">
            {location?.title}
          </h1>
          <h3>
            Inspire your kids to love healthy foods & turn your picky
            eater into a food explorer.
          </h3>

          <Button className="text-uppercase px-5 py-3 mb-5 bg-orange">
            Find Our Classes
            <FaArrowRight className="ms-2" size={22} />
          </Button>

          <div>
            <h5 className=" text-weight-700">Contact Us</h5>
            <div className="d-flex flex-nowrap ">
              <Image src={Pic} className="pic-container"></Image>
              <div>
                <h4 className="px-3 ">
                  <strong>{location?.location_information?.franchise_owner_first_name} {location?.location_information?.franchise_owner_last_name}</strong>
                  &nbsp;·&nbsp;Franchise owner
                </h4>
                <div className="d-flex flex-nowrap">
                  <Image
                    src={IconTelephone}
                    className="icons-contact"
                  ></Image>
                  <p className="px-3 margin-none">{location?.phone_number}</p>
                </div>
                <div className="d-flex flex-nowrap">
                  <Image
                    src={IconMail}
                    className="icons-contact"
                  ></Image>
                  <p className="px-3 margin-none">
                    {location?.location_information?.franchise_owner_email}
                  </p>
                </div>
                {location?.location_information?.facebook && (
                  <div className="d-flex flex-nowrap">
                    <Image
                      src={IconFacebook}
                      className="icons-contact"
                    ></Image>
                    <a className="px-3 margin-none" href={location?.location_information?.facebook} target="_blank" rel="noreferrer">
                      {location?.location_information?.facebook}
                    </a>
                  </div>
                )}

                {location?.location_information?.instagram && (
                  <div className="d-flex flex-nowrap">
                    <Image
                      src={IconInstagram}
                      className="icons-contact"
                    ></Image>
                    <a className="px-3 margin-none" href={location?.location_information?.instagram} target="_blank" rel="noreferrer">
                      {location?.location_information?.instagram}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="about-us-slider mt-5 offset-min ">

          <div className="carousel-container-about-us">
            <Slider {...carouselSettings}>
              {imgSlides.map((item, index) => (
                <div key={index} className="carousel-item">
                  <Image src={item}></Image>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      <div className=" w-100">
        <div className="my-5 d-flex justify-content-center">
          <Image src={Dots} className=""></Image>
        </div>
        <h3 className="text-align-center w-100 text-weight-700 ">
          Everywhere You Want Us to Be!
        </h3>
        <h5 className="mt-5 text-weight-700  ">
          Welcome to Sticky Fingers Cooking®, your home-grown kids'
          cooking school! We don’t have a fixed location; we are
          everywhere you want us to be. Whether it's at your school,
          camp, community center or your child’s birthday party, we
          bring the fun and education of cooking directly to you.
        </h5>
        <div className="w-100 d-flex justify-content-center">
          <Button className="button-header justify-content-center text-uppercase">
            Why we’re Grape
          </Button>
        </div>
      </div>

      <div className=" w-100">
        <div className="my-5 d-flex justify-content-center">
          <Image src={Dots} className=""></Image>
        </div>

        <h3 className="text-align-center w-100 text-weight-700 ">
          Who We Are
        </h3>

        <h5 className="text-weight-700">
          {location?.location_information?.who_we_are}
        </h5>

        <div className="d-flex flex-nowrap my-5">
          <Image src={Pic} className="pic-container"></Image>
          <div className="contact-container">
            <h4 className="px-3 w-100 contact-text">
              - <strong>{location?.manager?.full_name}</strong> 
              &nbsp;·&nbsp;{/*{franchise.contactRole} */}
            </h4>
            <div className="d-flex flex-nowrap">
              <Image
                src={IconTelephone}
                className="icons-contact"
              ></Image>
              <p className="px-3 margin-none">{location?.phone_number}</p>
            </div>
            |
            <div className="d-flex flex-nowrap">
              <Image
                src={IconMail}
                className="icons-contact"
              ></Image>
              <p className="px-3 margin-none">
                {location?.location_information?.franchise_owner_email}
              </p>
            </div>
            |
            {location?.location_information?.facebook && (
              <div className="d-flex flex-nowrap">
                <Image
                  src={IconFacebook}
                  className="icons-contact"
                ></Image>
                <a className="px-3 margin-none" href={location?.location_information?.facebook} target="_blank" rel="noreferrer">
                  {location?.location_information?.facebook}
                </a>
              </div>
            )}

            {location?.location_information?.instagram && (
              <div className="d-flex flex-nowrap">
                <Image
                  src={IconInstagram}
                  className="icons-contact"
                ></Image>
                <a className="px-3 margin-none" href={location?.location_information?.instagram} target="_blank" rel="noreferrer">
                  {location?.location_information?.instagram}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className=" w-100">
        <div className="my-5 d-flex justify-content-center">
          <Image src={Dots} className=""></Image>
        </div>

        <h3 className="text-align-center w-100 text-weight-700 mb-5">
          Our Core Values + Mission
        </h3>
        <div className="w-100 d-flex core-values justify-content-center">
          <div className="box-values text-uppercase d-flex justify-content-center flex-wrap px-3 py-3">
            <div className="box-image-container">
              <Image src={Trust} className="box-values-image"></Image>
            </div>
            Trust
          </div>
          <div className="box-values text-uppercase d-flex justify-content-center flex-wrap px-3 py-3">
            <div className="box-image-container">
              <Image
                src={Impact}
                className="box-values-image"
              ></Image>
            </div>
            Impact
          </div>
          <div className="box-values text-uppercase d-flex justify-content-center flex-wrap px-3 py-3">
            <div className="box-image-container">
              <Image
                src={Innovation}
                className="box-values-image"
              ></Image>
            </div>
            Innovation
          </div>
          <div className="box-values text-uppercase d-flex justify-content-center flex-wrap px-3 py-3">
            <div className="box-image-container">
              <Image
                src={Community}
                className="box-values-image"
              ></Image>
            </div>
            Community
          </div>
          <div className="box-values text-uppercase d-flex justify-content-center flex-wrap px-3 py-3">
            <div className="box-image-container inspiration-img">
              <Image
                src={Inspiration}
                className="box-values-image"
              ></Image>
            </div>
            Inspiration
          </div>
        </div>

        <div className="w-100 mt-5">
          <h5 className="text-weight-700 text-align-center">
            At Sticky Fingers Cooking®, we are mission focused on
            Cultivating ‘Cool’inary Curiosity in Kids®—supporting
            lifelong skills in preparing, eating, and sharing
            nutritious foods. Because it's Thyme to Turnip the Beet on
            what kids eat®.
          </h5>
        </div>

        <div className="my-5 d-flex justify-content-center">
          <Image src={Dots} className=""></Image>
        </div>

        <div className="w-100 mt-5 ">
          <div className="d-flex justify-content-center recipe-image">
            <Image src={Recipe} className="recipe-image"></Image>
          </div>
          <h3 className="text-weight-700 text-align-center mb-5">
            Our Recipe for Community Impact
          </h3>

          <h5 className="text-weight-700">
            Sticky Fingers Cooking® is dedicated to making a
            deliciously meaningful impact in our communities. With
            hundreds of thousands of students taught in thousands of
            schools and venues across the U.S., we’re proud to enrich
            children’s lives through our mobile cooking school model.
            <ul className="mt-3">
              <ol>
                <strong>Community Outreach: </strong> We offer our
                programs to a wide range of schools, libraries, camps,
                community centers, and more, spreading the joy of
                cooking and healthy eating.
              </ol>
              <ol>
                <strong>Giving Back: </strong> Giving back to our
                community is baked into our company culture. We
                proudly reinvest in the communities we serve,
                enriching lives and supporting local initiatives.
              </ol>
            </ul>
            If you’re interested in bringing Sticky Fingers Cooking®
            to your school, camp, or organizing a fun birthday party,
            get in touch with us. We look forward to creating
            delightful culinary experiences and Cultivating
            'COOL'INARY CURIOSITY in Kids® in our community. LETTUCE
            make a difference together!
          </h5>
        </div>
      </div>
    </Container>
  )
}