import { ButtonData } from '../../../../../components/buttonGroup/ButtonGroup';
import { useEffect, useState } from 'react';
import SFCTable from '../../../../../components/tables/SFCTable';
import { useToast } from '../../../../../context/ToastContext';
import { GetQueryParams } from '../../../../../types/api.type';
import { useGetCoursesQuery } from '../../../../../services/endpoints/schedule/course';
import { Course } from '../../../../../types/course.type';
import { FormSelect, FormSelectOptions } from '../../../../../components/tables/filterForms/FormSelect';
import { FilterField, FilterForm } from '../../../../../components/tables/filterForms/FilterForm';
import { SearchField } from '../../../../../components/tables/filterForms/SearchField';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { SimpleButton } from '../../../../../components/buttons/SimpleButton';
import { TimeField } from '../../../../../components/tables/filterForms/TimeField';
import { DateField } from '../../../../../components/tables/filterForms/DateField';
import { sessionColumns, sessionInvoiceColumns, sessionOtherColumns, simpleScheduleColumns } from '../../../../../utils/consts/columnDefs/sessionColumns';
import { Scopes, User } from '../../../../../services/endpoints/people/user';
import { dateInTimeZone, UTCToDate } from '../../../../../utils/dateUtils';
import { Lesson } from '../../../../../types/lesson.type';
import { getCurrentUser } from '../../../../../services/helper';
import { DetailSection, LabelText } from '../../../../../components/generic';
import CurrencyCell from '../../../../../components/tables/cellComponents/CurrencyCell';
import { useDataContext } from '../../../../../context';
import { useGetVenuesQuery } from '../../../../../services/endpoints/places/venue';
import { useGetThemesQuery } from '../../../../../services/endpoints/content/theme';
import { useGetAllOrganizationsQuery, useGetOrganizationQuery } from '../../../../../services/endpoints/places/organization';

const defaultScope = 'upcoming';
const currentUser = getCurrentUser();

export const userCanEditCreateDelete_Sessions = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin || user?.isAdmin || user?.isGlobal || user?.isLocationAdmin) {
    return true;
  }

  return false;
}

export const userCanView_Sessions = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin || user?.isAdmin || user?.isGlobal || user?.isLocationAdmin || user?.isInstructor) {
    return true;
  }

  return false;
}

export const userCanGenerateInvoice_Sessions = (user: User | null) => {
  if(user?.isSuper || user?.isSupremeAdmin) {
    return true;
  }

  return false;
}

export const courseTypeOptions: FormSelectOptions[] = [
  {
    value: 'after_school',
    label: 'After School'
  },
  {
    value: 'camp',
    label: 'Camp'
  },
  {
    value: 'event',
    label: 'Event'
  }
];

export const statusOptions: FormSelectOptions[] = [
  {
    value: 'cancelled',
    label: 'Cancelled'
  },
  {
    value: 'offline',
    label: 'Offline'
  },
  {
    value: 'open',
    label: 'Open'
  }
];

export const paymentTypeOptions: FormSelectOptions[] = [
  {
    value: 'website',
    label: 'Website'
  },
  {
    value: 'onsite',
    label: 'Onsite'
  }
];

export const isOnlineOptions: FormSelectOptions[] = [
  
  {
    value: 'online',
    label: 'Online'
  },
  {
    value: 'in_person',
    label: 'In-person'
  }
];

export const invoiceTypeOptions: FormSelectOptions[] = [
  {
    value: 'per_student',
    label: 'Per student'
  },
  {
    value: 'flat_rate',
    label: 'Flat rate'
  }
];

export const payTypeOptions: FormSelectOptions[] = [
  {
    value: 'flat_rate',
    label: 'Flat Rate'
  },
  {
    value: 'per_hour',
    label: 'Per hour'
  }
];

export const affirmativeOptions: FormSelectOptions[] = [
  {
    label: 'Yes',
    value: "true"
  },
  {
    label: 'No',
    value: "false"
  }
];

let numberReciptsOptions: FormSelectOptions[] = [];
for(let i=0; i<6; i++) numberReciptsOptions.push({label: `${i}`, value: i})


export const SessionsView = () => {
  const { addToast } = useToast();
  const { locationOptions } = useDataContext();
  // const { cour } = useDataContext();
  const [filters, setFilters] = useState<GetQueryParams>({ scope: defaultScope, data: {page: 1, entries: 20} });
  const [scopes, setScopes] = useState<Scopes>({});
  const { data, error, isLoading, isFetching } = useGetCoursesQuery(filters);
  const [courses, setCourses] = useState<Course[]>([]);
  const [buttons, setButtons] = useState<ButtonData[][]>();
  const navigate: NavigateFunction = useNavigate();
  const isGlobalUser = (currentUser?.isGlobal);
  const isFinanceViewerUser = (currentUser?.canSeeFinanceMenu);
  
  const { data: venueOptionsData } = useGetVenuesQuery(); 

  let venueOptions: FormSelectOptions[] = [];

  if(venueOptionsData) {
    venueOptions = venueOptionsData.results.map(v => {
      let ret: FormSelectOptions = {
        value: v.id,
        label: v.title
      }

      return ret;
    });
  }

  const { data: themeOptionsData } = useGetThemesQuery();

  let themeOptions: FormSelectOptions[] = [];

  if(themeOptionsData) {
    themeOptions = themeOptionsData.results.map((v) => ({value: v.id, label: v.name}));
  }

  const { data: organizationOptionsData } = useGetAllOrganizationsQuery();

  let organizationOptions: FormSelectOptions[] = [];

  if(organizationOptionsData) {
    organizationOptions = organizationOptionsData.results.map(({id, name}) => ({value: id, label: name})).sort((a, b) => a.label.localeCompare(b.label));
  }

  const filterFormFields: FilterField[] = [
    {
      name: 'title',
      element: (
        <SearchField name="title" label="Session Title" placeholder="Search session" />
      )
    },
    {
      name: 'id',
      label: 'ID',
      type: 'number',
    },
    {
      name: 'venue_id',
      element: (
        <FormSelect
          name="venue_id"
          label="Venue"
          options={venueOptions}
        />
      )
    },
    {
      name: 'instructor_full_name',
      label: 'Instructor',
    },
    {
      name: 'location_id',
      element: (
        <FormSelect
          name="location_id"
          label="Location"
          options={locationOptions}
        />
      ),
    },
    {
      name: 'organization_id',
      element: (
        <FormSelect
          name="organization_id"
          label="Organization"
          options={organizationOptions}
        />
      ),
    },
    {
      name: 'status',
      element: (
        <FormSelect
          name="status"
          label="Status"
          options={statusOptions}
        />
      )
    },
    {
      name: 'course_type',
      element: (
        <FormSelect
          name="course_type"
          label="Session Type"
          options={courseTypeOptions}
        />
      )
    },
    {
      name: 'payment_type',
      element: (
        <FormSelect
          name="payment_type"
          label="Payment Type"
          options={paymentTypeOptions}
        />
      )
    },
    {
      name: 'is_online',
      element: (
        <FormSelect
          name="is_online"
          label="Program Type"
          options={isOnlineOptions}
        />
      )
    },
    {
      name: 'invoice_type',
      element: (
        <FormSelect
          name="invoice_type"
          label="Invoice Type"
          options={invoiceTypeOptions}
        />
      )
    },
    {
      name: 'is_onsite_enrollment_locked',
      element: (
        <FormSelect
          name="is_onsite_enrollment_locked"
          label="Uses Enrollment Code"
          options={affirmativeOptions}
        />
      )
    },
    {
      name: 'pay_type',
      element: (
        <FormSelect
          name="pay_type"
          label="Instructor Pay Type Filter"
          options={payTypeOptions}
        />
      )
    },
    {
      name: 'can_be_prorated',
      element: (
        <FormSelect
          name="can_be_prorated"
          label="Prorated"
          options={affirmativeOptions}
        />
      )
    },
    // {
    //   name: 'is_onsite_enrollment_locked',
    //   element: (
    //     <FormSelect
    //       name="is_onsite_enrollment_locked"
    //       label="Onsite Enrollment Lock"
    //       options={affirmativeOptions}
    //     />
    //   )
    // },
    {
      name: 'venue_id',
      label: 'Venue ID',
      type: 'number',
    },
    {
      name: 'theme',
      element: (
        <FormSelect
          name="theme_id"
          label="Theme"
          options={themeOptions}
        />
      )
    },
    // {
    //   name: 'time',
    //   element: (
    //     <TimeField name="formatted_time" label="Time" />
    //   )
    // },
    {
      name: 'starts_from',
      id: 'starts_on',
      operator: 'greater_equal_than',
      element: (
        <DateField name="starts_from" label="Starts On [From - To]" />
      )
    },
    {
      name: 'starts_to',
      id: 'starts_on',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="starts_to" />
      )
    },
    {
      name: 'ends_from',
      id: 'ends_on',
      operator: 'greater_equal_than',
      element: <DateField name="ends_from" label="Ends On [From - To]" />
    },
    {
      name: 'ends_to',
      id: 'ends_on',
      operator: 'lesser_equal_than',
      element: <DateField name="ends_to" />
    },
    {
      name: 'created_at_from',
      id: 'created_at',
      operator: 'greater_equal_than',
      element: (
        <DateField name="created_at_from" label="Created At [From - To]" />
      )
    },
    {
      name: 'created_at_to',
      id: 'created_at',
      operator: 'lesser_equal_than',
      element: (
        <DateField name="created_at_to" />
      )
    },
    {
      name: 'day_of_week',
      label: 'Day of Week',
    },
    {
      name: 'receipt_count',
      element: (
        <FormSelect
          name="receipt_count"
          label="Number of Receipts"
          options={numberReciptsOptions}
        />
      )
    }
  ];

  const sidebars = (filters?.scope === "needs_invoice" && isFinanceViewerUser) ? ([
    <DetailSection title="Summary">
      <LabelText label="Total Amount Receivable:" >
        <CurrencyCell value={String(data?.totals?.total_receivable)} />
      </LabelText>
      <LabelText label="Total Amount Invoiced:" >
        <CurrencyCell value={String(data?.totals?.total_invoiced)} />
      </LabelText>
      <LabelText label="Difference:" >
        <CurrencyCell value={String((data?.totals?.total_receivable ?? 0) - (data?.totals?.total_invoiced ?? 0))} />
      </LabelText>
    </DetailSection>
  ]) : undefined;

  useEffect(() => {
    if (!error && !isLoading && data && data.results) {
      if (data.scopes) {
        setScopes(data.scopes.reduce((acc: Scopes, curr: Scopes) => ({...acc, ...curr}), {} as Scopes));
        setButtons(data.scopes.map(sMap => Object.entries(sMap).map(([scope, title]) => {
          let scope_count = 0;
          if(data.scope_counts != null) {
            scope_count = data.scope_counts[scope] ?? 0;
          }
          return {
            title: `${title} (${scope_count})`,
            key: scope,
          }
        })));
      }
    } else if (error) {
      addToast('Error while loading data', 'error');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, error, isLoading]);

  useEffect(() => {
    if (data && data.results) {
        setCourses(data.results);
    }
  }, [data]);

  const setFilterBy = (filterBy: { operator: string, field: string, value: string }[]) => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        filterBy,
      }
    }))
  };

  return (
    <div className="py-2">
      <SFCTable
        name={`${scopes[filters?.scope!] ?? ''} Sessions`}
        columns={sessionColumns.concat(['needs_invoice', 'over_invoiced'].includes(filters?.scope ?? '') ? sessionInvoiceColumns : sessionOtherColumns)}
        data={courses}
        count={data?.count}
        defaultScope={defaultScope}
        scopes={buttons}
        isLoading={isLoading}
        isFetching={isFetching}
        sidebars={sidebars}
        indexDownloadPath='courses'
        searchbarActive={false}
        page_Size={20}
        defaultSort={
          {
            id: ['needs_invoice', 'over_invoiced', 'with_waitlist'].includes(filters?.scope ?? '') ? "ends_on" : "starts_on", 
            desc: true
          }}
        expandedItem={{
          columns: simpleScheduleColumns,
          rowKey: 'lessons',
          filterFn: ((lesson: Lesson) => UTCToDate(`${lesson.date} ${lesson.starts_at}`, 'yyyy-MM-dd h:mm a') >= dateInTimeZone())
        }}
        filterForm={(
          <FilterForm
            fields={filterFormFields} 
            onApplyFilters={setFilterBy}
          />
        )}
        onFiltersChanged={setFilters}
        newItemEl={userCanEditCreateDelete_Sessions(currentUser) && (
          <SimpleButton
            variant="sfc-blueberry"
            height={40}
            width={250}
            onClick={() => navigate('/admin/schedule/sessions/new')}
          >
            New session
          </SimpleButton>
        )}
      />
    </div>
  );
}
