import {
  Container,
  Image,
  Button,
} from "react-bootstrap";
import Banana from "../../../assets/images/Banana.png";
import Grapes from "../../../assets/images/Grapes.png";
import Coconut from "../../../assets/images/Coconut.png";
import Orange from "../../../assets/images/Orange-Slice.png";
import Onion from "../../../assets/images/Yellow-Onion.png";
import BellPepper from "../../../assets/images/Bell-Pepper.png";
import Mushroom from "../../../assets/images/Mushroom.png";
import Avocado from "../../../assets/images/Avocado-Half.png";

export const SFCCommunity = () => {
  return (
    <Container className="pb-5 mt-5">
    <div className="w-100 d-flex community-content">
      <div className="community-text align-content-center ">
        <p className="text-uppercase mt-5 text-weight-700 letter-spacing-min text-blue">
          sfc in your community
        </p>
        <h2 className="my-3  text-weight-700 text-blue">
          Spread Good Food and Cheer
        </h2>
        <p className="">
          Give the kids in your community the opportunity to learn how to
          make and enjoy tasty, healthy, fun food! Contact us today to bring
          Sticky Fingers Cooking to your library, camp, church, or troop.
          <span className="d-block mt-4">
            Please provide contact information for your organization’s
            enrichment coordinator. We look forward to cooking with you!{" "}
          </span>
        </p>
        <Button className="text-uppercase button-radius mb-5  ">
          contact us
        </Button>
      </div>

      <div className="community-imgs my-5 d-flex flex-wrap align-content-center offset-min">
        <div className="card-fruit-container">
          <div className="card-fruit-text">
            <h5>No kitchen required</h5>
            <div className="text-data">
              All we need is a room with an outlet and proximity to a sink.
              We provide everything else!
            </div>
          </div>
          <Image
            className="card-fruit"
            src={Banana}
            alt="sticky fingers cooking banana graphic"
          />
        </div>
        <div className="card-fruit-container">
          <div className="card-fruit-text">
            <h5>Flexible scheduling</h5>
            <div className="text-data">Sessions are between six and 14 weeks. You choose the day, times and frequency!</div>
          </div>
          <Image
            className="card-fruit "
            src={Grapes}
            alt="sticky fingers cooking banana graphic"
          />
        </div>
        <div className="card-fruit-container">
          <div className="card-fruit-text">
            <h5>100% school safety compliant</h5>
            <div className="text-data">All of our instructors have background checks, fingerprinting and insurance.</div>
          </div>
          <Image
            className="card-fruit"
            src={Coconut}
            alt="sticky fingers cooking banana graphic"
          />
        </div>
        <div className="card-fruit-container">
          <div className="card-fruit-text">
            <h5>Easy class registration</h5>
            <div className="text-data">We offer free online enrollment, payment, collection, and class roster organization.</div>
          </div>
          <Image
            className="card-fruit"
            src={BellPepper}
            alt="sticky fingers cooking banana graphic"
          />
        </div>
        <div className="card-fruit-container">
          <div className="card-fruit-text">
            <h5>Materials included</h5>
            <div className="text-data">We provide all marketing materials to promote classes to your community.</div>
          </div>
          <Image
            className="card-fruit"
            src={Avocado}
            alt="sticky fingers cooking banana graphic"
          />
        </div>
        <div className="card-fruit-container">
          <div className="card-fruit-text">
            <h5>No cost to schools</h5>
            <div className="text-data">Tuition ranges from $16.50 to $22.50 per class, per child, paid by parents or guardians.</div>
          </div>
          <Image
            className="card-fruit"
            src={Orange}
            alt="sticky fingers cooking banana graphic"
          />
        </div>
        <div className="card-fruit-container">
          <div className="card-fruit-text">
            <h5>Discounts and scholarships</h5>
            <div className="text-data">Discounts are available to parents who volunteer in class. Limit two kids per family.</div>
          </div>
          <Image
            className="card-fruit"
            src={Mushroom}
            alt="sticky fingers cooking banana graphic"
          />
        </div>
        <div className="card-fruit-container">
          <div className="card-fruit-text">
            <h5>Online recipe collection</h5>
            <div className="text-data">Every family has access to our tasty online recipe collection for at home family cooking fun.</div>
          </div>
          <Image
            className="card-fruit"
            src={Onion}
            alt="sticky fingers cooking banana graphic"
          />
        </div>
      </div>
    </div>
  </Container>
  );
};