import { Modal, Row, Col, Button } from "react-bootstrap";
import Login from "../../features/user/Login";
import { Image } from "react-bootstrap";
import SFCLogoMD from "../../assets/images/sticky-fingers-logo-stacked-2.png";
import "./LoginModal.css";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../services/hooks";
import { hideModal } from "../../services/slicers/modalSlice";
interface VideoModallProps {
  show: boolean;
  urlVideo:string;
  onHide:any;

}

const VideoModal: React.FC<VideoModallProps> = (props:any) => {
  const dispatch = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();

  const handleClose = () => {
    dispatch(hideModal());
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <iframe width="100%" height="615" src={props.urlVideo}></iframe>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VideoModal;
