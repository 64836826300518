import { ColumnDef } from '@tanstack/react-table';
import { LinkCell } from '../../../components/tables/cellComponents/LinkCell';
import { Stack } from 'react-bootstrap';
import { DateCell } from '../../../components/tables/cellComponents/DateCell';
import { Feedback, FranchiseeLead, Gallery, NewsItem, Page, Snippet, TeamProfile } from '../../../types/website.types';
import { getPartition } from '../../utils';
import YesNoCell from '../../../components/tables/cellComponents/YesNoCell';
import ImageWithErrorHandling from '../../../components/image/ImageWithErrorHandling';
import { getCurrentUser } from '../../../services/helper';
import { userCanEditCreateDelete_NewsItems } from '../../../features/dash/views/website/NewsItems/NewsItemsView';
import { userCanEditCreateDelete_Pages } from '../../../features/dash/views/website/Pages/PagesView';
import { userCanEditCreateDelete_Snippets } from '../../../features/dash/views/website/Snippets/SnippetsView';
import { userCanEditCreateDelete_TeamProfiles } from '../../../features/dash/views/website/TeamProfiles/TeamProfilesView';
import { userCanEditCreateDelete_Feedbacks } from '../../../features/dash/views/website/Feedbacks/FeedbacksView';
import { userCanEditCreateDelete_Galleries } from '../../../features/dash/views/website/Galleries/GalleriesView';

const currentUser = getCurrentUser();

export const newsItemColumns: ColumnDef<NewsItem>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
    cell: ({ getValue }) => <LinkCell content={getValue<number>()} url={`/admin/website/news-items/${getValue<number>()}`} />
  },
  {
    header: 'Image',
    accessorKey: 'image_file_name',
    cell: ({ row }) => {
      let resource_id = getPartition(row.original.id);
      let url = `${row.original.s3_base_url ?? ''}/${resource_id}/${row.original.image_file_name}`;
      let content = <ImageWithErrorHandling src={url ?? ""} alt={row.original.image_file_name} />;
      return <LinkCell content={content} url={`/admin/website/news-items/${row.original.id}`} />;
    }
  },
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Published On',
    accessorKey: 'published_on',
    cell: ({ getValue }) => (
      <DateCell date={getValue<string>()} format={'MM/dd/yyyy'} />
    )
  },
  {
    header: () => null,
    id: 'actions',
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell content="View" url={`/admin/website/news-items/${row.original.id}`} underline />

        {userCanEditCreateDelete_NewsItems(currentUser) && (
          <>
            <LinkCell content="Edit" url={`/admin/website/news-items/${row.original.id}/edit`} underline />
            <LinkCell content="Delete" url={`/admin/website/news-items/${row.original.id}/delete`} underline />
          </>
        )}
      </Stack>
    )
  },
];

export const pageColumns: ColumnDef<Page>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
    cell: ({ getValue }) => <LinkCell content={getValue<number>()} url={`/admin/website/pages/${getValue<number>()}`} />
  },
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Image',
    accessorKey: 'image_file_name',
    enableSorting: false,
    cell: ({ row }) => {
      let resource_id = getPartition(row.original.id);
      let url = `${row.original.s3_base_url ?? ''}/${resource_id}/${row.original.image_file_name}`;
      let content = <ImageWithErrorHandling src={url ?? ""} alt={row.original.image_file_name} />;
      return <LinkCell content={content} url={`/admin/website/pages/${row.original.id}`} />;
    }
  },
  {
    header: 'Slug',
    accessorKey: 'slug',
  },
  {
    header: 'Is Featured',
    accessorKey: 'is_featured',
    cell: ({ getValue }) => <YesNoCell value={getValue<boolean>()} />
  },
  {
    header: () => null,
    id: 'actions',
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell content="View" url={`/admin/website/pages/${row.original.id}`} underline />

        {userCanEditCreateDelete_Pages(currentUser) && (
          <>
            <LinkCell content="Edit" url={`/admin/website/pages/${row.original.id}/edit`} underline />
            <LinkCell content="Delete" url={`/admin/website/pages/${row.original.id}/delete`} underline />
          </>
        )}
      </Stack>
    )
  },
];

export const snippetColumns: ColumnDef<Snippet>[] = [
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: () => null,
    id: 'actions',
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell content="View" url={`/admin/website/snippets/${row.original.id}`} underline />
        {userCanEditCreateDelete_Snippets(currentUser) && (
          <>
            <LinkCell content="Edit" url={`/admin/website/snippets/${row.original.id}/edit`} underline />
            <LinkCell content="Delete" url={`/admin/website/snippets/${row.original.id}/delete`} underline />
          </>
        )}
      </Stack>
    )
  },
];

export const teamProfileColumns: ColumnDef<TeamProfile>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
    cell: ({ getValue }) => <LinkCell content={getValue<number>()} url={`/admin/website/team-profiles/${getValue<number>()}`} />
  },
  {
    header: 'Image',
    accessorKey: 'image_file_name',
    cell: ({ row }) => {
      let resource_id = getPartition(row.original.id);
      let url = `${row.original.s3_base_url ?? ''}/${resource_id}/${row.original.image_file_name}`;
      let content = <ImageWithErrorHandling src={url ?? ""} alt={row.original.image_file_name} />;
      return <LinkCell content={content} url={`/admin/website/team-profiles/${row.original.id}`} />;
    }
  },
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Title',
    accessorKey: 'title',
  },
  {
    header: 'Location',
    accessorKey: 'location_id',
    cell: ({ row }) => <LinkCell content={row.original.location?.title} url={`/admin/places/locations/${row.original.location_id}`} />
  },
  {
    header: () => null,
    id: 'actions',
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell content="View" url={`/admin/website/team-profiles/${row.original.id}`} underline />
        
        {userCanEditCreateDelete_TeamProfiles(currentUser) && (
          <>
            <LinkCell content="Edit" url={`/admin/website/team-profiles/${row.original.id}/edit`} underline />
            <LinkCell content="Delete" url={`/admin/website/team-profiles/${row.original.id}/delete`} underline />
          </>
        )}
      </Stack>
    )
  },
];

export const feedbackColumns: ColumnDef<Feedback>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
    cell: ({ getValue }) => <LinkCell content={getValue<number>()} url={`/admin/website/feedbacks/${getValue<number>()}`} />
  },
  {
    header: 'First Name',
    accessorKey: 'first_name',
  },
  {
    header: 'Last Name',
    accessorKey: 'last_name',
  },
  {
    header: 'Location',
    accessorKey: 'location',
  },
  {
    header: 'Email',
    accessorKey: 'email',
  },
  {
    header: 'Phone Number',
    accessorKey: 'phone_number',
  },
  {
    header: 'Message',
    accessorKey: 'message',
  },
  {
    header: 'Created At',
    accessorKey: 'created_at',
    cell: ({ getValue }) => (
      <DateCell date={getValue<string>()} format={'MM/dd/yyyy'} />
    )
  },
  {
    header: 'Updated At',
    accessorKey: 'updated_at',
    cell: ({ getValue }) => (
      <DateCell date={getValue<string>()} format={'MM/dd/yyyy'} />
    )
  },
  {
    header: () => null,
    id: 'actions',
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell content="View" url={`/admin/website/feedbacks/${row.original.id}`} underline />

        {userCanEditCreateDelete_Feedbacks(currentUser) && (
          <>
            <LinkCell content="Edit" url={`/admin/website/feedbacks/${row.original.id}/edit`} underline />
            <LinkCell content="Delete" url={`/admin/website/feedbacks/${row.original.id}/delete`} underline />
          </>
        )}
      </Stack>
    )
  },
];

export const franchiseeLeadsColumns: ColumnDef<FranchiseeLead>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
    cell: ({ getValue }) => <LinkCell content={getValue<number>()} url={`/admin/website/franchisee-leads/${getValue<number>()}`} />
  },
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Email',
    accessorKey: 'email',
  },
  {
    header: 'Phone Number',
    accessorKey: 'phone_number',
  },
  {
    header: 'Region',
    accessorKey: 'region',
  },
  {
    header: 'Created At',
    accessorKey: 'created_at',
    cell: ({ getValue }) => (
      <DateCell date={getValue<string>()} format={'MM/dd/yyyy'} />
    )
  },
  {
    header: 'Updated At',
    accessorKey: 'updated_at',
    cell: ({ getValue }) => (
      <DateCell date={getValue<string>()} format={'MM/dd/yyyy'} />
    )
  },
  {
    header: () => null,
    id: 'actions',
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell content="View" url={`/admin/website/franchisee-leads/${row.original.id}`} underline />
        <LinkCell content="Edit" url={`/admin/website/franchisee-leads/${row.original.id}/edit`} underline />
        <LinkCell content="Delete" url={`/admin/website/franchisee-leads/${row.original.id}/delete`} underline />
      </Stack>
    )
  },
];

export const galleryColumns: ColumnDef<Gallery>[] = [
  {
    header: 'ID',
    accessorKey: 'id',
    cell: ({ getValue }) => <LinkCell content={getValue<number>()} url={`/admin/website/galleries/${getValue<number>()}`} />
  },
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: () => null,
    id: 'actions',
    enableSorting: false,
    cell: ({ row }) => (
      <Stack direction="horizontal" gap={2}>
        <LinkCell content="View" url={`/admin/website/galleries/${row.original.id}`} underline />

        {userCanEditCreateDelete_Galleries(currentUser) && (
          <>
            <LinkCell content="Edit" url={`/admin/website/galleries/${row.original.id}/edit`} underline />
            <LinkCell content="Delete" url={`/admin/website/galleries/${row.original.id}/delete`} underline />
          </>
        )}
      </Stack>
    )
  },
];