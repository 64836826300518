import { useEffect, useState } from "react";
import { useGetFavoriteCoursesQuery } from "../../services/endpoints/schedule/course";
import { Course } from "../../types/course.type";
import { GetQueryParams } from "../../types/api.type";
import SessionCard from "./SessionCard";
import { Col, Form, Row, Stack } from "react-bootstrap";
import { Recipe } from "../../types/lessonPlan.type";
import { useGetFavoriteRecipeQuery } from "../../services/endpoints/content/recipe";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import RecipesGrid from "../home/recipes/RecipesGrid";
interface FavoritesProps {}

const Favorites: React.FC<FavoritesProps> = ({}) => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  // const [skipRecipes, setSkipRecipes] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);

  const [pageSize, setPageSize] = useState(5);
  const [pageSizeRecipes, setPageSizeRecipes] = useState(9);
  const [filters, setFilters] = useState<GetQueryParams>({
    data: {
      entries: pageSize,
    },
  });
  const [filtersRecipes, setFiltersRecipes] = useState<GetQueryParams>({
    data: {
      entries: pageSizeRecipes,
    },
  });
  const { data, refetch } = useGetFavoriteCoursesQuery(filters);
  const { data:dataRecipes } = useGetFavoriteRecipeQuery(filtersRecipes);
//Courses
  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      data: {
        ...prev!.data,
        entries: pageSize,
      },
    }));
  }, [pageSize]);

  useEffect(() => {
    if (data?.results) {
      setCourses(data?.results);
    }
  }, [data]);

  let numPages =
    data?.count !== 0 ? Math.ceil(data?.count! / data?.entries!) : 1;
  if (isNaN(numPages)) {
    numPages = 1;
  }

//Recipes
useEffect(() => {
  setFilters((prev) => ({
    ...prev,
    data: {
      ...prev!.data,
      entries: pageSizeRecipes,
    },
  }));
}, [pageSizeRecipes]);

  useEffect(() => {
    let count = 0;
    // if (!skipRecipes) {
      if (dataRecipes?.results) {
        setRecipes(dataRecipes?.results);
      }
      if (dataRecipes?.count) {
        count += dataRecipes?.count;
      // }
  }
  setCount(count);

  }, [recipes]);
let numPagesRecipes =
dataRecipes?.count !== 0 ? Math.ceil(dataRecipes?.count! / dataRecipes?.entries!) : 1;
  if (isNaN(numPagesRecipes)) {
    numPagesRecipes = 1;
  }
  return (
    <div className="container-fluid">
      <Tabs
      defaultActiveKey="Courses"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="Courses" title="Courses">
      {courses.map((course, index) => (
        <SessionCard
          key={index}
          course={course}
          edit={() => {}}
          updateFavorites={refetch}
          isFavoritePage={true}
        />
      ))}
      <hr />
      <Row className="align-items-center p-lg-4 my-3 my-lg-0">
        <Col xs={12}>
          <Stack direction="horizontal" gap={3}>
            <span
              className="fw-normal ms-2 ms-lg-0"
              style={{ fontSize: ".875rem", color: "#344054" }}
            >
              Viewing:
            </span>
            <Form.Select
              id="count"
              value={pageSize}
              onChange={(e) => setPageSize(+e.target.value)}
              style={{
                width: 39,
                height: 24,
                fontSize: ".875rem",
                color: "#344054",
              }}
              className="px-1 py-0 pagination"
            >
              {[5, 10, 20, 50].map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </Form.Select>
            <span
              className="d-none d-lg-block"
              style={{ fontSize: ".875rem", color: "#344054" }}
            >
              {`Page ${data?.page ? data.page : 1} of ${numPages}`}
            </span>
          </Stack>
        </Col>
      </Row>
      </Tab>
      <Tab eventKey="Recipes" title="Recipes">
      <RecipesGrid data={[...recipes]} count={count} />
      </Tab>
      
    </Tabs>
    
    </div>
    
  );
};

export default Favorites;
