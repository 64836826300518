// Need to use the React-specific entry point to import createApi
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { authHeaderToken } from "./auth-header";
import { logoutUser } from './helper';

const host = 'localhost';
// const host = 'sfc-dev.net';
// const port = 3000;
// const baseUrl = `http://${host}${port ? ":"+port : ""}/api/`;
// const baseUrl = `/api/`;
const baseUrl = `https://testing.stickyfingerscooking.com/api/`

const baseQuery = fetchBaseQuery({ 
  baseUrl, 
  prepareHeaders: (headers) => {
      const token = authHeaderToken();
      if (token) {
          headers.set('x-access-token', token);
          headers.set('Accept', 'application/json')
      }
      return headers;
  }
})

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
   logoutUser();
  }
  return result
}

// Define a service using a base URL and expected endpoints
export const sfcApi = createApi({
  reducerPath: 'sfcApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Admins', 'Instructors', 'Parent', 'Child', 'Allergy', 'Waiver', // people
    'Course', 'Lesson', 'LessonAssignments', // schedule
    'CurrentEntries', 'TimesheetOverrides', // timesheets
    'Venue', 'Location', 'Organization', 'ZipCode', 'Territory', // places
    'Invoice', 'InvoiceItem', 'InvoiceClient', 'Receipt', 'Payment', 'Expense', 'Enrollment','CurrentEntries','PowerBI','Invoicer','StripeKey','Intent','CreatePayment', // finance
    'Order', 'Scholarship', 'Discount', 'PromotionalCode', 'Refund','Waitlist', // orders
    'Recipe', 'Documents', 'Themes', 'Joke', 'JokeCategory', 'FeaturedIngredients', 'CountryFacts', 'FoodHistories', 'KitchenSkills', 'Questions', // Content
    'Guides', 'Steps', 'TrainingVideos', // Training
    'NewsItem', 'Feedback', 'FranchiseeLead', 'Gallery', 'Snippet', 'TeamProfile', 'Page', //websites
    'Registrations', 'Testimonial' ], //websites
  endpoints: () => ({}),
});

type DownloadIndexParams = { 
  method?: 'GET'|'POST';
  format: 'json'|'csv'|'xlsx'|'pdf'; 
  path: string; 
  body?: any; 
  scope?: string;
  filename?: string;
};

export const downloadIndex = async (params: DownloadIndexParams) => {
  const token = authHeaderToken();
  let headers = new Headers({
    "Content-Type": "application/json",
  });
  if (token) {
      headers.set('x-access-token', token);
  }

  let url = baseUrl + params.path + `${params?.scope ? `?scope=${params.scope}` : ''}${params?.format && params?.format !== 'pdf' ? `${params?.scope ? '&' : '?'}format=${params.format}` : ''}`
  try {
    const response = await fetch(url, {
      headers,
      method: params.method ?? 'POST',
      body: (params.method === 'POST') ? JSON.stringify(params.body) : undefined,
    });
  
    let filename = `${params.filename ?? params.path}.${params.format}`;

    if (response.status !== 200) {
      console.log(await response.json());
      return null;
    }
  
    response.blob()
    .then(blob => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
    
  } catch (error) {
    console.log(error);
  }

  return null;
}


type DownloadInvoiceParams = {
  path: string; 
}
export const downloadInvoice = async (params: DownloadInvoiceParams) => {
  try {
  const token = authHeaderToken();
  let headers = new Headers({
    "Content-Type": "application/json",
  });
  if (token) {
      headers.set('x-access-token', token);
  }

  let url = baseUrl + params.path;
  
    const response = await fetch(url, {
      headers
    });

    if (!response.ok) {
      throw new Error('Failed to fetch the PDF');
    }

    // Get the Blob data
    const blob = await response.blob();

    // Create a URL for the Blob and open it in a new window
    const openurl = URL.createObjectURL(blob);
    window.open(openurl, '_blank');
  } catch (error) {
    // addToast('Error while fetching the PDF: ' + error.message, 'error');
    alert('Error while fetching the PDF: ' + error.message);
  }

  return null;
}